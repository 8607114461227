<template>
    <div class="shipment-tabs-table-wrapper-component" v-resize="onResize">
        <div class="default-view" v-if="currentViewTab === 0">
            <!-- FOR DESKTOP -->
            <v-data-table 
                :headers="computedHeadersShipment" 
                :items="getCurrentShipmentsData"
                :items-per-page="getShipmentItemsPerPage"
                item-key="name"
                class="shipments-table"
                :id="getCurrentShipmentsData.length !== 0 ? '' : 'table-no-data'"
                v-bind:class="{
                    'no-data-table': (typeof getCurrentShipmentsData !== 'undefined' && getCurrentShipmentsData.length === 0),
                }"
                hide-default-footer
                style="box-shadow: none !important"
                @page-count="pageCount = $event"
                fixed-header
                hide-default-header
                v-if="!isShipmentMobile"
                ref="my-table"> <!-- mobile-breakpoint="769" -->
                <!-- :page="paginationSearchedModel"                
                'no-current-pagination' : getCurrentPaginationCountClass(),                 
                'has-searched-data' : search !== '' && getCurrentShipmentsData.length === 0 -->

                <template v-slot:header="{ props: { headers } }">
                    <thead>
                        <tr>
                            <th v-for="(item, index) in headers" 
                                :key="index"
                                role="columnheader"
                                :aria-label="item.text"
                                scope="col"
                                v-bind:class="
                                    [item.sortable ? 'sortable' : '',
                                    item.align == ' d-none' ? 'd-none' : `text-${item.align}`]"
                                @click="item.sortable ? changeSort(item.field) : ''"
                                v-bind:style="`width: ${item.width}; min-width: ${item.width};`">

                                {{ item.text }}
                                <!-- <v-icon v-if="item.sortable" small>{{ shipmentIcon }}</v-icon> -->
                            </th>
                        </tr> 
                    </thead>
                </template>

                <template v-slot:no-data>
					<div class="no-data-preloader" v-if="isShipmentStillFetching">
						<v-progress-circular :size="30" color="#1A6D9E" indeterminate>
						</v-progress-circular>
					</div>
					<div class="no-data-wrapper"
						v-if="!isShipmentStillFetching && getCurrentShipmentsData.length === 0">
						<div class="no-data-icon">
							<img src="@/assets/icons/noShipmentData.svg" alt="" width="65px" />
						</div>

						<div class="no-data-heading" v-if="search === ''">
							<p v-if="activeShipmentTab === 1">
								<span v-if="activeShipmentSubtab === 0">
									No Shipments
								</span>
								<span v-if="activeShipmentSubtab === 1">
									No Awaiting Departure Shipments
								</span>
								<span v-if="activeShipmentSubtab === 2">
									No In Transit Shipments
								</span>
								<span v-if="activeShipmentSubtab === 3">
									No Arrived Shipments
								</span>
								<span v-if="activeShipmentSubtab === 4">
									No Picked Up Shipments
								</span>
								<span v-if="activeShipmentSubtab === 5">
									No Requires Attention Shipments
								</span>
							</p>
						</div>
						<div class="no-data-heading" v-if="search !== ''">
							<p> No matches found. Try a different keyword. </p>
						</div>
					</div>
				</template>

                <template v-slot:item="props">
                    <tr class="shifl-shipment-row" :data-id="props.item.id" :id="props.item.id">
                        <!-- :class="itemRowBackground(props.item)" -->
                        <td class="shifl-shipment-reference">
                            <div class="departure-content-wrapper">
                                <div class="flag-wrapper">
                                    <p style="margin-bottom: 0px"> {{ props.item.shifl_ref }} </p>
                                </div>

                                <div class="d-flex reference-column gap-1" @mouseenter="detectLineClamp(props.item, props.item.id, 'reference')">
                                    <!-- <p :id="`td-reference-content-${props.item.id}`"
                                        style="color: #69758C;" class="reference-number"
                                        v-if="props.item.customer_reference_number !== 'null' &&
                                            props.item.customer_reference_number !== null &&
                                            props.item.customer_reference_number !== '' && 
                                            props.item.customer_reference_number !== 'undefined'">
                                        {{ props.item.customer_reference_number }}
                                    </p> -->
                                    
                                    <CalendarTooltip :data="props.item" isFor="event-info-inner-content" :isShowTooltip.sync="isTruncatedText" :nudge="false">
                                        <template slot="tooltip-main-data" slot-scope="{ on, attrs }">
                                            <div v-on="on" v-bind="attrs" :id="`td-reference-content-${props.item.id}`" class="reference-number">
                                                <p style="color: #69758C;" v-if="props.item.customer_reference_number !== null">
                                                    {{ props.item.customer_reference_number }}
                                                </p>
                                            </div>
                                        </template>

                                        <template slot="tooltip-data">
                                            <div class="tooltip-data-destination mb-2 mt-1">
                                                <span class="font-semi-bold tooltip-title">Customer Reference Number:</span>
                                                <p class="mb-0 mt-1 font-medium" style="font-size: 11px;">
                                                    {{ props.item.customer_reference_number }}
                                                </p>
                                            </div>
                                        </template>
                                    </CalendarTooltip>
                                    
                                    <p class="mb-0" v-if="props.item.consolidation_status == 2">
                                        <span class="consolidatation-status">
                                            {{ props.item.consolidation_status == 2 ? "Consolidated" : "" }}
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </td>

                        <td class="shifl-shipment-location-etd">
                            <div class="departure-content-wrapper">
                                <div class="flag-wrapper">
                                    <p style="margin-bottom: 0px">
                                        {{ props.item.location_from_name !== null ? props.item.location_from_name : 'N/A' }}
                                    </p>
                                </div>

                                <div>
                                    <p style="color: #69758C;">
                                        {{ convertDateAction(props.item.etd) }}
                                    </p>
                                </div>
                            </div>
                        </td>

                        <td class="shifl-shipment-type">
                            <div class="shifl-shipment-type-content d-flex align-center">
                                <div class="shipment-modes d-flex align-center mr-2" 
                                    v-if="props.item.mode !== null && props.item.mode !== ''">
                                    <img :src="getImgUrl(props.item.mode, 'mode')" 
                                        height="20px" 
                                        width="22px">
                                </div>
                                <div class="shipment-type">
                                    <div class="shipment-type-content d-flex align-center">
                                        <img :src="getImgUrl(props.item.shipment_type, 'shipment_type')" 
                                            height="20px" 
                                            width="20px"
                                            v-if="props.item.shipment_type !== null && props.item.shipment_type !== '' && props.item.shipment_type !== 'null'">

                                        <span v-else> N/A </span>
                                    </div>

                                    <span style="padding-bottom: 2px;" 
                                        v-if="props.item.shipment_type == 'FCL' && props.item.containers_qty !== 0"> 
                                        ({{ props.item.containers_qty }})
                                    </span>
                                </div>
                            </div>
                            
                            <div class="shipment-type" v-if="
                                props.item.is_shipment_tracking_create === 1 && 
                                props.item.containers_qty > 0 && 
                                props.item.shipment_type === null">
                                
                                <img src="@/assets/images/big-container.svg" />
                                <span style="padding-bottom:3px;">({{ props.item.containers_qty }})</span>
                            </div>
                        </td>

                        <td class="shifl-shipment-location-eta">
                            <div class="arrival-content-wrapper">
                                <div class="flag-wrapper">
                                    <p style="margin-bottom: 0px">
                                        {{ props.item.location_to_name !== null ? props.item.location_to_name : 'N/A' }}
                                    </p>
                                </div>
                                <p style="color: #69758C;">
                                    {{ convertDateAction(props.item.eta) }}
                                </p>
                            </div>
                        </td>

                        <td class="shifl-shipment-containers">
                            <div v-if="props.item.containers_qty > 0">
                                <v-menu open-on-hover bottom offset-y 
                                    v-if="props.item.containers_qty > 1" 
                                    open-delay="200"
                                    close-delay="200"
                                    content-class="shipment-containers-menu"
                                    :close-on-click="false"
                                    :close-on-content-click="false"
                                    :nudge-top="40"
                                    :nudge-left="10">

                                    <template v-slot:activator="{ on, attrs }">
                                        <div class="container-num-desktop d-flex justify-start" v-on="on" v-bind="attrs">
                                            {{ props.item.containers[0].container_number }}
                                            <span style="color: #1A6D9E;" v-if="props.item.containers_qty > 1">,</span>
                                            <p style="color: #1A6D9E;" class="ml-1"> +{{ props.item.containers_qty - 1}} </p>
                                        </div>
                                    </template>

                                    <div class="tooltip-data-destination mb-2 mt-1" @mouseleave="onMouseLeaveContainer">
                                        <span class="font-semi-bold tooltip-title"></span>
                                        <div class="mb-0 mt-1 font-medium" style="font-size: 11px;">
                                            <p v-for="(cont, index) in props.item.containers" :key="index" class="mb-0">
                                                {{ cont.container_number }}
                                            </p>
                                        </div>
                                    </div>
                                </v-menu>

                                <div v-else-if="props.item.containers_qty === 1">
                                    {{ props.item.containers[0].container_number }}
                                </div>
                            </div>
                            <span v-else class="no-data-text">No data</span>
                        </td>

                        <td class="shifl-shipment-suppliers" @mouseenter="detectLineClamp(props.item, props.item.id, 'supplier')">
                            <CalendarTooltip :data="props.item" isFor="event-info-inner-content" :isShowTooltip.sync="isTruncatedText" :nudge="false">
                                <template slot="tooltip-main-data" slot-scope="{ on, attrs }">
                                    <div class="supplier-desktop" v-on="on" v-bind="attrs" :id="`td-supplier-content-${props.item.id}`"
                                        :class="activeShipmentTab == 0 ? 'active-tab' : ''">
                                        <span>{{ checkSuppliersName(props.item.suppliers_names) }}</span>

                                        <span v-if="props.item.suppliers_names === null || props.item.suppliers_names.length == 0">
                                            <span>N/A</span>
                                        </span>
                                    </div>
                                </template>

                                <template slot="tooltip-data">
                                    <div class="tooltip-data-destination mb-2 mt-1">
                                        <span class="font-semi-bold tooltip-title">Suppliers:</span>
                                        <p class="mb-0 mt-1 font-medium" style="font-size: 11px;">
                                            {{ checkSuppliersName(props.item.suppliers_names) }} 
                                        </p>
                                    </div>
                                </template>
                            </CalendarTooltip>
                        </td>

                        <td class="shifl-shipment-po" @mouseenter="detectLineClamp(props.item, props.item.id, 'po')">
                            <CalendarTooltip :data="props.item" isFor="event-info-inner-content" :isShowTooltip.sync="isTruncatedText" :nudge="false">
                                <template slot="tooltip-main-data" slot-scope="{ on, attrs }">
                                    <div class="po-num-desktop" v-on="on" v-bind="attrs">
                                        <p :id="`td-po-content-${props.item.id}`">
                                            {{ props.item.po_num.join(", ") }}
                                        </p>
                                    </div>

                                    <div v-if="props.item.po_num == null || props.item.po_num.length == 0">
                                        <p> N/A </p>
                                    </div> 
                                </template>

                                <template slot="tooltip-data">
                                    <div class="tooltip-data-destination mb-2 mt-1">
                                        <span class="font-semi-bold tooltip-title">POs:</span>
                                        <p class="mb-0 mt-1 font-medium" style="font-size: 11px;">
                                            <span v-for="(name, index) in props.item.po_num" :key="index">
                                                {{ name }}<template v-if="index + 1 < props.item.po_num.length">, </template>
                                            </span>
                                        </p>
                                    </div>
                                </template>
                            </CalendarTooltip>
                        </td>

                        <td v-if="activeShipmentSubtab === 1"> <!-- for cut off date -->
                            {{ convertDateAction(props.item.cut_off_date) }}
                        </td>

                        <td v-if="activeShipmentSubtab === 3 || activeShipmentSubtab === 4"> <!-- for lfd -->
                            {{ getLFDDate(props.item.containers) }}
                        </td>

                        <td class="shifl-shipment-status">
                            <div class="status d-flex" v-if="props.item.status !== null && props.item.tracking_status !== null">
                                <div class="d-flex t-shipment-status-wrapper">
                                    <!-- <div :class="getShipmentStatusClass(props.item, 'tracking')" 
                                        v-if="typeof props.item.tracking_status !== 'undefined' && props.item.tracking_status !== ''" class="font-medium pa-0">
                                        <v-chip>
                                            {{ props.item.tracking_status }}
                                        </v-chip>
                                    </div> -->
                                    <div style="margin-right: 2px !important;" class="font-medium" 
                                        v-if="props.item.status==='Partially discharged - released'">
                                        <v-chip text-color="#253041" class="pa-0 pr-1">Partial Discharged</v-chip>
                                        <v-chip text-color="#04783A" class="pa-0">- Released</v-chip>
                                    </div>
                                    <div style="margin-right: 2px; !important" class="font-medium" 
                                        v-else-if="props.item.status==='Partially discharged - hold'">
                                        <v-chip text-color="#253041" class="pa-0 pr-1">Partial Discharged</v-chip>
                                        <v-chip text-color="#B11B1B" class="pa-0">- Hold</v-chip>
                                    </div>
                                    <div class="font-medium pa-0" v-else :class="getShipmentStatusClass(props.item, 'default')">
                                        <v-chip> {{ ucFirst(props.item.status.toLowerCase()) }} </v-chip>
                                    </div>
                                </div>
                            </div>
                        </td>

                        <td class="shifl-shipment-actions pr-2">
                            <div class="shipment-action-button d-flex align-end justify-end mr-1">
                                <button class="btn-white" @click.stop="handleClick(props.item)">
                                    <img src="../../../../assets/icons/visibility-gray.svg" alt="" height="16px" width="16px">
                                </button>
                                <div class="three-dots-wrapper">
                                    <v-menu offset-y bottom left content-class="pending-dropdown-container">
                                        <template v-slot:activator="{ on, attrs }">
                                            <button v-bind="attrs" v-on="on" class="shipment-three-dots-container">
                                                <custom-icon
                                                    iconName="three-dots"
                                                    color="#1A6D9E"
                                                    width="11"
                                                    height="3"
                                                />
                                            </button>
                                        </template>
                                        <v-list>
                                            <v-list-item>
                                                <v-list-item-title class="k-fw-sm k-font-inter-regular k-dark-blue" @click="markShipmentCompletedDialog(props.item)">
                                                    Mark As Completed
                                                </v-list-item-title>
                                            </v-list-item>
                                            <!-- <v-list-item>
                                                <v-list-item-title class="k-fw-sm k-font-inter-regular">
                                                    Edit Shipment
                                                </v-list-item-title>
                                            </v-list-item> -->
                                            <!-- <v-list-item>
                                                <v-list-item-title class="k-fw-sm k-font-inter-regular k-red" @click="cancelRequestForm(props.item.id)">
                                                    Request Cancellation
                                                </v-list-item-title>
                                            </v-list-item> -->
                                        </v-list>
                                    </v-menu>
                                </div>
                            </div> 
                        </td>
                    </tr>
                </template>
            </v-data-table>

            <!-- FOR MOBILE -->
            <v-data-table 
                :headers="headersShipment" 
                :items="getCurrentShipmentsData"
                :items-per-page="getShipmentItemsPerPage"
                item-key="name"
                class="table-mobile shipments-table-mobile shipments-tab-header-mobile"
                v-bind:class="{
                    'no-data-table': (typeof getCurrentShipmentsData !== 'undefined' && getCurrentShipmentsData.length === 0),
                    'no-current-pagination' : getCurrentPaginationCountClass(),
                    'has-searched-data' : search !== '' && getCurrentShipmentsData.length === 0
                }"
                :id="getCurrentShipmentsData.length !== 0 ? '' : 'table-no-data'"
                hide-default-footer
                style="box-shadow: none !important"
                @page-count="pageCount = $event"
                @click:row="handleClick"
                v-if="isShipmentMobile"
                ref="my-table"> <!-- mobile-breakpoint="769" -->
                <!-- :page="paginationSearchedModel" -->
                
                <template v-slot:no-data>
                    <div class="no-data-preloader" v-if="getShipmentLoadingStatus">
                        <v-progress-circular
                            :size="30"
                            color="#1A6D9E"
                            indeterminate
                            v-if="getShipmentLoadingStatus">
                        </v-progress-circular>
                    </div>

                    <div v-if="!getShipmentLoadingStatus && getCurrentShipmentsData.length == 0" class="no-data-wrapper">
                        <div class="no-data-icon">
                            <img src="@/assets/icons/noShipmentData.svg" alt="" width="65px">
                        </div>

                        <div class="no-data-heading">
                            <p v-if="activeTab == 1">
                                <span v-if="search === ''">No Shipments</span>
                                <span v-else>No Shipments found. <br/> Try searching another keyword.</span>
                            </p>
                        </div>
                    </div>
                </template>

                <template v-slot:[`item.shifl_ref`]="{ item }">
                    <div class="table-mobile-data mobile-reference k-flex k-items-start">
                        <div class="mobile-reference-content k-flex k-flex-col k-items-start">
                            <span class="k-flex k-items-start">
                                <span class="mr-2">{{ item.shifl_ref }}</span>

                                <div class="mobile-mode d-flex align-center">
                                    <span class="mr-2 d-flex align-center">
                                        <img :src="getImgUrl(item.mode, 'mode')" height="18px"  width="20px">
                                    </span>

                                    <div class="d-flex align-center mr-1" 
                                        v-if="item.shipment_type !== null && item.shipment_type !== '' && item.shipment_type !== 'null'">                                    
                                        <img :src="getImgUrl(item.shipment_type, 'shipment_type')" 
                                            height="18px" 
                                            width="20px"
                                            v-if="item.shipment_type !== null && item.shipment_type !== '' && item.shipment_type !== 'null'">

                                        <span style="margin-left: 6px; font-weight: 500; font-size: 14px;" class="font-regular"
                                            v-if="item.shipment_type == 'FCL' && item.containers_qty !== 0"> 
                                            ({{ item.containers_qty }})
                                        </span>
                                    </div>
                                </div>
                            </span>
                        </div>

                        <div class="shipment-action-button d-flex align-end justify-end">
                            <div class="three-dots-wrapper">
                                <v-menu offset-y bottom left content-class="pending-dropdown-container">
                                    <template v-slot:activator="{ on, attrs }">
                                        <button v-bind="attrs" v-on="on" class="shipment-three-dots-container">
                                            <custom-icon
                                                iconName="three-dots"
                                                color="#1A6D9E"
                                                width="11"
                                                height="3"
                                            />
                                        </button>
                                    </template>
                                    <v-list>
                                        <v-list-item>
                                            <v-list-item-title class="k-fw-sm k-font-inter-regular" @click="handleClick(item)">
                                                View
                                            </v-list-item-title>
                                        </v-list-item>

                                        <v-list-item>
                                            <v-list-item-title class="k-fw-sm k-font-inter-regular k-dark-blue" @click="markShipmentCompletedDialog(item)">
                                                Mark As Completed
                                            </v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </div>
                        </div> 

                        <!-- static only for viewing purposes of todos
                        <div class="shipment-item-todos-mobile d-flex align-center justify-center" 
                            v-if="index !== 1 && index !== 2">
                            <img src="../../../../assets/icons/to-dos-blue.svg" alt="" height="12px" width="12px"> 
                            <span class="todos-count ml-2 font-medium" style="color: #1A6D9E;">(1)</span>
                        </div> -->
                    </div>
                </template>

                <template v-slot:[`item.location_from_name`]="{ item }">
                    <div class="table-mobile-data-content">
                        <div class="table-mobile-data mb-1">
                            <div class="mobile-departure-wrapper mobile-body-title">
                                <span style="color: #69758C;">Departure</span>
                            </div>

                            <div class="mobile-departure-wrapper mobile-body-data" style="text-align: left !important;">
                                <div class="flag-wrapper">
                                    <p style="margin-bottom: 0px;">
                                        <span> {{ item.location_from_name !== null ? item.location_from_name : 'N/A' }}
                                            <span class="departure-date ml-1">
                                                ({{ convertDateAction(item.etd) }})
                                            </span>
                                        </span>                                    
                                    </p>
                                </div>
                            </div>      
                        </div>

                        <div class="table-mobile-data mb-1">
                            <div class="mobile-arrival-wrapper mobile-body-title">
                                <span style="color: #69758C;">Arrival</span>
                            </div>

                            <div class="arrival-wrapper mobile-body-data" style="text-align: right !important;">
                                <div class="flag-wrapper">
                                    <p style="margin-bottom: 0px">
                                        <span> {{ item.location_to_name !== null ? item.location_to_name : 'N/A' }}                                            
                                            <span class="arrival-date ml-1">
                                                ({{ convertDateAction(item.eta) }})
                                            </span>
                                        </span>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="table-mobile-data mb-2">
                            <div class="mobile-supplier-wrapper mobile-body-title">
                                <span style="color: #69758C;">Supplier</span>
                            </div>

                            <div class="po-num-mobile mobile-body-data" style="margin-left: 10px !important;">
                                <div v-if="item.suppliers_names.length > 2" >
                                    <!-- <p>{{ item.suppliers_names[0] }},</p>
                                    <div class="d-flex align-center justify-end">
                                        <p class="mr-1 second-supplier-name">{{ item.suppliers_names[1] }},</p>
                                        <span v-if="item.suppliers_names.length > 2" style="color: #1A6D9E;">
                                            +{{ item.suppliers_names.length - 2 }} Other
                                        </span>
                                    </div> -->

                                    {{ checkSuppliersNameMobile(checkSuppliersName(item.suppliers_names), 50) }},
                                    <span style="color: #1A6D9E;">+{{ item.suppliers_names.length - 2 }} Other</span>
                                </div>
                                <div v-else>
                                    {{ checkSuppliersNameMobile(checkSuppliersName(item.suppliers_names), 55) }}
                                </div>
                            </div>

                            <div v-if="item.suppliers_names === null || item.suppliers_names.length == 0">
                                <span>N/A</span>
                            </div>
                        </div>

                        <div class="table-mobile-data mb-1">
                            <div class="mobile-pos-wrapper mobile-body-title">
                                <span style="color: #69758C;">POs</span>
                            </div>

                            <div class="po-num-mobile mobile-body-data">
                                <p>
                                    <span v-for="(name, index) in item.po_num" :key="index">
                                        <span v-if="index === 0 || index === 1">
                                            {{ name }}<template v-if="index + 1 < item.po_num.length">, </template>
                                        </span>
                                    </span>

                                    <span v-if="item.po_num.length > 2" style="color: #1A6D9E;">
                                        +{{ item.po_num.length - 2 }} Other
                                    </span>
                                </p>
                            </div>

                            <div v-if="item.po_num == null || item.po_num.length == 0">
                                <p> {{ item.is_tracking_shipment ? '' : 'N/A'}} </p>
                            </div>
                        </div>

                        <div class="table-mobile-data mb-1" v-if="shipmentCurrentTab === 1">
                            <div class="mobile-arrival-wrapper mobile-body-title">
                                <span style="color: #69758C;">Cut off Date</span>
                            </div>

                            <div class="arrival-wrapper mobile-body-data" style="text-align: right !important;">
                                <div class="flag-wrapper">
                                    <p style="margin-bottom: 0px">
                                        <span>N/A</span>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="table-mobile-data mb-1" v-if="shipmentCurrentTab === 3 || shipmentCurrentTab === 4">
                            <div class="mobile-arrival-wrapper mobile-body-title">
                                <span style="color: #69758C;">LFD</span>
                            </div>

                            <div class="arrival-wrapper mobile-body-data" style="text-align: right !important;">
                                <div class="flag-wrapper">
                                    <p style="margin-bottom: 0px">
                                        <span>N/A</span>
                                    </p>
                                </div>
                            </div>
                        </div>

                        <div class="table-mobile-data mb-1">
                            <div class="mobile-supplier-wrapper mobile-body-title">
                                <span style="color: #69758C;">Status</span>
                            </div>

                            <div class="status d-flex justify-end mobile-body-data" v-if="item.status !== null">
                                <div class="d-flex t-shipment-status-wrapper">
                                    <div class="font-medium pa-0 mr-0" :class="getShipmentStatusClass(item, 'default')">
                                        <v-chip> {{ ucFirst(item.status.toLowerCase()) }} </v-chip>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>

                <!-- backup code for showing the tracking status -->
                <template v-slot:[`item.mode`]="{ item }"> 
                    <div class="status d-flex" v-if="item.status !== null && item.tracking_status !== null">
                        <div class="d-flex t-shipment-status-wrapper">
                            <div :class="getShipmentStatusClass(item, 'tracking')" 
                                v-if="typeof item.tracking_status !== 'undefined' && item.tracking_status !== ''" class="font-medium pa-0">
                                <v-chip>
                                    {{ item.tracking_status }}
                                </v-chip>
                            </div>
                            <div style="margin-right: 2px !important;" class="font-medium" 
                                v-if="item.status==='Partially discharged - released'">
                                <v-chip text-color="#253041" class="pa-0 pr-1">Partial Discharged</v-chip>
                                <v-chip text-color="#04783A" class="pa-0">- Released</v-chip>
                            </div>
                            <div style="margin-right: 2px; !important" class="font-medium" 
                                v-else-if="item.status==='Partially discharged - hold'">
                                <v-chip text-color="#253041" class="pa-0 pr-1">Partial Discharged</v-chip>
                                <v-chip text-color="#B11B1B" class="pa-0">- Hold</v-chip>
                            </div>
                            <div class="font-medium pa-0" v-else :class="getShipmentStatusClass(item, 'default')">
                                <v-chip> {{ ucFirst(item.status.toLowerCase()) }} </v-chip>
                            </div>
                        </div>
                    </div>
                </template>
            </v-data-table>
        </div>

        <div class="calendar-view" v-if="currentViewTab === 1">
            <CalendarView 
                :isFilterEnabled="isFilterEnabled"
                @handleClick="handleClick"
                :currentCalendarType.sync="type"
                :currentDateCalendar.sync="currentDateCalendar"
                :tab="tab"
                :search.sync="search"
                :selectedDefaultFilter.sync="selectedCalendarFilter"
                :selectedCalendarOption="selectedCalendarOption"
                :isAllSelected="isAllSelected"
                :isCalendarFilterSelectionEmpty.sync="isCalendarFilterSelectionEmpty" />
        </div>

        <div class="card-view" v-if="currentViewTab === 2">
            <CardView 
                :getCurrentShipmentsData="getCurrentShipmentsData"
                :getShipmentLastPage="getShipmentPagination"
                :tab="tab"
                :search.sync="search" />
        </div>

        <div class="compact-view" v-if="currentViewTab === 3">
            <ReportViewCustom
                :isFilterEnabled="isFilterEnabled"
                @handleClick="handleClick"
                :tab="tab"
                :search.sync="search"
                :shipmentIcon="shipmentIcon"
                :activeTab="1" />
        </div>

        <!-- pagination for no search -->
        <Pagination 
            v-if="activeShipmentTab === 1 && getCurrentShipmentsData !== 'undefined' && search == '' && currentViewTab === 0"
            :totalPage.sync="paginationTotalPage"
            :currentPage.sync="paginationNoSearchedModel"
            @handlePageChange="handlePageChange"
            :isMobile="false"
            :from="paginationFrom"
            :to="paginationTo"
            :totalItems="paginationTotalItems"
            :perPage.sync="rowsPerPage"
            :isLoading="isShipmentStillFetching" />

        <Pagination 
            v-if="activeShipmentTab === 1 && getCurrentShipmentsData !== 'undefined' && search !== '' && currentViewTab === 0"
            :totalPage.sync="getShipmentPagination"
            :currentPage.sync="paginationSearchedModel"
            @handlePageChange="handlePageSearched"
            :isMobile="false"
            :from="paginationFrom"
            :to="paginationTo"
            :totalItems="paginationTotalItems"
            :perPage.sync="rowsPerPage"
            :isLoading="loadingShipmentsData" />
        
        <ConfirmDialog :dialogData.sync="openCancelRequestForm">
			<template v-slot:dialog_icon>
				<div class="header-wrapper-close">
					<img src="@/assets/icons/icon-delete.svg" alt="alert" />
				</div>
			</template>

			<template v-slot:dialog_title>
				<h2>Request Cancellation</h2>
			</template>

			<template v-slot:dialog_content>
				<p>
					Do you want to request cancellation for this Shipment?
				</p>
                <div class="form-label mt-3">
                    <span class="cancel-reason-label">REASON FOR CANCELLATION</span>
                </div>
                <v-textarea
                    :rows="4"
                    outlined
                    placeholder="Type reason for cancellation"
                    hide-details="auto"
                    class="text-fields"
                    required
                    v-model="cancel_reason"
                >
                </v-textarea>
			</template>

			<template v-slot:dialog_actions>
				<v-btn class="btn-blue" text @click="submitCancelRequestForm()" :disabled="getCancelShipmentLoading">
					<span v-if="getCancelShipmentLoading">Cancel Request Processing...</span>
					<span v-else>Cancel Request</span>
				</v-btn>
				<v-btn class="btn-white" text @click="openCancelRequestForm = false" :disabled="getCancelShipmentLoading">
					Close
				</v-btn>
			</template>
		</ConfirmDialog>

        <ConfirmDialog :dialogData.sync="markShipmentDialog">
			<template v-slot:dialog_icon>
				<div class="header-wrapper-close">
					<img src="@/assets/icons/icon-delete.svg" alt="alert" />
				</div>
			</template>

			<template v-slot:dialog_title>
				<h2>Mark Completed & Stop Tracking</h2>
			</template>

			<template v-slot:dialog_content>
				<p>
					If you mark this shipment as completed, we will stop tracking of this particular shipment. Are you sure?
				</p>
			</template>

			<template v-slot:dialog_actions>
				<v-btn class="btn-blue" text @click="submitMarkShipmentCompleted(markCompletedShipment)" :disabled="getmarkShipmentCompletedLoading">
					<span>Mark Completed</span>
				</v-btn>
				<v-btn class="btn-white" text @click="markShipmentDialog = false" :disabled="getmarkShipmentCompletedLoading">
					Close
				</v-btn>
			</template>
		</ConfirmDialog>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import globalMethods from '../../../../utils/globalMethods'
import _ from 'lodash';
import CustomIcon from "@/components/Icons/CustomIcon";
import ConfirmDialog from "@/components/Dialog/GlobalDialog/ConfirmDialog.vue";

import CalendarView from "./Views/CalendarView.vue";
import ReportViewCustom from "./Views/ReportViewCustom.vue";
import CardView from "./Views/CardView.vue";
import moment from "moment";
import CalendarTooltip from './Views/components/CalendarTooltip.vue';
import { shipmentStatusesLists } from "./js/statusLists";
import Pagination from "@/components/PaginationComponent/Pagination";

export default {
    props: [
        'shipments', 
        'activeTab', 
        'isMobile', 
        'search', 
        'paginationData', 
        'shipmentIcon', 
        'currentViewTab', 
        'isFilterEnabled', 
        'currentCalendarType', 
        'currentDateCalendar', 
        'tab', 
        'selectedDefaultFilter',
        'selectedCalendarOption',
        'isAllSelected',
        'isCalendarFilterSelectionEmpty',
        'shipmentCurrentTab',
        'awaitingDeparture',
        'inTransit',
        'arrived',
        'pickedUp',
		'requiresAttention',
        'loadingShipmentsData'
    ],
    components: {
        CustomIcon,
        ConfirmDialog,
        CalendarView,
        ReportViewCustom,
        CardView,
        CalendarTooltip,
        Pagination
    },
    data: () => ({
        pageCount: 0,
        windowWidth: window.innerWidth,
        headersShipment: [
            {
                text: "Reference",
                align: "start",
                sortable: false,
                value: "shifl_ref",
                width: "8%",
                fixed: true
            },
            {
                text: "Departure",
                align: "start",
                value: "location_from_name",
                sortable: false,
                width: "13%", 
                fixed: true,
                field: 'etd',
            },
            {
                text: "Type",
                align: "start",
                value: "mode",
                sortable: false,
                width: "9%",
                fixed: true
            },
            {
                text: "Arrival",
                align: "start",
                value: "location_to_name",
                sortable: false,
                width: "14%", 
                fixed: true,
                field: 'eta'
            },
            {
                text: "Containers",
                align: "start",
                value: "containers",
                sortable: false,
                width: "13%", 
                fixed: true,
                field: 'eta'
            },
            {
                text: "Suppliers",
                align: "start",
                value: "suppliers_names",
                sortable: false,
                width: "18%",
                fixed: true
            },
            {
                text: "PO",
                align: "start",
                value: "po_num",
                sortable: false,
                width: "15%",
                fixed: true
            },
            {
                text: "Cut off Date",
                align: "start",
                value: "cut_off_date",
                sortable: false,
                width: "14%",
                fixed: true
            },
            {
                text: "LFD",
                align: "start",
                value: "lfd",
                sortable: false,
                width: "14%",
                fixed: true
            },
            {
                text: "Status",
                value: "shipment_status",
                align: 'start',
                sortable: true,
                width: "auto",
                fixed: true
            },
            /*
            {
                text: "ToDos",
                value: "todos",
                align: 'center',
                sortable: false,
                width: "5%",
                fixed: true
            },*/
            {
                text: "",
                value: "actions",
                align: 'end',
                sortable: false,
                width: "5%",
                fixed: true
            },
            {
                text: "Container",
                align: ' d-none',
                sortable: false,
                value: "container_num_list",
                width: "0",
                fixed: true
            },
            {
                text: "Mbl",
                align: ' d-none',
                sortable: false,
                value: "mbl_num",
                width: "0",
                fixed: true
            },
            {
                text: "Hbl",
                align: ' d-none',
                sortable: false,
                value: "hbl_num",
                width: "0",
                fixed: true
            },
            {
                text: "Ams",
                align: ' d-none',
                sortable: false,
                value: "ams_num",
                width: "0",
                fixed: true
            },
        ],
        openCancelRequestForm: false,
        cancel_reason: '',
        markShipmentDialog: false,
        cancelShipmentItemId: null,
        markCompletedShipment: null,
        isTruncatedText: false,
        isShipmentMobile: false,
        onShowTooltip: false
    }),
    computed: {
        ...mapGetters([
            "getAllShipments",
            "getShipmentLoadingStatus",
            "getSearchedShipments",
            "getSearchedShipmentLoading",
            "getSearchedShipmentPagination",
            "getCancelShipmentLoading",
            "getmarkShipmentCompletedLoading",
            "getAwaitingDepartureShipmentsLoading",
            "getInTransitShipmentsLoading",
            "getArrivedShipmentsLoading",
            "getPickedUpShipmentsLoading",
            "getRequiresAShipmentsLoading"
        ]),
        activeShipmentTab: {
            get() {
                return this.activeTab
            },
            set(value) {
                this.$emit('update:activeTab', value)
            }
        },
        activeShipmentSubtab: {
			get() {
				return this.shipmentCurrentTab;
			},
			set(value) {
				this.$emit("update:shipmentCurrentTab", value);
			},
		},
        pagination: {
            get() {
                return this.$store.state.shipment;
            },
        },
        paginationShipmentSubtabs: {
            get() {
                let storePagination1 = this.$store.state.shipmentSubTabs;
                return storePagination1.shipmentsPaginationLists;
            },
        },
        getCurrentShipmentsData() {
            let filteredShipments = [];
            
			if (typeof this.getSearchedShipments !== "undefined" && this.getSearchedShipments !== null) {
				if (this.search !== "" && this.activeShipmentSubtab === 0 &&
					this.getSearchedShipments.tab === "shipments") {
					filteredShipments = this.getSearchedShipments.shipments;

				} else if (this.search !== "" && this.activeShipmentSubtab === 1 &&
					this.getSearchedShipments.tab === "shipments2") {
					filteredShipments = this.getSearchedShipments.shipments;

				} else if (this.search !== "" && this.activeShipmentSubtab === 2 &&
					this.getSearchedShipments.tab === "shipments3") {
					filteredShipments = this.getSearchedShipments.shipments;

				} else if (this.search !== "" && this.activeShipmentSubtab === 3 &&
					this.getSearchedShipments.tab === "shipments4") {
					filteredShipments = this.getSearchedShipments.shipments;

				} else if (this.search !== "" && this.activeShipmentSubtab === 4 &&
					this.getSearchedShipments.tab === "shipments5") {
					filteredShipments = this.getSearchedShipments.shipments;

				} else if (this.search !== "" && this.activeShipmentSubtab === 5 &&
					this.getSearchedShipments.tab === "shipments6") {
					filteredShipments = this.getSearchedShipments.shipments;

				} else {
					if (this.activeShipmentSubtab === 0)
						filteredShipments = this.shipments;

					else if (this.activeShipmentSubtab === 1) 
						filteredShipments = this.awaitingDeparture;
					
                    else if (this.activeShipmentSubtab === 2)
						filteredShipments = this.inTransit;

                    else if (this.activeShipmentSubtab === 3)
						filteredShipments = this.arrived;

                    else if (this.activeShipmentSubtab === 4)
						filteredShipments = this.pickedUp;

                    else if (this.activeShipmentSubtab === 5)
						filteredShipments = this.requiresAttention;
				}
			} else {
				if (this.activeShipmentSubtab === 0)
					filteredShipments = this.shipments;

				else if (this.activeShipmentSubtab === 1)
					filteredShipments = this.awaitingDeparture;

                else if (this.activeShipmentSubtab === 2)
                    filteredShipments = this.inTransit;

                else if (this.activeShipmentSubtab === 3)
                    filteredShipments = this.arrived;

                else if (this.activeShipmentSubtab === 4)
                    filteredShipments = this.pickedUp;

                else if (this.activeShipmentSubtab === 5)
                    filteredShipments = this.requiresAttention;
			}
            
            if (filteredShipments.length > 0) {
                filteredShipments.map((fs, key ) => {
                    filteredShipments[key].external_shipment = 0;
                    filteredShipments[key].external_shipment_tracking = 0;
                    if (!Array.isArray(fs.po_list)) {
                        filteredShipments[key].po_list = []
                    }

                    if (fs.is_tracking_shipment==1) {
                        filteredShipments[key].external_shipment = 1
                    }
                    if (typeof fs.terminal_fortynine!=='undefined' && fs.terminal_fortynine!==null && typeof fs.terminal_fortynine.attributes!=='undefined') {
                        
                        let getAttributes = fs.terminal_fortynine.attributes
                        let getContainers = fs.terminal_fortynine.containers
                        if (getAttributes !=='' && getAttributes!==null && fs.is_tracking_shipment == 1) {
                            filteredShipments[key].external_shipment_tracking = 1
                            filteredShipments[key].external_shipment_containers = []
                        }

                        if (getContainers!=='undefined' ) {
                            getContainers = JSON.parse(getContainers)
                            filteredShipments[key].external_shipment_containers = getContainers;
                        }
                    }
                })
            }
            return filteredShipments;
		},
        getShipmentItemsPerPage() {
            return 20;
        },
        getShipmentPagination() {
            if (typeof this.getSearchedShipments !== "undefined" && this.getSearchedShipments !== null) {
                if (this.search !== "") {
                    if (this.activeShipmentSubtab === 0 && this.getSearchedShipments.tab === "shipments") {
                        return this.getSearchedShipments.last_page;
                    } else if (this.activeShipmentSubtab === 1 && this.getSearchedShipments.tab === "shipments2") {
                        return this.getSearchedShipments.last_page;
                    } else if (this.activeShipmentSubtab === 2 && this.getSearchedShipments.tab === "shipments3") {
                        return this.getSearchedShipments.last_page;
                    } else if (this.activeShipmentSubtab === 3 && this.getSearchedShipments.tab === "shipments4") {
                        return this.getSearchedShipments.last_page;
                    } else if (this.activeShipmentSubtab === 4 && this.getSearchedShipments.tab === "shipments5") {
                        return this.getSearchedShipments.last_page;
                    } else if (this.activeShipmentSubtab === 5 && this.getSearchedShipments.tab === "shipments6") {
                        return this.getSearchedShipments.last_page;
                    }
                }
            }
            return this.paginationTotal;
        },
        type: {
            get() {
                return this.currentCalendarType;
            },
            set(v) {
                this.$emit("update:currentCalendarType", v)
            }
        },
        selectedCalendarFilter: {
            get() {
                return this.selectedDefaultFilter;
            },
            set(v) {
                this.$emit("update:selectedDefaultFilter", v)
            }
        },
        paginationTotal() {
            if (this.activeShipmentSubtab === 0)
                return this.pagination.shipments.last_page;
            else if (this.activeShipmentSubtab === 1)
                return this.paginationShipmentSubtabs.awaitingDeparture.last_page;            
            else if (this.activeShipmentSubtab === 2)
                return this.paginationShipmentSubtabs.inTransit.last_page;            
            else if (this.activeShipmentSubtab === 3)
                return this.paginationShipmentSubtabs.arrived.last_page;            
            else if (this.activeShipmentSubtab === 4)
                return this.paginationShipmentSubtabs.pickedUp.last_page;            
            else if (this.activeShipmentSubtab === 5)
                return this.paginationShipmentSubtabs.requiresAttention.last_page;            

            return 1;
        },
        isShipmentStillFetching() {
            let loading = false;

            if (this.shipmentCurrentTab === 0)
                loading = this.getShipmentLoadingStatus;
            else if (this.shipmentCurrentTab === 1)
                loading = this.getAwaitingDepartureShipmentsLoading;
            else if (this.shipmentCurrentTab === 2)
                loading = this.getInTransitShipmentsLoading;
            else if (this.shipmentCurrentTab === 3)
                loading = this.getArrivedShipmentsLoading;
            else if (this.shipmentCurrentTab === 4)
                loading = this.getPickedUpShipmentsLoading;
            else if (this.shipmentCurrentTab === 5)
                loading = this.getRequiresAShipmentsLoading;

            return loading;
        },
        computedHeadersShipment() {
            let defaultHeaders = this.headersShipment;
            defaultHeaders = defaultHeaders.filter(v => {
                if (this.activeShipmentSubtab === 1) {
                    return v.text !== "LFD";
                } else if (this.activeShipmentSubtab === 3 || this.activeShipmentSubtab === 4) {
                    return v.text !== "Cut off Date";
                } else {
                    return v.text !== "Cut off Date" && v.text !== "LFD";
                }
            })
            return defaultHeaders;
        },
        // both searched and not pagination object
        paginationObject: {
            get() {
                if (this.search === "") {
                    if (this.activeShipmentSubtab === 0) 
                        return this.pagination.shipments;
                    else if (this.activeShipmentSubtab === 1)
                        return this.paginationShipmentSubtabs.awaitingDeparture;
                    else if (this.activeShipmentSubtab === 2)
                        return this.paginationShipmentSubtabs.inTransit;
                    else if (this.activeShipmentSubtab === 3)
                        return this.paginationShipmentSubtabs.arrived;
                    else if (this.activeShipmentSubtab === 4)
                        return this.paginationShipmentSubtabs.pickedUp;
                    else if (this.activeShipmentSubtab === 5)
                        return this.paginationShipmentSubtabs.requiresAttention;
                } else {
                    if (typeof this.getSearchedShipments !== "undefined" && this.getSearchedShipments !== null) {
                        if (this.activeShipmentSubtab === 0 && this.getSearchedShipments.tab === "shipments") {
                            return this.getSearchedShipments;
                        } else if (this.activeShipmentSubtab === 1 && this.getSearchedShipments.tab === "shipments2") {
                            return this.getSearchedShipments;
                        } else if (this.activeShipmentSubtab === 2 && this.getSearchedShipments.tab === "shipments3") {
                            return this.getSearchedShipments;
                        } else if (this.activeShipmentSubtab === 3 && this.getSearchedShipments.tab === "shipments4") {
                            return this.getSearchedShipments;
                        } else if (this.activeShipmentSubtab === 4 && this.getSearchedShipments.tab === "shipments5") {
                            return this.getSearchedShipments;
                        } else if (this.activeShipmentSubtab === 5 && this.getSearchedShipments.tab === "shipments6") {
                            return this.getSearchedShipments;
                        }
                    }
                }
                return null;                
            },
        },
        paginationTotalPage: {
            get() {
                return this.paginationObject.last_page !== null ? this.paginationObject.last_page : 0;
            }
        },
        paginationTotalItems: {
            get() {
                return this.paginationObject.total !== null ? this.paginationObject.total : 0;
            },
        },
        paginationFrom: {
            get() {
                return this.paginationObject !== null && this.paginationObject.from !== null ? this.paginationObject.from : 0;
            },
        },
        paginationTo: {
            get() {
                return this.paginationObject !== null && this.paginationObject.to !== null ? this.paginationObject.to : 0;
            },
        },
        rowsPerPage: {
            get() {
                return this.paginationObject.per_page;
            },
            set(value) {
                let data = { per_page: value, page: 1, currentTabPerPage: this.paginationObject.per_page };
                this.$emit("update:itemsPerPage", data);
                return value;
            }
        },
        // no search pagination model and total
        paginationNoSearchedModel: {
            get() {
                if (this.activeShipmentSubtab === 0) 
                    return this.pagination.shipments.current_page;            
                else if (this.activeShipmentSubtab === 1)
                    return this.paginationShipmentSubtabs.awaitingDeparture.current_page;                
                else if (this.activeShipmentSubtab === 2)
                    return this.paginationShipmentSubtabs.inTransit.current_page;                
                else if (this.activeShipmentSubtab === 3)
                    return this.paginationShipmentSubtabs.arrived.current_page;                
                else if (this.activeShipmentSubtab === 4)
                    return this.paginationShipmentSubtabs.pickedUp.current_page;                
                else if (this.activeShipmentSubtab === 5)
                    return this.paginationShipmentSubtabs.requiresAttention.current_page;                

                return 1;
            },
            set(v) {
                if (this.activeShipmentSubtab === 0) {
                    let storePagination = this.$store.state.shipment.shipmentsPagination.shipmentTab;
                    storePagination.current_page = v;
                }
            }
        },
        paginationSearchedModel: {
            get() {
                if (typeof this.getSearchedShipments !== "undefined" && this.getSearchedShipments !== null) {
                    if (this.search !== "") {
                        if (this.activeShipmentSubtab === 0 && this.getSearchedShipments.tab === "shipments") {
                            return this.getSearchedShipments.current_page;
                        } else if (this.activeShipmentSubtab === 1 && this.getSearchedShipments.tab === "shipments2") {
                            return this.getSearchedShipments.current_page;
                        } else if (this.activeShipmentSubtab === 2 && this.getSearchedShipments.tab === "shipments3") {
                            return this.getSearchedShipments.current_page;
                        } else if (this.activeShipmentSubtab === 3 && this.getSearchedShipments.tab === "shipments4") {
                            return this.getSearchedShipments.current_page;
                        } else if (this.activeShipmentSubtab === 4 && this.getSearchedShipments.tab === "shipments5") {
                            return this.getSearchedShipments.current_page;
                        } else if (this.activeShipmentSubtab === 5 && this.getSearchedShipments.tab === "shipments6") {
                            return this.getSearchedShipments.current_page;
                        }
                    }
                }
                return this.paginationNoSearchedModel;
            },
            set(value) {
                if (typeof this.getSearchedShipments !== 'undefined' && this.getSearchedShipments !== null) {
                    if (this.search !== "") {
                        if (this.activeShipmentSubtab === 0 && this.getSearchedShipments.tab === "shipments") {
                            this.$store.state.shipment.searchedShipmentsPagination.current_page = value;
                        } else if (this.activeShipmentSubtab === 1 && this.getSearchedShipments.tab === "shipments2") {
                            this.$store.state.shipment.searchedShipmentsPagination.current_page = value;
                        } else if (this.activeShipmentSubtab === 2 && this.getSearchedShipments.tab === "shipments3") {
                            this.$store.state.shipment.searchedShipmentsPagination.current_page = value;
                        } else if (this.activeShipmentSubtab === 3 && this.getSearchedShipments.tab === "shipments4") {
                            this.$store.state.shipment.searchedShipmentsPagination.current_page = value;
                        } else if (this.activeShipmentSubtab === 4 && this.getSearchedShipments.tab === "shipments5") {
                            this.$store.state.shipment.searchedShipmentsPagination.current_page = value;
                        } else if (this.activeShipmentSubtab === 5 && this.getSearchedShipments.tab === "shipments6") {
                            this.$store.state.shipment.searchedShipmentsPagination.current_page = value;
                        }
                    }
                }
                this.$emit('update:paginationNoSearchedModel', value)
            }
        },
    },
    watch: {
        currentViewTab(v) {
            if (v === 0 && !this.isMobile) {  // shouldn't be applied to mobile
                setTimeout(() => {
                    this.checkTbodyWrapper();
                }, 100);
            }
        }
    },
    mounted() {
        //set current page
        this.$store.dispatch("page/setPage", "shipments");
        if (this.currentViewTab === 0 && !this.isMobile)  // shouldn't be applied to mobile
            this.checkTbodyWrapper();
    },
    methods: {
        ...mapActions([
            "fetchShipments",
            "setShipmentOrder",
            "fetchShipmentsSearched",
            "cancelShipment",
            "markShipmentCompleted",
            "fetchCompletedShipments",
            "fetchAwaitingShipments",
			"fetchInTransitShipments",
			"fetchArrivedShipments",
			"fetchPickedUpShipments",
			"fetchRequiresAttentionShipments"
        ]),
        ...globalMethods,
        ucFirst(str) {
            let pieces = str.split(" ")
            for ( let i = 0; i < pieces.length; i++ ) {
                let j = pieces[i].charAt(0).toUpperCase()
                pieces[i] = j + pieces[i].substr(1)
            }
            return pieces.join(" ")
        },
        getShipmentStatusClass(item, type) {
            let { tracking_status, status } = item;
            let setValue = (type ==='tracking') ? tracking_status : status;
            let setClass = _.find(shipmentStatusesLists, e => e.value === setValue.toLowerCase());
            setClass = (typeof setClass !== 'undefined') ? setClass.class : '';
            return setClass;
        },
        getCurrentPaginationCountClass() {
            if (this.search === "") {
                if (this.activeShipmentSubtab === 0) {
                    return typeof this.pagination.shipments.last_page !== 'undefined' ? 
                        this.pagination.shipments.last_page <= 1 : false;
                
                } else if (this.activeShipmentSubtab === 1) {
                    return typeof this.paginationShipmentSubtabs.awaitingDeparture.last_page !== 'undefined' ? 
                        this.paginationShipmentSubtabs.awaitingDeparture.last_page <= 1 : false;
                
                } else if (this.activeShipmentSubtab === 2) {
                    return typeof this.paginationShipmentSubtabs.inTransit.last_page !== 'undefined' ? 
                        this.paginationShipmentSubtabs.inTransit.last_page <= 1 : false;
                
                } else if (this.activeShipmentSubtab === 3) {
                    return typeof this.paginationShipmentSubtabs.arrived.last_page !== 'undefined' ? 
                        this.paginationShipmentSubtabs.arrived.last_page <= 1 : false;
                
                } else if (this.activeShipmentSubtab === 4) {
                    return typeof this.paginationShipmentSubtabs.pickedUp.last_page !== 'undefined' ? 
                        this.paginationShipmentSubtabs.pickedUp.last_page <= 1 : false;
                
                } else if (this.activeShipmentSubtab === 5) {
                    return typeof this.paginationShipmentSubtabs.requiresAttention.last_page !== 'undefined' ? 
                        this.paginationShipmentSubtabs.requiresAttention.last_page <= 1 : false;
                }
            } else {
                return typeof this.getShipmentPagination !== 'undefined' ? this.getShipmentPagination <= 1 : false;
            }          
        },
        checkPaginationMoreThanOne() {
            if (this.activeShipmentSubtab === 0) {
                return typeof this.pagination.shipments.last_page !== 'undefined' ? 
                    this.pagination.shipments.last_page > 1 : false;
            
            } else if (this.activeShipmentSubtab === 1) {
                return typeof this.paginationShipmentSubtabs.awaitingDeparture.last_page !== 'undefined' ? 
                    this.paginationShipmentSubtabs.awaitingDeparture.last_page > 1 : false;
            
            } else if (this.activeShipmentSubtab === 2) {
                return typeof this.paginationShipmentSubtabs.inTransit.last_page !== 'undefined' ? 
                    this.paginationShipmentSubtabs.inTransit.last_page > 1 : false;
            
            } else if (this.activeShipmentSubtab === 3) {
                return typeof this.paginationShipmentSubtabs.arrived.last_page !== 'undefined' ? 
                    this.paginationShipmentSubtabs.arrived.last_page > 1 : false;
            
            } else if (this.activeShipmentSubtab === 4) {
                return typeof this.paginationShipmentSubtabs.pickedUp.last_page !== 'undefined' ? 
                    this.paginationShipmentSubtabs.pickedUp.last_page > 1 : false;
            
            } else if (this.activeShipmentSubtab === 5) {
                return typeof this.paginationShipmentSubtabs.requiresAttention.last_page !== 'undefined' ? 
                    this.paginationShipmentSubtabs.requiresAttention.last_page > 1 : false;
            }
            return false;
        },
        addWheelClickedEventListener(element, callback) {
            var started = false;
            var removed = false;
            var onDocumentMouseup = function() {
                started = false;
            };
            var onElementMousedown = function(e) {
                e.preventDefault();  // prevents auto-scrolling action
                started = (e.button === 1);
            };
            var onElementMouseup = function(e) {
                if (started) {
                    started = false;
                    callback(e);
                }
            };
            var onElementClick = function(e) {
                callback(e);
            }
            document.addEventListener('mouseup', onDocumentMouseup);
            element.addEventListener('mousedown', onElementMousedown);
            element.addEventListener('mouseup', onElementMouseup);
            element.addEventListener('click', onElementClick);

            return function() {
                if (removed) {
                    return;
                }
                removed = true;
                document.removeEventListener('mouseup', onDocumentMouseup);
                element.removeEventListener('mousedown', onElementMousedown);
                element.removeEventListener('mouseup', onElementMouseup);
                element.removeEventListener('click', onElementClick);
            };
        },
        handleClick(value) {
            this.$emit('handleClick', value)
        },
        onResize() {
            this.windowWidth = window.innerWidth;
            if (window.screen.width < 769) {
				this.isShipmentMobile = true;
			} else {
				this.isShipmentMobile = false;
			}
        },
        async changeSort(field) {
            this.$emit('sort', 1, field)
        },
        itemRowBackground: function (item) {
            return item.status == "Past last free day" ? "light-red-bg" : "";
        },
        async handlePageChange(page) {
            this.$emit('handlePageChange', page)
            this.handleScrollToTop()
        },
        async handlePageSearched(page) {
            let data = { page, tab: 'shipments' } // tab: 'shipments-completed-merge'
            this.$emit('handlePageSearched', data)
            this.handleScrollToTop();
        },
        handleScrollToTop() {
            var table = this.$refs['my-table'];
            var wrapper = table.$el.querySelector('div.v-data-table__wrapper');            
            this.$vuetify.goTo(table); // to table
            this.$vuetify.goTo(table, {container: wrapper}); // to header
        },
        cancelRequestForm(item) {
            this.cancelShipmentItemId = item;
            this.openCancelRequestForm = true;
            this.cancel_reason = '';
        },
        submitCancelRequestForm() {
            if(this.cancelShipmentItemId && this.cancelShipmentItemId !== 0 && this.cancel_reason !== '') {
				let payloadObject = {'shipmentId': this.cancelShipmentItemId, 'cancel_reason': this.cancel_reason, 'type': 'shipment'}
				this.cancelShipment(payloadObject)
					.then((response) => {
						this.openCancelRequestForm = false;
						this.notificationErrorCustom(response.data.message);
					})
					.catch((e) => {
						console.log(e);
                        this.openCancelRequestForm = false;
                        this.notificationErrorCustom('SOMETHING WENT WRONG!');
					})
			} else {
                this.notificationErrorCustom('Please fill the reason for cancellation field.');
            }
        },
        markShipmentCompletedDialog(item){
            this.markShipmentDialog = true
            this.markCompletedShipment = item
        },
        async submitMarkShipmentCompleted(item){
            this.markShipmentCompleted(item.id).then(()=>{
                this.notificationMessage(`Reference #${item.shifl_ref} has been marked as completed`)
                this.callShipmentAPI();
                this.markShipmentDialog = false
            }).catch((e) => {
                console.log(e);
            });
        },
        getShipmentSubtabName(tab) {
			let storePagination = this.$store.state.shipment.shipmentsPagination;
			let storePaginationSubtab = this.$store.state.shipmentSubTabs;
			let storeShipmentTabData = this.$store.state.shipment;

			let payload = { page: 1, order: "asc", per_page: this.rowsPerPage };

			if (tab === 0) {
				payload.page = storePagination.shipmentTab.currentTab === 1
					? storePagination.shipmentTab.current_page : 1;
				payload.order = storeShipmentTabData.shipmentOrder.order;
			} else {
				let tabName = tab === 1 ? "awaitingDeparture" 
							: tab === 2 ? "inTransit" 
							: tab === 3 ? "arrived" 
							: tab === 4 ? "pickedUp"
							: "requiresAttention";

				payload.page = storePagination.shipmentTab.currentTab === 1 ?
					storePaginationSubtab.shipmentsPaginationLists[tabName].current_page : 1;
				payload.order = storeShipmentTabData.shipmentOrder.order;
			}
			return payload;
		},
        callShipmentAPI() {
            let storePagination = this.$store.state.shipment.shipmentsPagination;
            let storeShipmentTabData = this.$store.state.shipment;

            let payloadShipments = this.getShipmentSubtabName(this.shipmentCurrentTab);
            let payloadCompleted = {
                page: (storePagination.completedTab.currentTab === 2) ? storePagination.completedTab.current_page : 1,
                order: storeShipmentTabData.completedOrder.order,
                per_page: this.rowsPerPage
            }

            this.fetchShipments(payloadShipments);
            this.fetchAwaitingShipments(payloadShipments);
            this.fetchInTransitShipments(payloadShipments);
            this.fetchArrivedShipments(payloadShipments);
            this.fetchPickedUpShipments(payloadShipments);
            this.fetchRequiresAttentionShipments(payloadShipments);
            this.fetchCompletedShipments(payloadCompleted);
        },
        checkSuppliersName(suppliers) {
            if (Array.isArray(suppliers) && suppliers !== null) {
                return suppliers.filter(item => item !== '').join(', ');
            } else { return ''; }
        },
        checkSuppliersNameMobile(suppliers, count) {
            let str = suppliers;
            if (str.length > count) str = str.substring(0, count) + "...";
            return str;
        },
        convertDateAction(v) {
            if (v !== null && v !== "") {
                return moment.utc(v).format('MMM DD, YYYY');
            } else { return "N/A"; }
        },
        getImgUrl(item, type) {
            if (type === "mode") {
                let shipmentMode = (item !== null && item !== "") ? item.toLowerCase() : "";
                if (shipmentMode !== "") {
                    // shipmentMode = shipmentMode === "air" ? "airplane" : shipmentMode;
                    return require(`@/assets/icons/shipment-table-icons/${shipmentMode}.svg`)
                }
            } else {
                let shipmentTypes = [
                    {
                        text: "LCL",
                        value: "small-container"
                    },
                    {
                        text: "LTL",
                        value: "small-container"
                    },
                    {
                        text: "Air",
                        value: "airplane-shipment"
                    },
                    {
                        text: "FCL",
                        value: "big-container"
                    },
                    {
                        text: "FTL",
                        value: "big-container"
                    }
                ]
                if (item !== null && item !== "") {
                    let findValue = _.find(shipmentTypes, e => ( e.text === item ));
                    if (findValue !== undefined) {
                        return require(`@/assets/icons/shipment-table-icons/${findValue.value}.svg`)
                    } else return ""
                } else { return "" }
            }
		},
        // for detecting ellipsis
        detectLineClamp(item, id, elementId) {
            let element1 = null;
            if (elementId === "supplier")
                element1 = document.querySelector(`#td-supplier-content-${id}`);
            else if (elementId === "reference")
                element1 = document.querySelector(`#td-reference-content-${id}`);
            else
                element1 = document.querySelector(`#td-po-content-${id}`);            

            if (item !== null && element1 !== null) {
                let elementValues = this.isEllipsisActive(element1, "line");
                this.isTruncatedText = elementValues;
            }
        },
        isEllipsisActive(element, type) {
            if (type === "line") {
                if (element.clientHeight < element.scrollHeight) return true;                
            } else {
                if (element.clientWidth < element.scrollWidth) {
                    var style = element.currentStyle || window.getComputedStyle(element);
                    return style.textOverflow === 'ellipsis'
                }
            }
            return false;
        },
        getLFDDate(containers) {
            let dates = [];
            let earliest_date = "";

            if (containers.length > 0) {
                dates = containers.filter(item => (item.pickup_lfd !== null))
            }
            // get the earliest date of pickup lfd for arrived and pickup tabs
            if (dates.length > 0) {
                earliest_date = new Date(
                    Math.min(...dates.map(element => {
                        return new Date(element.pickup_lfd);
                    }),
                ),);
            }
            return earliest_date !== "" ? this.convertDateAction(earliest_date) : "N/A";
        },
        checkTbodyWrapper() {
            let dis = this;
            const tbodyWrapper = document.querySelector('.v-data-table__wrapper tbody');
            this.addWheelClickedEventListener(tbodyWrapper, function(e) {
                let row = e.target.closest("tr");
                if (e.which === 2) {
                    if (row !== null) {
                        let link = window.location.origin + `/shipment/${row.id}`;
                        var anchor = document.createElement('a');
                        anchor.href = link;
                        anchor.target="_blank";
                        anchor.click();
                    }
                } else {
                    if (e.which === 1)
                        dis.$router.push(`shipment/${row.id}`).catch(() => {}); // redirect to shipment details
                }
            });
        },
        onMouseLeaveContainer() {
            setTimeout(() => {
                window.getSelection().removeAllRanges();
            }, 500);            
        }
    },
    updated() {}
}
</script>

<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap');
@import '../../../../assets/scss/utilities.scss';
@import './scss/shipmentsTabTable.scss';

.vc-popover-content {
    z-index: 100;
}

.po-num-mobile .second-supplier-name {
    width: 200px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.v-menu__content {
    &.menuable__content__active {
        background-color: #fff !important;
        opacity: 1 !important;
        box-shadow: 0px 0px 1px 0px #29292929,
                    0px 8px 16px 0px #29292933;

        &::before {
            display: none !important;
        }
    }

    // shipment containers tooltip
    &.shipment-containers-menu {
        width: 150px !important;
        min-width: 150px !important;
        padding: 0 !important;
        transition: none !important;

        .tooltip-data-destination {
            margin-bottom: 4px !important;

            p {
                padding: 6px 18px !important;
                border-bottom: 1px solid $shifl-neutral-n20 !important;
                font-size: 14px !important;
                height: 32px !important;
                font-family: "Inter-Regular", sans-serif !important;
                color: $shifl-neutral-n90 !important;

                &:last-child {
                    border-bottom: none !important;
                }
            }
        }
    }

}
</style>

<template>
	<div class="notification-setting-m">
		<div class="">
			<div class="item-main-m mt-1 mb-2" v-for="(notif, i) in notificationsItems" :key="i">
				<div :class="`item-m ${notif.isFor}`">
					<p class="item-heading" :class="i === (notificationsItems.length - 1) ? 'mb-2' : ''">
						{{ notif.notificationTitle }}
					</p>					

					<div v-if="notif.isFor === 'unreturned_container_alert'" class="unreturned-notif-wrapper mb-4">
						<!-- <div style="display: -webkit-box;" class="my-1">
							Container is
							<v-select
								class="item-unreturned-select mt-0 pt-0"
								:items="unreturnedContainerList"
								item-value="value"
								item-text="text"
								:menu-props="{ contentClass: 'item-unreturned-list', top: true, offsetY: true }"
								v-model="unreturnedContainerValue"
								append-icon="mdi-chevron-down"
								hide-details="auto"
								attach
								@change="updateContainerAlert">

								<template v-slot:item="{ item, on, attrs }">
									<v-list-item
										class="d-flex justify-start"
										style="border: none;"
										v-on="on"
										v-bind="attrs">
										<p class="name d-flex justify-space-between align-center font-regular mb-0">
											{{ item.text }}
											<img class="py-0" 
												width="13"
												src="@/assets/icons/checkMark-green.svg" 
												v-if="item.value === unreturnedContainerValue"/>
										</p>
									</v-list-item>
								</template>
							</v-select>
						</div>
						days out of the port & not returned -->

						<div style="display: -webkit-box;">
							<span class="mr-1">When the container is</span>
							<v-text-field
								class="item-unreturned-select mt-0 pt-0"
								v-model="unreturnedContainerValue"
								hide-details="auto"
								@change="updateContainerAlert"
								type="number"
								@keydown="restrictValues($event)"
								@wheel="$event.target.blur()">
							</v-text-field>
							days or <p class="hours-converted mb-0 text-center pb-1"> 
								{{ getConvertedHours(unreturnedContainerValue) }} hrs
							</p>
						</div>
						<div style="display: -webkit-box;" class="my-1">
							<!-- days or <p class="hours-converted mb-0 text-center"> 
								{{ getConvertedHours(unreturnedContainerValue) }} hrs
							</p>  -->
							out of the port & not returned
						</div>
					</div>	
					<div class="item-right-section-m">
						<template>
							<v-checkbox
								class="notify-type fixed-len mt-2"
								label="Email"
								v-model="notif.notificationEmail"
								@click="showEmaiList(notif.isFor, notif.notificationEmail, notif)"
								hide-details="auto" />

							<div class="item-description-m" v-if="notif.notificationEmail">
								<div class="d-flex" style="width: 100%;">
									<p class="email-text-m">Send Email to:</p>
									<button @click="showDailog(notif.emails, getCurrentItem(notif))">
										<p class="d-flex align-center"> 
											<img class="py-0 mr-1" 
												width="12"
												src="@/assets/icons/edit-delivery-location.svg" />
											Edit
										</p>
									</button>
								</div>
							</div>

							<div class="email-descp-m-wrapper" v-if="notif.notificationEmail">
								<div class="inner-email-desc-m" v-for="email in calculateUserEmails(notif.emails)" :key="email">
									<div v-if="!/@([Ss]hifl\.com|[Ss]hifl\.cn)/.test(email)" class="email-descp-m">
										<p>{{ email }}</p>
									</div>
								</div>
							</div>
						</template>

						<v-checkbox
							class="upper notify-type"
							label="Push Notification"
							:input-value="notif.notificationPushValue !== 'Push Notification Turned Off'"
							v-model="notif.notifPushBooleanVal"
							@click="onClickPushCheckbox(notif, notif.notifPushBooleanVal)"
							hide-details="auto" />

						<v-select
							:ref="notif.refName"
							class="item-notif-settings-m"
							:items="stateData[notif.refName]"
							:class="notif.notificationPush !== 'OFF' ? 'active' : 'inactive'"
							v-model="notif.notificationPushValue"
							:menu-props="{ contentClass: 'notifi-menu-m', bottom: true, offsetY: true }"
							append-icon="mdi-chevron-down"
							attach
							@blur="blurSelect(notif)"
							@change="(e) => { onChangePushOption(e, notif, notif.notifPushBooleanVal) }"
							@click="setTimeAction(notif)"							
							hide-details="auto"
							:disabled="getCustomTimeLoading">

							<template v-slot:selection="{ item }">
								<p class="notification-selection font-medium mb-0 pl-3"
									:class="item === 'Push Notification Turned Off' ? 'n-default--text' : ''">
									{{ (item === "Push Notification Turned Off" || item === "") ? "Turned Off" : item }}

									<span class="notification-selection font-medium" v-if="notif.notificationPush === 'CT'">
										{{ getCustomNotifTime(item, notif.pushField) }}
									</span>
								</p>
                            </template>

							<template v-slot:item="{ item, on, attrs }">
								<v-list-item
									v-if="item == 'Custom Time'"
									class="d-flex justify-start"
									style="border: none; min-height: 38px; height: 38px"
									v-on="on"
									@click="openCustomTimeDialogM(notif.isFor, item)"
									v-bind="attrs">
									<p class="name font-regular mb-0" style="color: #253041">
										{{ item }}
									</p>
								</v-list-item>

								<v-list-item
									v-else
									class="d-flex justify-start"
									style="border: none; min-height: 38px; height: 38px"
									v-on="on"
									v-bind="attrs">

									<p class="name font-regular mb-0" style="color: #253041">
										{{ item }}
									</p>
								</v-list-item>
							</template>
						</v-select>	
					</div>
				</div>
			</div>
		</div>
		<AddEmailUserM 
			:currentCustomer="getCustomer" 
			:id="getCustomer.id" 
			@getNotificationData="getNotificationData" 
			@close="closeEmailDialog" />

		<CustomTimeMo :stateData="stateData" @getNotificationData="getNotificationData" />
		<AlertMailMobile @getNotificationData="getNotificationData" />
	</div>
</template>
<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
import { stateData, notificationsItems, unreturnedContainerList } from "../staticData";
import AddEmailUserM from "./AddEmailUserM.vue";
import AlertMailMobile from "./AlertMailMobile.vue";
import CustomTimeMo from "./CustomTimeMo.vue";
import moment from "moment";
import _ from "lodash";
const APIBaseUrl = process.env.VUE_APP_BASE_URL;
import axios from "axios";

export default {
	components: {
		CustomTimeMo,
		AddEmailUserM,
		AlertMailMobile,
	},
	data: () => ({
		stateData,
		notificationsItems,
		unreturnedContainerList,
		unreturnedContainerValue: 0
	}),
	computed: {
		...mapGetters("notificationNew", ["getBoxOpen", "getCustomer", "getCurrentEditItem", "getCustomTimeLoading"]),
		pushSettings() {
			return this.getCustomer.push_notification_setting;
		},
		emailSettings() {
			return this.getCustomer.email_recipient_setting;
		},
		bookingRequestNotifi: {
			get() {
				return this.getPushWholeValue(this.pushSettings.booking_request);
			},
			set(value) {
				this.updateLocalStateM("booking_request", value);
			},
		},
		bookingUpdatesNotifi: {
			get() {
				return this.getPushWholeValue(this.pushSettings.booking_updates);
			},
			set(value) {
				this.updateLocalStateM("booking_updates", value);
			},
		},
		bookingNotifi: { // booking confirmation
			get() {
				return this.getPushWholeValue(this.pushSettings.booking_confirmation);
			},
			set(value) {
				this.updateLocalStateM("booking_confirmation", value);
			},
		},
		consolidationRequestNotifi: {
			get() {
				return this.getPushWholeValue(this.pushSettings.consolidation_request);
			},
			set(value) {
				this.updateLocalStateM("consolidation_request", value);
			},
		},
		documentUploadNotifi: {
			get() {
				return this.getPushWholeValue(this.pushSettings.document_upload);
			},
			set(value) {
				this.updateLocalStateM("document_upload", value);
			},
		},
		departureNotfi: {
			get() {
				return this.getPushWholeValue(this.pushSettings.departure_notice);
			},
			set(value) {
				this.updateLocalStateM("departure_notice", value);
			},
		},
		arrivalNotifi: {
			get() {
				return this.getPushWholeValue(this.pushSettings.arrival_notice);
			},
			set(value) {
				this.updateLocalStateM("arrival_notice", value);
			},
		},
		DONotifi: {
			get() {
				return this.getPushWholeValue(this.pushSettings.delivery_order);
			},
			set(value) {
				this.updateLocalStateM("delivery_order", value);
			},
		},
		etaChangeAlertNotifi: {
			get() {
				return this.getPushWholeValue(this.pushSettings.eta_change_alert);
			},
			set(value) {
				this.updateLocalStateM("eta_change_alert", value);
			},
		},
		dischargeNotifi: {
			get() {
				return this.getPushWholeValue(this.pushSettings.discharged_alert);
			},
			set(value) {
				this.updateLocalStateM("discharged_alert", value);
			},
		},
		lfdNotifi: {
			get() {
				return this.getPushWholeValue(this.pushSettings.lfd_alert);
			},
			set(value) {
				this.updateLocalStateM("lfd_alert", value);
			},
		},
		unreturnedContNotifi: {
			get() {
				return this.getPushWholeValue(this.pushSettings.unreturned_container_alert);
			},
			set(value) {
				this.updateLocalStateM("unreturned_container_alert", value);
			},
		}
	},
	methods: {
		...mapActions("notificationNew", [
			"updateEmailSettings",
			"updateNotificationTime",
		]),
		...mapMutations("notificationNew", [
			"SET_CURRENT_EDIT_ITEM",
			"SET_EMAIL_DAILOG",
			"CURRENT_EMAIL_RECPIENTS",
			"SET_BOX_OPEN",
			"SET_CUSTOMER",
			"SET_CUSTOM_TIME_DAILOG",
			"SET_EMAIL_WARNING",
			"SET_TEMP_PERIOD",
		]),
		restrictValues(e) {
            if(e.key=='.' && e.keyCode==190) {
                e.preventDefault()
            }
        },
		calculateUserEmails(emails) {
			let calculatedEmails = [];
			calculatedEmails = typeof emails === "string" ? JSON.parse(emails) : emails;
			return calculatedEmails
		},
		updateLocalStateM(notifi, val, isFor) {
			let customer = this.getCustomer;

			if (isFor === "email") {
				customer.email_recipient_setting[notifi] = val;
				this.SET_CUSTOMER(customer);
			} else {
				switch (val) {
					case "Custom Time":
						customer.push_notification_setting[notifi] = "CT";
						this.SET_CUSTOMER(customer);
						break;
					case "Active (Anytime)":
						customer.push_notification_setting[notifi] = "AA";
						this.SET_CUSTOMER(customer);
						break;
					default:
						customer.push_notification_setting[notifi] = "WH";
						this.SET_CUSTOMER(customer);
						break;
				}
			}
		},
		showDailog(emails, item) {
			this.SET_CURRENT_EDIT_ITEM(item);
			this.SET_EMAIL_DAILOG(true);
			this.CURRENT_EMAIL_RECPIENTS(emails);
		},
		showEmaiList(fieldName, status, item) {
			let emails = typeof item.emails === "string" ? JSON.parse(item.emails) : item.emails;
			emails = emails !== null ? emails : [];	
			this.SET_CURRENT_EDIT_ITEM(fieldName);

			if (emails.length > 0) {
				let values = [];
				emails.map(v => { values.push(/@([Ss]hifl\.com|[Ss]hifl\.cn)/.test(v)) })

				if (values.length > 0) {
					let findNonShiflEmail = _.findIndex(values, v => ( v === false));
					if (findNonShiflEmail === -1) {
						emails = [];

						if (!status) {
							this.setEmailNotification(fieldName, status);
						}
					}
				}
			}

			if (emails.length === 0) {
				if (status) {
					this.showDailog(item.emails, this.getCurrentItem(item));
				}
			} else {
				if (fieldName == "eta_change_alert" || fieldName == "departure_notice" || fieldName == "document_upload") {
					this.setEmailNotification(fieldName, status);
					return;
				}
				if (status) {
					this.setEmailNotification(fieldName, status);
					return;
				}

				this.SET_EMAIL_WARNING(true);
				// this.SET_CURRENT_EDIT_ITEM(fieldName);
			}
		},
		closeEmailDialog(emails) {
			if (emails.length === 0) {
				let currentTitle = this.getCurrentEditItem === "Booking" ? "Booking Confirmation" : this.getCurrentEditItem;
				let findData = _.find(this.notificationsItems, e => (e.notificationTitle === currentTitle));
				if (findData !== undefined) {
					let existingEmails = typeof findData.emails === "string" ? JSON.parse(findData.emails) : findData.emails;
					existingEmails = existingEmails !== null ? existingEmails : [];

					if (findData.notificationEmail) {
						if (existingEmails.length === 0) {
							findData.notificationEmail = 0;
						} else if (existingEmails.length > 0) {
							let values = [];
							existingEmails.map(v => { values.push(/@([Ss]hifl\.com|[Ss]hifl\.cn)/.test(v)) });
							let isAllEmailsShifl = false;

							if (values.length > 0) {
								let findNonShiflEmail = _.findIndex(values, v => ( v === false));
								if (findNonShiflEmail === -1) {
									isAllEmailsShifl = true;
								}
							}

							if (isAllEmailsShifl) {
								let isValue = "";
								let currentTitle = this.getCurrentEditItem === "Booking" ? "Booking Confirmation" : this.getCurrentEditItem;
								let findData = _.find(notificationsItems, e => (e.notificationTitle === currentTitle));

								if (findData !== undefined) {
									isValue = findData.emailField
								}

								let findVal = this.getCustomer.email_recipient_setting[isValue];
								if (findVal !== findData.notificationEmail) {
									findData.notificationEmail = 0;
								}
							}
						}
					}
				}
			}
			this.SET_EMAIL_DAILOG(false);
		},
		openCustomTimeDialogM(notifi) {
			switch (notifi) {
				case "booking":
					this.SET_CURRENT_EDIT_ITEM("Booking");
					this.SET_CUSTOM_TIME_DAILOG(true);
					break;
				case "booking_updates":
					this.SET_CURRENT_EDIT_ITEM("New Schedules & Updates");
					this.SET_CUSTOM_TIME_DAILOG(true);
					break;
				case "document_upload":
					this.SET_CURRENT_EDIT_ITEM("Document Upload");
					this.SET_CUSTOM_TIME_DAILOG(true);
					break;
				case "departure_notice":
					this.SET_CURRENT_EDIT_ITEM("Departure Notice");
					this.SET_CUSTOM_TIME_DAILOG(true);
					break;
				case "arrival_notice":
					this.SET_CURRENT_EDIT_ITEM("Arrival Notice");
					this.SET_CUSTOM_TIME_DAILOG(true);
					break;
				case "delivery_order":
					this.SET_CURRENT_EDIT_ITEM("Delivery Order");
					this.SET_CUSTOM_TIME_DAILOG(true);
					break;
				case "booking_request":
					this.SET_CURRENT_EDIT_ITEM("Booking Request");
					this.SET_CUSTOM_TIME_DAILOG(true);
					break;
				case "consolidation_request":
					this.SET_CURRENT_EDIT_ITEM("Consolidation Request");
					this.SET_CUSTOM_TIME_DAILOG(true);
					break;
				default:
					this.SET_CURRENT_EDIT_ITEM("ETA Change Alert");
					this.SET_CUSTOM_TIME_DAILOG(true);
					break;
			}
		},
		updateNotification(notification, period) {
			let form = new FormData();
			form.append("customer_id", this.getCustomer.id);

			form.append("type", notification);
			if (period == "Active (Anytime)") {
				form.append("active_anytime", true);
			} else if (period == "Working Hours (8AM-5PM)") {
				form.append("working_hours", true);
			} else if (period == "OFF") {
				form.append("off_notification", true);
			} else {
				form.append("custom_time", true);
			}

			// switch (notification) {
			// 	case "Booking":
			// 		form.append("type", "booking");
			// 		if (period == "Active (Anytime)") {
			// 			form.append("active_anytime", true);
			// 		} else if (period == "Working Hours (8AM-5PM)") {
			// 			form.append("working_hours", true);
			// 		} else if (period == "OFF") {
			// 			form.append("off_notification", true);
			// 		} else {
			// 			form.append("custom_time", true);
			// 		}

			// 		break;
			// 	case "New Schedules & Updates":
			// 		form.append("type", "booking_updates");

			// 		if (period == "Active (Anytime)") {
			// 			form.append("active_anytime", true);
			// 		} else if (period == "Working Hours (8AM-5PM)") {
			// 			form.append("working_hours", true);
			// 		} else if (period == "OFF") {
			// 			form.append("off_notification", true);
			// 		} else {
			// 			form.append("custom_time", true);
			// 		}

			// 		break;
			// 	case "Document Upload":
			// 		form.append("type", "document_upload");
			// 		if (period == "Active (Anytime)") {
			// 			form.append("active_anytime", true);
			// 		} else if (period == "Working Hours (8AM-5PM)") {
			// 			form.append("working_hours", true);
			// 		} else if (period == "OFF") {
			// 			form.append("off_notification", true);
			// 		} else {
			// 			form.append("custom_time", true);
			// 		}

			// 		break;
			// 	case "Departure Notice":
			// 		form.append("type", "departure_notice");
			// 		if (period == "Active (Anytime)") {
			// 			form.append("active_anytime", true);
			// 		} else if (period == "Working Hours (8AM-5PM)") {
			// 			form.append("working_hours", true);
			// 		} else if (period == "OFF") {
			// 			form.append("off_notification", true);
			// 		} else {
			// 			form.append("custom_time", true);
			// 		}

			// 		break;
			// 	case "Arrival Notice":
			// 		form.append("type", "arrival_notice");

			// 		if (period == "Active (Anytime)") {
			// 			form.append("active_anytime", true);
			// 		} else if (period == "Working Hours (8AM-5PM)") {
			// 			form.append("working_hours", true);
			// 		} else if (period == "OFF") {
			// 			form.append("off_notification", true);
			// 		} else {
			// 			form.append("custom_time", true);
			// 		}

			// 		break;
			// 	case "Delivery Order":
			// 		form.append("type", "delivery_order");

			// 		if (period == "Active (Anytime)") {
			// 			form.append("active_anytime", true);
			// 		} else if (period == "Working Hours (8AM-5PM)") {
			// 			form.append("working_hours", true);
			// 		} else if (period == "OFF") {
			// 			form.append("off_notification", true);
			// 		} else {
			// 			form.append("custom_time", true);
			// 		}

			// 		break;
			// 	case "Booking Request":
			// 		form.append("type", "booking_request");

			// 		if (period == "Active (Anytime)") {
			// 			form.append("active_anytime", true);
			// 		} else if (period == "Working Hours (8AM-5PM)") {
			// 			form.append("working_hours", true);
			// 		} else if (period == "OFF") {
			// 			form.append("off_notification", true);
			// 		} else {
			// 			form.append("custom_time", true);
			// 		}

			// 		break;
			// 	case "Consolidation Request":
			// 		form.append("type", "consolidation_request");

			// 		if (period == "Active (Anytime)") {
			// 			form.append("active_anytime", true);
			// 		} else if (period == "Working Hours (8AM-5PM)") {
			// 			form.append("working_hours", true);
			// 		} else if (period == "OFF") {
			// 			form.append("off_notification", true);
			// 		} else {
			// 			form.append("custom_time", true);
			// 		}

			// 		break;
			// 	default:
			// 		form.append("type", "eta_changed_alert");
			// 		if (period == "Active (Anytime)") {
			// 			form.append("active_anytime", true);
			// 		} else if (period == "Working Hours (8AM-5PM)") {
			// 			form.append("working_hours", true);
			// 		} else if (period == "OFF") {
			// 			form.append("off_notification", true);
			// 		} else {
			// 			form.append("custom_time", true);
			// 		}

			// 		break;
			// }

			this.updateNotificationTime(form);
		},
		setEmailNotification(notification, status) {
			let form = new FormData();
			form.append("status", status);
			form.append("customer_id", this.getCustomer.id);

			let notifType = notification === "booking" ? "booking_confirmation" : notification;
			form.append("type", notifType);
			this.updateEmailSettings(form);
			// switch (notification) {
			// 	case "booking":
			// 		form.append("type", "booking");
			// 		break;
			// 	case "booking_updates":
			// 		form.append("type", "booking_updates");
			// 		break;
			// 	case "document_upload":
			// 		form.append("type", "document_upload");
			// 		break;
			// 	case "departure_notice":
			// 		form.append("type", "departure_notice");
			// 		break;
			// 	case "arrival_notice":
			// 		form.append("type", "arrival_notice");
			// 		break;
			// 	case "delivery_order":
			// 		form.append("type", "delivery_order");
			// 		break;
			// 	case "booking_request":
			// 		form.append("type", "booking_request");
			// 		break;
			// 	case "consolidation_request":
			// 		form.append("type", "consolidation_request");
			// 		break;
			// 	default:
			// 		form.append("type", "eta_change_alert");
			// }
			// this.updateEmailSettings(form);
		},
		// dynamic methods start
		getNotificationData() {
			if (this.getCustomer !== "undefined" && this.getCustomer !== null) {
				// let customer = this.getCustomer;
				let pushSettings = this.pushSettings;
				let emailSettings = this.emailSettings;

				this.notificationsItems.map((v) => {
					v.notificationEmail = emailSettings[v.emailField];
					v.notificationPush = pushSettings[v.pushField];
					v.notificationPushValue = this.getFieldValue(v.pushField);
					v.emails = emailSettings[v.emailListField] !== null && emailSettings[v.emailListField] !== null
						? emailSettings[v.emailListField] : "[]";
					v.notifPushBooleanVal = pushSettings[v.pushField] !== "OFF" ? true : false;
				})

				this.unreturnedContainerValue = emailSettings.unreturned_container_days;
			}
		},
		getFieldValue(field) {
			if (field === "booking_request") return this.bookingRequestNotifi;
			else if (field === "booking_updates") return this.bookingUpdatesNotifi;
			else if (field === "booking_confirmation") return this.bookingNotifi;
			else if (field === "consolidation_request") return this.consolidationRequestNotifi;
			else if (field === "document_upload") return this.documentUploadNotifi;
			else if (field === "departure_notice") return this.departureNotfi;
			else if (field === "arrival_notice") return this.arrivalNotifi;
			else if (field === "delivery_order") return this.DONotifi;
			else if (field === "eta_change_alert") return this.etaChangeAlertNotifi;
			else if (field === "discharged_alert") return this.dischargeNotifi;
			else if (field === "lfd_alert") return this.lfdNotifi;
			else if (field === "unreturned_container_alert") return this.unreturnedContNotifi;
		},
		onClickPushCheckbox(item, status) {
			const menuComponent = this.$refs[item.refName][0];
			let currentEditItem = item.notificationTitle === "Booking Confirmation" ? "Booking" : item.notificationTitle;

			if (!this.getBoxOpen.show) {
				let payload = { show: true, ref: item.refName, state: item.refName };
				this.SET_BOX_OPEN(payload);
			}
			if (item.notifPushBooleanVal) {
				// if (this.stateData[item.refName].length === 4) this.stateData[item.refName].shift();				
				menuComponent.activateMenu();
				menuComponent.focus();

				// let findItem = _.filter(this.notificationsItems, e => ( 
				// 	e.notifPushBooleanVal && e.notificationPush === "OFF" && e.refName !== item.refName
				// ));

				// if (findItem.length > 0) {
				// 	findItem.map(v => {
				// 		this.notificationsItems.map(x => {
				// 			if (v.refName === x.refName) {
				// 				const newItem = "Push Notification Turned Off";
				// 				x.notifPushBooleanVal = false;
				// 				this.stateData[x.refName].unshift(newItem);
				// 				let otherMenuComponent = this.$refs[x.refName][0];
				// 				otherMenuComponent.blur();
				// 			}								
				// 		})
				// 	})
				// }
			} else {
				// let currentEditItem = item.notificationTitle === "Booking Confirmation" 
				// 	? "Booking" : item.notificationTitle;
				// this.setDropdownToDefault(item);
				
				if (!item.notifPushBooleanVal && item.notificationPush !== "OFF") {
					this.updateNotification(item.pushField, "OFF");
				}				
				menuComponent.blur();
				let payload = { show: false, ref: null, state: null };
				this.SET_BOX_OPEN(payload);
			}

			if (!status) {
				this.SET_CURRENT_EDIT_ITEM(currentEditItem);
			}
		},
		onChangePushOption(val, item, status) {
			let currentEditItem = item.notificationTitle === "Booking Confirmation" ? "Booking" : item.notificationTitle;

			if (status) this.SET_CURRENT_EDIT_ITEM(currentEditItem);

			if (val == "Custom Time") {
				this.SET_CUSTOM_TIME_DAILOG(true);
				return;
			}
			this.updateNotification(item.pushField, val);
		},
		setTimeAction(item) {
			this.SET_TEMP_PERIOD(this.getCustomer.push_notification_setting[item.pushField]);
			const menuComponent = this.$refs[item.refName][0];
			// if (this.stateData[item.refName].length == 4 && this.getCustomer.push_notification_setting[item.pushField] !== "OFF") {
			// 	this.stateData[item.refName].shift();
			// } 
			if (this.getCustomer.push_notification_setting[item.pushField] === "OFF") {
				// this.setDropdownToDefault(item);
				let findIndex = _.findIndex(this.notificationsItems, e => (e.refName === item.refName));				
				if (findIndex > -1) {
					this.notificationsItems[findIndex].notifPushBooleanVal = false;
				}
				menuComponent.blur();
				return;
			}
		},
		getCurrentItem(item) {
			return item.notificationTitle === "Booking Confirmation" ? "Booking" : item.notificationTitle;
		},
		getCustomNotifTime(item, field) {
			let time = "";
			let newTime = "";
			if (item === "Custom Time") {
				let timeField = `time_${field}`;
				time = this.pushSettings[timeField];
				
				time = time !== null ? JSON.parse(time) : "";

				if (time !== "" && time.length > 0) {
					time[0] = moment(time[0], ["hh:mm A"]).format("h:mmA");
					time[1] = moment(time[1], ["hh:mm A"]).format("h:mmA");
					newTime = `(${time[0]}-${time[1]})`
				}
				return newTime;
			}
		},
		blurSelect(item) {
			if (item !== undefined) {
				let findIndex = _.findIndex(this.notificationsItems, e => ( e.refName === item.refName ));
				if (findIndex > -1) {
					if (item.notifPushBooleanVal && item.notificationPush === "OFF" && !this.getCustomTimeLoading) {
						if (item.notificationPushValue !== "Custom Time") {
							const menuComponent = this.$refs[item.refName][0];
							setTimeout(() => {
								this.notificationsItems[findIndex].notifPushBooleanVal = false;
								menuComponent.blur();
							}, 200);
						}
					}
				}
			}
		},
		setDropdownToDefault(item) {
			if (this.stateData[item.refName].length === 3) {
				const newItem = "Push Notification Turned Off";
				this.stateData[item.refName].unshift(newItem);
			}
		},
		getPushWholeValue(item) {
			if (item !== null) {
				if (item == "OFF") {
					return "Push Notification Turned Off";
				} else if (item == "AA") {
					return "Active (Anytime)";
				} else if (item == "WH") {
					return "Working Hours (8AM-5PM)";
				} else {
					return "Custom Time";
				}
			}
		},
		async updateContainerAlert(value) {
			let payload = {
				customer_id: this.getCustomer.id,
				unreturned_container_hours: value
			}			
			await axios
			.post(`${APIBaseUrl}/v2/notifications/update-unreturned-container-days`, payload)
			.then(() => {
				this.getCustomer.email_recipient_setting.unreturned_container_hours = value;
			})
			.catch((e) => {
				console.log(e);
			});
		},
		getConvertedHours(value) {
			if (value !== null && value !== "") {
				// let parseValue = parseFloat(value);
				// if (Number.isInteger(parseValue)) {
				// 	return parseValue * 24;
				// } else {
				// 	let floatValue = (parseFloat(value) * 24).toFixed(2);
				// 	let checkValue = floatValue.split(".");
				// 	let finalValue = checkValue[1] === "00" ? checkValue[0] : floatValue;
				// 	return finalValue
				// }

				return parseInt(value * 24);
			} else return 0;
		},
		getConvertedDays(value) {
			if (value !== null && value !== "") {
				let parseValue = parseFloat(value);
				if (Number.isInteger(parseValue)) {
					return parseValue / 24;
				}
			}
			return value;
		}
	},
	watch: {
		getCustomer() {
			this.getNotificationData();
		},
		getCustomTimeDailog(val) {
			if (!val) {
				this.getNotificationData();
			}
		}
	},
	mounted() {
		this.getNotificationData();
	}
};
</script>
<style lang="scss">
.v-menu__content {
	&.notifi-menu-m {
		.v-list {
			.v-list-item {
				min-height: 38px !important;
				&:hover {
					background-color: $shifl-primary-b10;
				}
				&::before {
					display: none;
				}
				&.v-list-item--active {
					background-color: $shifl-primary-b10;
				}

				&:first-child {
					display: none !important;
				}
			}
		}
	}
}
.notification-setting-m {
	padding: 10px;
	background-color: #f1f6fa !important;

	.sub-heading {
		color: $shifl-neutral-n90;
		font-size: 16px;
		font-family: "Inter-SemiBold", sans-serif;
	}
	.item-main-m {
		border-bottom: 1px solid $shifl-neutral-n30;
		border-radius: 4px;
		padding: 12px;
		background-color: #fff;

		.item-m {
			&.unreturned_container_alert {
				min-height: 80px;

				.unreturned-notif-wrapper {
					color: $shifl-neutral-n70;
					font-size: 14px;

					.item-unreturned-select {
						width: 43px;
						font-size: 14px;
						padding-top: 0 !important;
						height: 24px;
						// border: 1px solid $shifl-primary-b90 !important;
						border-radius: 4px;
						margin: 0 6px;

						.v-input__control,
						.v-input__slot {
							height: 24px;
							background-color: #fff !important;
							.v-select__selection.v-select__selection--comma {
								margin: 0 !important;
							}
							input {
								padding: 0 !important;
								text-align: right;
							}
							.v-icon {
								font-size: 18px !important;
							}
						}

						.v-input__slot {							
							border: 1px solid $shifl-neutral-n40 !important;
							padding: 0 4px 0 6px;
						}
					}

					.hours-converted {
						color: $shifl-neutral-n90 !important;
						background-color: $shifl-neutral-n30;
						border-radius: 4px;
						height: 24px;
						min-width: 40px;
						padding: 2px 6px;
						display: flex;
						justify-content: center;
						align-items: center;
						margin: 0 4px;
					}
				}
			}
			.item-heading {
				font-size: 14px;
				color: $shifl-neutral-n90;
				font-family: "Inter-SemiBold", sans-serif;
				padding-left: 2px;
			}
			.item-right-section-m {
				margin-top: -20px;
				.upper {
					margin-top: -10px;
					width: 155px !important;
				}
				display: flex;
				flex-direction: column;
				line-height: -10px !important;
				justify-content: center;
				.notify-type {
					margin-bottom: 8px;
				}
				.notify-type .v-input__slot .v-label {
					color: $shifl-neutral-n90;
					font-size: 14px;
					font-family: "Inter-Regular";
				}

				i {
					&.mdi-checkbox-blank-outline {
						width: 26px !important;
						padding: 3px !important;

						&::before {
							content: "" !important;
							background-image: url("/checkbox-empty-icon-1.svg") !important;
							background-position: center !important;
							background-repeat: no-repeat !important;
							background-size: cover !important;
							width: 16px !important;
							height: 16px !important;
						}
					}

					&.mdi-checkbox-marked {
						width: 26px !important;
						padding: 3px !important;

						&::before {
							content: "" !important;
							background-image: url("/checkbox-filled-icon-1.svg") !important;
							background-position: center !important;
							background-repeat: no-repeat !important;
							background-size: cover !important;
							width: 16px !important;
							height: 16px !important;
						}
					}
				}
				.fixed-len {
					width: 75px !important;
				}
				.item-notif-settings-m {
					border: 1px solid $shifl-neutral-n40;
					border-radius: 4px;
					width: 100%;
					align-items: center;
					padding-top: 0;
					margin-top: 0;
					background-color: #fff !important;
					.v-input__slot {
						min-height: 38px;
					}
					.notification-selection {
						color: $shifl-primary-b90;
						font-size: 12px !important;
						width: 100%;
						text-align: center;

						&.n-default--text {
							color: $shifl-neutral-n50;
						}
					}
					.v-input__control .v-select__slot .v-select__selection {
						font-size: 12px;
						color: $shifl-neutral-n50;
						font-family: "Inter-Medium", sans-serif !important;
						width: 100% !important;
						margin-left: 10px !important;
						text-align: center !important;
					}
					.v-input__append-inner .v-input__icon {
						margin-top: 4px;
						margin-right: 4px;

						i {
							color: $shifl-primary-b90;
							font-size: 20px;
						}
					}
				}
			}
		}
		.item-description-m {
			background-color: $shifl-primary-b10;
			padding: 0px 8px;
			// margin-bottom: 16px;
			min-height: 34px;
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin-top: 0;
			width: 100%;
			border-top-left-radius: 4px;
    		border-top-right-radius: 4px;

			.email-text-m {
				color: $shifl-neutral-n90;
				font-family: "Inter-Regular", sans-serif;
				font-size: 12px;
				margin-bottom: 0;
				margin-right: 6px;
			}
			button {
				p {
					color: $shifl-primary-b90;
					font-family: "Inter-Medium";
					font-size: 12px;
					margin-bottom: 0;
				}
			}
		}
		.email-descp-m-wrapper {
			background-color: $shifl-primary-b10;
			padding: 0 8px 8px;
			margin-bottom: 16px;
			border-bottom-left-radius: 4px;
    		border-bottom-right-radius: 4px;

			.inner-email-desc-m {
				display: inline-block;
			}

			.email-descp-m {
				background-color: #fff;
				border-radius: 4px;
				height: 24px;
				padding: 3px 8px;
				display: flex;
				align-items: center;
				border: 1px solid $shifl-neutral-n40;
				margin-right: 6px;
				margin-bottom: 4px;

				p {
					color: $shifl-neutral-n90;
					font-size: 12px;
					font-family: "Inter-Regular";
					margin-bottom: 0;
					display: inline-block;
				}
			}
		}		
	}
}
.item-notif-settings-m.active {
	.v-input__control .v-select__slot .v-select__selection {
		color: $shifl-primary-b90 !important;
		font-family: "Inter-SemiBold", sans-serif !important;
	}
}
.v-text-field > .v-input__control > .v-input__slot:before,
.v-text-field > .v-input__control > .v-input__slot:after {
	border: none !important;
}
</style>
<template>
	<div class="compact-table-wrapper-sec">
        <div class="overlay-loading" v-if="isFetchingData">
            <v-progress-circular
                :size="30"
                color="#1A6D9E"
                indeterminate
                v-if="isFetchingData">
            </v-progress-circular>
            <p class="mt-2" style="font-size: 14px; color: #253041;" v-if="current_page > 1">Fetching data...</p>
        </div>

        <v-data-table
            :headers="headers"
            :items="cardViewShipmentsData"
            item-key="id"
            fixed-header
            hide-default-footer
            class="compact-table"
            :items-per-page="itemsPerPage">

            <template v-for="(header, i) in headers" v-slot:[`header.${header.value}`]="{}">
                <div :key="header.value" class="header-text-filter font-semi-bold main-header" :class="header.align">
                    {{ header.text }}

                    <span class="ml-2" v-if="header.sortable">
                        <v-icon class="customize-icon" small>{{ shipmentIcon }}</v-icon>
                    </span>
                </div>

                <div @click.stop :key="i" v-if="isFilterEnabled" class="header-text-filter --row" 
                    :class="`${header.type} ${header.value}`"
                    :style="`width: calc(${header.width}  - 1px)`">
                    <v-text-field
                        :key="i"
                        v-model.trim="multiSearch[header.value]"
                        class="pa"
                        :type="header.type"
                        hide-details="auto"
                        outlined
                        dense
                        v-if="header.type === 'text'"
                        prepend-inner-icon="mdi-magnify"
                        clearable
                        clear-icon="mdi-close"
                        placeholder="Search"
                        :style="`width: calc(${header.width})`"
                        @input="(e) => { handleSearch(header, 'input') }">

                        <template v-slot:prepend-inner>
                            <v-menu offset-y bottom min-width="150px" content-class="operation-menus">
                                <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs" v-on="on">
                                        <v-icon left size="18px" color="#9CA5B4" style="margin-right: 0 !important;">
                                            {{ getHeaderOperationIcon(header.operation) }}
                                        </v-icon>
                                    </span>
                                </template>
                                <v-card>
                                    <v-card-text class="pa-0">
                                        <v-list class="operation-lists">
                                            <v-list-item-group v-model="header.operation">
                                                <v-list-item v-for="(item, i) in header.filterItems" :key="i" class="operation-items" @click="verifyValue(header, 'input')">
                                                    <v-list-item-title>
                                                        <v-icon left size="18px">{{ item.icon }}</v-icon>
                                                        {{ item.text }}
                                                    </v-list-item-title>
                                                </v-list-item>
                                            </v-list-item-group>
                                        </v-list>
                                    </v-card-text>
                                </v-card>
                            </v-menu>
                        </template>
                    </v-text-field>

                    <v-autocomplete
                        class="text-fields select-items header-filter-items"
                        v-model="multiSearch[header.value]"
                        :items="header.items"
                        item-text="name"
                        item-value="id"
                        outlined
                        hide-details="auto"
                        clearable
                        attach
                        v-else-if="header.type === 'select'"
                        placeholder="Select"
                        @change="(e) => { handleSearch(header, 'select') }"
                        :menu-props="{ bottom: true, offsetY: true, contentClass: 'header-filter-items' }">
                    </v-autocomplete>

                    <div class="date-picker-wrapper" v-else-if="header.type === 'date'" style="height: 40px; width: 100%;">
                        <div class="vc-date-picker__wrapper d-flex align-center" v-if="header.operation !== 2">
                            <v-menu offset-y bottom min-width="150px" content-class="operation-menus">
                                <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs" v-on="on">
                                        <v-icon left size="18px" color="#9CA5B4" class="mr-1">
                                            {{ getHeaderOperationIcon(header.operation) }}
                                        </v-icon>
                                    </span>
                                </template>
                                <v-card>
                                    <v-card-text class="pa-0">
                                        <v-list class="operation-lists">
                                            <v-list-item-group v-model="header.operation">
                                                <v-list-item v-for="(item, i) in header.filterItems" :key="i" class="operation-items" @click="handleSearch(header, 'input')">
                                                    <v-list-item-title>
                                                        <v-icon left size="18px">{{ item.icon }}</v-icon>
                                                        {{ item.text }}
                                                    </v-list-item-title>
                                                </v-list-item>
                                            </v-list-item-group>
                                        </v-list>
                                    </v-card-text>
                                </v-card>
                            </v-menu>
                            
                            <vc-date-picker
                                title-position="left"
                                is-expanded
                                v-model="multiSearch[header.value]"
                                mode="date"
                                :popover="{ visibility: 'focus' }"
                                :firstDayOfWeek="2"
                                :masks="masks"
                                :select-attribute="attribute"
                                ref="dateField"
                                @input="(e) => { handleSearch(header, 'input') }">

                                <template #default="{ inputValue, inputEvents }">
                                    <input
                                        :value="inputValue"
                                        v-on="inputEvents"
                                        class="dxdate-vc"
                                        placeholder="Select" />
                                </template>
                            </vc-date-picker>

                            <button type="button" class="vc-date-clear-button" @click="clearValue(header)" style="margin-bottom: 2px;" 
                                v-if="typeof multiSearch[header.value] !== 'undefined' && multiSearch[header.value] !== '' &&
                                multiSearch[header.value] !== null">
                                <v-icon size="18px" color="#B11B1B">mdi-close</v-icon>
                            </button>
                        </div>

                        <div v-else>
                            <div class="vc-date-picker__wrapper d-flex align-center">
                                <v-menu offset-y bottom min-width="150px" content-class="operation-menus">
                                    <template v-slot:activator="{ on, attrs }">
                                        <span v-bind="attrs" v-on="on">
                                            <v-icon left size="18px" color="#9CA5B4" class="mr-1">
                                                {{ getHeaderOperationIcon(header.operation) }}
                                            </v-icon>
                                        </span>
                                    </template>
                                    <v-card>
                                        <v-card-text class="pa-0">
                                            <v-list class="operation-lists">
                                                <v-list-item-group v-model="header.operation">
                                                    <v-list-item v-for="(item, i) in header.filterItems" :key="i" class="operation-items" @click="handleSearch(header, 'input')">
                                                        <v-list-item-title>
                                                            <v-icon left size="18px">{{ item.icon }}</v-icon>
                                                            {{ item.text }}
                                                        </v-list-item-title>
                                                    </v-list-item>
                                                </v-list-item-group>
                                            </v-list>
                                        </v-card-text>
                                    </v-card>
                                </v-menu>
                                
                                <vc-date-picker 
                                    v-model="range[header.value]" 
                                    is-range
                                    title-position="left"
                                    :popover="{ visibility: 'focus' }"
                                    is-expanded
                                    :firstDayOfWeek="2"
                                    :masks="masks"
                                    :select-attribute="attributeRange"
                                    :drag-attribute="attributeRange"
                                    @input="(e) => { handleSearch(header, 'select-date') }">

                                    <template #default="{ inputValue, inputEvents }">
                                        <input
                                            :value="`${inputValue.start} - ${inputValue.end}`"
                                            v-on="inputEvents.start"
                                            class="dxdate-vc"
                                            placeholder="Select"
                                            style="margin-left: 2px;" />
                                    </template>
                                </vc-date-picker>

                                <button type="button" class="vc-date-clear-button" @click="clearValue(header)" style="margin-bottom: 2px;" 
                                    v-if="typeof range[header.value] !== 'undefined' && range[header.value] !== ''">
                                    <v-icon size="18px" color="#B11B1B">mdi-close</v-icon>
                                </button>
                            </div>
                        </div>
                    </div>

                    <v-text-field
                        :key="i"
                        v-model="multiSearch[header.value]"
                        class="pa"
                        :type="header.type"
                        hide-details="auto"
                        outlined
                        dense
                        disabled
                        v-else>
                    </v-text-field>
                </div>
            </template>

            <template v-slot:[`item.etd`]="{ item }">
                <div class="template-data">
                    <span :style="`color: ${item.etd === null ? '#253041' : '#1A6D9E'}`">
                        {{ convertDateAction(item.etd) }}
                    </span>
                </div>
            </template>

            <template v-slot:[`item.eta`]="{ item }">
                <div class="template-data">
                    <span :style="`color: ${item.eta === null ? '#253041' : '#1A6D9E'}`">
                        {{ convertDateAction(item.eta) }}
                    </span>
                </div>
            </template>

            <template v-slot:[`item.mode`]="{ item }">
                <div class="template-data d-flex justify-center align-center">
                    <div class="shipment-type d-flex align-center justify-center" v-if="item.mode !== null && item.mode !== ''">
                        <img v-if="item.mode == 'Ocean'" src="@/assets/icons/ocean.svg" width="20px" height="20px" />
                        <img v-if="item.mode == 'Air'" src="@/assets/icons/airplane.svg" width="20px" height="20px" />
                        <img v-if="item.mode == 'Truck'" src="@/assets/icons/truck.svg" width="20px" height="20px" />
                    </div>

                    <div class="no-shipment-type text-center" v-if="item.mode === null || item.mode === ''">
                        N/A
                    </div>
                </div>
            </template>

            <template v-slot:[`item.shipment_type`]="{ item }">
                <div class="template-data d-flex justify-center align-center">
                    <div class="shipment-type d-flex justify-center align-center" 
                        v-if="item.shipment_type !== null && item.shipment_type !== ''">
                        <img v-if="item.shipment_type == 'LCL'" src="@/assets/images/small-container.svg" width="20px" height="20px" />
                        <img v-if="item.shipment_type == 'Air'" src="@/assets/images/airplane-shipment.svg" width="20px" height="20px" />
                        <img v-if="item.shipment_type == 'FCL'" src="@/assets/images/big-container.svg" width="20px" height="20px" />
                    </div>

                    <div class="no-shipment-type text-center" v-if="(item.shipment_type == null || item.shipment_type == '')">
                        N/A
                    </div>
                </div>
            </template>

            <template v-slot:[`item.po_num`]="{ item }">
                <div class="template-data">
                    {{ concatPO(item.po_num) }}                        
                </div>
            </template>

            <template v-slot:[`item.shipment_status`]="{ item }">
                <div class="report-view-status d-flex">
                    <div class="d-flex t-shipment-status-wrapper">
                        <div class="font-medium pa-0" :class="getShipmentStatusClass(item, 'default')">
                            <v-chip> {{ ucFirst(item.status.toLowerCase()) }} </v-chip>
                        </div>
                    </div>
                </div>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
                <div class="shipment-action-button d-flex align-center justify-center">
                    <button class="btn-white" @click="handleClick(item)"
                        style="width: 34px !important; height: 34px !important;">
                        <img src="@/assets/icons/visibility-gray.svg" alt="" height="16px" width="16px">
                    </button>
                    <div class="three-dots-wrapper">
                        <v-menu offset-y bottom left content-class="pending-dropdown-container">
                            <template v-slot:activator="{ on, attrs }">
                                <button v-bind="attrs" v-on="on" class="shipment-three-dots-container"
                                style="width: 34px !important; height: 34px !important;">
                                    <custom-icon
                                        iconName="three-dots"
                                        color="#1A6D9E"
                                        width="11"
                                        height="3" />
                                </button>
                            </template>
                            <v-list>
                                <v-list-item>
                                    <v-list-item-title class="k-fw-sm k-font-inter-regular k-dark-blue" @click="markShipmentCompletedDialog(item)">
                                        Mark As Completed
                                    </v-list-item-title>
                                </v-list-item>
                                <!-- <v-list-item>
                                    <v-list-item-title class="k-fw-sm k-font-inter-regular">
                                        Edit Shipment
                                    </v-list-item-title>
                                </v-list-item> -->
                                <v-list-item>
                                    <v-list-item-title class="k-fw-sm k-font-inter-regular k-red" @click="cancelRequestForm(item.id)">
                                        Request Cancellation
                                    </v-list-item-title>
                                </v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                </div>  
            </template>

            <template v-slot:no-data>
                <div class="no-data-preloader" v-if="isFetchingData">
                    <v-progress-circular
                        :size="30"
                        color="#1A6D9E"
                        indeterminate>
                    </v-progress-circular>
                </div>

                <div v-if="!isFetchingData" class="no-data-wrapper">
                    <div class="no-data-icon">
                        <img src="@/assets/icons/noShipmentData.svg" alt="" width="65px">
                    </div>

                    <div class="no-data-heading">
                        <p> No Shipments </p>
                    </div>
                </div>
            </template>
        </v-data-table>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex"
import globalMethods from '@/utils/globalMethods'
import _ from "lodash";
import moment from "moment";
import CustomIcon from "@/components/Icons/CustomIcon";

const baseURL = process.env.VUE_APP_BASE_URL;
import axios from "axios";
var cancel;
var CancelToken = axios.CancelToken;
import { shipmentStatusesLists } from "../js/statusLists";

export default {
	name: "ReportViewCustom",
    props: ['isFilterEnabled', 'tab', 'search', 'shipmentIcon', 'pendingShipmentTab', 'activeTab'],
    components: {
        CustomIcon
    },
	data() {
		return {
			headers: [
				{
                    text: "Reference",
                    align: "start",
                    sortable: false,
                    value: "shifl_ref",
                    width: "120px",
                    fixed: true,
                    type: "text",
                    operation: "contains",
                    filterItems: [
                        {
                            text: "Contains",
                            icon: "mdi-magnify"
                        },
                        {
                            text: "Equals",
                            icon: "mdi-equal"
                        },
                    ]
                },
                {
                    text: "Departure",
                    align: "start",
                    value: "location_from_name",
                    sortable: false,
                    width: "150px", 
                    fixed: true,
                    type: "text",
                    operation: "contains",
                    filterItems: [
                        {
                            text: "Contains",
                            icon: "mdi-magnify"
                        },
                        {
                            text: "Equals",
                            icon: "mdi-equal"
                        },
                    ]
                },
                {
                    text: "Departure Date",
                    align: "start",
                    value: "etd",
                    sortable: true,
                    width: "180px", 
                    fixed: true,
                    type: "date",
                    operation: "contains",
                    filterItems: [
                        {
                            text: "Contains",
                            icon: "mdi-magnify"
                        },
                        {
                            text: "Equals",
                            icon: "mdi-equal"
                        },
                        {
                            text: "Between",
                            icon: "mdi-arrow-left-right"
                        },
                        {
                            text: "Less than",
                            icon: "mdi-less-than"
                        },
                        {
                            text: "Greater than",
                            icon: "mdi-greater-than"
                        },
                    ]
                },
                {
                    text: "Mode",
                    align: "center",
                    value: "mode",
                    sortable: false,
                    width: "112px",
                    fixed: true,
                    type: "select",
                    items: [
                        {
                            value: "Ocean",
                            name: "Ocean"
                        },
                        {
                            value: "Air",
                            name: "Air"
                        },
                        {
                            value: "Rail",
                            name: "Rail"
                        },
                        {
                            value: "Truck",
                            name: "Truck"
                        },
                    ],
                    operation: "equals",
                    filterItems: [
                        {
                            text: "Contains",
                            icon: "mdi-magnify"
                        },
                        {
                            text: "Equals",
                            icon: "mdi-equal"
                        },
                    ]
                },
                {
                    text: "Type",
                    align: "center",
                    value: "shipment_type",
                    sortable: false,
                    width: "100px",
                    fixed: true,
                    type: "select",
                    items: [
                        {
                            value: "FCL",
                            name: "FCL"
                        },
                        {
                            value: "LCL",
                            name: "LCL"
                        },
                        {
                            value: "Air",
                            name: "Air"
                        },
                    ],
                    operation: "equals",
                    filterItems: [
                        {
                            text: "Contains",
                            icon: "mdi-magnify"
                        },
                        {
                            text: "Equals",
                            icon: "mdi-equal"
                        },
                    ]
                },
                {
                    text: "Container",
                    align: "center",
                    value: "containers_qty",
                    sortable: false,
                    width: "110px",
                    fixed: true,
                    type: "text",
                    operation: "contains",
                    filterItems: [
                        {
                            text: "Contains",
                            icon: "mdi-magnify"
                        },
                        {
                            text: "Equals",
                            icon: "mdi-equal"
                        },
                        {
                            text: "Less than",
                            icon: "mdi-less-than"
                        },
                        {
                            text: "Greater than",
                            icon: "mdi-greater-than"
                        },
                    ]
                },
                {
                    text: "Arrival",
                    align: "start",
                    value: "location_to_name",
                    sortable: false,
                    width: "180px", 
                    fixed: true,
                    type: "text",
                    operation: "contains",
                    filterItems: [
                        {
                            text: "Contains",
                            icon: "mdi-magnify"
                        },
                        {
                            text: "Equals",
                            icon: "mdi-equal"
                        },
                    ]
                },
                {
                    text: "Arrival Date",
                    align: "start",
                    value: "eta",
                    sortable: true,
                    width: "180px", 
                    fixed: true,
                    type: "date",
                    operation: "contains",
                    filterItems: [
                        {
                            text: "Contains",
                            icon: "mdi-magnify"
                        },
                        {
                            text: "Equals",
                            icon: "mdi-equal"
                        },
                        {
                            text: "Between",
                            icon: "mdi-arrow-left-right"
                        },
                        {
                            text: "Less than",
                            icon: "mdi-less-than"
                        },
                        {
                            text: "Greater than",
                            icon: "mdi-greater-than"
                        },
                    ]
                },
                {
                    text: "PO",
                    align: "start",
                    value: "po_num",
                    sortable: false,
                    width: "180px",
                    fixed: true,
                    type: "text",
                    operation: "contains",
                    filterItems: [
                        {
                            text: "Contains",
                            icon: "mdi-magnify"
                        },
                        {
                            text: "Equals",
                            icon: "mdi-equal"
                        },
                    ]
                },
                {
                    text: "Status",
                    value: "shipment_status",
                    align: 'start',
                    sortable: false,
                    width: "210px",
                    fixed: true,
                    type: "select",
                    operation: "contains",
                    filterItems: [
                        {
                            text: "Contains",
                            icon: "mdi-magnify"
                        },
                        {
                            text: "Equals",
                            icon: "mdi-equal"
                        },
                    ],
                    items: [
                        {
                            value: "Awaiting Departure",
                            name: "Awaiting Departure"
                        },
                        {
                            value: "In Transit",
                            name: "In Transit"
                        },
                        {
                            value: "In Transit - Pending Release",
                            name: "In Transit - Pending Release"
                        },
                        {
                            value: "In Transit - Released",
                            name: "In Transit - Released"
                        },
                        {
                            value: "Discharged",
                            name: "Discharged"
                        },
                        {
                            value: "Discharged - Released",
                            name: "Discharged - Released"
                        },
                        {
                            value: "Discharged - Hold",
                            name: "Discharged - Hold"
                        },
                        {
                            value: "Partial Discharged - Released",
                            name: "Partial Discharged - Released"
                        },
                        {
                            value: "Partial Discharged - Hold",
                            name: "Partial Discharged - Hold"
                        },
                    ],
                },
                {
                    text: "",
                    value: "actions",
                    align: 'start',
                    sortable: false,
                    width: "100px",
                    fixed: true,
                },
			],
            trackingStatusMap: [
                {
                    class: 'manual-tracking',
                    value: 'Manual Tracking'
                },
                {
                    class: 'auto-tracking',
                    value: 'Auto Tracking'
                },
                {
                    class: 'auto-tracked',
                    value: 'Auto Tracked'
                },
                {
                    class: 'manually-tracked',
                    value: 'Manually Tracked'
                },
                {
                    class: 'not-tracking',
                    value: 'Not Tracking'
                },
                {
                    class: 'past-last-free-day',
                    value: 'Past Last Free Day'
                },
                {
                    class: 'discharged-released',
                    value: 'Discharged - released'
                },
                {
                    class: 'in-transit-hold',
                    value: 'In transit - hold'
                },
                {
                    class: 'in-transit-released',
                    value: 'In transit - released'
                },
                {
                    class: 'discharged-hold',
                    value: 'Discharged - Hold'
                },
                {
                    class: 'partially-discharged',
                    value: 'Partially discharged'
                }
            ],
			multiSearch: {},
            itemsPerPage: 20,
            bottom: false,
            shipmentsData: [],
            current_page: 1,
            last_page: 1,
            isFetchingData: false,
            typingTimeout: 0,
            attribute: {
            highlight: {
                    style: {
                        backgroundColor: '#1A6D9E', // blue
                        borderRadius: '4px'
                    },
                    contentStyle: {
                        color: '#ffffff', // color of the text
                        borderRadius: '4px'
                    }
                },
            },
            masks: {
                weekdays: "WWW"
            },
            // range: {
            //     eta: {
            //         start: "",
            //         end: ""
            //     },
            //     etd: {
            //         start: "",
            //         end: ""
            //     }
            // },
            range: {},
            attributeRange: {
                highlight: {
                    start: {
                        style: {
                            backgroundColor: '#1A6D9E', // blue
                            borderRadius: '4px 0 0 4px'
                        },
                        contentStyle: {
                            color: '#ffffff', // color of the text
                            borderRadius: '4px'
                        }
                    },
                    base: {
                        style: {
                            backgroundColor: '#E1F4FF', // light blue
                        }
                    },
                    end: {
                        style: {
                            backgroundColor: '#1A6D9E', // blue
                            borderRadius: '0 4px 4px 0'
                        },
                        contentStyle: {
                            color: '#ffffff', // color of the text
                            borderRadius: '4px'
                        }
                    },
                },
            },
            isShow: false
		};
	},
	computed: {
        ...mapGetters([
            "getAllShipmentsNoPaginationShipments",
            "getAllShipmentsNoPaginationCompleted",
            "getSearchedShipments",
            "getAllShipmentsNoPaginationBooking"
        ]),
        cardViewShipmentsData() {
            let shipmentsArray = []

            // if (typeof this.getSearchedShipments !== 'undefined' && this.getSearchedShipments !== null) {
            //     if (this.search !== '') { // && this.getSearchedShipments.tab === 'shipments'
            //         shipmentsArray = this.getSearchedShipments.shipments
            //     } else {
            //         shipmentsArray = this.shipmentsData
            //     }
            // } else {
            //     shipmentsArray = this.shipmentsData
            // }

            shipmentsArray = this.shipmentsData;

            return shipmentsArray
        },
	},
    created() {},
    mounted() {
        //set current page
        this.$store.dispatch("page/setPage", "shipments");

        const tableWrapper = document.querySelector('.v-data-table__wrapper')
        tableWrapper.addEventListener('scroll', this.bottomVisible);

        if (this.tab === "shipments" || this.tab === "completed") {
            this.fetchShipmentsData();
        } else {
            this.getBookingShipmentData();
        }

        // if (this.tab === "shipments") {
        //     if (this.getAllShipmentsNoPaginationShipments.shipments.length !== 0) {
        //         this.shipmentsData = this.getAllShipmentsNoPaginationShipments.shipments;
        //         this.current_page = this.getAllShipmentsNoPaginationShipments.current_page;
        //         this.last_page = this.getAllShipmentsNoPaginationShipments.last_page;
        //     } else {
        //         this.fetchShipmentsData();
        //     } 
        // } else if (this.tab === "completed") {
        //     if (this.getAllShipmentsNoPaginationCompleted.shipments.length !== 0) {
        //         this.shipmentsData = this.getAllShipmentsNoPaginationCompleted.shipments;
        //         this.current_page = this.getAllShipmentsNoPaginationCompleted.current_page;
        //         this.last_page = this.getAllShipmentsNoPaginationCompleted.last_page;
        //     } else {
        //         this.fetchShipmentsData();
        //     } 
        // } else {
        //     this.getBookingShipmentData();
        // }
    },
    watch: {
        bottom(v) {
            if (v && this.current_page <= this.last_page && !this.isFetchingData && !this.isFilterEnabled) {
                this.fetchShipmentsData()
            }
        },
        pendingShipmentTab(nv, ov) {
            if (nv !== ov) {
                this.current_page = 1;
                this.last_page = 1;
                this.shipmentsData = [];                
                this.getBookingShipmentData();
            }
        },
        isFilterEnabled(v) {
            if (!v) {
                this.multiSearch = {}
                this.shipmentsData = [];
                this.current_page = 1;
                // this.fetchShipmentsData();
                this.headers.map(v => {
                    v.operation = "contains";
                });
            }
        },
    },
    methods: {
        ...mapActions([
            "fetchShipments",
            "setShipmentOrder",
            "fetchShipmentsSearched",
            "cancelShipment",
            "markShipmentCompleted",
            "fetchCompletedShipments",
            "setShipmentsNoPaginationShipments",
            "setShipmentsNoPaginationCompleted",
            "setShipmentsNoPaginationDraft",
            "setShipmentsNoPaginationPendingQuote",
            "setShipmentsNoPaginationPending",
            "setShipmentsNoPaginationSnoozed",
            "setShipmentsNoPaginationExpired",
            "setShipmentsNoPaginationConsolidated"
        ]),
        ...globalMethods,
        bottomVisible(e) {
            let targetBottomHeight = e.target.scrollHeight - e.target.offsetHeight;

            if (Math.ceil(e.target.scrollTop) >= (targetBottomHeight - 10)) {
                if (this.current_page <= this.last_page && !this.isFetchingData) {
                    this.bottom = true;
                    this.current_page+=1;
                }
            } else {
                this.bottom = false;
            }
        },
        async fetchShipmentsData() {
            this.isFetchingData = true;

            let passedData = {
                method: "get",
                url: `${baseURL}/v2/shipment-tab`,
                params: { 
                    compact_view: false,
                    shipment_tab: this.tab,
                    order_by: this.tab !== 'completed' ? "asc" : "desc",
                    sort_column: "eta",
                    per_page: 15,
                    page: this.current_page
                }
            }

            if (this.search !== "") {
                passedData.params.search = this.search;
            }

            await axios(passedData)
            .then(res => {
                if (res.data) {
                    this.current_page = res.data.current_page;
                    this.last_page = res.data.last_page
                    this.shipmentsData = [ ...this.shipmentsData, ...res.data.data ]
                    this.itemsPerPage = res.data.total

                    // let data = {
                    //     current_page: this.current_page,
                    //     last_page: this.last_page,
                    //     shipments: this.shipmentsData,
                    //     tab: this.tab
                    // }     

                    // if (this.tab === "completed") {
                    //     this.setShipmentsNoPaginationCompleted(data)
                    // } else if (this.tab === "shipments") {
                    //     this.setShipmentsNoPaginationShipments(data)
                    // } else {
                    //     if (this.pendingShipmentTab === 0) {
                    //         this.setShipmentsNoPaginationDraft(data)
                    //     } else if (this.pendingShipmentTab === 1) {
                    //         this.setShipmentsNoPaginationPendingQuote(data)
                    //     } else if (this.pendingShipmentTab === 2) {
                    //         this.setShipmentsNoPaginationPending(data)
                    //     } else if (this.pendingShipmentTab === 3) {
                    //         this.setShipmentsNoPaginationConsolidated(data)
                    //     } else if (this.pendingShipmentTab === 4) {
                    //         this.setShipmentsNoPaginationSnoozed(data)
                    //     } else if (this.pendingShipmentTab === 5) {
                    //         this.setShipmentsNoPaginationExpired(data)
                    //     }
                    // }
                }
            }).catch(e => {
                console.log(e, 'error');
            })

            this.isFetchingData = false;
        },
        getBookingShipmentData() {
            if (this.pendingShipmentTab === 0) {
                if (this.getAllShipmentsNoPaginationBooking.draft.shipments.length !== 0) {
                    this.shipmentsData = this.getAllShipmentsNoPaginationBooking.draft.shipments;
                    this.current_page = this.getAllShipmentsNoPaginationBooking.draft.current_page;
                    this.last_page = this.getAllShipmentsNoPaginationBooking.draft.last_page;
                } else {
                    this.fetchShipmentsData();
                } 
            } else if (this.pendingShipmentTab === 1) {
                if (this.getAllShipmentsNoPaginationBooking.pendingQuote.shipments.length !== 0) {
                    this.shipmentsData = this.getAllShipmentsNoPaginationBooking.pendingQuote.shipments;
                    this.current_page = this.getAllShipmentsNoPaginationBooking.pendingQuote.current_page;
                    this.last_page = this.getAllShipmentsNoPaginationBooking.pendingQuote.last_page;
                } else {
                    this.fetchShipmentsData();
                } 
            } else if (this.pendingShipmentTab === 2) {
                if (this.getAllShipmentsNoPaginationBooking.pending.shipments.length !== 0) {
                    this.shipmentsData = this.getAllShipmentsNoPaginationBooking.pending.shipments;
                    this.current_page = this.getAllShipmentsNoPaginationBooking.pending.current_page;
                    this.last_page = this.getAllShipmentsNoPaginationBooking.pending.last_page;
                } else {
                    this.fetchShipmentsData();
                } 
            } else if (this.pendingShipmentTab === 3) {
                if (this.getAllShipmentsNoPaginationBooking.consolidated.shipments.length !== 0) {
                    this.shipmentsData = this.getAllShipmentsNoPaginationBooking.consolidated.shipments;
                    this.current_page = this.getAllShipmentsNoPaginationBooking.consolidated.current_page;
                    this.last_page = this.getAllShipmentsNoPaginationBooking.consolidated.last_page;
                } else {
                    this.fetchShipmentsData();
                } 
            } else if (this.pendingShipmentTab === 4) {
                if (this.getAllShipmentsNoPaginationBooking.snoozed.shipments.length !== 0) {
                    this.shipmentsData = this.getAllShipmentsNoPaginationBooking.snoozed.shipments;
                    this.current_page = this.getAllShipmentsNoPaginationBooking.snoozed.current_page;
                    this.last_page = this.getAllShipmentsNoPaginationBooking.snoozed.last_page;
                } else {
                    this.fetchShipmentsData();
                } 
            } else {
                if (this.getAllShipmentsNoPaginationBooking.expired.shipments.length !== 0) {
                    this.shipmentsData = this.getAllShipmentsNoPaginationBooking.expired.shipments;
                    this.current_page = this.getAllShipmentsNoPaginationBooking.expired.current_page;
                    this.last_page = this.getAllShipmentsNoPaginationBooking.expired.last_page;
                } else {
                    this.fetchShipmentsData();
                }
            }
        },
        handleClick(value) {
            this.$router.push(`shipment/${value.id}`)
            // this.$emit('handleClick', value)
        },
        concatPO(v) {
            if (v.length > 0) {
                return v.join(', ');
            } else {
                return "N/A";
            }
        },
        getShipmentStatusClass(item, type) {
            let { tracking_status, status } = item;

            let setValue = (type ==='tracking') ? tracking_status : status;
            let setClass = _.find(shipmentStatusesLists, e => e.value === setValue.toLowerCase());
            setClass = (typeof setClass !== 'undefined') ? setClass.class : '';

            return setClass;
        },
        convertDateAction(date) {
            if (date !== null && date !== "") {
                return moment(date).format("MM/DD/YYYY");
            } else {
                return "N/A";
            }
        },
        isMilestoneDone(shipment, isFor) {
            let todayConvert = moment().format();

            if (shipment !== null) {                
                if (isFor === "etd") {
                    if (shipment.etd !== null) {
                        let etdConvert = this.convertDateAction(shipment.etd);

                        if (moment(todayConvert).isAfter(etdConvert) ||
                            moment(todayConvert).isSame(etdConvert)) {
                            return true;
                        } else if (moment(todayConvert).isBefore(etdConvert)) {
                            return false;
                        }
                    }
                } else if (isFor === "eta") {
                    if (shipment.eta !== null) {
                        let etaConvert = this.convertDateAction(shipment.eta);

                        if (moment(todayConvert).isAfter(etaConvert) ||
                            moment(todayConvert).isSame(etaConvert)) {
                            return true;
                        } else if (moment(todayConvert).isBefore(etaConvert)) {
                            return false;
                        }
                    }
                }
            }
        },
        checkCardMilestoneDoneClass(shipment, isFor) {
            if (isFor === "m1") {
                if (this.isMilestoneDone(shipment, 'etd')) {
                    return 'active-milestone left right'
                }
            }
             
            if (isFor === "etd") {
                if (this.isMilestoneDone(shipment, isFor)) {
                    if (this.isMilestoneDone(shipment, 'eta')) {
                        return 'active-milestone left right'
                    } else {
                        return 'active-milestone left'
                    }
                }
            }

            if (isFor === "eta") {
                if (this.isMilestoneDone(shipment, isFor)) {
                    return 'active-milestone left'
                }
            }

            // if (isFor === "eta") {
            //     if (this.isMilestoneDone(shipment, isFor)) {
            //         if (this.isMilestoneDone(shipment, 'eta')) {
            //             return 'active-milestone'
            //         }
            //     }
            // }
        },
        getImage(shipment, isFor) {
            let todayConvert = moment().format();

            if (shipment !== null) {                
                if (isFor === "etd") {
                    if (shipment.etd !== null) {
                        let etdConvert = this.convertDateAction(shipment.etd);

                        if (moment(todayConvert).isAfter(etdConvert) ||
                            moment(todayConvert).isSame(etdConvert)) {
                            return require('../../../../../assets/icons/milestones/plane-active.svg')
                        } else if (moment(todayConvert).isBefore(etdConvert)) {
                            return require('../../../../../assets/icons/milestones/plane-inactive.svg')
                        }
                    }
                } else if (isFor === "eta") {
                    if (shipment.eta !== null) {
                        let etaConvert = this.convertDateAction(shipment.eta);

                        if (moment(todayConvert).isAfter(etaConvert) ||
                            moment(todayConvert).isSame(etaConvert)) {
                            return require('../../../../../assets/icons/milestones/truck-active.svg')
                        } else if (moment(todayConvert).isBefore(etaConvert)) {
                            return require('../../../../../assets/icons/milestones/truck-inactive.svg')
                        }
                    }
                }
            }
        },
        ucFirst(str) {
            let pieces = str.split(" ")
            for ( let i = 0; i < pieces.length; i++ ) {
                let j = pieces[i].charAt(0).toUpperCase()
                pieces[i] = j + pieces[i].substr(1)
            }
            return pieces.join(" ")
        },
        verifyValue(headerinfo, type) {
            console.log(headerinfo, type, 'verify');
            // let key = headerinfo.value

            // if (type !== "select-date") {
            //     if (typeof this.multiSearch[key] !== "undefined" && this.multiSearch[key] !== "" && 
            //         this.multiSearch[key] !== null && this.multiSearch[key] !== "null") {
            //         this.multiSearch[key] = null;
            //         this.handleSearch(headerinfo, type)
            //     }
            // } else {
            //     if (typeof this.range[key] !== "undefined" && this.range[key] !== "" && 
            //         this.range[key] !== null && this.range[key] !== "null") {
            //         this.range[key] = null;
            //         this.handleSearch(headerinfo, type)
            //     }
            // }
        },
        handleSearch(headerinfo, type) {
            let fields = type === "select-date" ? Object.keys(this.range) : Object.keys(this.multiSearch);
            const data = [];

            if (fields.length > 0) {
				fields.forEach((key) => {
                    if (type === "select-date") {
                        fields.forEach((key) => {
                            if (typeof this.range[key] !== "undefined" && this.range[key] !== "" && 
                                this.range[key] !== null && this.range[key] !== "null") {
                                data.push({
                                    field: key,
                                    operation: "between",
                                    value: {
                                        from: moment(this.range[key].start).format("YYYY-MM-DD"),
                                        to: moment(this.range[key].end).format("YYYY-MM-DD")
                                    }
                                });
                            }
                        });
                    } else {
                        if (typeof this.multiSearch[key] !== "undefined" && this.multiSearch[key] !== "" && 
                            this.multiSearch[key] !== null && this.multiSearch[key] !== "null") {
                            data.push({
                                field: key,
                                operation: headerinfo.type !== "date" ? this.getOperationValue(headerinfo.operation) : "equals",
                                value: headerinfo.type !== "date" ? 
                                    this.multiSearch[key] : moment(this.multiSearch[key]).format("YYYY-MM-DD")
                            });
                        }
                    }
				});

                if (type === 'input') {
                    if (cancel !== undefined) {
                        cancel("cancel_previous_request")
                    }
                    clearTimeout(this.typingTimeout)
                    this.typingTimeout = setTimeout(() => {
                        this.isFetchingData = true;
                        this.shipmentsData = [];
                        this.current_page = 1;
                        this.apiCall(data);
                    }, 500)
                } else {
                    if (cancel !== undefined) {
                        cancel("cancel_previous_request")
                    }
                    clearTimeout(this.typingTimeout)
                    this.typingTimeout = setTimeout(() => {
                        this.isFetchingData = true;
                        this.shipmentsData = [];
                        this.current_page = 1;
                        this.apiCall(data);
                    }, 10)
                }
            }
        },
        async apiCall(data) {
            if (data !== null) {
                this.isFetchingData = true;
                let passedData = {
                    method: "get",
                    url: `${baseURL}/v2/shipment-tab`,
                    cancelToken: new CancelToken(function executor(c) {
                        cancel = c
                    }),
                    params: {
                        compact_view: false,
                        shipment_tab: this.tab,
                        order_by: this.tab !== 'completed' ? "asc" : "desc",
                        sort_column: "eta",
                        per_page: 15,
                        page: this.current_page,
                    }
                }

                if (data.length > 0) {
                    passedData.params.filters = JSON.stringify(data);
                }

                await axios(passedData)
                .then(res => {
                    if (res.data) {
                        this.current_page = res.data.current_page;
                        this.last_page = res.data.last_page
                        this.shipmentsData = [ ...this.shipmentsData, ...res.data.data ]

                        // let data = {
                        //     current_page: this.current_page,
                        //     last_page: this.last_page,
                        //     shipments: this.shipmentsData,
                        //     tab: this.tab
                        // }

                        // if (this.tab === "completed") {
                        //     this.setShipmentsNoPaginationCompleted(data)
                        // } else if (this.tab === "shipments") {
                        //     this.setShipmentsNoPaginationShipments(data)
                        // } else {
                        //     if (this.pendingShipmentTab === 0) {
                        //         this.setShipmentsNoPaginationDraft(data)
                        //     } else if (this.pendingShipmentTab === 1) {
                        //         this.setShipmentsNoPaginationPendingQuote(data)
                        //     } else if (this.pendingShipmentTab === 2) {
                        //         this.setShipmentsNoPaginationPending(data)
                        //     } else if (this.pendingShipmentTab === 3) {
                        //         this.setShipmentsNoPaginationConsolidated(data)
                        //     } else if (this.pendingShipmentTab === 4) {
                        //         this.setShipmentsNoPaginationSnoozed(data)
                        //     } else if (this.pendingShipmentTab === 5) {
                        //         this.setShipmentsNoPaginationExpired(data)
                        //     }
                        // }
                        this.isFetchingData = false;
                    }
                }).catch(e => {
                    console.log(e);
                    if (e.message !== "cancel_previous_request") {
                        this.isFetchingData = false;
                    }
                })
            } else {
                // this.setSearchedCategoriesVal([])
                console.log("error filtering");
            }
        },
        getHeaderOperationIcon(icon) {
            if (icon !== null) {
                if (icon === 0 || icon === "contains") {
                    return "mdi-magnify"
                } else if (icon === 1 || icon === "equals") {
                    return "mdi-equal"
                } else if (icon === 2 || icon === "between") {
                    return "mdi-arrow-left-right"
                } else if (icon === 3 || icon === "less than") {
                    return "mdi-less-than"
                } else if (icon === 4 || icon === "greater than") {
                    return "mdi-greater-than"
                }
            } else {
                return "mdi-magnify"
            }
        },
        getOperationValue(value) {
            if (value !== null) {
                if (value === 0 || value === "contains") {
                    return "contains"
                } else if (value === 1 || value === "equals") {
                    return "equals"
                } else if (value === 2 || value === "between") {
                    return "between"
                } else if (value === 3 || value === "less than") {
                    return "less than"
                } else if (value === 4 || value === "greater than") {
                    return "greater than"
                }
            } else {
                return "contains"
            }
        },
        clearValue(filterField) {
            if (filterField !== null) {
                let fields = filterField.type === "date" && filterField.operation === "between"
                    ? Object.keys(this.range) : Object.keys(this.multiSearch);

                if (fields.length > 0) {
                    fields.forEach((key) => {
                        if (key === filterField.value) {
                            this.handleSearch(filterField, 'input')
                            this.multiSearch[key] = null
                            this.range[key] = null
                        }
                    });
                }
            }
        },
        showToggle() {
            this.isShow = !this.isShow
        }
    },
    updated() {},
    beforeDestroy() {
        const tableWrapper = document.querySelector('.v-data-table__wrapper');
        if (tableWrapper !== null) {
            tableWrapper.removeEventListener('scroll', this.bottomVisible);
        }        
    }
};
</script>

<style lang="scss">
@import "@/assets/scss/vc_calendar.scss";
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"inbound-pallet-section task-tab"},[_c('v-data-table',{staticClass:"elevation-1 inbound-view-pallets",class:_vm.taskData.length == 0 ? 'no-data-table' : '',attrs:{"headers":_vm.taskHeader,"items":_vm.taskData.tasks,"item-key":"id","mobile-breakpoint":"769","hide-default-footer":"","items-per-page":500},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [(_vm.taskData.tasks.length == 0)?_c('div',{staticClass:"no-data-wrapper"},[_c('div',{staticClass:"no-data-heading"},[_c('p',{staticClass:"text-center mb-0"},[_vm._v(" No assigned tasks ")])])]):_vm._e()]},proxy:true},{key:"item.tasktype",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.removeUnderscore(item.tasktype)))])]}},{key:"item.task_employees",fn:function(ref){
var item = ref.item;
return [(item.task_employees.length > 0)?_c('div',{staticClass:"warehouse-employees-wrapper"},_vm._l((item.task_employees),function(employee,i){return _c('span',{key:i},[_vm._v(" "+_vm._s(employee.name)),(i+1 < item.task_employees.length)?_c('span',[_vm._v(", ")]):_vm._e()])}),0):_c('div',[_vm._v("--")])]}},{key:"item.updated_at",fn:function(ref){
var item = ref.item;
return [(item.task_employees.length !== 0)?_c('div',{staticClass:"updated-by-at-wrapper"},[_c('div',{staticClass:"mb-1"},[_vm._v(_vm._s(item.updated_by))]),_c('div',{staticStyle:{"color":"#69758C"}},[_vm._v(_vm._s(_vm.formatDate(item.updated_at)))])]):_c('div',[_vm._v("--")])]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.task_employees.length !== 0)?_c('div',{staticClass:"task-status-wrapper"},[_c('v-chip',{staticClass:"task-chip",class:_vm.removeUnderscore(item.status) !== null ? _vm.removeUnderscore(item.status) : 'pending'},[_vm._v(" "+_vm._s(_vm.removeUnderscore(item.status) !== null ? _vm.removeUnderscore(item.status) : 'pending')+" ")])],1):_c('div',[_vm._v("--")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [(item.task_employees.length !== 0 && !_vm.isWarehouseConnected)?_c('div',{staticClass:"actions-wrapper"},[_c('button',{staticClass:"btn-white",on:{"click":function($event){return _vm.editTask(item)}}},[_c('img',{attrs:{"src":require("@/assets/icons/edit-blue.svg"),"alt":"Edit"}})])]):_vm._e()]}}],null,true)}),_c('AssignEmployeeDialog',{attrs:{"dialogData":_vm.showAssignEmployeeDialog,"editedItemData":_vm.selectedInboundOrders,"fromComponent":'Inbound',"editedIndexData":_vm.editedIndexAssign},on:{"update:dialogData":function($event){_vm.showAssignEmployeeDialog=$event},"update:dialog-data":function($event){_vm.showAssignEmployeeDialog=$event},"update:editedItemData":function($event){_vm.selectedInboundOrders=$event},"update:edited-item-data":function($event){_vm.selectedInboundOrders=$event},"close":_vm.closeAssignEmployee,"update:editedIndexData":function($event){_vm.editedIndexAssign=$event},"update:edited-index-data":function($event){_vm.editedIndexAssign=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
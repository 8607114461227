<!-- @format -->

<template>
	<div @click="handleGlobalClick" class="containerMain" v-resize="onResize">
		<no-connected-supplier-modal
			:show="closeNoConnectedSupplierModalView"
			className="add-shipment-dialog-modal"
			:isMobile="isMobile"
			:closeNoConnectedSupplierModalRole="closeNoConnectedSupplierModalRole"
			:bookingShipmentDialogView.sync="bookingShipmentDialogView"
			@close="closeNoSupplierConnectedModal">
			<template v-slot:title>
				<div>
					<generic-icon iconName="warning"></generic-icon>
				</div>
			</template>
			<template v-slot:actions="{ footer }">
				<div class="d-flex footer">
					<v-btn @click.stop="footer.close" class="save-btn btn-blue" text>
						<span>{{ "I understood" }}</span>
					</v-btn>
				</div>
			</template>
		</no-connected-supplier-modal>

		<add-shipment-dialog-modal
			:show="addShipmentDialogModalView"
			:refNumber.sync="refNumber"
			className="add-shipment-dialog-modal"
			:isMobile="isMobile"
			@close="closeAddShipmentModal"
			@addAnotherShipment="addAnotherShipment">
			<template v-slot:title>
				<div>
					<generic-icon iconName="check-success"></generic-icon>
				</div>
			</template>
			<template v-slot:actions="{ footer }">
				<div class="d-flex footer">
					<v-btn @click.stop="footer.closeRefresh"
						class="save-btn btn-blue"
						text>
						<span>{{ "I understood" }}</span>
					</v-btn>
					<v-btn class="delete-cancel btn-white edit-shipment-cancel-btn btn-blue"
						text
						@click="footer.addAnotherShipment">
						<span>{{ "Add Another" }}</span>
					</v-btn>
				</div>
			</template>
		</add-shipment-dialog-modal>

		<booking-shipment-dialog
			:reference="`${getEditingDraftShipment ? 'formEditShipment' : 'formBookingShipment'}`"
			v-if="bookingShipmentDialogView"
			className="edit-shipment-dialog-wrapper"
			:show.sync="bookingShipmentDialogView"
			:bookingShipmentDialogView.sync="bookingShipmentDialogView"
			:isMobile="isMobile"
			:submitRequestModalView.sync="submitRequestModalView"
			:bookingRequestSubmittedModalView.sync="bookingRequestSubmittedModalView"
			:item="editedItem"
			:rules="createShipmentRules"
			:windowWidth="windowWidth"
			:isEdit.sync="isEdit"
			:closeNoConnectedSupplierModalView.sync="
				closeNoConnectedSupplierModalView
			"
			:closeNoConnectedSupplierModalRole.sync="
				closeNoConnectedSupplierModalRole
			"
			:addShipmentDialogModalView.sync="addShipmentDialogModalView"
			@close="handleCloseBookingShipmentDialog"
			@reloadShipments="callShipmentsAPI">
			<template v-slot:title>
				<div id="headline-custom-wrapper">
					<span v-if="1 == 2" class="headline-custom">
						{{ "Add Shipment" }}
					</span>
					<span class="headline-custom-track booking">
						{{ isEdit ? "Edit Shipment" : "Create Booking Request" }}
					</span>
				</div>
			</template>
			<template v-slot:sidebar="{ mainContent }">
				<div style="padding-top: 16px !important;"
					class="d-flex flex-column first-column sidebar-item-main-wrapper">
					<div :class="
							`d-flex align-center sidebar-items-wrapper ${
								sidebarItem.selected ? 'selected' : ''
							}`"
						v-bind:key="`si-${key}`"
						v-for="(sidebarItem, key) in mainContent.sidebarItems">

						<a @click.stop="mainContent.selectPage(sidebarItem)"
							:class="`d-flex sidebar-item align-center ${
								sidebarItem.selected ? 'selected' : ''
							}`">
							<kenetic-icon
								:paddingTop="`${sidebarItem.icon === 'general' ? 6 : 0}`"
								:color="`${sidebarItem.selected ? '#0171A1' : '#253041'}`"
								v-if="sidebarItem.icon !== ''"
								:iconName="sidebarItem.icon"
								:width="sidebarItem.width"
								:height="sidebarItem.height" />
							<div style="margin-left: 13px;" class="sidebar-label">
								{{ sidebarItem.label }}
							</div>
						</a>
					</div>
				</div>
			</template>
			<template v-slot:actions="{ footer }">
				<div class="d-flex footer">
					<v-btn
						v-if="!getEditingShipment"
						style="margin-right: 8px;"
						:disabled="footer.createLoading"
						@click.stop="footer.createShipment"
						class="save-btn btn-blue"
						text>
						<span>{{ "Submit Request" }}</span>
					</v-btn>
					<v-btn
						v-if="getEditingShipment"
						style="margin-right: 8px;"
						:disabled="footer.createLoading"
						@click.stop="footer.updateShipment"
						class="save-btn btn-blue"
						text>
						<span>{{ "Save" }}</span>
					</v-btn>
					<v-btn
						v-if="!getEditingShipment"
						:disabled="footer.submitLoading"
						style="margin-right: 8px;"
						class="delete-cancel btn-white edit-shipment-cancel-btn btn-blue"
						text
						@click="footer.saveAsDraft">
						<span style="color: #0171A1;">{{ "Save as Draft" }}</span>
					</v-btn>
					<v-btn
						class="delete-cancel btn-white edit-shipment-cancel-btn btn-blue"
						text
						@click="footer.close">
						<span>{{ "Cancel" }}</span>
					</v-btn>
				</div>
			</template>
		</booking-shipment-dialog>

		<create-shipment-dialog
			reference="formCreateShipment"
			v-if="createShipmentDialogView"
			className="edit-shipment-dialog-wrapper"
			:show="createShipmentDialogView"
			:isMobile="isMobile"
			:rules="createShipmentRules"
			:item.sync="editedItem"
			:isEdit.sync="isEdit"
			:windowWidth="windowWidth"
			:addShipmentDialogModalView.sync="addShipmentDialogModalView"
			:refNumber.sync="refNumber"
			@close="handleCloseCreateShipmentDialog"
			@reloadShipments="callShipmentsAPI"
			:addBulkShipmentDialogModalView.sync="addBulkShipmentDialogModalView"
			:shipmentCounter.sync="shipmentCounter">
			<template v-slot:title>
				<div id="headline-custom-wrapper">
					<span class="headline-custom">{{
						getEditingShipment
							? "Edit Shipment"
							: getMarkingBookedExternal
							? "Mark Booked External"
							: "Add Shipment for Tracking"
					}}</span>
					<span v-if="!getMarkingBookedExternal && 1 == 2" class="headline-custom-track">Track Shipment</span>
				</div>
			</template>
			<template v-slot:sidebar="{ mainContent }">
				<div v-if="!getMarkingBookedExternal"
					class="d-flex flex-column first-column sidebar-item-main-wrapper">
					<div :class="`d-flex align-center sidebar-items-wrapper ${
							sidebarItem.selected ? 'selected' : ''
						}`"
						v-bind:key="`si-${key}`"
						v-for="(sidebarItem, key) in mainContent.sidebarItems">
						
						<a @click.stop="mainContent.selectPage(sidebarItem)"
							:class="`d-flex sidebar-item align-center ${
								sidebarItem.selected ? 'selected' : ''
							}`">
							<kenetic-icon
								:paddingTop="`${sidebarItem.icon === 'general' ? 6 : 0}`"
								:color="`${sidebarItem.selected ? '#0171A1' : '#253041'}`"
								v-if="sidebarItem.icon !== ''"
								:iconName="sidebarItem.icon"
								:width="sidebarItem.width"
								:height="sidebarItem.height" />

							<div style="margin-left: 13px;" class="sidebar-label">
								{{
									getAddingShipmentTracking && sidebarItem.icon === "po-icon"
										? "Orders"
										: sidebarItem.label
								}}
							</div>
						</a>
					</div>
				</div>
			</template>
			<template v-slot:actions="{ footer }">
				<div class="d-flex footer">
					<v-btn v-if="!getMarkingBookedExternal"
						:disabled="footer.createLoading"
						@click.stop="
							() => { isEdit ? footer.updateShipment() : footer.createShipment(); }"
						class="save-btn btn-blue"
						text>
						<span>{{ isEdit ? "Edit Shipment" : "Add Shipment" }}</span>
					</v-btn>
					<v-btn :disabled="
							!footer.checkMarkExternalValidation() || footer.updateLoading
						"
						@click.stop="footer.updateShipment"
						class="save-btn btn-blue"
						v-if="getMarkingBookedExternal">
						<span>Mark Booked External</span>
					</v-btn>
					<v-btn class="delete-cancel btn-white edit-shipment-cancel-btn btn-blue"
						text
						@click="footer.close">
						<span>{{ "Cancel" }}</span>
					</v-btn>
				</div>
			</template>
		</create-shipment-dialog>

		<div v-if="!ArchivedShipmentFlag" id="shipments_header">
			<div id="shipments_root" :class="`${windowWidth <= 768 ? 'k-flex-col-reverse' : ''}`">
				<v-tabs
					v-if="!isMobile"
					show-arrows
					class="customTab"
					height="50px"
					@change="onTabChange"
					v-model="activeTab">

					<v-tab v-for="(n, i) in tabs" :key="i" @click="getCurrentTab(i)">
						<span>
							<span :class="n == 'Completed' ? 'tab-name-completed' : 'tab-name'">
								<span>
									<span v-if="n == 'Pending Approval'"> Booking </span>
									<span v-else> {{ n.label }} </span>
								</span>
							</span>
							<v-badge
								v-if="n.label !== 'Completed'"
								color="#9CA5B4"
								class="customBadge"
								bordered
								:content="getShipmentCount(i)">
							</v-badge>
						</span>
					</v-tab>
				</v-tabs>

				<v-tabs
					v-if="isMobile && selectedTab === 0"
					show-arrows
					:class="
						`customTab custom-tab-mobile custom-tab-mobile-special ${
							tabsComputed.length > 3 ? 'custom-tab-mobile-special-wrapper' : ''
						}`"
					height="45px"
					@change="onTabMobileChange"
					v-model="activeTabComputed">
					<v-tab
						:style="`${i == 0 && selectedTab > 0 ? 'display: none;' : ''}`"
						v-for="(n, i) in tabsComputed"
						:key="i"
						:class="`${tabsComputed.length == 3 ? `v-tab-special ${n}` : `${n}`}`">
						<span class="tab-name"> {{ n }} </span>
					</v-tab>
				</v-tabs>

				<v-tabs v-if="isMobile && activeTab === 1"
					:class="
						`customTab custom-tab-mobile custom-tab-mobile-special ${
							shipmentSubtabs.length > 3 ? 'custom-tab-mobile-special-wrapper' : ''
						}`
					"
					height="45px"
					@change="onShipmentTabChange"
					v-model="shipmentCurrentTab">

					<v-tab
						v-for="(tab, index) in shipmentSubtabs"
						:key="index"
						:class="tab.class">
						<span class="d-flex align-center justify-center">
							<span class="font-medium mr-1 tab-name">{{ tab.name }}</span>
							<div class="shipment-sub-badges font-medium" v-if="getShipmentSubtabsCount[tab.countValue] !== 0"
								v-bind:class="{
									'active': index === shipmentCurrentTab,
									'requires-a' : getHasPastLastFreeDay && index === 5
								}">
								{{ getShipmentSubtabsCount[tab.countValue] }}
							</div>
						</span>
					</v-tab>
				</v-tabs>

				<div class="shipment-tabs-mobile" v-if="isMobile">
					<div>
						<v-select
							@change="selectTab"
							append-icon="mdi-chevron-down"
							class="tab-selections"
							:items="tabs"
							v-model="selectedTab"
							item-text="label"
							item-value="value"
							hide-details="auto"
							:menu-props="{ bottom: true, offsetY: true }"
							height="45px">
						</v-select>
					</div>
					<div>
						<!-- @showAddShipmentDialog="showAddShipmentDialog" -->
						<shipment-dropdown
							@showBookingShipmentDialog="showBookingShipmentDialog"
							@showSingleAddShipmentDialog="showSingleAddShipmentDialog"
							:shipmentDropDownClicked.sync="shipmentDropDownClicked"
							:isMobile="isMobile"
							:isEdit.sync="isEdit">
						</shipment-dropdown>

						<v-btn v-if="1 == 2"
							class="track-shipment-mobile track-shipment btn-white mr-4 shipment-header-button-btn"
							@click="showAddShipmentDialog">
							{{ "Add Shipment" }}
						</v-btn>
						<v-btn v-if="1 == 2"
							class="track-shipment-mobile track-shipment btn-white mr-4 shipment-header-button-btn"
							@click="openTrackShipment">
							Track
						</v-btn>

						<div class="shipment-mobile-menu-archived ml-2">
							<v-menu offset-y bottom left content-class="pending-dropdown-container">
								<template v-slot:activator="{ on, attrs }">
									<button
										v-bind="attrs"
										v-on="on"
										class="three-dots-container"
										style="height: 40px !important; width: 38px !important;">
										<custom-icon
											iconName="three-dots"
											color="#0171A1"
											width="11"
											height="3" />
									</button>
								</template>
								<v-list>
									<v-list-item>
										<v-list-item-title class="k-fw-sm k-font-inter-regular d-flex"
											@click="showBookingTemplates()">
											<img
												src="@/assets/icons/resources.svg"
												style="margin-right: 10px;" />
											Booking Templates
										</v-list-item-title>
									</v-list-item>
									<v-list-item>
										<v-list-item-title class="k-fw-sm k-font-inter-regular d-flex"
											@click="showArchivedShipment()">
											<img
												src="@/assets/icons/archive.svg"
												style="margin-right: 10px;" />
											Archived
										</v-list-item-title>
									</v-list-item>
								</v-list>
							</v-menu>
						</div>
					</div>
				</div>

				<div v-if="!isMobile" class="shipment-header-buttons" style="height: 50px;">
					<!-- @showAddShipmentDialog="showAddShipmentDialog" -->
					<shipment-dropdown
						@showBookingShipmentDialog="showBookingShipmentDialog"
						@showSingleAddShipmentDialog="showSingleAddShipmentDialog"
						:isMobile="isMobile"
						:isEdit.sync="isEdit">
					</shipment-dropdown>
					<div v-if="isMobile" style="width:2%;"></div>
				</div>

				<div style="height: 50px;" v-if="!isMobile">
					<v-menu offset-y bottom left content-class="pending-dropdown-container">
						<template v-slot:activator="{ on, attrs }">
							<button
								v-bind="attrs"
								v-on="on"
								class="three-dots-container"
								style="height: 40px !important; width: 40px !important;">
								<custom-icon
									iconName="three-dots"
									color="#0171A1"
									width="11"
									height="3" />
							</button>
						</template>
						<v-list>
							<v-list-item>
								<v-list-item-title
									class="k-fw-sm k-font-inter-regular d-flex"
									@click="showBookingTemplates()" >
									<img src="@/assets/icons/resources.svg"
										style="margin-right: 10px;" />
									Booking Templates
								</v-list-item-title>
							</v-list-item>
							<v-list-item>
								<v-list-item-title class="k-fw-sm k-font-inter-regular d-flex"
									@click="showArchivedShipment()">
									<img src="@/assets/icons/archive.svg"
										style="margin-right: 10px;" />
									Archived
								</v-list-item-title>
							</v-list-item>
						</v-list>
					</v-menu>
				</div>
			</div>

			<div class="filters-wrapper d-flex align-center"
				:class="shipments.length > 0 || completed.length > 0 || currentViewTab === 0 ? 'justify-space-between' : 'justify-end' "
				:style="(activeTab === 2 && completed.length === 0) ? 'border-bottom: none !important;' : ''">

				<div class="pending-approval-tab mr-0" v-if="activeTab === 0">
					<v-tabs
						v-if="!isMobile"
						:class="`pending-inner-tab ${
							isMobile
								? 'pending-inner-tab-mobile'
								: 'pending-inner-tab-desktop'
						} ${windowWidth <= 912 && windowWidth >= 769 ? 'k-w-100' : ''}`"
						@change="onPendingTabChange"
						v-model="pendingCurrentTab">
						<v-tab
							v-for="(tab, index) in pendingSubTabs"
							:key="index"
							class="pending-tabs"
							:class="pendingSubTabsClasses[index]">
							{{ tab }}
						</v-tab>
					</v-tabs>

					<v-spacer></v-spacer>

					<div :class="`pending-tabs-search-wrapper ${
						isMobile
							? 'pending-tabs-search-wrapper-mobile'
							: 'pending-tabs-search-wrapper-desktop'
						}`"
						v-if="isShowSearchShipment()">
						<div class="search-wrapper search-wrapper-pending">
							<!-- <v-btn
								class="save-btn btn-white mr-2"
								v-if="pendingCurrentTab === 3">
								<span>Customize Table</span>
							</v-btn> -->
							<div class="search-wrapper-pending-group">
								<img src="@/assets/images/search-icon.svg" alt="" />
								<input
									v-if="!isMobile"
									class="search"
									type="text"
									id="search-input"
									v-model.trim="search"
									@input="
										handleSearch(
											pendingCurrentTab === 0
												? 'booking_draft'
												: pendingCurrentTab === 1
												? 'booking_pending_quote'
												: pendingCurrentTab === 2
												? 'booking_pending_approval'
												: pendingCurrentTab === 3
												? 'consolidated'
												: pendingCurrentTab === 4
												? 'booking_snoozed'
												: 'booking_expired'
										)
									"
									placeholder="Search Shipment"
									autocomplete="off"/>
								
								<input
									v-if="isMobile"
									class="search"
									type="text"
									id="search-input"
									v-model.trim="search"
									placeholder="Search Shipment"
									@input="
										handleSearch(
											pendingCurrentTab === 0
												? 'booking_draft'
												: pendingCurrentTab === 1
												? 'booking_pending_quote'
												: pendingCurrentTab === 2
												? 'booking_pending_approval'
												: pendingCurrentTab === 3
												? 'consolidated'
												: pendingCurrentTab === 4
												? 'booking_snoozed'
												: 'booking_expired'
										)
									"
									autocomplete="off"/>

								<button @click="clearSearched" v-if="search !== ''">
									<img
										src="@/assets/images/close.svg"
										alt="clear"
										class="img-clear-searched"
									/>
								</button>
							</div>
						</div>
					</div>
				</div>

				<div class="shipment-sub-tabs mr-0" v-if="activeTab === 1 && currentViewTab === 0 && !isMobile">
					<v-tabs
						v-if="!isMobile"
						:class="`shipment-inner-tab ${
							isMobile ? 'shipment-inner-tab-mobile' : 'shipment-inner-tab-desktop'
						} ${windowWidth <= 912 && windowWidth >= 769 ? 'k-w-100' : ''}`"
						@change="onShipmentTabChange"
						v-model="shipmentCurrentTab">

						<v-tab
							v-for="(tab, index) in shipmentSubtabs"
							:key="index"
							class="shipment-tabs"
							:class="tab.class">
							<span class="font-medium mr-1">{{ tab.name }}</span>
							<div class="shipment-sub-badges font-medium" v-if="getShipmentSubtabsCount[tab.countValue] !== 0"
								v-bind:class="{
									'active': index === shipmentCurrentTab,
									'requires-a' : getHasPastLastFreeDay && index === 5
								}"> 
								{{ getShipmentSubtabsCount[tab.countValue] }}
							</div>
						</v-tab>
					</v-tabs>
				</div>

				<div class="d-flex justify-start align-center filter-wrapper-content" v-if="activeTab !== 0">
					<div class="shipments-tabs-search-wrapper">
						<button
							class="btn-white btn-filter mr-2"
							@click="activateFilter"
							:class="isFilterEnabled ? 'activated' : ''"
							v-if="currentViewTab === 3 && shipments.length > 0">
							<img
								src="@/assets/icons/filter-blue.svg"
								alt=""
								class="mr-1"
								width="17px"
								height="16px" />
							{{ isFilterEnabled ? "Hide Filter" : "Show Filter" }}
						</button>

						<div class="search-wrapper" v-if="activeTab === 1 && shipments.length > 0 && checkWindowSize()">
							<img src="@/assets/icons/search-dark.svg" alt="" />

							<input
								class="search"
								type="text"
								id="search-input-shipments1"
								v-model.trim="search"
								placeholder="Search Shipment"
								@input="handleSearch('shipments')"
								autocomplete="off"
								ref="shipmentSearchInput" />

							<button @click="clearSearched" v-if="search !== ''">
								<img
									src="@/assets/icons/Close.svg"
									alt="clear"
									class="img-clear-searched"
									width="18px"
									height="18px" />
							</button>
						</div>

						<div class="search-wrapper" v-if="activeTab === 2 && completed.length > 0">
							<img src="@/assets/images/search-icon.svg" alt="" />

							<input
								class="search"
								type="text"
								id="search-input"
								v-model.trim="search"
								placeholder="Search Shipment"
								@input="handleSearch('completed')"
								autocomplete="off" />

							<button @click="clearSearched" v-if="search !== ''">
								<img
									src="@/assets/images/close.svg"
									alt="clear"
									class="img-clear-searched" />
							</button>
						</div>
					</div>
				</div>
				
				<!-- v-if="activeTab !== 0 && !isMobile" -->
				<div class="shipments-view-lists d-flex align-center" v-if="activeTab === 1 && !isMobile">
					<div :class="`search-wrapper search-shipment-tab ${isShipmentSearchExpanded ? 'expanded' : ''}`" 
						v-if="activeTab === 1 && shipments.length > 0 && currentViewTab === 0 && !isMobile">

						<button @click="toggleShipmentSearch" class="toggle-btn" id="toggle-search-btn" v-if="!isMobile">
							<img
								src="@/assets/icons/search-dark.svg"
								alt="clear"
								class="img-searched-button" 
								width="18px"
								height="18px"
								id="img-search-expand" />
						</button>

						<div class="floating-shipment-search" v-if="isShipmentSearchExpanded && !isMobile">
							<img src="@/assets/icons/search-dark.svg" alt="" />

							<input
								class="search"
								type="text"
								id="search-input-shipments1"
								v-model.trim="search"
								placeholder="Search Shipment"
								@input="handleSearch('shipments')"
								autocomplete="off"
								ref="shipmentSearchInputFloat"
								@blur="blurInputSearch" />

							<button @click="clearSearched" id="expand-close-btn">
								<img src="@/assets/icons/Close.svg"
									alt="clear"
									class="img-clear-searched"
									width="18px"
									height="18px" />
							</button>
						</div> 
					</div>
					
					<div class="select-view-tabs-wrapper" v-if="!isShipmentSearchExpanded">
						<v-tabs
							class="select-view-tabs"
							v-model="currentViewTab"
							@change="setSelectedShipmentView">

							<v-tab v-for="(n, i) in viewTabsName" :key="i">
								<v-tooltip
									bottom
									content-class="tooltip-wrapper tooltip-bottom"
									open-delay="300"
									close-delay="0"
									transition="fade-transition">
									<template v-slot:activator="{ on }">
										<div v-on="on" class="d-flex align-center">
											<img :src="getImgUrl(n.image, i)"
												alt=""
												width="18px"
												height="18px" />
										</div>
									</template>
									<span style="font-size:13px;">{{ n.name }}</span>
								</v-tooltip>
							</v-tab>
						</v-tabs>
					</div>
				</div>
			</div>

			<div class="calendar-menu-wrapper d-flex justify-space-between align-center" v-if="currentViewTab === 1">
				<div class="d-flex align-center">
					<div class="calendar-view-picker ml-3 d-flex justify-start align-center"
						style="min-width: 260px;">
						<div class="select-view-tabs-wrapper">
							<v-menu
								v-model="menu2"
								:close-on-content-click="false"
								transition="scale-transition"
								offset-y
								min-width="auto"
								bottom
								right
								content-class="shipment-calendar-v-menu">

								<template v-slot:activator="{ on, attrs }">
									<div class="calendar-activator-wrapper">
										<button class="v-calendar-selection ml-2" v-if="currentCalendarType === 1" v-bind="attrs" v-on="on">
											<span class="v-calendar-selection-month font-semi-bold">
												{{ computedDateFormatted }}

												<v-icon v-if="currentCalendarType === 1">
													{{ menu2 ? 'mdi-chevron-up' : 'mdi-chevron-down' }}
												</v-icon>
											</span>
										</button>

										<div class="d-flex justify-start align-center" v-if="currentCalendarType === 0">
											<button @click="getWeekDate('prev')" class="navigation-dates-button">
												<v-icon color="#1A6D9E" size="24">mdi-chevron-left</v-icon>
											</button>

											<button class="v-calendar-selection ml-1" v-bind="attrs" v-on="on">
												<span class="v-calendar-selection-month font-semi-bold">
													{{ computedDateFormatted }}
												</span>
											</button>

											<button @click="getWeekDate('next')" class="navigation-dates-button next pl-1">
												<v-icon color="#1A6D9E" size="24">mdi-chevron-right</v-icon>
											</button>
										</div>
									</div>
								</template>

								<v-date-picker
									no-title
									v-model="currentDateCalendar"
									@input="menu2 = false"
									:type="currentCalendarType === 1 ? 'month' : 'date'"
									:month-format="formatDateMonth"
									:weekday-format="getWeekdayLabel"
									class="shipment-calendar-picker"
									ref="shipment-calendar-picker"
									@click:date="datePickerClickedDate"
									@click:month="datePickerClickedMonth">

									<div class="new-weekly-calendar-picker" v-if="currentCalendarType === 0">
										<v-select
											class="select-items shrink mt-0 mr-4"
											:items="getMonthsLists"
											v-model="getCurrentMonth"
											item-text="name"
											item-value="value"
											dense
											return-object
											outlined
											hide-details="auto"
											placeholder="Select Month"
											attach
											:menu-props="{ 
												bottom: true, 
												offsetY: true, 
												contentClass: 'calendar-filter-select-month-date' 
											}"
											append-icon="mdi-chevron-down"
											@change="onChangeSelectDate">
										</v-select>

										<v-select
											class="select-items shrink mt-0"
											:items="getYearsLists"
											v-model="getCurrentYear"
											item-text="name"
											item-value="value"
											dense
											return-object
											outlined
											hide-details="auto"
											placeholder="Select Year"
											attach
											:menu-props="{ 
												bottom: true, 
												offsetY: true, 
												contentClass: 'calendar-filter-select-month-date' 
											}"
											append-icon="mdi-chevron-down"
											@change="onChangeSelectDate">
										</v-select>
									</div>
								</v-date-picker>
							</v-menu>
							
							<v-tabs
								class="select-view-tabs ml-3"
								v-model="currentCalendarType"
								@change="setSelectedCalendarType">
								<v-tab v-for="(n, i) in calendarType" :key="i">
									<span>{{ n.name }}</span>
								</v-tab>
							</v-tabs>
						</div>
					</div>
					<div class="calendar-filter-menu ml-3 d-flex align-center">
						<v-select
							class="select-items calendar-filter-input mt-0"
							:items="calendarFilterItems"
							v-model="selectedDefaultFilter"
							dense
							item-text="name"
							item-value="value"
							hide-details="auto"
							append-icon="mdi-chevron-down"
							:menu-props="{
								contentClass: 'calendar-list-items',
								bottom: true,
								offsetY: true,
								right: true,
							}"
							prefix="Show By:"
							placeholder="Select Type"
							multiple
							return-object
							required
							:class="selectedDefaultFilter.length === 0 ? 'custom-empty-selection' : ''"
							@blur="blurInputSelect">

							<template v-slot:selection="{ item, index }">
								<span style="color: #253041;" v-if="
									(selectedDefaultFilter.length === calendarFilterItems.length) && 
									(index === (calendarFilterItems.length - 1))"> All
								</span>

								<span style="color: #253041;" v-else-if="
									selectedDefaultFilter.length > 0 && 
									(selectedDefaultFilter.length !== calendarFilterItems.length)">
									{{ item.name }}<template v-if="index + 1 < selectedDefaultFilter.length">,<span class="ml-1"></span></template>
								</span>	
							</template>

							<template v-slot:prepend-item>
								<v-list-item @mousedown.prevent @click="setSelectedItems">
									<v-list-item-action>
										<v-icon> {{ selectAllIcon }} </v-icon>
										<p class="name font-medium ml-2">All</p>
									</v-list-item-action>
                                </v-list-item>
							</template>

							<template v-slot:item="{ item, on, attrs }">
                                <v-list-item v-on="on" v-bind="attrs" #default="{ active }" @click="setSelectFilter(item)">
                                    <v-list-item-action>
                                        <v-checkbox 
											:input-value="active" 
											:label="item.name">
										</v-checkbox>
										<!-- v-model="item.isSelected" -->
                                    </v-list-item-action>
                                </v-list-item>
                            </template>
						</v-select>
					</div>
				</div>

				<div class="calendar-color-indications-wrapper">
					<v-list class="pa-0 color-indication-wrapper">
						<v-list-item
							v-for="(item, i) in colorIndicationData"
							:key="i"
							:ripple="false"
							style="min-height: 30px; padding: 0 6px 0 0 !important"
							@click.stop="setSelectedColorIndication(item, i)"
							v-show="item.isSelected">

							<div :class="`color-indication ${item.value} ${item.class}`"
								:style="`background-color: ${checkTypeBgColor(`${item.value}`)}`">
								<v-list-item-title>
									<span>{{ item.name }}</span>
								</v-list-item-title>
							</div>
						</v-list-item>
					</v-list>
					
					<v-menu bottom left offset-y content-class="shipment-lists-menu">
						<template v-slot:activator="{ on, attrs }">
							<v-btn class="btn-option-colors mr-2" icon v-bind="attrs" v-on="on" :ripple="false">
								<img src="@/assets/icons/settings-blue.svg" alt="dots" width="16px" height="16px">
							</v-btn>
						</template>

						<v-list class="outbound-lists">
							<v-subheader>Card Style</v-subheader>
							<v-list-item @click="selectedColor('white')" :class="selectedCalendarOption === 'white' ? 'active' : ''">
								<v-list-item-title> 
									<div class="color-card-info">
										<div class="color-indication-wrapper">
											<div class="color-indication etd">
												<span>23</span>
											</div>
										</div>
										White Cards 
									</div>
									<div v-if="selectedCalendarOption === 'white'">
										<img src="@/assets/icons/check-blue.svg" alt="check" width="16px" height="16px">
									</div>
								</v-list-item-title>
							</v-list-item>

							<v-list-item @click="selectedColor('color')" :class="selectedCalendarOption === 'color' ? 'active' : ''">
								<v-list-item-title> 
									<div class="color-card-info">
										<div class="color-indication-wrapper">
											<div class="color-indication etd colored">
												<span>23</span>
											</div>
										</div>
										Colored Cards 
									</div>
									<div v-if="selectedCalendarOption === 'color'">
										<img src="@/assets/icons/check-blue.svg" alt="check" width="16px" height="16px">
									</div>
								</v-list-item-title>
							</v-list-item>
						</v-list>
					</v-menu>
				</div>
			</div>
		</div>

		<ShipmentsDesktopTable
			:shipments="shipments"
			:pending="pending"
			:expired="expired"
			:snooze="snooze"
			:draft="draft"
			:pendingQuote="pendingQuote"
			:completed="completed"
			:consolidated="consolidated"
			:editedIndex.sync="editedIndex"
			:editedItem.sync="editedItem"
			:isMobile="isMobile"
			:activeTab.sync="activeTab"
			:pendingCurrentTab.sync="pendingCurrentTab"
			:tablePage.sync="page"
			:search.sync="search"
			v-if="!isMobile && !ArchivedShipmentFlag"
			@showEditShipment="showEditShipment"
			:paginationData.sync="$store.state.shipment.shipmentsPagination"
			:currentViewTab="currentViewTab"
			:isFilterEnabled="isFilterEnabled"
			:currentCalendarType.sync="currentCalendarType"
			:currentDateCalendar.sync="calendarDateFinal"
			:selectedDefaultFilter.sync="selectedDefaultFilter"
			:selectedCalendarOption="selectedCalendarOption"
			:isAllSelected="isAllSelected"
			:isCalendarFilterSelectionEmpty.sync="isCalendarFilterSelectionEmpty"
			:shipmentCurrentTab.sync="shipmentCurrentTab"
			:awaitingDeparture="awaitingDeparture"
			:inTransit="inTransit"
			:arrived="arrived"
			:pickedUp="pickedUp"
			:requiresAttention="requiresAttention"
			:itemsPerPage.sync="itemsPerPage" />

		<ShipmentsMobileTable
			:shipments="shipments"
			:pending="pending"
			:expired="expired"
			:snooze="snooze"
			:draft="draft"
			:pendingQuote="pendingQuote"
			:completed="completed"
			:consolidated="consolidated"
			:editedIndex.sync="editedIndex"
			:editedItem.sync="editedItem"
			:isMobile="isMobile"
			:activeTab.sync="activeTab"
			:pendingCurrentTab.sync="pendingCurrentTab"
			:tablePage.sync="page"
			:search.sync="search"
			v-if="isMobile && !ArchivedShipmentFlag"
			@showEditShipment="showEditShipment"
			:paginationData.sync="$store.state.shipment.shipmentsPagination"
			:currentViewTab="currentViewTab"
			:isFilterEnabled="isFilterEnabled"
			:currentCalendarType.sync="currentCalendarType"
			:currentDateCalendar.sync="calendarDateFinal"
			:selectedDefaultFilter.sync="selectedDefaultFilter"
			:selectedCalendarOption="selectedCalendarOption"
			:isAllSelected="isAllSelected"
			:isCalendarFilterSelectionEmpty.sync="isCalendarFilterSelectionEmpty"
			:shipmentCurrentTab.sync="shipmentCurrentTab"
			:awaitingDeparture="awaitingDeparture"
			:inTransit="inTransit"
			:arrived="arrived"
			:pickedUp="pickedUp"
			:requiresAttention="requiresAttention" />

		<TrackShipment
			:dialogData.sync="dialogTrackShipment"
			:editedItemData.sync="editedTrackShipment"
			@close="closeTrackShipment"
			:isMobile="isMobile" />

		<ArchivedShipment
			v-if="ArchivedShipmentFlag"
			:dialog.sync="dialogView"
			:selectedTab="activeTab"
			@hideArchivedShipment="hideArchivedShipment"
			:isMobile="isMobile" />

		<BulkShipmentAddedModal
			:show="addBulkShipmentDialogModalView"
			:shipmentCounter="shipmentCounter"
			className="add-shipment-dialog-modal"
			:isMobile="isMobile"
			@close="closeAddShipmentModal"
			@addAnotherShipment="addAnotherShipment">
			<template v-slot:title>
				<div>
					<generic-icon iconName="check-success"></generic-icon>
				</div>
			</template>
			<template v-slot:actions="{ footer }">
				<div class="d-flex footer">
					<v-btn
						@click.stop="footer.closeRefresh"
						class="save-btn btn-blue"
						text >
						<span>Done Adding</span>
					</v-btn>
					<v-btn
						class="delete-cancel btn-white edit-shipment-cancel-btn btn-blue"
						text
						@click="footer.addAnotherShipment" >
						<span>Add More</span>
					</v-btn>
				</div>
			</template>
		</BulkShipmentAddedModal>

		<CreateSingleShipmentDialog
			reference="formCreateShipment"
			v-if="createSingleShipmentDialogView"
			className="create-shipment-dialog-wrapper"
			:show.sync="createSingleShipmentDialogView"
			:isMobile="isMobile"
			:rules="createShipmentRules"
			:item.sync="editedItem"
			:isEdit.sync="isEdit"
			:windowWidth="windowWidth"
			:addShipmentDialogModalView.sync="addShipmentDialogModalView"
			:refNumber.sync="refNumber"
			@close="handleCloseCreateSingleShipmentDialog"
			@reloadShipments="callShipmentsAPI"
			:addBulkShipmentDialogModalView.sync="addBulkShipmentDialogModalView"
			@showSingleShipmentSuccessDialog="showSingleShipmentSuccessDialog"
			:createSingleShipmentId.sync="createSingleShipmentId"
			@createSingleShipmentPayload="createSingleShipmentPayload"
			:createSingleShipmentPayloadData="createSingleShipmentPayloadData"
			:createdShipmentType.sync="createdShipmentType"
			:shipmentCounter.sync="shipmentCounter">
			<template v-slot:title>
				<div id="headline-custom-wrapper">
					<span class="headline-custom">{{
						getEditingShipment
							? "Edit Shipment"
							: getMarkingBookedExternal
							? "Mark Booked External"
							: "Add Shipment for Tracking"
					}}</span>
					<span v-if="!getMarkingBookedExternal && 1 == 2"
						class="headline-custom-track">
						Track Shipment
					</span>
				</div>
			</template>
		</CreateSingleShipmentDialog>

		<CreateSingleShipmentSuccessDialog
			:show="createSingleShipmentSuccessDialogView"
			:createSingleShipmentId.sync="createSingleShipmentId"
			:createdShipmentDetails.sync="getShipmentDetails"
			:getShipmentDetailsLoading.sync="getShipmentDetailsLoading"
			@showAddMoreShipmentInfo="showAddMoreShipmentInfo"
			@close="handleCloseSingleShipmentSuccessDialog"
			:createdShipmentType.sync="createdShipmentType"
			@shipmentDetails="passShipmentDetailsToEditComp" />

		<AddMoreShipmentInfoDialog
			:show="showAddMoreShipmentInfoDialog"
			:createSingleShipmentId.sync="createSingleShipmentId"
			@close="handleCloseAddMoreShipmentInfoDialog"
			:createdShipmentType.sync="createdShipmentType"
			:getShipmentDetailsToEditComp="getShipmentDetailsToEditComp"
			@shipmentInfoUpdatedSuccess="shipmentInfoUpdated = true"
			:editItem="shipmentEditItem"
			:isShipmentDetailExist="isShipmentDetailExist" />

		<ViewBookingTemplatesDialog
			:dialogData.sync="viewBookingTemplates"
			@close="viewBookingTemplates = false" />

		<ConfirmDialog :dialogData.sync="createSingleShipmentPayloadData.invalid_mbl_number">
			<template v-slot:dialog_icon>
				<div class="header-wrapper-close">
					<img src="@/assets/icons/icon-delete.svg" alt="alert" />
				</div>
			</template>

			<template v-slot:dialog_title>
				<h2>Invalid MBL</h2>
			</template>

			<template v-slot:dialog_content>
				<p>
					The MBL number you have provided can’t be tracked, Do you want to
					update the MBL number or Do you want to continue.
				</p>
			</template>

			<template v-slot:dialog_actions>
				<v-btn
					class="btn-blue"
					text
					@click="editMblNumber()"
					:disabled="getCreateShipmentLoading">
					<span>Edit MBL</span>
				</v-btn>
				<v-btn
					class="btn-white"
					text
					@click="addShipmentAnyway()"
					:disabled="getCreateShipmentLoading">
					<span v-if="getCreateShipmentLoading">Shipment Adding...</span>
					<span v-else>Add Shipment Anyway</span>
				</v-btn>
			</template>
		</ConfirmDialog>

		<ConfirmDialog :dialogData.sync="createSingleShipmentPayloadData.existing_lcl_shipment">
			<template v-slot:dialog_icon>
				<div class="header-wrapper-close">
					<img src="@/assets/icons/icon-delete.svg" alt="alert" />
				</div>
			</template>

			<template v-slot:dialog_title>
				<h2>Is this a LCL shipment?</h2>
			</template>

			<template v-slot:dialog_content>
				<p>
					This MBL is already being tracked by our system. Do you want to track
					this as an LCL Shipment?
				</p>
			</template>

			<template v-slot:dialog_actions>
				<v-btn
					class="btn-blue"
					text
					@click="addShipmentAnyway()"
					:disabled="getCreateShipmentLoading">
					<span v-if="getCreateShipmentLoading">Shipment Adding...</span>
					<span v-else>Yes, Continue</span>
				</v-btn>
				<v-btn
					class="btn-white"
					text
					@click="editMblNumber()"
					:disabled="getCreateShipmentLoading">
					<span>No, Change MBL</span>
				</v-btn>
			</template>
		</ConfirmDialog>

		<ConfirmDialog :dialogData.sync="createSingleShipmentPayloadData.existing_mbl_number">
			<template v-slot:dialog_icon>
				<div class="header-wrapper-close">
					<img src="@/assets/icons/icon-delete.svg" alt="alert" />
				</div>
			</template>

			<template v-slot:dialog_title>
				<h2>Shipment Already Exist!</h2>
			</template>

			<template v-slot:dialog_content>
				<p>
					There is an existing shipment with the same MBL number. Do you want to
					view the shipment?
				</p>
			</template>

			<template v-slot:dialog_actions>
				<v-btn class="btn-blue" text>
					<span>
						<a :href="'/shipment/' + createSingleShipmentPayloadData.existing_shipment_id"
							style="color: #FFFFFF; text-decoration: none;">
							View Shipment
						</a>
					</span>
				</v-btn>
				<v-btn class="btn-white" text @click="editMblNumber()">
					<span>Edit MBL</span>
				</v-btn>
			</template>
		</ConfirmDialog>

		<ConfirmDialog :dialogData.sync="createSingleShipmentPayloadData.existing_fcl_shipment">
			<template v-slot:dialog_icon>
				<div class="header-wrapper-close">
					<img src="@/assets/icons/icon-delete.svg" alt="alert" />
				</div>
			</template>

			<template v-slot:dialog_title>
				<h2>Shipment Already Exist!</h2>
			</template>

			<template v-slot:dialog_content>
				<p>
					There is an existing shipment with the same MBL number. Do you want to
					view the shipment?
				</p>
			</template>

			<template v-slot:dialog_actions>
				<v-btn class="btn-blue" text>
					<span>
						<a :href="'/shipment/' + createSingleShipmentPayloadData.existing_shipment_id"
							style="color: #FFFFFF; text-decoration: none;">
							View Shipment
						</a>
					</span>
				</v-btn>
				<v-btn class="btn-white" text @click="editMblNumber()">
					<span>Edit MBL</span>
				</v-btn>
			</template>
		</ConfirmDialog>

		<ConfirmDialog :dialogData.sync="shipmentInfoUpdated">
			<template v-slot:dialog_icon>
				<div class="header-wrapper-close">
					<img src="@/assets/icons/CircleCheckOutline.svg" alt="alert" />
				</div>
			</template>

			<template v-slot:dialog_title>
				<h2>Shipment Updated Successfully</h2>
			</template>

			<template v-slot:dialog_content>
				<p>Your shipment has been successfully updated! you can view it.</p>
			</template>

			<template v-slot:dialog_actions>
				<v-btn class="btn-blue" text>
					<span>
						<a :href="'/shipment/' + createSingleShipmentId"
							style="color: #FFFFFF; text-decoration: none;">
							View Shipment
						</a>
					</span>
				</v-btn>
				<v-btn class="btn-white" text @click="shipmentInfoUpdated = false">
					<span>Close</span>
				</v-btn>
			</template>
		</ConfirmDialog>
	</div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import ShipmentsDesktopTable from "../components/Tables/Shipments/ShipmentsDesktopTable.vue";
import ShipmentsMobileTable from "../components/Tables/Shipments/ShipmentsMobileTable.vue";
import TrackShipment from "../components/ShipmentComponents/TrackShipment/TrackShipmentDialog.vue";
import axios from "axios";
import KeneticIcon from "../components/Icons/KeneticIcon";
import GenericIcon from "../components/Icons/GenericIcon";
import CreateShipmentDialog from "../components/Dialog/FormShipmentDialog/CreateShipmentDialog";
import BookingShipmentDialog from "../components/Dialog/FormShipmentDialog/BookingShipmentDialog";
import AddShipmentDialogModal from "../components/Dialog/FormShipmentDialog/Modals/AddShipmentModal";
import NoConnectedSupplierModal from "../components/Dialog/FormShipmentDialog/Modals/NoConnectedSupplierModal";
import ShipmentDropdown from "../components/Dropdown/ShipmentDropdown";
import CustomIcon from "@/components/Icons/CustomIcon";
import ArchivedShipment from "../components/Tables/Shipments/ArchivedShipment.vue";
import BulkShipmentAddedModal from "@/components/Dialog/FormShipmentDialog/Modals/BulkShipmentAddedModal";
import CreateSingleShipmentDialog from "../components/Dialog/FormShipmentDialog/CreateSingleShipmentDialog";
import CreateSingleShipmentSuccessDialog from "../components/Dialog/FormShipmentDialog/CreateSingleShipmentSuccessDialog";
import AddMoreShipmentInfoDialog from "../components/Dialog/FormShipmentDialog/AddMoreShipmentInfoDialog";
import ConfirmDialog from "@/components/Dialog/GlobalDialog/ConfirmDialog.vue";
import BookingItem from "../components/Dialog/FormShipmentDialog/Structures/BookingItem";
import moment from "moment";
import ViewBookingTemplatesDialog from "../components/ShipmentComponents/BookingShipment/SubComponents/ViewBookingTemplatesDialog.vue";
import globalMethods from '../utils/globalMethods';
import { monthLists, yearLists } from "../components/Tables/Shipments/Tabs/js/datesModel";
import { tabs, shipmentSubtabs, calendarType,
	viewTabsName, calendarFilterItems, calendarBgColors, pendingSubTabs, pendingSubTabsClasses } 
from "../components/Tables/Shipments/Tabs/js/shipmentFilters";
import _ from "lodash";

let cancel;
let CancelToken = axios.CancelToken;

export default {
	components: {
		KeneticIcon,
		AddShipmentDialogModal,
		ShipmentsDesktopTable,
		ShipmentsMobileTable,
		TrackShipment,
		CreateShipmentDialog,
		BookingShipmentDialog,
		NoConnectedSupplierModal,
		ShipmentDropdown,
		GenericIcon,
		CustomIcon,
		ArchivedShipment,
		BulkShipmentAddedModal,
		CreateSingleShipmentDialog,
		CreateSingleShipmentSuccessDialog,
		AddMoreShipmentInfoDialog,
		ConfirmDialog,
		ViewBookingTemplatesDialog,
	},
	data: () => ({
		windowWidth: 0,
		menu: false,
		isEdit: false,
		addShipmentDialogModalView: false,
		refNumber: "",
		shipmentDropDownClicked: false,
		submitRequestModalView: false,
		closeNoConnectedSupplierModalView: false,
		closeNoConnectedSupplierModalRole: "",
		bookingRequestSubmittedModalView: false,
		page: 1,
		searchPendingData: "",
		createShipmentRules: {
			mbl_num: [(v) => !!v || "MBL number is required."],
			etd: [(v) => !!v || "ETD is required."],
			eta: [(v) => !!v || "ETA is required."],
			contact_number: [(v) => !!v || "Contact Number is required."],
		},
		createShipmentDialogView: false,
		bookingShipmentDialogView: false,
		dialog: false,
		isMobile: false,
		itemsPerPage: 20,
		typingTimeout: 0,
		tabs,
		pendingSubTabs,
		pendingSubTabsClasses,
		activeTab: 1,
		selectedTab: 1,
		pendingCurrentTab: 0,
		search: "",
		dialogDelete: false,
		editedIndex: -1,
		editedItem: {
			Departure: "",
			Arrival: "",
			Suppliers: "",
			PO: "",
			Status: "",
			id: "",
			mode: "",
			container_num_list: "",
		},
		defaultItem: {
			Departure: "",
			Arrival: "",
			Suppliers: "",
			PO: "",
			Status: "",
			id: "",
			mode: "",
			container_num_list: "",
		},
		dialogTrackShipment: false,
		editedTrackShipment: {
			mbl_num: "",
			po_num: null,
		},
		defaultTrackShipment: {
			mbl_num: "",
			po_num: null,
		},
		ArchivedShipmentFlag: false,
		dialogView: false,
		addBulkShipmentDialogModalView: false,
		shipmentCounter: "",
		createSingleShipmentDialogView: false,
		createSingleShipmentSuccessDialogView: false,
		createSingleShipmentId: "",
		showAddMoreShipmentInfoDialog: false,
		createSingleShipmentPayloadData: {},
		createdShipmentType: "",
		getShipmentDetailsToEditComp: {},
		shipmentInfoUpdated: false,
		shipmentEditItem: BookingItem,
		isShipmentDetailExist: {},
		currentViewTab: 0,
		currentViewTabCopy: 0,
		viewTabsName,
		isFilterEnabled: false,
		calendarType,
		currentCalendarType: 0,
		currentDateCalendar: moment(new Date()).format("YYYY-MM-DD"),
		calendarDateFinal: moment(new Date()).format("YYYY-MM-DD"),
		menu2: false,
		calendarFilterItems,
		selectedDefaultFilter: [],
		viewBookingTemplates: false,
		selectedCalendarOption: "color",
		calendarBgColors,
		isAllSelected: false,
		isColorIndicationSelected: false,
		isCalendarFilterSelectionEmpty: false,
		daysOfWeek: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
		selectedColorIndicationItem: null,
		shipmentCurrentTab: 0,
		shipmentSubtabs,
		isShipmentSearchExpanded: false,
	}),
	computed: {
		...mapGetters([
			"getAllShipments",
			"getAllPendingShipments",
			"getAllDraftShipments",
			"getAllDraftShipmentsLoading",
			"getAllPendingQuoteShipments",
			"getAllSnoozeShipments",
			"getAllExpiredShipments",
			"getAllCompletedShipments",
			"getShipmentLoadingStatus",
			"getAllPendingShipmentsLoading",
			"getAllExpiredShipmentsLoading",
			"getAllCompletedShipmentsLoading",
			"getSearchedShipments",
			"getSearchedShipmentLoading",
			"getCreateShipmentLoading",
			"getTerminalRegions",
			"getConsolidatedShipments",
			"getAllShipmentsCalendarView",
			"getAwaitingDepartureShipments",
			"getInTransitShipments",
			"getArrivedShipments",
			"getPickedUpShipments",
			"getRequiresAShipments",
			"getShipmentSubtabsCount",
			"getHasPastLastFreeDay"
		]),
		getEditingShipment() {
			return this.$store.getters["page/getEditingShipment"];
		},
		getEditingDraftShipment() {
			return this.$store.getters["page/getEditingDraftShipment"];
		},
		getMarkingBookedExternal() {
			return this.$store.getters["page/getMarkingBookedExternal"];
		},
		getAddingShipmentTracking() {
			return this.$store.getters["page/getAddingShipmentTracking"];
		},
		getCreatingBookingRequest() {
			return this.$store.getters["page/getCreatingBookingRequest"];
		},
		activeTabComputed: {
			get() {
				if (this.selectedTab > 0) { return this.activeTab; } 
				else { return this.pendingCurrentTab; }
			},
			set(value) {
				if (this.selectedTab > 0) { this.activeTab = value; } 
				else { this.pendingCurrentTab = value; }
			},
		},
		tabsComputed() {
			if (this.selectedTab > 0) {
				let newTabs = [];
				newTabs = ["Booking", "Shipments", "Completed"];
				return newTabs;
			} else { return this.pendingSubTabs; }
		},
		shipments() {
			let data = [];
			if (typeof this.getAllShipments.shipments !== "undefined") {
				data = this.getAllShipments.shipments;
			}
			return data;
		},
		snooze() {
			let data = [];
			if (typeof this.getAllSnoozeShipments.shipments !== "undefined") {
				data = this.getAllSnoozeShipments.shipments;
			}
			return data;
		},
		pendingQuote() {
			let data = [];

			if (typeof this.getAllPendingQuoteShipments.shipments !== "undefined") {
				data = this.getAllPendingQuoteShipments.shipments;
			}
			return data;
		},
		pending() {
			let data = [];

			if (typeof this.getAllPendingShipments.shipments !== "undefined") {
				data = this.getAllPendingShipments.shipments;
			}

			return data;
		},
		expired() {
			let data = [];

			if (typeof this.getAllExpiredShipments.shipments !== "undefined") {
				data = this.getAllExpiredShipments.shipments;
			}

			return data;
		},
		draft() {
			let data = [];

			if (typeof this.getAllDraftShipments.shipments !== "undefined") {
				data = this.getAllDraftShipments.shipments;
			}

			return data;
		},
		completed() {
			let data = [];

			if (typeof this.getAllCompletedShipments.shipments !== "undefined") {
				data = this.getAllCompletedShipments.shipments;
			}

			return data;
		},
		consolidated() {
			let data = [];

			if (typeof this.getConsolidatedShipments.shipments !== "undefined") {
				data = this.getConsolidatedShipments.shipments;
			}

			return data;
		},
		awaitingDeparture() {
			let data = [];

			if (typeof this.getAwaitingDepartureShipments.shipments !== "undefined") {
				data = this.getAwaitingDepartureShipments.shipments;
			}

			return data;
		},
		inTransit() {
			let data = [];

			if (typeof this.getInTransitShipments.shipments !== "undefined") {
				data = this.getInTransitShipments.shipments;
			}

			return data;
		},
		arrived() {
			let data = [];

			if (typeof this.getArrivedShipments.shipments !== "undefined") {
				data = this.getArrivedShipments.shipments;
			}

			return data;
		},
		pickedUp() {
			let data = [];

			if (typeof this.getPickedUpShipments.shipments !== "undefined") {
				data = this.getPickedUpShipments.shipments;
			}

			return data;
		},
		requiresAttention() {
			let data = [];

			if (typeof this.getRequiresAShipments.shipments !== "undefined") {
				data = this.getRequiresAShipments.shipments;
			}

			return data;
		},
		selectAllIcon() {
			if (this.selectedDefaultFilter.length === this.calendarFilterItems.length)
				return "mdi-checkbox-marked"

			if (this.selectedDefaultFilter.length > 0 && 
				this.calendarFilterItems.length > this.selectedDefaultFilter.length)
				return "mdi-minus-box"

			return "mdi-checkbox-blank-outline"
		},
		colorIndicationData() {
			let data = this.calendarFilterItems;
			if (this.isColorIndicationSelected) data = this.calendarFilterItems;
			else data = this.selectedDefaultFilter;
			data.sort((a, b) => a.id - b.id)
			return data;
		},
		computedDateFormatted() {
			return this.formatComputedDateValue(this.calendarDateFinal)
		},
		dateFormatted: {
			get() {
				return this.formatDate(new Date().toISOString().substr(0, 10));
			},
			set(val) {
				return val;
			}
		},
		getMonthsLists() { return monthLists; },
		getYearsLists() { return yearLists; },
		getCurrentMonth: {
			get() {
				return this.formatDateMonth(this.currentDateCalendar);
			},
			set(v) {
				let a = this.formatWeeklySelectedDate(v, "month");
				return this.formatDateMonth(a);
			}
		},
		getCurrentYear: {
			get() {
				return moment(this.currentDateCalendar).format("YYYY").toString();
			},
			set(v) {
				let a = this.formatWeeklySelectedDate(v, "year");
				return moment(a).format("YYYY").toString();
			}
		},
	},
	watch: {
		dialog(val) {
			val || this.close();
		},
		dialogDelete(val) {
			val || this.closeDelete();
		},
		currentDateCalendar(val) {
			this.dateFormatted = this.formatComputedDateValue(this.currentDateCalendar);
			localStorage.setItem("shipment_calendar_current_date", val);
		},
		currentCalendarType(nv) {
			if (nv === 0) {
				this.currentDateCalendar = moment(this.calendarDateFinal).format("YYYY-MM-DD");
			} else {
				this.currentDateCalendar = moment(this.calendarDateFinal).format("YYYY-MM");
			}
		},
		menu2(val) {
			if (!val) {
				if (this.currentCalendarType === 0) {
					this.currentDateCalendar = moment(this.calendarDateFinal).format("YYYY-MM-DD");
				} else {
					this.currentDateCalendar = moment(this.calendarDateFinal).format("YYYY-MM");
				}
			}
		},
		isMobile(val) {
			if (val) this.currentViewTab = 0;
		},
	},
	async created() {
		window.addEventListener('beforeunload', this.handler);
	},
	async mounted() {
		//clear shipment documents
		this.clearShipmentDocuments();
		this.selectedDefaultFilter = _.filter(this.calendarFilterItems, e => (e.isDefault));

		//set current page
		this.$store.dispatch("page/setPage", "shipments");
		this.windowWidth = window.innerWidth;

		//set tab
		if (this.$store.state.page.currentTab !== "undefined") {
			if (this.activeTab !== this.$store.state.page.currentTab) {
				this.activeTab = this.$store.state.page.currentTab;
			}
		}

		// set current shipment page
		if (this.$store.state.page.currentShipmentPage !== "undefined") {
			if (this.page !== this.$store.state.page.currentShipmentPage) {
				this.page = this.$store.state.page.currentShipmentPage;
			}
		}

		// set current pending tab
		if (this.$store.state.page.currentPendingShipmentTab !== "undefined") {
			if (this.pendingCurrentTab !== this.$store.state.page.currentPendingShipmentTab) {
				this.pendingCurrentTab = this.$store.state.page.currentPendingShipmentTab;
			}
		}

		this.selectedTab = this.$store.state.page.currentTab;
		let selectedOption = localStorage.getItem("selected_shipment_calendar_option");
		this.selectedCalendarOption = selectedOption === null ? "color" : selectedOption;

		let selectedShipmentView = localStorage.getItem("selected_shipment_view");
		this.currentViewTab = selectedShipmentView === null ? 0 : parseInt(selectedShipmentView);
		this.currentViewTabCopy = this.currentViewTab;

		if (this.currentViewTab === 1) {
			let selectedCalendarType = localStorage.getItem("selected_calendar_type");
			this.currentCalendarType = selectedCalendarType === null ? 0 : parseInt(selectedCalendarType);

			let currentDateSelected = localStorage.getItem("shipment_calendar_current_date");
			this.calendarDateFinal = currentDateSelected !== null ? currentDateSelected : moment().format("YYYY-MM-DD");
			this.currentDateCalendar = this.calendarDateFinal;
		}

		//clear tooltips
		this.clear();

		//call all shipments api
		this.callShipmentsAPI();
	},
	methods: {
		...mapActions([
			"fetchShipments",
			"fetchCompletedShipments",
			"fetchPendingShipments",
			"fetchPendingQuoteShipments",
			"fetchConsolidationRequests",
			"fetchSnoozeShipments",
			"fetchDraftShipments",
			"fetchExpiredShipments",
			"setShipmentPagination",
			"setPendingPagination",
			"setPendingQuotePagination",
			"setConsolidationRequestsPagination",
			"setDraftPagination",
			"setSnoozePagination",
			"setExpiredPagination",
			"setCompletedPagination",
			"fetchShipmentsSearched",
			"setSearchedPagination",
			"setShipmentSearchedVal",
			"setSearchedShipmentsLoading",
			"clearTooltips",
			"clearShipmentDocuments",
			"fetchShipmentDetails",
			"createShipment",
			"fetchAwaitingShipments",
			"fetchInTransitShipments",
			"fetchArrivedShipments",
			"fetchPickedUpShipments",
			"fetchRequiresAttentionShipments"
		]),
		...globalMethods,
		onShipmentTabChange(i) {
			this.isShipmentSearchExpanded = false;
			this.setSearchDefault();
			this.shipmentCurrentTab = i;
			this.page = 1;
		},
		showEditShipment(item) {
			this.editedIndex = this.shipments.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.isEdit = true;
			if (this.getMarkingBookedExternal) {
				this.createShipmentDialogView = true;
			} else {
				this.bookingShipmentDialogView = true;
			}
		},
		markBookedExternal() {
			console.log("edited item", this.editedItem);
		},
		closeNoSupplierConnectedModal() {
			this.closeNoConnectedSupplierModalView = false;
		},
		closeAddShipmentModal() {
			this.addShipmentDialogModalView = false;
		},
		addAnotherShipment() {
			this.addBulkShipmentDialogModalView = false;
			this.createSingleShipmentDialogView = true;
		},
		handleCloseCreateShipmentDialog() {
			this.createShipmentDialogView = false;
		},
		handleCloseBookingShipmentDialog() {
			this.bookingShipmentDialogView = false;
			this.$store.dispatch("page/setEditingDraftShipment", false);
		},
		showBookingShipmentDialog() {
			this.bookingShipmentDialogView = true;
		},
		showAddShipmentDialog() {
			this.createShipmentDialogView = true;
		},
		showSingleAddShipmentDialog() {
			this.createSingleShipmentDialogView = true;
		},
		showSingleShipmentSuccessDialog() {
			this.createSingleShipmentDialogView = false;
			this.createSingleShipmentSuccessDialogView = true;
			this.createSingleShipmentPayloadData.invalid_mbl_number = false;
			this.createSingleShipmentPayloadData.existing_mbl_number = false;
			this.createSingleShipmentPayloadData.existing_fcl_shipment = false;
			this.createSingleShipmentPayloadData.existing_lcl_shipment = false;
		},
		showAddMoreShipmentInfo() {
			this.createSingleShipmentDialogView = false;
			this.createSingleShipmentSuccessDialogView = false;
			this.showAddMoreShipmentInfoDialog = true;
		},
		getCurrentTabMobile(id) {
			if (this.activeTab == 0) {
				this.pendingCurrentTab = id;
				this.currentViewTab = 0;
			} else {
				this.activeTab = id;
				this.selectedTab = id;
			}
		},
		getCurrentTab(id) {
			this.selectedTab = id;
			this.$store.dispatch("page/setTab", id);			
			if (id == 0 || id === 2) {
				this.currentViewTab = 0;
			} else {
				if (this.currentViewTabCopy !== this.currentViewTab) {
					this.currentViewTab = this.currentViewTabCopy;
				}
			}
		},
		selectTab(value) {
			this.$store.dispatch("page/setCurrentShipmentPage", 1);
			this.selectedTab = value;
			this.$store.dispatch("page/setTab", value);
			this.activeTab = value;
			this.pendingCurrentTab = 0;
			this.$store.dispatch("page/setCurrentPendingShipmentTab", 0);
			this.onPendingTabChange();

			let storePagination = this.$store.state.shipment.shipmentsPagination;
			let storeShipmentTabData = this.$store.state.shipment;

			if (value === 2 && this.completed.length === 0) {
				let payloadCompleted = {
					page: storePagination.completedTab.currentTab === 2 ? storePagination.completedTab.current_page : 1,
					order: storeShipmentTabData.completedOrder.order,
				};

				this.fetchCompletedShipments(payloadCompleted).then(() => {
					this.currentTabCompleted();
				});	
			}
		},
		clear(e) {
			if (typeof e !== "undefined") {
				let classList = e.target.classList;
				if (!classList.contains("custom-tooltip")) {
					if (
						typeof this.getAllPendingQuoteShipments !== "undefined" &&
						this.getAllPendingQuoteShipments.shipments !== "undefined"
					) {
						this.clearTooltips(this.getAllPendingQuoteShipments);
					}

					if (
						typeof this.getAllSnoozeShipments !== "undefined" &&
						this.getAllSnoozeShipments.shipments !== "undefined"
					) {
						let snoozeShipments = this.getAllSnoozeShipments;
						snoozeShipments.shipment_type = "snooze";
						this.clearTooltips(snoozeShipments);
					}

					if (
						typeof this.getAllPendingShipments !== "undefined" &&
						this.getAllPendingShipments.shipments !== "undefined"
					) {
						let pendingShipments = this.getAllPendingShipments;
						pendingShipments.shipment_type = "pending";
						this.clearTooltips(pendingShipments);
					}

					if (
						typeof this.getAllExpiredShipments !== "undefined" &&
						this.getAllExpiredShipments.shipments !== "undefined"
					) {
						let expiredShipments = this.getAllExpiredShipments;
						expiredShipments.shipment_type = "expired";
						this.clearTooltips(expiredShipments);
					}
				}
			} else {
				let limit = 10000;
				let start = 0;
				let t = setInterval(() => {
					if (start === limit) {
						clearInterval(t);
					} else {
						if (
							typeof this.getAllPendingQuoteShipments !== "undefined" &&
							this.getAllPendingQuoteShipments.shipments !== "undefined"
						) {
							let pendingQuoteShipments = this.getAllPendingQuoteShipments;
							let expiredShipments = this.getAllExpiredShipments;
							let snoozeShipments = this.getAllSnoozeShipments;
							let pendingShipments = this.getAllPendingShipments;
							expiredShipments.shipment_type = "expired";
							snoozeShipments.shipment_type = "snooze";
							pendingShipments.shipment_type = "pending";

							this.clearTooltips(pendingQuoteShipments);
							this.clearTooltips(expiredShipments);
							this.clearTooltips(snoozeShipments);
							this.clearTooltips(pendingShipments);
							clearInterval(t);
						} else {
							start += 200;
						}
					}
				}, 200);
			}
		},
		setSearchDefault() {
			this.search = "";
			this.searchPendingData = "";				
			setTimeout(() => {
				this.setShipmentSearchedVal([]);
			}, 300);
		},
		clearSearched() {
			if (this.activeTab === 1) {
				if (this.search !== "") {
					this.setSearchDefault();
					document.getElementById("search-input-shipments1").focus();
				} else {
					this.isShipmentSearchExpanded = false;
				}
			} else {
				this.setSearchDefault();
			}
		},
		isShowSearchShipment() {
			if (this.activeTab === 0) {
				if (this.pendingCurrentTab === 0 && this.draft.length > 0) {
					return true;
				} else if (this.pendingCurrentTab === 1 && this.pendingQuote.length > 0) {
					return true;
				} else if (this.pendingCurrentTab === 2 && this.pending.length > 0) {
					return true;
				} else if (this.pendingCurrentTab === 3 && this.consolidated.length > 0) {
					return true;
				} else if (this.pendingCurrentTab === 4 && this.snooze.length > 0) {
					return true;
				} else if (this.pendingCurrentTab === 5 && this.expired.length > 0) {
					return true;
				} else { return false; }
			}
		},
		handleGlobalClick(e) {
			this.clear(e);
		},
		getShipmentCount(tab) {
			let data = "0";
			if (tab == 0) {
				let pendingLength = typeof this.getAllPendingShipments.total !== "undefined"
						? this.getAllPendingShipments.total : 0;
				let snoozeLength = typeof this.getAllSnoozeShipments.total !== "undefined"
						? this.getAllSnoozeShipments.total : 0;
				let pendingQuoteLength = typeof this.getAllPendingQuoteShipments.total !== "undefined"
						? this.getAllPendingQuoteShipments.total : 0;
				let draftLength = typeof this.getAllDraftShipments.total !== "undefined"
						? this.getAllDraftShipments.total : 0;

				data = parseInt(pendingLength + snoozeLength + pendingQuoteLength + draftLength);
			} else {
				data = typeof this.getAllShipments.total !== "undefined"
						? this.getAllShipments.total : 0;
			}

			let finalData = data !== 0 ? data : "0";
			return finalData;
		},
		async onTabMobileChange(id) {
			this.page = 1;
			// set current shipment page back to 1 if user changes tab
			this.$store.dispatch("page/setCurrentShipmentPage", 1);
			this.search = "";
			this.searchPendingData = "";
			this.setShipmentSearchedVal([]);

			if (this.selectedTab === 0) {
				this.activeTab = 0;
				this.pendingCurrentTab = id;
				this.onPendingTabChange();
			} else {
				this.page = 1;
				// set current shipment page back to 1 if user changes tab
				this.$store.dispatch("page/setCurrentShipmentPage", 1);
				this.$store.dispatch("page/setCurrentPendingShipmentTab", 0);
				this.$store.dispatch("page/setTab", id);
				this.search = "";
				this.setShipmentSearchedVal([]);
				this.activeTab = id;
				this.selectedTab = id;
			}
		},
		async onTabChange() {
			this.page = 1;
			// set current shipment page back to 1 if user changes tab
			this.$store.dispatch("page/setCurrentShipmentPage", 1);
			this.$store.dispatch("page/setCurrentPendingShipmentTab", 0);
			this.search = "";
			this.searchPendingData = "";
			this.setShipmentSearchedVal([]);
		},
		onPendingTabChange() {
			this.$store.dispatch(
				"page/setCurrentPendingShipmentTab",
				this.pendingCurrentTab
			);
			this.search = "";
			this.searchPendingData = "";
			let expiredShipments = this.getAllExpiredShipments;
			let pendingShipments = this.getAllPendingShipments;
			let pendingQuoteShipments = this.getAllPendingQuoteShipments;
			let snoozeShipments = this.getAllSnoozeShipments;
			let consolidatedShipments = this.getConsolidatedShipments;

			expiredShipments.shipment_type = "expired";
			pendingShipments.shipment_type = "pending";
			pendingQuoteShipments.shipment_type = "pendingQuote";
			snoozeShipments.shipment_type = "snooze";
			consolidatedShipments.shipment_type = "consolidated";

			this.clearTooltips(pendingQuoteShipments);
			this.clearTooltips(pendingShipments);
			this.clearTooltips(snoozeShipments);
			this.clearTooltips(expiredShipments);
			this.clearTooltips(consolidatedShipments);
			this.setShipmentSearchedVal([]);
		},
		onResize() {
			this.windowWidth = window.innerWidth;
			if (window.screen.width < 769) {
				this.isMobile = true;
			} else {
				this.isMobile = false;
			}
		},
		openTrackShipment() {
			this.dialogTrackShipment = true;
			this.$nextTick(() => {
				this.editedTrackShipment = Object.assign({}, this.defaultTrackShipment);
			});
		},
		closeTrackShipment() {
			this.dialogTrackShipment = false;
			this.$nextTick(() => {
				this.editedTrackShipment = Object.assign({}, this.defaultTrackShipment);
			});
		},
		editItem(item) {
			this.editedIndex = this.shipments.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.dialog = true;
		},
		deleteItem(item) {
			this.editedIndex = this.shipments.indexOf(item);
			this.editedItem = Object.assign({}, item);
			this.dialogDelete = true;
		},
		deleteItemConfirm() {
			this.shipments.splice(this.editedIndex, 1);
			this.closeDelete();
		},
		close() {
			this.dialog = false;
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
			});
		},
		closeDelete() {
			this.dialogDelete = false;
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.defaultItem);
				this.editedIndex = -1;
			});
		},
		save() {
			if (this.editedIndex > -1) {
				Object.assign(this.shipments[this.editedIndex], this.editedItem);
			} else {
				this.shipments.push(this.editedItem);
			}

			this.close();
		},
		currentTabPendingQuote() {
			if (typeof this.getAllPendingQuoteShipments !== "undefined") {
				let pending = this.getAllPendingQuoteShipments;
				this.getTotalPendingQuoteShipments(pending);
			} else {
				this.currentTabPendingQuote();
			}
		},
		currentTabConsolidationRequests() {
			if (typeof this.getConsolidatedShipments !== "undefined") {
				let consolidated = this.getConsolidatedShipments;
				this.getTotalConsolidationRequestsShipments(consolidated);
			} else {
				this.currentTabConsolidationRequests();
			}
		},
		currentTabPending() {
			if (typeof this.getAllPendingShipments !== "undefined") {
				let pending = this.getAllPendingShipments;
				this.getTotalPendingShipments(pending);
			} else {
				this.currentTabPending();
			}
		},
		currentTabDraft() {
			if (typeof this.getAllDraftShipments !== "undefined") {
				let pending = this.getAllDraftShipments;
				this.getTotalDraftShipments(pending);
			} else {
				this.currentTabDraft();
			}
		},
		currentTabSnooze() {
			if (typeof this.getAllSnoozeShipments !== "undefined") {
				let expired = this.getAllSnoozeShipments;
				this.getTotalSnoozeShipments(expired);
			} else {
				this.currentTabSnooze();
			}
		},
		currentTabExpired() {
			if (typeof this.getAllExpiredShipments !== "undefined") {
				let expired = this.getAllExpiredShipments;
				this.getTotalExpiredShipments(expired);
			} else {
				this.currentTabExpired();
			}
		},
		currentTabCompleted() {
			if (typeof this.getAllCompletedShipments !== "undefined") {
				let completed = this.getAllCompletedShipments;
				this.getTotalCompletedShipments(completed);
			} else {
				this.currentTabCompleted();
			}
		},
		getTotalSnoozeShipments(shipments_data) {
			let pagination = {
				currentTab: 1,
				currentSubTab: 0,
				current_page: 1,
				old_page: 1,
				total: 0,
				per_page: 0,
			};

			if (shipments_data !== null) {
				if (typeof shipments_data.last_page !== "undefined") {
					pagination.total = shipments_data.last_page;
				}

				if (typeof shipments_data.current_page !== "undefined") {
					pagination.current_page = shipments_data.current_page;
					pagination.old_page = shipments_data.current_page;
				}

				if (typeof shipments_data.per_page !== "undefined") {
					pagination.per_page = shipments_data.per_page;
				}
			}

			this.setSnoozePagination(pagination);
		},
		getTotalPendingQuoteShipments(shipments_data) {
			let pagination = {
				currentTab: 1,
				currentSubTab: 0,
				current_page: 1,
				old_page: 1,
				total: 0,
				per_page: 0,
			};

			if (shipments_data !== null) {
				if (typeof shipments_data.last_page !== "undefined") {
					pagination.total = shipments_data.last_page;
				}

				if (typeof shipments_data.current_page !== "undefined") {
					pagination.current_page = shipments_data.current_page;
					pagination.old_page = shipments_data.current_page;
				}

				if (typeof shipments_data.per_page !== "undefined") {
					pagination.per_page = shipments_data.per_page;
				}
			}

			this.setPendingQuotePagination(pagination);
		},
		getTotalConsolidationRequestsShipments(shipments_data) {
			let pagination = {
				currentTab: 0,
				currentSubTab: 3,
				current_page: 1,
				old_page: 1,
				total: 0,
				per_page: 0,
			};

			if (shipments_data !== null) {
				if (typeof shipments_data.last_page !== "undefined") {
					pagination.total = shipments_data.last_page;
				}

				if (typeof shipments_data.current_page !== "undefined") {
					pagination.current_page = shipments_data.current_page;
					pagination.old_page = shipments_data.current_page;
				}

				if (typeof shipments_data.per_page !== "undefined") {
					pagination.per_page = shipments_data.per_page;
				}
			}

			this.setConsolidationRequestsPagination(pagination);
		},
		getTotalDraftShipments(shipments_data) {
			let pagination = {
				currentTab: 1,
				currentSubTab: 0,
				current_page: 1,
				old_page: 1,
				total: 0,
				per_page: 0,
			};

			if (shipments_data !== null) {
				if (typeof shipments_data.last_page !== "undefined") {
					pagination.total = shipments_data.last_page;
				}

				if (typeof shipments_data.current_page !== "undefined") {
					pagination.current_page = shipments_data.current_page;
					pagination.old_page = shipments_data.current_page;
				}

				if (typeof shipments_data.per_page !== "undefined") {
					pagination.per_page = shipments_data.per_page;
				}
			}
			this.setDraftPagination(pagination);
		},
		getTotalPendingShipments(shipments_data) {
			let pagination = {
				currentTab: 1,
				currentSubTab: 0,
				current_page: 1,
				old_page: 1,
				total: 0,
				per_page: 0,
			};

			if (shipments_data !== null) {
				if (typeof shipments_data.last_page !== "undefined") {
					pagination.total = shipments_data.last_page;
				}

				if (typeof shipments_data.current_page !== "undefined") {
					pagination.current_page = shipments_data.current_page;
					pagination.old_page = shipments_data.current_page;
				}

				if (typeof shipments_data.per_page !== "undefined") {
					pagination.per_page = shipments_data.per_page;
				}
			}

			this.setPendingPagination(pagination);
		},
		getTotalExpiredShipments(shipments_data) {
			let pagination = {
				currentTab: 1,
				currentSubTab: 0,
				current_page: 1,
				old_page: 1,
				total: 0,
				per_page: 0,
			};

			if (shipments_data !== null) {
				if (typeof shipments_data.last_page !== "undefined") {
					pagination.total = shipments_data.last_page;
				}

				if (typeof shipments_data.current_page !== "undefined") {
					pagination.current_page = shipments_data.current_page;
					pagination.old_page = shipments_data.current_page;
				}

				if (typeof shipments_data.per_page !== "undefined") {
					pagination.per_page = shipments_data.per_page;
				}
			}

			this.setExpiredPagination(pagination);
		},
		getTotalShipments(shipments_data) {
			let pagination = {
				currentTab: 1,
				currentSubTab: 0,
				current_page: 1,
				old_page: 1,
				total: 0,
				per_page: 0,
			};

			if (shipments_data !== null) {
				if (typeof shipments_data.last_page !== "undefined") {
					pagination.total = shipments_data.last_page;
				}

				if (typeof shipments_data.current_page !== "undefined") {
					pagination.current_page = shipments_data.current_page;
					pagination.old_page = shipments_data.current_page;
				}

				if (typeof shipments_data.per_page !== "undefined") {
					pagination.per_page = shipments_data.per_page;
				}
			}

			this.setShipmentPagination(pagination);
		},
		getTotalCompletedShipments(shipments_data) {
			let pagination = {
				currentTab: 1,
				currentSubTab: 0,
				current_page: 1,
				old_page: 1,
				total: 0,
				per_page: 0,
			};

			if (shipments_data !== null) {
				if (typeof shipments_data.last_page !== "undefined") {
					pagination.total = shipments_data.last_page;
				}

				if (typeof shipments_data.current_page !== "undefined") {
					pagination.current_page = shipments_data.current_page;
					pagination.old_page = shipments_data.current_page;
				}

				if (typeof shipments_data.per_page !== "undefined") {
					pagination.per_page = shipments_data.per_page;
				}
			}

			this.setCompletedPagination(pagination);
		},
		getTotalSearchedShipments(shipments_data) {
			let pagination = {
				currentTab: 1,
				currentSubTab: 0,
				current_page: 1,
				old_page: 1,
				total: 0,
				per_page: 0,
			};

			if (shipments_data !== null) {
				if (typeof shipments_data.last_page !== "undefined") {
					pagination.total = shipments_data.last_page;
				}

				if (typeof shipments_data.current_page !== "undefined") {
					pagination.current_page = shipments_data.current_page;
					pagination.old_page = shipments_data.current_page;
				}

				if (typeof shipments_data.per_page !== "undefined") {
					pagination.per_page = shipments_data.per_page;
				}
			}
			this.setSearchedPagination(pagination);
		},
		async handleSearchPending(tab) {
			if (cancel !== undefined) {
				cancel();
			}
			this.setSearchedShipmentsLoading(false);
			clearTimeout(this.typingTimeout);
			this.typingTimeout = setTimeout(() => {
				this.search = this.searchPendingData;
				let data = { search: this.search, tab,
				};
				this.setSearchedShipmentsLoading(true);
				this.apiCall(data);
			}, 800);
		},
		async handleSearch(tab) {
			if (cancel !== undefined) {
				cancel();
			}
			this.setSearchedShipmentsLoading(false);
			clearTimeout(this.typingTimeout);
			this.typingTimeout = setTimeout(() => {
				let data = { search: this.search, tab, };
				this.searchPendingData = this.search;
				this.setSearchedShipmentsLoading(true);
				this.apiCall(data);
			}, 800);
		},
		apiCall(data) {
			if (data !== null && this.search !== "") {
				console.log(this.itemsPerPage)

				let per_page = 20;
				if (this.itemsPerPage !== null && this.itemsPerPage.per_page !== undefined) {
					per_page = this.itemsPerPage.per_page;
				}
				let passedData = {
					method: "get",
					url: "/v2/shipment-tab",
					cancelToken: new CancelToken(function executor(c) {
						cancel = c;
					}),
					params: {
						shipment_tab: data.tab,
						page: data.page,
						per_page,
						compact_view: false,
						search: this.search,
						tab: data.tab,
					},
				};

				if (data.tab === "shipments") {
					passedData.params.sort_column = "eta";
					passedData.params.order_by = this.$store.state.shipment.shipmentOrder.order;

					if (this.shipmentCurrentTab !== 0) {
						passedData.params.shipments_sub_tab = shipmentSubtabs[this.shipmentCurrentTab].subTabValue;
						passedData.params.tab = `shipments${this.shipmentCurrentTab + 1}`;
					}
				} else if (data.tab === "completed") {
					passedData.params.sort_column = "eta";
					passedData.params.order_by = this.$store.state.shipment.completedOrder.order;
				}

				this.fetchShipmentsSearched(passedData)
					.then(() => {
						if (typeof this.getSearchedShipments !== "undefined" &&
							this.getSearchedShipments !== null) {
							this.getTotalSearchedShipments(this.getSearchedShipments);
						}
					})
					.catch((e) => {
						this.setSearchedShipmentsLoading(false);
						console.log(e, "Search error");
					});
			} else { this.setShipmentSearchedVal([]); }
		},
		getShipmentSubtabName(tab) {
			let storePagination = this.$store.state.shipment.shipmentsPagination;
			let storePaginationSubtab = this.$store.state.shipmentSubTabs;
			let storeShipmentTabData = this.$store.state.shipment;
			let payload = { page: 1, order: "asc" };

			if (tab === 0) {
				payload.page = storePagination.shipmentTab.currentTab === 1
					? storePagination.shipmentTab.current_page : 1;
				payload.order = storeShipmentTabData.shipmentOrder.order;
			} else {
				let tabName = tab === 1 ? "awaitingDeparture" 
							: tab === 2 ? "inTransit" 
							: tab === 3 ? "arrived" 
							: tab === 4 ? "pickedUp"
							: "requiresAttention";

				payload.page = storePagination.shipmentTab.currentTab === 1 ?
					storePaginationSubtab.shipmentsPaginationLists[tabName].current_page : 1;
				payload.order = storeShipmentTabData.shipmentOrder.order;
			}
			return payload;
		},
		async callShipmentsAPI() {
			let storePagination = this.$store.state.shipment.shipmentsPagination;
			let storeShipmentTabData = this.$store.state.shipment;
			let payloadShipments = this.getShipmentSubtabName(this.shipmentCurrentTab);

			let payloadDraft = storePagination.draftTab.currentTab === 0 && storePagination.draftTab.currentSubTab === 2
						? storePagination.draftTab.current_page : 1;
			let payloadPendingQuote = storePagination.pendingQuoteTab.currentTab === 0 && storePagination.pendingTab.currentSubTab === 0
						? storePagination.pendingQuoteTab.current_page : 1;
			let payloadPending = storePagination.pendingTab.currentTab === 0 && storePagination.pendingTab.currentSubTab === 1
						? storePagination.pendingTab.current_page : 1;
			let payloadConsolidated = storePagination.consolidationTab.currentTab === 0 &&
						storePagination.consolidationTab.currentSubTab === 0
						? storePagination.consolidationTab.current_page : 1;

			let payloadSnoozed = storePagination.snoozeTab.currentTab === 0 && storePagination.snoozeTab.currentSubTab === 2
						? storePagination.snoozeTab.current_page : 1;
			let payloadExpired = storePagination.expiredTab.currentTab === 0 && storePagination.expiredTab.currentSubTab === 3
						? storePagination.expiredTab.current_page : 1;

			let payloadCompleted = {
				page: storePagination.completedTab.currentTab === 2 ? storePagination.completedTab.current_page : 1,
				order: storeShipmentTabData.completedOrder.order,
			};

			if (this.shipments.length === 0 &&
				this.snooze.length === 0 &&
				this.pendingQuote.length === 0 &&
				this.pending.length === 0 &&
				this.expired.length === 0 &&
				this.completed.length === 0 &&
				this.draft.length === 0 ) {
				
				await this.fetchShipments(payloadShipments).then(async () => {
					// call other tabs if shipments is not empty
					if (this.shipments.length !== 0) {
						await this.fetchAwaitingShipments(payloadShipments);
						this.fetchInTransitShipments(payloadShipments);
						this.fetchArrivedShipments(payloadShipments);
						this.fetchPickedUpShipments(payloadShipments);
						this.fetchRequiresAttentionShipments(payloadShipments);
					}
				});
				
				await this.fetchPendingQuoteShipments(payloadPendingQuote).then(() => { 
					this.currentTabPendingQuote(); 					

					this.fetchDraftShipments(payloadDraft).then(() => {
						this.currentTabDraft();
					});

					this.fetchPendingShipments(payloadPending).then(() => {
						this.currentTabPending();
					});

					this.fetchConsolidationRequests(payloadConsolidated).then(() => {
						this.currentTabConsolidationRequests();
					});

					this.fetchSnoozeShipments(payloadSnoozed).then(() => {
						this.currentTabSnooze();
					});

					this.fetchExpiredShipments(payloadExpired).then(() => {
						this.currentTabExpired();
					});
				});

				await this.fetchCompletedShipments(payloadCompleted).then(() => {
					this.currentTabCompleted();
				});
			}
		},
		showArchivedShipment() {
			this.ArchivedShipmentFlag = true;
		},
		hideArchivedShipment() {
			this.ArchivedShipmentFlag = false;
		},
		handleCloseCreateSingleShipmentDialog() {
			this.createSingleShipmentDialogView = false;
		},
		handleCloseSingleShipmentSuccessDialog() {
			this.createSingleShipmentSuccessDialogView = false;
		},
		handleCloseAddMoreShipmentInfoDialog() {
			this.showAddMoreShipmentInfoDialog = false;
		},
		async fetchCreatedShipmentInfo() {
			await this.$store.dispatch(
				"booking/fetchShipmentDetails", this.createSingleShipmentId
			);
		},
		getShipmentDetails() {
			return this.$store.getters["booking/getShipmentDetails"];
		},
		getShipmentDetailsLoading() {
			return this.$store.getters["booking/getShipmentDetailsLoading"];
		},
		createSingleShipmentPayload(payload) {
			payload["is_edit_dialog"] = true;
			this.createSingleShipmentPayloadData = payload;
		},
		editMblNumber() {
			this.createSingleShipmentPayloadData.existing_mbl_number = false;
			this.createSingleShipmentPayloadData.existing_lcl_shipment = false;
			this.createSingleShipmentPayloadData.existing_fcl_shipment = false;
			this.createSingleShipmentPayloadData.invalid_mbl_number = false;
			this.createSingleShipmentDialogView = true;
		},
		addShipmentAnyway() {
			if (this.createSingleShipmentPayloadData.mbl_num !== "") {
				this.createShipment(this.createSingleShipmentPayloadData)
					.then((response) => {
						if (response.data) {
							if (
								this.createSingleShipmentPayloadData.invalid_mbl_number === true
							) {
								this.createdShipmentType = "withInValidMBL";
								this.createSingleShipmentDialogView = false;
								this.createSingleShipmentSuccessDialogView = true;
							} else {
								this.createdShipmentType = "withValidMBL";
								this.createSingleShipmentDialogView = false;
								this.createSingleShipmentSuccessDialogView = true;
							}
							this.createSingleShipmentPayloadData.invalid_mbl_number = false;
							this.createSingleShipmentPayloadData.existing_mbl_number = false;
							this.createSingleShipmentPayloadData.existing_fcl_shipment = false;
							this.createSingleShipmentPayloadData.existing_lcl_shipment = false;
							this.createSingleShipmentId = response.data.shipment_id;
							this.createSingleShipmentPayload.mbl_number = "";
							this.createSingleShipmentPayload.booking_number = "";
							this.createSingleShipmentPayload.customer_reference_number = "";
						} else {
							this.notificationError("SOMETHING WENT WRONG!");
						}
					})
					.catch((e) => {
						console.log(e);
						this.notificationError("SOMETHING WENT WRONG!");
					});
			}
		},
		passShipmentDetailsToEditComp(payload) {
			this.getShipmentDetailsToEditComp = payload;

			if (this.getShipmentDetailsToEditComp.mbl_num) {
				let getSelectedLocationFrom = this.getTerminalRegions.find(
					(getTerminalRegions) => {
						return (
							getTerminalRegions.name ==
							this.getShipmentDetailsToEditComp.location_from_name
						);
					}
				);
				let getSelectedLocationTo = this.getTerminalRegions.find(
					(getTerminalRegions) => {
						return (
							getTerminalRegions.name ==
							this.getShipmentDetailsToEditComp.location_to_name
						);
					}
				);
				this.shipmentEditItem.location_from = getSelectedLocationFrom?.id
					? getSelectedLocationFrom.id
					: "";
				this.shipmentEditItem.location_to = getSelectedLocationTo?.id
					? getSelectedLocationTo.id
					: "";
				this.shipmentEditItem.eta = moment(
					this.getShipmentDetailsToEditComp.eta
				).format("YYYY-MM-DD");
				this.shipmentEditItem.etd = moment(
					this.getShipmentDetailsToEditComp.etd
				).format("YYYY-MM-DD");
				this.shipmentEditItem.vessel = this.getShipmentDetailsToEditComp.vessel;
				this.shipmentEditItem.voyage_number = this.getShipmentDetailsToEditComp.voyage_number;
				this.shipmentEditItem.carrier_id = this.getShipmentDetailsToEditComp.carrier;

				let isLocationFromExist = this.shipmentEditItem.location_from
					? true
					: false;
				let isLocationToExist = this.shipmentEditItem.location_to
					? true
					: false;
				let isEtaExist = this.shipmentEditItem.eta ? true : false;
				let isEtdExist = this.shipmentEditItem.etd ? true : false;
				let isVesselExist = this.shipmentEditItem.vessel ? true : false;
				let isVoyageNumberExist = this.shipmentEditItem.voyage_number
					? true
					: false;
				let isCarrierExist = this.shipmentEditItem.carrier_id ? true : false;

				this.isShipmentDetailExist = {
					isLocationFromExist: isLocationFromExist,
					isLocationToExist: isLocationToExist,
					isEtaExist: isEtaExist,
					isEtdExist: isEtdExist,
					isVesselExist: isVesselExist,
					isVoyageNumberExist: isVoyageNumberExist,
					isCarrierExist: isCarrierExist,
				};
			}
		},
		// calendar views actions starts
		setSelectedShipmentView(i) {
			this.currentViewTab = i;
			this.currentViewTabCopy = i;
			this.isFilterEnabled = false;
			localStorage.setItem("selected_shipment_view", i);
		},
		setSelectedCalendarType(i) {
			this.currentCalendarType = i;
			localStorage.setItem("selected_calendar_type", i);
		},
		getImgUrl(image_name, index) {
			if (image_name !== "undefined" && image_name !== null) {
				let name =
					index === this.currentViewTab ? image_name + "-blue" : image_name;
				return require(`../assets/icons/shipment-view-icons/${name}.svg`);
			}
		},
		activateFilter() {
			this.isFilterEnabled = !this.isFilterEnabled;
		},
		formatDateMonth(v) {
			return moment(v).format("MMMM");
		},
		formatComputedDateValue(date) {
			if (!date) return null
			else {
				return this.currentCalendarType === 1
					? moment(date).format("MMMM YYYY") : this.calculateSelectedDates(date);
			}
		},
		formatWeeklySelectedDate(v, isFor) {
			let date = moment(this.currentDateCalendar).format("YYYY-MM-DD")
			const [year, month, day] = date.split("-");
			let newDate = "";

			if (isFor === "month") {
				let newMonth = v.num_value;
				newDate = `${year}-${newMonth}-${day}`;
			} else {
				let newYear = v.value;
				newDate = `${newYear}-${month}-${day}`;
			}
			this.currentDateCalendar = moment(newDate).format("YYYY-MM-DD");
		},
		calculateSelectedDates(date) {
			var currentDate = moment(date);
			var weekStart = currentDate.clone().startOf("week");
			// var weekEnd = currentDate.clone().endOf('week');

			var days = [];
			for (var i = 0; i <= 6; i++) {
				days.push(moment(weekStart).add(i, "days").format("YYYY-MM-DD"));
			}
			let getFirstDay = moment(days[0]).format("DD MMM"),
				getLastDay = moment(days[6]).format("DD MMM, YYYY");

			let value = `${getFirstDay} - ${getLastDay}`;
			return value;
		},
		getWeekDate(isFor) {
			this.menu2 = false;
			let timeTracker = moment(this.currentDateCalendar);
			if (isFor === "next") {
				this.currentDateCalendar = timeTracker.add(1, "weeks").format("YYYY-MM-DD");
			} else {
				this.currentDateCalendar = timeTracker.add(-1, "weeks").format("YYYY-MM-DD");
			}
			this.calendarDateFinal = this.currentDateCalendar;
		},
		getWeekdayLabel(date) {
			let i = new Date(date).getDay(date);
      		return this.daysOfWeek[i]
		},
		showBookingTemplates() {
			this.viewBookingTemplates = true;
		},
		selectedColor(type) {
			localStorage.setItem("selected_shipment_calendar_option", type);
			this.selectedCalendarOption = type;
			this.isCalendarFilterSelectionEmpty = false;
		},
		checkTypeBgColor(type) {
			let { defaultBgColor, etdColored, etaColored, lfdColored, apptColored, returnLfdColored } = this.calendarBgColors;
			let assignedColors = {
                etd: this.selectedCalendarOption === "color" ? etdColored : defaultBgColor,
                eta: this.selectedCalendarOption === "color" ? etaColored : defaultBgColor,
                pickup_lfd: this.selectedCalendarOption === "color" ? lfdColored : defaultBgColor,
				appointment: this.selectedCalendarOption === "color" ? apptColored : defaultBgColor,
				return_lfd: this.selectedCalendarOption === "color" ? returnLfdColored : defaultBgColor,
            }
			if (type !== null) { return assignedColors[type]; }
		},
		setSelectedItems() {
			this.calendarFilterItems.map(v => { v.class = "" });
			this.isColorIndicationSelected = false;
			this.isCalendarFilterSelectionEmpty = false;
			this.$nextTick(() => {
				if (this.selectedDefaultFilter.length === this.calendarFilterItems.length) {
					this.selectedDefaultFilter = [];
					this.isAllSelected = false;
					this.calendarFilterItems.map(v => { v.isSelected = false });
				} else {
					this.selectedDefaultFilter = this.calendarFilterItems.slice();
					if (this.selectedDefaultFilter.length === this.calendarFilterItems.length) {
						this.isAllSelected = true;
						this.calendarFilterItems.map(v => { v.isSelected = true });
					} else this.isAllSelected = false;
				}
			})
		},
		setSelectFilter(selectedFilter) {
			// OLD CODE
			// this.isCalendarFilterSelectionEmpty = false;
			// this.isColorIndicationSelected = false;
			// this.calendarFilterItems.map(v => {
			// 	v.class = "";
			// 	if (selectedFilter.value === v.value) {
			// 		v.isSelected = !v.isSelected;
			// 	}
			// })

			this.isCalendarFilterSelectionEmpty = false;
			setTimeout(() => {
				this.calendarFilterItems.map(v => {
					v.class = "";
					if (!this.isColorIndicationSelected) {
						if (selectedFilter.value === v.value) {
							v.isSelected = !v.isSelected;
						}
					} else {
						if (v.value !== selectedFilter.value) {
							let findItemFromSelected = _.find(this.selectedDefaultFilter, e => (
								e.value === v.value
							));
							
							if (findItemFromSelected === undefined) {
								if (v.isSelected) {
									v.isSelected = false;
								}
							}
						} else {
							if (!v.isSelected) {
								v.isSelected = true;
							}
							this.selectedDefaultFilter.map(sv => { sv.class = ""; })
						}
					}
					setTimeout(() => {						
						this.isColorIndicationSelected = false;
					}, 100);
				})
			}, 100);
		},
		blurInputSelect() {
			if (this.selectedDefaultFilter.length === 0) this.isCalendarFilterSelectionEmpty = true;
			else this.isCalendarFilterSelectionEmpty = false;
		},
		getCalendarClosestTr(val) {
			if (val) {
				if (this.currentCalendarType === 0) {
					// add class to tr's based on their text content
					const trWrapper = document.querySelectorAll('.v-date-picker-table table tbody tr');
					trWrapper.forEach(item => { item.className = item.textContent; })

					// add class to td's that has buttons for background style
					const tdWrapper = document.querySelectorAll('.v-date-picker-table table tbody tr td:has(button)');
					tdWrapper.forEach(item => { item.className = "td-has-button"; })

					const trButtonActive = document.querySelector('.v-date-picker-table table tbody tr td button.v-btn--active');
					let row = trButtonActive.closest("tr");
					row.classList.add('tr-selected');
				}		
			}
		},
		datePickerClickedDate(date) {
			this.calendarDateFinal = date;
		},
		datePickerClickedMonth(date) {
			if (this.currentCalendarType === 1) this.calendarDateFinal = date;
		},
		onChangeSelectDate() {
			const trButtonActive = document.querySelectorAll('.v-date-picker-table table tbody tr.tr-selected');
			if (trButtonActive !== null)
				trButtonActive.forEach(item => { item.classList.remove('tr-selected'); })			
		},
		handler() {
			localStorage.removeItem("selected_shipment_calendar_option");
			localStorage.removeItem("selected_shipment_view");
			localStorage.removeItem("selected_calendar_type");
			localStorage.removeItem("shipment_calendar_current_date");
		},
		toggleShipmentSearch() {
			this.isShipmentSearchExpanded = true;
			setTimeout(() => {
				document.getElementById("search-input-shipments1").focus();
			}, 100);
		},
		blurInputSearch() {
			if (this.isShipmentSearchExpanded) {
				if (this.search === "") {
					this.setSearchDefault();
					this.isShipmentSearchExpanded = false;
				}
			}
		},
		checkWindowSize() {
			if (this.isMobile) return true;
			else {
				if (this.currentViewTab !== 0) return true;
				else return false;
			}
		},
		setSelectedColorIndication(selectedFilter) {
			let filteredItems = _.filter(this.calendarFilterItems, e => (e.isSelected));
			if (this.selectedColorIndicationItem !== selectedFilter) {
				this.isColorIndicationSelected = true;
				// this.selectedDefaultFilter = [this.calendarFilterItems[index]];
				this.selectedDefaultFilter = [selectedFilter];
				this.isAllSelected = false;
				this.selectedColorIndicationItem = selectedFilter;
				this.calendarFilterItems.map(v => { 
					if (selectedFilter.value === v.value)
						v.class = "active";
					else
						v.class = "inactive";					
				})
			} else {
				this.isColorIndicationSelected = !this.isColorIndicationSelected;
				this.selectedDefaultFilter = this.isColorIndicationSelected
					? [selectedFilter]
					: filteredItems;
				this.isAllSelected = this.isColorIndicationSelected ? false : true;
				if (this.isColorIndicationSelected) {
					this.calendarFilterItems.map(v => { 
						if (selectedFilter.value === v.value)
							v.class = "active";
						else
							v.class = "inactive";						
					})
				} else {
					this.calendarFilterItems.map(v => { v.class = "" });
				}
			}			
		},
	},
	async updated() {
		this.getCalendarClosestTr(this.menu2);
	},
	beforeDestroy() {
		window.removeEventListener('beforeunload', this.handler);
	}
};
</script>
<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap");
@import "../assets/scss/buttons.scss";
@import "../assets/scss/pages_scss/dialog/globalDialog.scss";
@import "../assets/scss/pages_scss/shipment/shipment.scss";
@import "../assets/scss/pages_scss/shipment/shipmentSearch.scss";
@import "../assets/scss/pages_scss/shipment/shipmentHeaderButtons.scss";
@import "../assets/scss/utilities.scss";
@import "../assets/scss/vc_calendar.scss";
@import "../components/Tables/Shipments/Tabs/scss/statuses.scss";
</style>
<template>
	<div class="company-profile-content" style="border-bottom: none;">
		<v-row>
			<v-col cols="12" sm="12">
				<div class="company-profile-title-container mb-1">
					<h3 class="company-profile-title">Company Information</h3>
					<button class="btn-white float-right" @click="openEditCompanyInfoDialog" 
						:style="isMobile ? 'padding: 12px !important' : ''"
						:disabled="getLoadingUserDetails">
						<span v-if="!isMobile">Edit Profile</span>
						<img src="@/assets/icons/edit-delivery-location.svg" alt="" width="16px" height="16px" v-else />
					</button>
				</div>
			</v-col>
		</v-row>

		<v-row v-if="getLoadingUserDetails" justify="center" class="mb-5">
			<div class="preloader">
				<v-progress-circular :size="40" color="#1A6D9E" indeterminate>
				</v-progress-circular>
			</div>
		</v-row>

		<div class="company-desktop-content-wrapper mt-4" v-if="!getLoadingUserDetails">
			
			<div class="company-profile-col first px-0">
				<div style="border: 1px solid #d8e7f0; border-radius: 4px; height: 85px; width: 85px; "
					class="d-flex align-center justify-center mr-3">
					<img src="../../../assets/icons/import-name-logo.svg"  alt="image-log" />
				</div>

				<div style="width: calc(100% - 100px);" v-if="isMobile">
					<p class="company-profile-value font-medium mb-1 mt-1">
						{{ companyDetail.company_name ? companyDetail.company_name : "N/A" }}
					</p>

					<div class="d-flex align-center justify-start mt-0">
						<div class="company-profile-label mr-2">
							Key
						</div>
						<div class="d-flex align-center justify-start">
							<p class="company-profile-value d-flex align-center justify-start" 
								v-if="companyDetail.company_key">
								<input type="hidden" id="company-key" :value=" companyDetail.company_key " />
								{{ companyDetail.company_key }}

								<span id="dddddd">
									<v-tooltip attach="#dddddd" content-class="right-arrow-new">
										<template v-slot:activator="{ on,attrs }">
											<span style="vertical-align: middle" class="pl-6 pointer d-flex align-center justify-start"
												@click.stop.prevent="copyTestingCode">
												<img width="18px" v-bind="attrs" v-on="on"  src="../../../assets/icons/copy-to-clipboard-new.svg" />
											</span>
										</template>
										<span>
											{{ 'Copy company key to clipboard' }}
										</span >
									</v-tooltip>
								</span>
							</p>
							<p class="company-profile-value" v-if="!companyDetail.company_key">
								{{ companyDetail.company_key ? companyDetail.company_key : "N/A" }}
							</p>
						</div>
					</div>
					
					<div class="d-flex justify-space-between">
						<button class="company-mobile-title-view font-medium" @click="open = !open"> 
							{{ open ? "Hide" : "View" }} Other Information
							<v-icon size="18" color="#1A6D9E">
								{{ open ? "mdi-chevron-up" : "mdi-chevron-down"}}
							</v-icon>
						</button>
					</div>
				</div>
			</div>

			<v-row class="mt-0">
				<v-col cols="12" sm="6" class="company-profile-col pl-5 mb-1" v-if="!isMobile">
					<v-row>
						<v-col cols="12" sm="4" class="company-profile-label pb-2">
							Company Name
						</v-col>
						<v-col cols="12" sm="8" class="pb-2">
							<p class="company-profile-value">
								{{ companyDetail.company_name ? companyDetail.company_name : "N/A" }}
							</p>
						</v-col>
					</v-row>

					<v-row class="mt-0">
						<v-col cols="12" sm="4" class="company-profile-label pb-2">
							Company Key
						</v-col>
						<v-col cols="12" sm="8" class="pb-2">
							<p class="company-profile-value" v-if=" companyDetail.company_key ">
								<input type="hidden" id="company-key" :value=" companyDetail.company_key " />
								{{ companyDetail.company_key }}
								<span id="dddddd">
								<v-tooltip
									attach="#dddddd"
									content-class=" right-arrow-new"
								>
									<template v-slot:activator="{ on,attrs }">
										<span  style="vertical-align: middle" class="pl-10 pointer"
											@click.stop.prevent=" copyTestingCode "
										>
											<img width="17px" v-bind="attrs" v-on="on"  src="../../../assets/icons/copy-to-clipboard-new.svg" />
										</span>
									</template>
									<span>
										{{ 'Copy company key to clipboard' }}
									</span >
							</v-tooltip>
						</span>
							</p>
							<p class="company-profile-value" v-if=" !companyDetail.company_key ">
								{{ companyDetail.company_key ? companyDetail.company_key : "N/A" }}
							</p>
						</v-col>
					</v-row>
					
					<v-row class="mt-0">
						<v-col cols="12" sm="4" class="company-profile-label pb-2">
							Email Address
						</v-col>
						<v-col cols="12" sm="8" class="pb-2">
							<div v-for="( item, index ) in  companyDetail.emails " :key=" index ">
								<p class="company-profile-value" v-if=" item.email ">
									{{ item.email }}
								</p>
							</div>
							<p class="company-profile-value" v-if=" !companyDetail.emails || companyDetail.emails.length == 0 ">
								N/A
							</p>
						</v-col>
					</v-row>
				</v-col>

				<v-col cols="12" sm="6" class="company-profile-col mb-1" v-if="!isMobile">
					<v-row>
						<v-col cols="12" sm="4" class="company-profile-label pb-2">
							Phone Number
						</v-col>
						<v-col cols="12" sm="8" class="pb-2">
							<p class="company-profile-value">
								{{ companyDetail.phone
									? (companyDetail.phone.substr(0, 2) == "+1" ? "" : "+1 ") +
									companyDetail.phone : "N/A"
								}}
							</p>
						</v-col>
					</v-row>

					<v-row class="mt-0">
						<v-col cols="12" sm="4" class="company-profile-label pb-2">
							Address
						</v-col>
						<v-col cols="12" sm="8" class="pb-2">
							<p class="company-profile-value">
								{{ companyDetail.address ? companyDetail.address : ""
								}}{{ companyDetail.city ? `, ${ companyDetail.city }` : ""
								}}{{ companyDetail.state ? `, ${ companyDetail.state }` : ""
								}}{{ companyDetail.zipcode ? `, ${ companyDetail.zipcode }` : ""
								}}{{ companyDetail.country ? `, ${ companyDetail.country }` : "" }}
							</p>
						</v-col>
					</v-row>
				</v-col>

				<v-col cols="12" sm="6" class="company-profile-col company-mobile-expand-wrapper" v-if="isMobile && open">
					<div class="d-flex align-center justify-start mt-0 mb-2">
						<div class="company-profile-label" style="width: 35%;">
							Email Address
						</div>
						<div style="width: 65%;">
							<div v-for="( item, index ) in  companyDetail.emails " :key=" index ">
								<p class="company-profile-value" v-if=" item.email ">
									{{ item.email }}
								</p>
							</div>
							<p class="company-profile-value" v-if=" !companyDetail.emails || companyDetail.emails.length == 0 ">
								N/A
							</p>
						</div>
					</div>

					<div class="d-flex align-center justify-start mt-0 mb-2">
						<div class="company-profile-label" style="width: 35%;">
							Phone Number
						</div>
						<div style="width: 65%;">
							<p class="company-profile-value">
								{{ companyDetail.phone
									? (companyDetail.phone.substr(0, 2) == "+1" ? "" : "+1 ") +
									companyDetail.phone : "N/A"
								}}
							</p>
						</div>
					</div>

					<div class="d-flex align-start justify-start mt-0 mb-2">
						<div class="company-profile-label" style="width: 35%;">
							Address
						</div>
						<div style="width: 65%;">
							<p class="company-profile-value">
								{{ companyDetail.address ? companyDetail.address : ""
								}}{{ companyDetail.city ? `, ${ companyDetail.city }` : ""
								}}{{ companyDetail.state ? `, ${ companyDetail.state }` : ""
								}}{{ companyDetail.zipcode ? `, ${ companyDetail.zipcode }` : ""
								}}{{ companyDetail.country ? `, ${ companyDetail.country }` : "" }}
							</p>
						</div>
					</div>
				</v-col>
			</v-row>
		</div>

		<!-- <v-row class="mb-2">
			<v-col cols="12" sm="1" class="company-profile-col first" style="margin-right: 19px;">
				<div style="border: 1px solid #d8e7f0; border-radius: 4px; height: 85px; width: 85px; "
					class="d-flex align-center justify-center mr-3 dddddddd">
					<img src="../../../assets/icons/import-name-logo.svg"  alt="image-log" />
				</div>

				<div v-if="isMobile">
					<p class="company-profile-value font-medium mb-1 mt-1">
						{{ companyDetail.company_name ? companyDetail.company_name : "N/A" }}
					</p>

					<div class="d-flex align-center justify-start mt-0">
						<div class="company-profile-label mr-2">
							Key
						</div>
						<div class="d-flex align-center justify-start">
							<p class="company-profile-value d-flex align-center justify-start" 
								v-if="companyDetail.company_key">
								<input type="hidden" id="company-key" :value=" companyDetail.company_key " />
								{{ companyDetail.company_key }}

								<span id="dddddd">
									<v-tooltip attach="#dddddd" content-class="right-arrow-new">
										<template v-slot:activator="{ on,attrs }">
											<span style="vertical-align: middle" class="pl-6 pointer d-flex align-center justify-start"
												@click.stop.prevent="copyTestingCode">
												<img width="18px" v-bind="attrs" v-on="on"  src="../../../assets/icons/copy-to-clipboard-new.svg" />
											</span>
										</template>
										<span>
											{{ 'Copy company key to clipboard' }}
										</span >
									</v-tooltip>
								</span>
							</p>
							<p class="company-profile-value" v-if="!companyDetail.company_key">
								{{ companyDetail.company_key ? companyDetail.company_key : "N/A" }}
							</p>
						</div>
					</div>
					
					<div class="d-flex justify-space-between">
						<button class="company-mobile-title-view font-medium" @click="open = !open"> 
							{{ open ? "Hide" : "View" }} Other Information
							<v-icon size="18" color="#1A6D9E">
								{{ open ? "mdi-chevron-up" : "mdi-chevron-down"}}
							</v-icon>
						</button>
					</div>
				</div>
			</v-col>

			<v-col cols="12" sm="5" class="company-profile-col" v-if="!isMobile">
				<v-row>
					<v-col cols="12" sm="3" class="company-profile-label">
						Company Name
					</v-col>
					<v-col cols="12" sm="9">
						<p class="company-profile-value">
							{{ companyDetail.company_name ? companyDetail.company_name : "N/A" }}
						</p>
					</v-col>
				</v-row>

				<v-row class="mt-0">
					<v-col cols="12" sm="3" class="company-profile-label">
						Company Key
					</v-col>
					<v-col cols="12" sm="9">
						<p class="company-profile-value" v-if=" companyDetail.company_key ">
							<input type="hidden" id="company-key" :value=" companyDetail.company_key " />
							{{ companyDetail.company_key }}
							<span id="dddddd">
							<v-tooltip
								attach="#dddddd"
								content-class=" right-arrow-new"
                            >
								<template v-slot:activator="{ on,attrs }">
									<span  style="vertical-align: middle" class="pl-10 pointer"
										@click.stop.prevent=" copyTestingCode "
									>
										<img width="17px" v-bind="attrs" v-on="on"  src="../../../assets/icons/copy-to-clipboard-new.svg" />
									</span>
								</template>
								<span>
                                    {{ 'Copy company key to clipboard' }}
                                </span >
						</v-tooltip>
					</span>
						</p>
						<p class="company-profile-value" v-if=" !companyDetail.company_key ">
							{{ companyDetail.company_key ? companyDetail.company_key : "N/A" }}
						</p>
					</v-col>
				</v-row>
				
				<v-row class="mt-0">
					<v-col cols="12" sm="3" class="company-profile-label">
						Email Address
					</v-col>
					<v-col cols="12" sm="9">
						<div v-for="( item, index ) in  companyDetail.emails " :key=" index ">
							<p class="company-profile-value" v-if=" item.email ">
								{{ item.email }}
							</p>
						</div>
						<p class="company-profile-value" v-if=" !companyDetail.emails || companyDetail.emails.length == 0 ">
							N/A
						</p>
					</v-col>
				</v-row>
			</v-col>

			<v-col cols="12" sm="4" class="company-profile-col" v-if="!isMobile">
				<v-row>
					<v-col cols="12" sm="4" class="company-profile-label">
						Phone Number
					</v-col>
					<v-col cols="12" sm="8">
						<p class="company-profile-value">
							{{
							companyDetail.phone
							? (companyDetail.phone.substr(0, 2) == "+1" ? "" : "+1 ") +
							companyDetail.phone
							: "N/A"
							}}
						</p>
					</v-col>
				</v-row>

				<v-row class="mt-0">
					<v-col cols="12" sm="4" class="company-profile-label">
						Address
					</v-col>
					<v-col cols="12" sm="8">
						<p class="company-profile-value">
							{{ companyDetail.address ? companyDetail.address : ""
							}}{{ companyDetail.city ? `, ${ companyDetail.city } ` : ""
							}}{{ companyDetail.state ? `, ${ companyDetail.state } ` : ""
							}}{{ companyDetail.zipcode ? `, ${ companyDetail.zipcode } ` : ""
							}}{{ companyDetail.country ? `, ${ companyDetail.country } ` : "" }}
						</p>
					</v-col>
				</v-row>

			</v-col>

			<v-col cols="12" sm="6" class="company-profile-col company-mobile-expand-wrapper" v-if="isMobile && open">
				<div class="d-flex align-center justify-start mt-0 mb-2">
					<div class="company-profile-label" style="width: 35%;">
						Email Address
					</div>
					<div style="width: 65%;">
						<div v-for="( item, index ) in  companyDetail.emails " :key=" index ">
							<p class="company-profile-value" v-if=" item.email ">
								{{ item.email }}
							</p>
						</div>
						<p class="company-profile-value" v-if=" !companyDetail.emails || companyDetail.emails.length == 0 ">
							N/A
						</p>
					</div>
				</div>

				<div class="d-flex align-center justify-start mt-0 mb-2">
					<div class="company-profile-label" style="width: 35%;">
						Phone Number
					</div>
					<div style="width: 65%;">
						<p class="company-profile-value">
							{{ companyDetail.phone
								? (companyDetail.phone.substr(0, 2) == "+1" ? "" : "+1 ") +
								companyDetail.phone : "N/A"
							}}
						</p>
					</div>
				</div>

				<div class="d-flex align-start justify-start mt-0 mb-2">
					<div class="company-profile-label" style="width: 35%;">
						Address
					</div>
					<div style="width: 65%;">
						<p class="company-profile-value">
							{{ companyDetail.address ? companyDetail.address : ""
							}}{{ companyDetail.city ? `, ${ companyDetail.city }` : ""
							}}{{ companyDetail.state ? `, ${ companyDetail.state }` : ""
							}}{{ companyDetail.zipcode ? `, ${ companyDetail.zipcode }` : ""
							}}{{ companyDetail.country ? `, ${ companyDetail.country }` : "" }}
						</p>
					</div>
				</div>
			</v-col>
		</v-row> -->

		<!-- <div class="row">
			<v-divider class="mt-5"></v-divider>
		</div> -->

		<v-snackbar v-model="snackbarFlag" :timeout="snackbarTimeout">
			Company key copied to clipboard.
		</v-snackbar>

		<EditCompanyInfoDialog :editDialog.sync="editDialog" :editedItemData.sync="editedItem" @close="close" />
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import EditCompanyInfoDialog from "../Dialog/EditCompanyInfoDialog.vue";

export default {
	name: "CompanyInfo",
	props: ["companyDetail", "isMobile"],
	components: {
		EditCompanyInfoDialog
	},
	computed: {
		...mapGetters({
			getLoadingUserDetails: "getLoadingUserDetails",
		}),
	},
	data() {
		return {
			snackbarTimeout: 5000,
			snackbarFlag: false,
			editDialog: false,
			editedItem: Object.assign({}, this.companyDetail),
			open: false
		};
	},
	watch: {
		getLoadingUserDetails() {
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.companyDetail);
			});
		}
	},
	methods: {
		openEditCompanyInfoDialog() {
			this.editDialog = true;
			this.editedItem = Object.assign({}, this.companyDetail);
		},
		close() {
			this.editDialog = false;
			this.$nextTick(() => {
				this.editedItem = Object.assign({}, this.companyDetail);
			});
		},
		copyTestingCode() {
			let testingCodeToCopy = document.querySelector("#company-key");
			testingCodeToCopy.setAttribute("type", "value");
			testingCodeToCopy.select();
			if (document.execCommand("copy")) {
				this.snackbarFlag = true;
			}

			/* unselect the range */
			testingCodeToCopy.setAttribute("type", "hidden");
			window.getSelection().removeAllRanges();
		},
	},
};
</script>

<style lang="scss">
@import "../../../assets/scss/buttons.scss";
.company-profile-content .v-tooltip__content.menuable__content__active {
	position: absolute;
	margin-top: 81px;
	margin-left: 25px;
    background-color: #253041 !important;
    line-height: 20px;

    &.right-arrow-new {
        overflow: inherit !important;
        z-index: 20;
        opacity: 1 !important;

        &::before {
            transform: rotate(90deg);
            content: " ";
            position: absolute;
            bottom: -45%;
            left: 45%;
            margin-top: -10px;
            border-width: 1px;
            border-style: solid;
            border-top: solid 10px transparent;
            border-bottom: solid 10px transparent;
            border-left: solid 10px transparent;
            border-left-color: #253041 !important;
        }
		
    }
}
</style>

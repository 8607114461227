var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"po-details-wrapper-table history"},[(!_vm.isMobile)?_c('v-data-table',{staticClass:"desktop-podetails-product elevation-0",class:{
                'no-data-table': (_vm.poHistoryItems.length === 0)
            },attrs:{"headers":_vm.headers,"items":_vm.poHistoryItems,"hide-default-footer":"","mobile-breakpoint":"1023","items-per-page":500},scopedSlots:_vm._u([{key:"item.updated_at",fn:function(ref){
            var item = ref.item;
return [_c('span',[_vm._v(" "+_vm._s(item.updated_at !== null ? _vm.formatDate(item.updated_at) : '--')+" ")])]}},{key:"item.updated_by",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(item.updated_by !== null ? item.updated_by : '--')+" ")])]}},{key:"item.update_type",fn:function(ref){
            var item = ref.item;
return [_c('span',{staticClass:"text-capitalize"},[_vm._v(" "+_vm._s(item.update_type !== null ? _vm.replaceString(item.update_type) : '--')+" ")])]}},{key:"item.actions",fn:function(ref){
            var item = ref.item;
return [_c('div',{staticClass:"po-history-button-wrapper"},[_c('button',{staticClass:"btn-white po-view",on:{"click":function($event){return _vm.showHistoryDetail(item)}}},[_vm._v(" View Details ")])])]}},{key:"no-data",fn:function(){return [(_vm.getPoHistoryLoading)?_c('div',{staticClass:"loading-wrapper pt-5"},[_c('v-progress-circular',{attrs:{"size":40,"color":"#1A6D9E","indeterminate":""}})],1):_vm._e(),(!_vm.getPoHistoryLoading && _vm.poHistoryItems.length == 0)?_c('div',{staticClass:"no-data-wrapper"},[_c('div',{staticClass:"no-data-heading"},[_c('p',[_vm._v("No data available in history")])])]):_vm._e()]},proxy:true}],null,true)}):_vm._e(),_c('PoHistoryPreviewDialog',{attrs:{"dialogData":_vm.showHistoryDetailDialog,"from":_vm.from,"updatedType":_vm.updatedType},on:{"update:dialogData":function($event){_vm.showHistoryDetailDialog=$event},"update:dialog-data":function($event){_vm.showHistoryDetailDialog=$event},"close":_vm.closeHistoryDetail}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<!-- @format -->

<template>
	<v-container fluid class="pa-0">
		<div class="shipment-details-wrapper-v2">
			<div class="preloader" v-if="detailsLoading">
				<v-progress-circular :size="40" color="#0171a1" indeterminate>
				</v-progress-circular>
			</div>

			<v-row v-if="!detailsLoading">
				<v-col class="first-col" sm="12" md="8">
					<div class="section-1">
						<div class="details-breadcrumbs ">
							<div class="navigation-back">
								<router-link to="/shipments" class="shipment-link">
									Shipments
								</router-link>

								<img
									src="../../assets/images/right_chevron.svg"
									alt="right_chevron"
									width="5px"
								/>

								<span
									class="shipment-ref"
									v-if="
										getFilteredNewShipmentDetails.shifl_ref !== 'undefined' &&
											getFilteredNewShipmentDetails.shifl_ref !== null
									"
								>
									{{ getFilteredNewShipmentDetails.shifl_ref }}
								</span>
							</div>
						</div>

						<div id="top-header" v-resize="onResize">
							<div class="reference-status">
								<div class="shipment-status-label">
									<h2
										v-if="
											getFilteredNewShipmentDetails.shifl_ref !== 'undefined' &&
												getFilteredNewShipmentDetails.shifl_ref !== null
										"
									>
										Ref #{{ getFilteredNewShipmentDetails.shifl_ref }}
									</h2>
									<div
										:class="
											getShipmentStatusClass(
												getFilteredNewShipmentDetails,
												'tracking'
											)
										"
										v-if="
											getFilteredNewShipmentDetails.tracking_status &&
												getFilteredNewShipmentDetails.tracking_status !==
													'Auto Tracking' &&
												getFilteredNewShipmentDetails.tracking_status !==
													'Auto Tracked' &&
												getFilteredNewShipmentDetails.tracking_status !==
													'Manual Tracking' &&
												getFilteredNewShipmentDetails.tracking_status !==
													'Not Tracking'	

										"
										class="font-medium shipment-tracking-status"
									>
										{{ getFilteredNewShipmentDetails.tracking_status }}
									</div>

									<p
										v-if="!isMobile && shipment_status"
										class="mb-0 label-name"
										:class="
											getShipmentStatusClass(getFilteredNewShipmentDetails)
										"
									>
										{{ shipment_status }}
									</p>
								</div>

								<div class="shipment-actions">
									<!-- <v-btn
										v-if="getEditShipments(getFilteredNewShipmentDetails)"
										class="btn-blue edit-shipment-btn"
										:class="isMobile ? 'mobile-btn' : ''"
									>
										<KeneticIcon iconName="edit-square" color="#FFFFFF" />
										<span class="pl-2" v-if="!isMobile">Edit Booking</span>
									</v-btn> -->

									<div class="menu-dropdown">
										<v-menu
											offset-y
											bottom
											left
											content-class="pending-dropdown-container"
										>
											<template v-slot:activator="{ on, attrs }">
												<button
													v-bind="attrs"
													v-on="on"
													class="three-dots-container"
													:class="isMobile ? 'mobile-btn' : ''"
												>
													<custom-icon
														iconName="three-dots"
														color="#0171A1"
														width="11"
														height="3"
													/>
												</button>
											</template>
											<v-list>
												<v-list-item style="width: 200px;">
													<!-- <template
														v-if="
															getFilteredNewShipmentDetails.booking_confirmed ==
																1 &&
																getFilteredNewShipmentDetails.mark_as_completed ==
																	0
														"
													>
														<v-list-item-title
															class="k-fw-sm k-font-inter-regular k-red"
															style="cursor: pointer;"
															@click="cancelBooking(1)"
														>
															Cancel Request
														</v-list-item-title>
													</template>
													<template v-else>
														<v-list-item-title
															class="k-fw-sm k-font-inter-regular k-red"
															style="cursor: pointer;"
															@click="cancelBooking(0)"
														>
															Cancel Request
														</v-list-item-title>
													</template> -->
													<template>
														<v-list-item-title
															class="k-fw-sm k-font-inter-regular k-blue"
															style="cursor: pointer;"
															@click="openBookingInfoDialog = true"
														>
															Original Booking Details
														</v-list-item-title>
													</template>
												</v-list-item>
											</v-list>
										</v-menu>
									</div>
								</div>
							</div>
							<div v-if="isMobile" class="shipment-status-label">
								<p
									class="mb-0 label-name"
									:class="getShipmentStatusClass(getFilteredNewShipmentDetails)"
								>
									{{ shipment_status }}
								</p>
							</div>

							<div
								:class="
									`d-flex align-center shipment-details-label ${
										isMobile ? 'mobile' : ''
									}`
								"
							>
								<div
									class="place-content"
									v-if="
										getFilteredNewShipmentDetails.type !== '' &&
											getFilteredNewShipmentDetails.type !== null
									"
								>
									<p class="heading">
										<span class="info-title">Type: </span>

										<img
											:src="shipmentTypeImg(getFilteredNewShipmentDetails.type)"
										/>

										{{ getFilteredNewShipmentDetails.type }}
									</p>
								</div>

								<div class="updated-at-content">
									<div class="mr-2 updated-title">Updated At</div>
									<div>
										{{ formatDate(getFilteredNewShipmentDetails.updated_at) }}
									</div>
								</div>
								<div
									class="to-eta-content"
									v-if="
										getFilteredNewShipmentDetails.eta_date &&
											getFilteredNewShipmentDetails.location_to
									"
								>
									<span class="mb-0 info-value"
										>{{
											getFilteredNewShipmentDetails.location_to
												? getFilteredNewShipmentDetails.location_to
												: "N/A"
										}}<span v-if="bookingConfirmed"
											>, ETA
											{{
												getFilteredNewShipmentDetails.eta_date
													? getFilteredNewShipmentDetails.eta_date
													: "N/A"
											}}</span
										>
									</span>
								</div>
							</div>

							<div class="shipment-tags">
								<span class="info-title">Tags </span>
								<span
									class="consolidatation-status"
									v-if="getNewShipmentDetails.consolidation_status == 2"
									>Consolidated</span
								>

								<a @click="addShipmentTag()">
									Add Tag
								</a>
							</div>
						</div>
						<v-tabs
							mobile-breakpoint="500"
							:class="`customTab ${isMobile ? 'customTab-mobile' : ''}`"
							height="50px"
							v-model="currentTab"
						>
							<v-tab
								v-for="(n, i) in tabs"
								:key="n"
								v-on:click="setMainTab(i)"
								:class="!isMobile && n == 'Milestones' ? 'd-none' : ''"
							>
								{{ n }}
							</v-tab>
						</v-tabs>
					</div>

					<div class="shipment-info-details">
						<div v-if="currentTab == 'Shipment Info' || currentTab == 0">
							<ShipmentInfoV2
								:getDetails="getFilteredNewShipmentDetails"
								:isMobile="isMobile"
								:userDataDetails="userDataDetails"
							/>
						</div>
						<div
							v-if="currentTab == 'Products' || currentTab == 1"
							class="shipment-details-tabs"
						>
							<ShipmentProducts :id="shipment_id" />
						</div>
						<div
							v-if="currentTab == 'Documents' || currentTab == 2"
							class="shipment-details-tabs"
						>
							<ShipmentDocuments
								:shipmentsLoading.sync="shipmentDocumentsLoading"
								@handleDocumentsChange="handleDocumentsChange"
								:getDetails="getFilteredNewShipmentDetails"
								:id="shipment_id"
								:isCurrentSelectedCustomer="isCurrentSelectedCustomer"
							/>
						</div>
						<div
							v-if="currentTab == 'Bills' || currentTab == 4"
							class="shipment-details-tabs"
						>
							<ShipmentBills :isMobile="isMobile" />
						</div>
						<div
							v-if="currentTab == 'Notes' || currentTab == 5"
							class="shipment-details-tabs"
						>
							<ShipmentNotes :id="shipment_id" />
						</div>
						<div
							v-if="isMobile && (currentTab == 'Milestones' || currentTab == 3)"
							class="shipment-details-tabs"
						>
							<Map
								v-show="this.getFilteredNewShipmentDetails.ais_link !== ''"
								:getDetails="getFilteredNewShipmentDetails"
							/>

							<MilestonesV2
								:loading="getShipmentTrackingDetailsLoading"
								:getDetails="getFilteredNewShipmentDetails"
								:status="shipment_status"
							/>
						</div>
					</div>
				</v-col>

				<v-col class="second-col" sm="12" md="4" v-if="!isMobile">
					<Map
						v-show="this.getFilteredNewShipmentDetails.ais_link"
						:getDetails="getFilteredNewShipmentDetails"
					/>

					<v-card
						class="pa-5"
						outlined
						tile
						:class="
							this.getFilteredNewShipmentDetails.ais_link !== ''
								? 'has-ais'
								: ''
						"
					>
						<div class="milestone-v2-content-wrapper">
							<MilestonesV2
								:getDetails="getFilteredNewShipmentDetails"
								:status="shipment_status"
							/>
						</div>
					</v-card>
				</v-col>
			</v-row>
		</div>

		<!-- <BookingShipmentDialogV2 
			:show.sync="viewBookingShipmentDialog"
			:reference="'edit-booking-shipment'"
			:shipmentData="shipmentData"
			@close="viewBookingShipmentDialog = false"
		/> -->

		<BookingInfoDialog
			:dialogData.sync="openBookingInfoDialog"
			@close="openBookingInfoDialog = false"
			:getDetails="getFilteredNewShipmentDetails"
			:isMobile="isMobile"
		/>

		<!-- Cancel Booking Request Dialog-->
		<ConfirmDialog :dialogData.sync="cancelBookingRequestDialog">
			<template v-slot:dialog_icon>
				<div class="header-wrapper-close">
					<img src="@/assets/icons/icon-delete.svg" alt="alert" />
				</div>
			</template>

			<template v-slot:dialog_title>
				<h2>Cancel Booking Request</h2>
			</template>

			<template v-slot:dialog_content>
				<p>
					Are you sure you want to cancel the booking request?
				</p>
			</template>

			<template v-slot:dialog_actions>
				<v-btn
					class="btn-blue"
					text
					@click="submitCancelBooking()"
					:disabled="getCancelShipmentLoading"
				>
					<span v-if="getCancelShipmentLoading"
						>Cancel Request Processing...</span
					>
					<span v-else>Cancel Request</span>
				</v-btn>
				<v-btn
					class="btn-white"
					text
					@click="cancelBookingRequestDialog = false"
				>
					<span>Close</span>
				</v-btn>
			</template>
		</ConfirmDialog>

		<!-- Request Cancellation for Shipment Dialog-->
		<ConfirmDialog :dialogData.sync="openCancelRequestForm">
			<template v-slot:dialog_icon>
				<div class="header-wrapper-close">
					<img src="@/assets/icons/icon-delete.svg" alt="alert" />
				</div>
			</template>

			<template v-slot:dialog_title>
				<h2>Request Cancellation</h2>
			</template>

			<template v-slot:dialog_content>
				<p>
					Do you want to request cancellation for this Shipment?
				</p>
				<div class="form-label mt-3">
					<span class="cancel-reason-label">REASON FOR CANCELLATION</span>
				</div>
				<v-textarea
					:rows="4"
					outlined
					placeholder="Type reason for cancellation"
					hide-details="auto"
					class="text-fields"
					required
					v-model="cancel_reason"
				>
				</v-textarea>
			</template>

			<template v-slot:dialog_actions>
				<v-btn
					class="btn-blue"
					text
					@click="submitCancelBooking()"
					:disabled="getCancelShipmentLoading"
				>
					<span v-if="getCancelShipmentLoading"
						>Cancel Request Processing...</span
					>
					<span v-else>Cancel Request</span>
				</v-btn>
				<v-btn
					class="btn-white"
					text
					@click="openCancelRequestForm = false"
					:disabled="getCancelShipmentLoading"
				>
					Close
				</v-btn>
			</template>
		</ConfirmDialog>

		<ConfirmDialog :dialogData.sync="openSwitchAccountDialog">
			<template v-slot:dialog_icon>
				<div class="header-wrapper-close">
					<img src="@/assets/icons/alert.svg" alt="alert" />
				</div>
			</template>

			<template v-slot:dialog_title>
				<h2>Switch Account</h2>
			</template>

			<template v-slot:dialog_content>
				<div class="switch-account-modal-wrapper">
					<p>
						This document request is for a different user account. You need to
						switch to the relevant account below.
					</p>

					<div
						class="switch-account-modal-details d-flex justify-start align-center"
					>
						<div class="switch-company-logo">
							<img
								src="@/assets/icons/import-name-logo.svg"
								alt=""
								width="20px"
								height="20px"
							/>
						</div>
						<div class="switch-company-details">
							<div
								class="switch-company-content"
								v-if="accountVendorDetails !== null"
							>
								<div class="switch-company-name">
									{{ accountVendorDetails.company_name || "" }}
								</div>
								<div class="switch-company-address">
									{{ accountVendorDetails.address || "" }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</template>

			<template v-slot:dialog_actions>
				<v-btn
					class="btn-blue"
					@click="confirmSwitch"
					:disabled="isSwitchingAccountLoading"
				>
					{{ isSwitchingAccountLoading ? "Switching..." : "Switch Account" }}
				</v-btn>

				<v-btn
					class="btn-white"
					@click="cancelSwitch"
					:disabled="isSwitchingAccountLoading"
				>
					Cancel
				</v-btn>
			</template>
		</ConfirmDialog>

		<ConfirmDialog :dialogData.sync="openLogoutAccountDialog">
			<template v-slot:dialog_icon>
				<div class="header-wrapper-close">
					<img src="@/assets/icons/alert.svg" alt="alert" />
				</div>
			</template>

			<template v-slot:dialog_title>
				<h2>Request is associated with different account</h2>
			</template>

			<template v-slot:dialog_content>
				<div class="switch-account-modal-wrapper">
					<p>
						This document request is associated with
						<span class="font-semi-bold">{{
							supplierCompanyName ? supplierCompanyName : ""
						}}</span
						>. If you are associated with the company, please logout from here
						and login with the associated account.
					</p>
				</div>
			</template>

			<template v-slot:dialog_actions>
				<v-btn
					class="btn-blue"
					@click="confirmLogout"
					:disabled="isLogoutLoading"
				>
					{{ isLogoutLoading ? "Confirming..." : "Logout" }}
				</v-btn>

				<v-btn
					class="btn-white"
					@click="cancelLogout"
					:disabled="isLogoutLoading"
				>
					Cancel
				</v-btn>
			</template>
		</ConfirmDialog>

		<ConfirmDialog :dialogData.sync="requestCancelledConfirmationDialog">
			<template v-slot:dialog_icon>
				<div class="header-wrapper-close">
					<img
						src="@/assets/icons/alert.svg"
						alt="alert"
						v-if="isRequestFor !== '' && isRequestFor === 'cancelled'"
					/>
					<img
						src="@/assets/icons/info-blue.svg"
						alt="alert"
						v-if="isRequestFor !== '' && isRequestFor === 'fulfilled'"
					/>
				</div>
			</template>

			<template v-slot:dialog_title>
				<h2>Request has been {{ isRequestFor !== "" ? isRequestFor : "" }}</h2>
			</template>

			<template v-slot:dialog_content>
				<p>
					This request has already been
					{{ isRequestFor !== "" ? isRequestFor : "" }}.
				</p>
			</template>

			<template v-slot:dialog_actions>
				<v-btn class="btn-blue" @click="requestCancelInfoClose">
					Understood
				</v-btn>
			</template>
		</ConfirmDialog>

		<ConfirmDialog :dialogData.sync="notAuthorizedToViewDialog">
			<template v-slot:dialog_icon>
				<div class="header-wrapper-close">
					<img src="@/assets/icons/alert.svg" alt="alert" />
				</div>
			</template>

			<template v-slot:dialog_title>
				<h2>Customer not authorized</h2>
			</template>

			<template v-slot:dialog_content>
				<p>
					<span class="font-semi-bold">{{
						parseUserDetails.default_customer.company_name || ""
					}}</span>
					is not authorized to view this shipment. If you are associated with
					the company, please switch to the associated account of this shipment.
				</p>
			</template>

			<template v-slot:dialog_actions>
				<v-btn class="btn-blue" @click="closeNotAuthorized">
					Understood
				</v-btn>
			</template>
		</ConfirmDialog>
	</v-container>
</template>

<script>
import MilestonesV2 from "../../components/ShipmentComponents/Milestones/MilestonesV2.vue";
import ShipmentInfoV2 from "@/components/ShipmentInfoV2.vue";
import ShipmentDocuments from "@/components/ShipmentDocuments.vue";
import ShipmentBills from "@/components/ShipmentBill.vue";

import Map from "@/components/Map.vue";
import { mapActions, mapGetters } from "vuex";
import moment from "moment";
import _ from "lodash";
import CustomIcon from "@/components/Icons/CustomIcon";
import ShipmentProducts from "@/components/ShipmentProducts.vue";
import ShipmentNotes from "@/components/ShipmentNotes.vue";
import globalMethods from "../../utils/globalMethods";
// import KeneticIcon from "@/components/Icons/KeneticIcon";
import ConfirmDialog from "@/components/Dialog/GlobalDialog/ConfirmDialog.vue";
// import BookingShipmentDialogV2 from '../components/ShipmentComponents/BookingShipment/BookingShipmentDialogV2.vue';

const baseURL = process.env.VUE_APP_BASE_URL;
import axios from "axios";
import BookingInfoDialog from "../../components/ShipmentComponents/BookingShipment/BookingInfo/BookingInfoDialog.vue";
// import { shipmentStatusesLists } from "./js/statusLists";
import { shipmentStatusesLists } from "../../components/Tables/Shipments/Tabs/js/statusLists";

export default {
	components: {
		// KeneticIcon,
		MilestonesV2,
		ShipmentInfoV2,
		ShipmentDocuments,
		Map,
		CustomIcon,
		ShipmentNotes,
		ShipmentProducts,
		ShipmentBills,
		ConfirmDialog,
		BookingInfoDialog,
		// BookingShipmentDialogV2,
	},
	data: () => ({
		windowWidth: 0,
		tabs: [
			"Shipment Info",
			"Cargo",
			"Documents",
			"Milestones",
			"Bills",
			"Notes",
		],
		currentTab: 0,
		isMobile: false,
		shipment_id: null,
		detailsLoading: true,
		shipment_status: null,
		shipmentDocumentsLoading: true,
		cancelBookingRequestDialog: false,
		openCancelRequestForm: false,
		cancel_reason: "",
		shipmentType: "",
		viewBookingShipmentDialog: false,
		shipmentData: null,
		openSwitchAccountDialog: false,
		accountVendorDetails: null,
		isSwitchingAccountLoading: false,
		openLogoutAccountDialog: false,
		isLogoutLoading: false,
		supplierCompanyName: "",
		requestCancelledConfirmationDialog: false,
		isRequestFor: "",
		isForViewShipmentData: null,
		notAuthorizedToViewDialog: false,
		userDataDetails: {},
		trackingStatusMap: [
			{
				class: "manual-tracking",
				value: "Manual Tracking",
			},
			{
				class: "auto-tracking",
				value: "Auto Tracking",
			},
			{
				class: "auto-tracked",
				value: "Auto Tracked",
			},
			{
				class: "manually-tracked",
				value: "Manually Tracked",
			},
			{
				class: "not-tracking",
				value: "Not Tracking",
			},
			{
				class: "past-last-free-day",
				value: "Past Last Free Day",
			},
			{
				class: "discharged-released",
				value: "Discharged - released",
			},
			{
				class: "in-transit-hold",
				value: "In transit - hold",
			},
			{
				class: "in-transit-released",
				value: "In transit - released",
			},
			{
				class: "discharged-hold",
				value: "Discharged - Hold",
			},
			{
				class: "partially-discharged",
				value: "Partially discharged",
			},
		],
		openBookingInfoDialog: false,
	}),
	async mounted() {
		try {
			this.userDataDetails = await JSON.parse(this.getUser);
		} catch (e) {
			this.userDataDetails = await this.getUser;
		}
		this.windowWidth = window.innerWidth;
		this.shipment_id = this.$route.params.id;
		//synchronous call for loading shipment meta and suppliers
		await this.loadShipmentMetaData();

		let paramsData = this.$router.currentRoute;
		let isForViewShipment = parseInt(paramsData.query.is_view);
		let customersAPI = this.parseUserDetails.customers_api;
		let currentSelectedCustomer = this.parseUserDetails.default_customer_id;
		let isFrom = paramsData.query.from;

		if (isFrom === "mail") {
			if (typeof paramsData.query.customer !== "undefined") {
				let type = paramsData.query.type;
				let vendorCustomerId = parseInt(paramsData.query.scustomer);
				let vendorName = paramsData.query.sCompanyName;
				let request_id = paramsData.query.rid;

				if (type === "Vendor") {
					let findVendor = _.find(
						customersAPI,
						(e) => e.id == vendorCustomerId
					);

					if (currentSelectedCustomer !== vendorCustomerId) {
						if (findVendor !== undefined) {
							this.openSwitchAccountDialog = true;
							this.accountVendorDetails = findVendor;
						} else {
							this.openLogoutAccountDialog = true;
							this.supplierCompanyName = vendorName !== null ? vendorName : "";
						}
					} else {
						this.setMainTab(2); // set current tab to documents
						// check if document is cancelled, then show cancelled dialog
						await this.checkForCancelDocument(request_id);
					}
				}
			} else {
				if (typeof isForViewShipment !== "undefined" && isForViewShipment) {
					await this.checkForAuthorization(this.shipment_id);

					if (this.isForViewShipmentData !== null) {
						if (this.isForViewShipmentData.authorised) {
							this.setMainTab(2); // set current tab to documents
						} else {
							// show not authorized to view the shipment
							this.notAuthorizedToViewDialog = true;
						}
					}
				}
			}
		}
		this.shipment_status = this.getFilteredNewShipmentDetails.shipment_status;

		if (
			this.getShipmentDocumentPreview !== undefined &&
			this.getShipmentDocumentPreview !== "undefined"
		) {
			if (
				this.getShipmentDocumentPreview.from !== undefined &&
				this.getShipmentDocumentPreview.from !== "undefined"
			) {
				if (this.getShipmentDocumentPreview.from == "document_notification") {
					this.setMainTab(2); // set current tab to documents
				}
			}
		}
	},
	async updated() {
		// if (this.shipment_id !== this.$route.params.id) {
		// 	this.shipment_id = this.$route.params.id;
		// 	this.loadShipmentMetaData();
		// }
	},
	watch: {
		updatedShipmentId(nv, ov) {
			if (nv !== ov) {
				this.detailsLoading = true;
				this.shipment_id = this.$route.params.id;
				this.loadShipmentMetaData();
			}
		},
		"$store.state.notificationNew.shipmentDocumentPreview": {
			deep: true,
			handler(newVal) {
				if (this.currentTab !== 2) {
					if (newVal !== undefined && newVal !== "undefined") {
						if (newVal.from !== undefined && newVal.from !== "undefined") {
							if (newVal.from == "document_notification") {
								this.setMainTab(2); // set current tab to documents
							}
						}
					}
				}
			},
		},
	},
	computed: {
		...mapGetters({
			getNewShipmentDetails: "shipmentDetailsV2/getNewShipmentDetails",
			getCancelShipmentLoading: "getCancelShipmentLoading",
			getUser: "getUser",
			getShipmentTrackingDetails:
				"shipmentDetailsV2/getShipmentTrackingDetails",
			getShipmentTrackingDetailsLoading:
				"shipmentDetailsV2/getShipmentTrackingDetailsLoading",
			getShipmentDocumentPreview: "notificationNew/getShipmentDocumentPreview",
		}),
		updatedShipmentId() {
			return this.$route.params.id;
		},
		getFilteredNewShipmentDetails() {
			let shipmentDetails = {
				...this.getNewShipmentDetails,
				...this.getNewShipmentDetails.header_information,
				terminalInfo: null,
				isAutoTracking: null,
				milestones: null,
				vessel: null,
				voyageNumber: null,
				containerFreeDays: null,
				cut_off_date: null,
				etd_date: null,
				eta_date: null,
				carrier: null,
			};
			const {
				port_of_discharge,
				containers: shipmentContainers,
				location_from,
				location_to,
				booking_confirmed,
			} = shipmentDetails;
			if (!Array.isArray(this.getShipmentTrackingDetails)) {
				const {
					isAutoTracking = null,
					terminalInfo,
					milestones,
					generalInfo,
					containers,
					ais_link,
				} = this.getShipmentTrackingDetails;

				let {
					vessel = null,
					voyageNumber,
					portOfDischarge,
					carrier,
					containerFreeDays,
					cut_off_date,
					etd_date,
					eta_date,
					locationFrom,
					locationTo,
				} = generalInfo;

				shipmentDetails["isAutoTracking"] = isAutoTracking
					? isAutoTracking
					: null;
				shipmentDetails["location_from"] = locationFrom
					? locationFrom
					: location_from;
				shipmentDetails["location_to"] = locationTo ? locationTo : location_to;
				shipmentDetails["terminalInfo"] = terminalInfo ? terminalInfo : null;
				shipmentDetails["milestones"] = milestones ? milestones : null;
				shipmentDetails["port_of_discharge"] = portOfDischarge
					? portOfDischarge
					: port_of_discharge;
				shipmentDetails["containers"] =
					booking_confirmed == 1 ? containers : shipmentContainers;
				shipmentDetails["vessel"] = vessel ? vessel : null;
				shipmentDetails["voyage_number"] = voyageNumber ? voyageNumber : null;
				shipmentDetails["containerFreeDays"] = containerFreeDays
					? containerFreeDays
					: null;
				shipmentDetails["cut_off_date"] = cut_off_date ? cut_off_date : null;
				shipmentDetails["etd_date"] = etd_date ? etd_date : null;
				shipmentDetails["eta_date"] = eta_date ? eta_date : null;
				shipmentDetails["carrier"] = carrier ? carrier : null;
				shipmentDetails["ais_link"] = ais_link;
			}
			return shipmentDetails;
		},
		isCurrentSelectedCustomer() {
			let user = this.userDataDetails;

			if (
				typeof this.getFilteredNewShipmentDetails !== "undefined" &&
				this.getFilteredNewShipmentDetails !== null
			) {
				if (this.getFilteredNewShipmentDetails.customer_id !== null) {
					if (
						user !== null &&
						user.default_customer_id ===
							this.getFilteredNewShipmentDetails.customer_id
					) {
						return true;
					} else return false;
				} else return false;
			} else return false;
		},
		parseUserDetails() {
			let userDetailsFromStorage = localStorage.getItem("shifl.user_details");
			let getUserFromAPI = this.userDataDetails;

			return userDetailsFromStorage !== null
				? JSON.parse(userDetailsFromStorage)
				: getUserFromAPI;
		},
		bookingConfirmed() {
			return this.getFilteredNewShipmentDetails?.booking_confirmed == 1;
		},
	},
	methods: {
		...mapActions({
			fetchNewShipmentDetails: "shipmentDetailsV2/fetchNewShipmentDetails",
			fetchShipmentTrackingDetails:
				"shipmentDetailsV2/fetchShipmentTrackingDetails",
			fetchShipmentDocuments: "fetchShipmentDocuments",
			cancelShipment: "cancelShipment",
			fetchShipments: "fetchShipments",
			fetchPendingShipments: "fetchPendingShipments",
			fetchExpiredShipments: "fetchExpiredShipments",
			fetchSnoozeShipments: "fetchSnoozeShipments",
			fetchDraftShipments: "fetchDraftShipments",
			fetchArchivedBookings: "fetchArchivedBookings",
			fetchPendingQuoteShipments: "fetchPendingQuoteShipments",
			fetchConsolidationRequests: "fetchConsolidationRequests",
			fetchCompletedShipments: "fetchCompletedShipments",
			updateCustomerPreference: "updateCustomerPreference",
			logout: "logout",
		}),
		...globalMethods,
		ucFirst(str) {
			let pieces = str.split(" ");
			for (let i = 0; i < pieces.length; i++) {
				let j = pieces[i].charAt(0).toUpperCase();
				pieces[i] = j + pieces[i].substr(1);
			}
			return pieces.join(" ");
		},

		formatDate(value) {
			let setDate = moment
				.utc(value)
				.local()
				.format("h:mmA, MMM DD, YYYY");
			return setDate === "Invalid date" ? "N/A" : setDate;
		},
		async handleDocumentsChange(page) {
			try {
				this.shipmentDocumentsLoading = true;
				await this.fetchShipmentDocuments({
					shipment_id: this.shipment_id,
					page,
				});
				this.shipmentDocumentsLoading = false;
			} catch (e) {
				console.log(e);
			}
		},
		setMainTab(value) {
			this.currentTab = value;
		},
		onResize() {
			this.windowWidth = window.innerWidth;
			if (window.innerWidth < 960) {
				this.isMobile = true;
			} else {
				this.isMobile = false;
			}
		},
		async loadShipmentMetaData() {
			try {
				await this.fetchNewShipmentDetails(this.shipment_id);
				let payload = {
					shipment_id: this.shipment_id,
					mbl_num: this.getFilteredNewShipmentDetails.mbl_num,
				};
				await this.fetchShipmentTrackingDetails(payload);
				this.detailsLoading = false;
			} catch (e) {
				console.log(e);
				this.detailsLoading = false;
			}

			//set current page
			this.$store.dispatch("page/setPage", "shipments");
		},
		cancelBooking(isShipment) {
			if (isShipment === 1) {
				this.shipmentType = "shipment";
				this.openCancelRequestForm = true;
			} else {
				this.shipmentType = "booking";
				this.cancelBookingRequestDialog = true;
			}
		},
		submitCancelBooking() {
			if (this.shipment_id && this.shipment_id !== 0) {
				if (this.shipmentType == "shipment" && this.cancel_reason === "") {
					this.notificationErrorCustom(
						"Please fill the reason for cancellation field."
					);
				} else {
					let payloadObject = {
						shipmentId: this.shipment_id,
						cancel_reason: this.cancel_reason,
						type: this.shipmentType,
					};
					this.cancelShipment(payloadObject)
						.then((response) => {
							this.cancelBookingRequestDialog = false;
							this.openCancelRequestForm = false;
							this.notificationErrorCustom(response.data.message);
							this.reloadAllShipments();
						})
						.catch((e) => {
							console.log(e);
							this.cancelBookingRequestDialog = false;
							this.openCancelRequestForm = false;
							this.notificationErrorCustom("SOMETHING WENT WRONG!");
						});
				}
			}
		},
		reloadAllShipments() {
			this.fetchShipments(1);
			this.fetchPendingShipments(1);
			this.fetchExpiredShipments(1);
			this.fetchSnoozeShipments(1);
			this.fetchPendingQuoteShipments(1);
			this.fetchConsolidationRequests(1);
			this.fetchCompletedShipments(1);
			this.fetchArchivedBookings(0);
		},
		confirmSwitch() {
			this.isSwitchingAccountLoading = true;
			let customer_id = this.accountVendorDetails.id;
			let q = this.$route.query;

			let userDetails = JSON.parse(localStorage.getItem("shifl.user_details"));
			if (customer_id !== userDetails.default_customer_id) {
				if (typeof userDetails === "object") {
					localStorage.setItem(
						"shifl.user_details",
						JSON.stringify({ ...userDetails, default_customer_id: customer_id })
					);
				}
				this.updateCustomerPreference(customer_id)
					.then((response) => {
						this.isSwitchingAccountLoading = false;
						if (typeof response.status !== "undefined") {
							if (response.status == "ok") {
								this.$router
									.push({
										query: { ...q, setDocumentTab: 1 },
									})
									.catch(() => {});

								let currentUrl = window.location.href;
								window.location.href = currentUrl;
							}
						}
					})
					.catch((e) => {
						this.isSwitchingAccountLoading = false;
						console.log(e);
					});
			}
		},
		cancelSwitch() {
			this.openSwitchAccountDialog = false;
			this.accountVendorDetails = null;
			this.$router.push("/shipments");
		},
		async confirmLogout() {
			this.isLogoutLoading = true;
			const fullPath = this.$route.fullPath;
			await this.logout();
			this.isLogoutLoading = false;
			localStorage.setItem("documentRedirectPath", fullPath);
			let q = this.$route.query;

			this.$router
				.push({
					query: { ...q, isFromLoggedIn: 1, setDocumentTab: 1 },
				})
				.catch(() => {});
			this.supplierCompanyName = "";
		},
		cancelLogout() {
			this.openLogoutAccountDialog = false;
			this.supplierCompanyName = "";
			this.$router.push("/shipments");
		},
		async checkForCancelDocument(id) {
			await axios
				.get(`${baseURL}/check-for-cancel/${id}`)
				.then((res) => {
					if (res.status === 200) {
						if (res.data.status) {
							this.requestCancelledConfirmationDialog = true;
							this.isRequestFor = res.data.message;
						}
					}
				})
				.catch((e) => {
					this.notificationError(e.message);
				});
		},
		requestCancelInfoClose() {
			this.requestCancelledConfirmationDialog = false;
			this.isRequestFor = "";
			// let q = this.$route.query

			// remove query params in url
			this.$router.push({ query: "" }).catch(() => {});
		},
		async checkForAuthorization(id) {
			await axios
				.get(`${baseURL}/check-for-authorization/${id}`)
				.then((res) => {
					if (res.status === 200) {
						this.isForViewShipmentData = res.data;
					}
				})
				.catch((e) => {
					this.notificationError(e.message);
				});
		},
		closeNotAuthorized() {
			this.notAuthorizedToViewDialog = false;
			this.isForViewShipmentData = null;
			this.$router.push("/shipments");
		},
		getShipmentStatusLabelClass({ shipment_status, is_draft }) {
			if (
				shipment_status == "Pending Approval" ||
				shipment_status == "Pending Quote" ||
				is_draft == 1
			) {
				return "primary-blue";
			}
		},
		getEditShipments({ shipment_status, is_draft }) {
			if (is_draft == 1 || shipment_status == "Pending Quote") {
				return true;
			}
		},
		shipmentTypeImg(type) {
			if (type == "LCL") {
				return require("../../assets/images/small-container.svg");
			} else if (type == "Air" || type == "AIR") {
				return require("../../assets/images/airplane-shipment.svg");
			} else if (type == "FCL") {
				return require("../../assets/images/big-container.svg");
			}
		},
		addShipmentTag() {
			console.log("addShipmentTag");
		},
		getShipmentStatusClass(item, type) {
			let { tracking_status } = item;

			let setValue =
				type === "tracking" ? tracking_status : this.shipment_status;
			let setClass = "";
			if (setValue) {
				setClass = _.find(
					shipmentStatusesLists,
					(e) => e.value === setValue.toLowerCase()
				);
				setClass = typeof setClass !== "undefined" ? setClass.class : "";
			}

			return setClass;
		},
	},
};
</script>

<style lang="scss">
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600&display=swap");
@import "@/assets/scss/pages_scss/shipment/shipmentDetails/shipmentDetailsV2.scss";
@import "@/assets/scss/buttons.scss";
@import "@/components/Tables/Shipments/Tabs/scss/statuses.scss";

.switch-account-modal-wrapper {
	.switch-account-modal-details {
		padding: 5px 8px;
		border: 1px solid #f3f4f7;
		border-radius: 4px;
		margin-top: 12px;

		.switch-company-logo {
			width: 30px;
			height: 30px;
			display: flex;
			justify-content: center;
			align-items: center;
			border: 1px solid #e7e9ee;
			border-radius: 50px;
			padding: 16px;
			margin-right: 8px;
			background-color: #f3f4f7;
		}

		.switch-company-details {
			line-height: 20px;

			.switch-company-name {
				font-family: "Inter-SemiBold", sans-serif;
				color: #253041 !important;
				font-size: 14px;
			}

			.switch-company-address {
				margin-bottom: 0;
				color: #69758c;
				font-size: 12px;
			}
		}
	}
}
</style>

import { render, staticRenderFns } from "./CategoryDesktopTable.vue?vue&type=template&id=c02e4b76&"
import script from "./CategoryDesktopTable.vue?vue&type=script&lang=js&"
export * from "./CategoryDesktopTable.vue?vue&type=script&lang=js&"
import style0 from "./CategoryDesktopTable.vue?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
installComponents(component, {VBtn,VDataTable,VProgressCircular,VSpacer,VToolbar,VToolbarTitle})

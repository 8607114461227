<!-- @format -->

<template>
	<div
		class="shipment-buyer-wrapper-v2 shipment-details-card"
		v-if="getDetails.consignees.length > 0"
	>
		<h3>
			Consignee
			<span class="user-role ml-1" v-if="getDetails.userRole == 'consignee'"
				>Your Role
			</span>
		</h3>
		<div v-if="getDetails.userRole !== 'consignee' && getDetails.userRole">
			<div
				class="shippers-consignees-details"
				v-for="(consignee, index) in getDetails.consignees"
				:key="index"
			>
				<v-menu
					content-class="info-details-menu"
					:close-on-content-click="false"
					:nudge-width="350"
					:nudge-bottom="40"
					offset-x
				>
					<template
						v-slot:activator="{ on, attrs }"
						v-if="(consignee.buyer_details && consignee.buyer_details.display_name) || consignee.company_name"
					>
						<div class="company-name">
							<p class="mb-0">
								<span
									v-if="getDetails.consignees.length > 1"
									class="shipper-consignee-index"
									>{{ index > 9 ? index + 1 : `0${index + 1}` }}</span
								>
								<span
									class="company-details"
									v-on="on"
									v-bind="attrs"
									@click="showConsigneeDetail(consignee.buyer_details)"
								>
									<span class="mb-0 cp-name">
										{{
											consignee.buyer_details
												? consignee.buyer_details.display_name
												: consignee.company_name
										}}
									</span>
								</span>
							</p>
							<span v-on="on" v-bind="attrs"
								><img src="../../../assets/icons/alert-info-gray.svg"
							/></span>
						</div>
					</template>
					<v-card v-if="!isMobile && consignee.buyer_details" class="info-menu-details-wrapper">
						<v-card-text>
							<p class="info-title">
								Consignee’s Details
							</p>
							<div class="general-info">
								<p class="mb-0 info-label">Address</p>
								<p class="mb-0 info-value">
									{{ checkValue(consignee.buyer_details.address) }}
								</p>
							</div>
							<div class="general-info">
								<p class="mb-0 info-label">Contact Name</p>
								<p class="mb-0 info-value">
									{{ checkValue(consignee.buyer_details.display_name) }}
								</p>
							</div>
							<div class="general-info">
								<p class="mb-0 info-label">Contact Tel</p>
								<p class="mb-0 info-value">
									{{ checkValue(consignee.buyer_details.phone) }}
								</p>
							</div>
							<div class="general-info" v-if="consignee.buyer_details.emails">
								<p class="mb-0 info-label">Contact Email</p>
								<p
									class="mb-0 info-value"
									v-for="(email, index) in consignee.buyer_details.emails"
									:key="index"
								>
									<a class="mailto" :href="`mailto:${email}`">{{ email }}</a>
								</p>
							</div>
						</v-card-text>
					</v-card>
				</v-menu>

				<div class="orders-details">
					<div class="order-numbers" v-if="consignee.orders && consignee.orders.length > 0">
						<v-chip v-for="(items, index) in consignee.orders" :key="index">
							So #{{ items }}
						</v-chip>
					</div>
					<div class="order-numbers" else>
						<v-chip v-for="(items, index) in consignee.manualOrders" :key="index">
							So #{{ items }}
						</v-chip>
					</div>
					<div class="cargo-details-wrapper">
						<v-row class="cargo-info">
							<v-col md="3" :cols="isMobile ? 5 : ''" class="cargo-dt-info">
								<div class="general-info">
									<p class="mb-0 info-label">Total Cartons</p>
									<p class="mb-0 info-value">
										{{ checkValue(consignee.cargoDetails.total_cartoon) }}
									</p>
								</div>
							</v-col>
							<v-col md="3" :cols="isMobile ? 4 : ''" class="cargo-dt-info">
								<div class="general-info">
									<p class="mb-0 info-label">Volume</p>
									<p class="mb-0 info-value">
										{{ checkValue(consignee.cargoDetails.total_volume) }}
									</p>
								</div>
							</v-col>
							<v-col md="2" :cols="isMobile ? 3 : ''" class="cargo-dt-info">
								<div class="general-info">
									<p class="mb-0 info-label">Weight</p>
									<p class="mb-0 info-value">
										{{ checkValue(consignee.cargoDetails.total_weight) }}
									</p>
								</div>
							</v-col>
							<v-col md="2" :cols="isMobile ? 4 : ''" class="cargo-dt-info">
								<div class="general-info">
									<p class="mb-0 info-label">Terms</p>
									<p class="mb-0 info-value">
										{{ checkValue(consignee.cargoDetails.incoterm_id) }}
									</p>
								</div>
							</v-col>
							<v-col md="3" :cols="isMobile ? 8 : ''" class="cargo-dt-info">
								<div class="general-info">
									<p class="mb-0 info-label">Product Description</p>
									<p class="mb-0 info-value">
										{{ checkValue(consignee.cargoDetails.product_description) }}
									</p>
								</div>
							</v-col>
						</v-row>
					</div>
				</div>
			</div>
			<v-dialog
				v-model="consigneeDetailDialog"
				width="auto"
				content-class="shipper-consignee-detail-dialog"
			>
				<v-card class="info-menu-details-wrapper rounded-0">
					<v-card-text>
						<p class="info-title">
							Consignee’s Details
						</p>
						<div class="general-info">
							<p class="mb-0 info-label">Address</p>
							<p class="mb-0 info-value">
								{{ checkValue(consigneeDetails.address) }}
							</p>
						</div>
						<div class="general-info">
							<p class="mb-0 info-label">Contact Name</p>
							<p class="mb-0 info-value">
								{{ checkValue(consigneeDetails.display_name) }}
							</p>
						</div>
						<div class="general-info">
							<p class="mb-0 info-label">Contact Tel</p>
							<p class="mb-0 info-value">
								{{ checkValue(consigneeDetails.phone) }}
							</p>
						</div>
						<div class="general-info" v-if="consigneeDetails.emails">
							<p class="mb-0 info-label">Contact Email</p>

							<p
								class="mb-0 info-value"
								v-for="(email, index) in consigneeDetails.emails"
								:key="index"
							>
								<a class="mailto" :href="`mailto:${email}`">{{ email }}</a>
							</p>
						</div>

						<div class="close-btn mt-2">
							<v-btn class="btn-white" @click="consigneeDetailDialog = false">
								close
							</v-btn>
						</div>
					</v-card-text>
				</v-card>
			</v-dialog>
		</div>
		<div v-else>
			<div
				class="shippers-consignees-details"
				v-for="(consignee, index) in getDetails.consignees"
				:key="index"
			>
				<h4 class="cm-cp-name">
					{{ consignee.company_name }}
				</h4>
				<div
					class="shippers-consignee-info-details"
					v-if="consignee.consignee_details_info"
				>
					<p class="mb-0 info-label">
						Details:
						<span class="mb-0 info-value">{{
							consignee.consignee_details_info
								? consignee.consignee_details_info
								: "N/A"
						}}</span>
					</p>
					<p class="mb-0 info-label" v-if="consignee.notify_details_info">
						Notify Party:
						<span class="mb-0 info-value">
							{{
								consignee.notify_details_info
									? consignee.notify_details_info
									: "N/A"
							}}
						</span>
					</p>
				</div>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	name: "ShipmentShipper",
	props: ["getDetails", "isMobile"],
	data: () => ({
		consigneeDetailDialog: false,
		consigneeDetails: {},
	}),
	methods: {
		checkValue(value) {
			return value == "" || value == null || value == "null" ? "N/A" : value;
		},
		showConsigneeDetail(item) {
			if (this.isMobile) {
				this.consigneeDetails = item;
				this.consigneeDetailDialog = true;
			}
		},
	},
};
</script>
<style lang="scss">
@import "../../../assets/scss/pages_scss/shipment/shipmentDetails/ShipmentShippersConsignees.scss";
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"storable-unit-wrapper-mobile"},[_c('div',{staticClass:"overlay search",class:_vm.search !== '' && _vm.getSearchedStorableUnitLoading ? 'show' : ''},[((_vm.search !== '' && _vm.getSearchedStorableUnitLoading))?_c('div',{staticClass:"preloader"},[_c('v-progress-circular',{attrs:{"size":40,"color":"#1A6D9E","indeterminate":""}})],1):_vm._e()]),_c('div',{staticClass:"overlay",class:_vm.search == '' && _vm.getHandleLoading ? 'show' : ''},[((_vm.search == '' && _vm.getHandleLoading))?_c('div',{staticClass:"preloader"},[_c('v-progress-circular',{attrs:{"size":40,"color":"#1A6D9E","indeterminate":""}})],1):_vm._e()]),_c('v-data-table',{staticClass:"inventory-table storable-mobile-table elevation-1",class:{
            'no-data-table': (typeof _vm.storableUnitItems !== 'undefined' && _vm.storableUnitItems.length === 0),
            'no-current-pagination' : (_vm.getTotalPage <= 1)
        },attrs:{"headers":_vm.headers,"items":_vm.storableUnitItems,"page":_vm.page,"items-per-page":_vm.itemsPerPage,"mobile-breakpoint":"769","item-key":"id","hide-default-footer":"","fixed-header":"","expanded":_vm.expanded,"single-expand":"","show-expand":"","show-select":"","item-class":_vm.itemRowBackground},on:{"update:page":function($event){_vm.page=$event},"page-count":function($event){_vm.pageCount = $event},"update:expanded":function($event){_vm.expanded=$event},"click:row":_vm.clickRow},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('div',{staticClass:"inventory-search-wrapper"},[_c('v-tabs',{staticClass:"inventory-inner-tab",on:{"change":_vm.onTabChange},model:{value:(_vm.currentTab),callback:function ($$v) {_vm.currentTab=$$v},expression:"currentTab"}},_vm._l((_vm.tabs),function(tab,index){return _c('v-tab',{key:index,class:index == 2 ? 'inventory-inner-tab-last' : ''},[_c('span',[_vm._v(_vm._s(tab))])])}),1),_c('v-spacer'),_c('div',{staticClass:"search-and-filter"},[(_vm.handleSearchComponent)?_c('div',{staticClass:"search-wrapper"},[_c('img',{attrs:{"src":require("@/assets/images/search-icon.svg"),"alt":""}}),_c('input',{directives:[{name:"model",rawName:"v-model.trim",value:(_vm.search),expression:"search",modifiers:{"trim":true}}],staticClass:"search",attrs:{"type":"text","id":"search-input","placeholder":"Search Storable Unit","autocomplete":"off"},domProps:{"value":(_vm.search)},on:{"input":[function($event){if($event.target.composing){ return; }_vm.search=$event.target.value.trim()},_vm.handleSearch],"blur":function($event){return _vm.$forceUpdate()}}})]):_vm._e()])],1)]},proxy:true},{key:"expanded-item",fn:function(ref){
        var headers = ref.headers;
        var item = ref.item;
return [_c('td',{attrs:{"colspan":headers.length}},[_c('div',{staticClass:"expanded-item-info"},[_c('div',{staticClass:"expanded-header-wrapper"},[_c('div',{staticClass:"expanded-header-content"},[_c('div',{staticClass:"header-title w-80"},[_vm._v("SKU")]),_c('div',{staticClass:"header-title w-10"},[_vm._v("CARTON")]),_c('div',{staticClass:"header-title w-10"},[_vm._v("UNIT")])])]),_c('div',{staticClass:"expanded-body-wrapper"},_vm._l((item.storable_unit_products),function(v,index){return _c('div',{key:index,staticClass:"expanded-body-content"},[_c('div',{staticClass:"header-data w-80"},[_vm._v(" #"+_vm._s((v.product !== null && v.product.sku !== null) ? v.product.sku : '')+" "+_vm._s((v.product !== null && v.product.name !== null) ? v.product.name : '')+" ")]),_c('div',{staticClass:"header-data w-10"},[_vm._v(" "+_vm._s(v.carton_count)+" ")]),_c('div',{staticClass:"header-data w-10"},[_vm._v(" "+_vm._s(v.total_unit)+" ")])])}),0)])])]}},{key:"item.label",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"inventory-wrapper"},[_c('div',{staticClass:"storable-label"},[_c('div',{staticClass:"inventory-blue-text",class:item.type !== null ? 'mr-1' : ''},[_vm._v(" "+_vm._s(item.label))]),(item.type !== null)?_c('div',{staticClass:"storable-type"},[_vm._v(" ("+_vm._s(item.type)+")")]):_vm._e(),_c('v-icon',{staticClass:"ml-1",staticStyle:{"padding-top":"2px"},attrs:{"color":"#1A6D9E"}},[_vm._v(" "+_vm._s(_vm.expanded.length > 0 && (_vm.expanded[0].label === item.label) ? 'mdi-chevron-up' : 'mdi-chevron-down')+" ")])],1),_c('div',{staticClass:"storable-location"},[_vm._v(" "+_vm._s(_vm.getLocationDetails(item.location))+" ")])])]}},{key:"item.type",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"inventory-wrapper specs"},[_c('div',{staticClass:"mr-1"},[_vm._v(" "+_vm._s(item.parse_dimensions.l)+"x "+_vm._s(item.parse_dimensions.w)+"x "+_vm._s(item.parse_dimensions.h)+" "+_vm._s(item.parse_dimensions.uom)+" ")]),_c('div',{staticClass:"weight"},[_c('div',{staticStyle:{"color":"#69758C !important"}},[_vm._v(" "+_vm._s(item.parse_weight.value)+" "+_vm._s(item.parse_weight.unit)+" ")])])])]}},{key:"item.spec",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"items-info"},[_c('p',[_vm._v(" "+_vm._s(item.no_of_sku !== null ? item.no_of_sku : 0)+" "),_c('span',{staticClass:"name"},[_vm._v("SKU")])]),_c('span',{staticClass:"separator"}),_c('p',[_vm._v(" "+_vm._s(item.total_carton_count !== null ? item.total_carton_count : 0)+" "),_c('span',{staticClass:"name"},[_vm._v("Cartons")])]),_c('span',{staticClass:"separator"}),_c('p',[_vm._v(" "+_vm._s(item.total_units !== null ? item.total_units : 0)+" "),_c('span',{staticClass:"name"},[_vm._v("Units")])])])]}},{key:"item.location",fn:function(ref){
        var item = ref.item;
return [_c('div',{staticClass:"items-info"},[_c('p',[_c('span',{staticClass:"name"},[_vm._v("UPDATED AT: ")]),_vm._v(" "+_vm._s(_vm.formatDate(item.updated_at))+" ")])])]}},{key:"no-data",fn:function(){return [(_vm.getHandlePageLoading)?_c('div',{staticClass:"loading-wrapper"},[_c('v-progress-circular',{attrs:{"size":40,"color":"#1A6D9E","indeterminate":""}})],1):_vm._e(),(!_vm.getStorableUnitsActiveLoading && _vm.storableUnitItems.length == 0)?_c('div',{staticClass:"no-data-wrapper"},[_c('div',{staticClass:"no-data-heading"},[_c('img',{attrs:{"src":require("@/assets/icons/empty-inventory-icon.svg"),"width":"40px","height":"42px","alt":""}}),_c('h3',[_vm._v(" No Storable Units ")]),(_vm.search == '')?_c('p',[(_vm.currentTab === 0)?_c('span',[_vm._v("You don't have any active storable units yet.")]):_vm._e(),(_vm.currentTab === 1)?_c('span',[_vm._v("No Storable Units in History yet.")]):_vm._e()]):_vm._e(),(_vm.search !== '')?_c('p',[_vm._v(" No Storable Units found. Try searching another keyword. ")]):_vm._e()])]):_vm._e()]},proxy:true}],null,true)}),_c('PaginationComponent',{attrs:{"totalPage":_vm.getTotalPage,"currentPage":_vm.getCurrentPage,"isMobile":_vm.isMobile},on:{"update:totalPage":function($event){_vm.getTotalPage=$event},"update:total-page":function($event){_vm.getTotalPage=$event},"update:currentPage":function($event){_vm.getCurrentPage=$event},"update:current-page":function($event){_vm.getCurrentPage=$event},"handlePageChange":_vm.handlePageChange}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }
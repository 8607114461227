var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"po-details-wrapper-table products"},[_c('div',[(!_vm.isMobile)?_c('v-data-table',{staticClass:"desktop-podetails-product elevation-0",attrs:{"headers":_vm.dynamicHeader,"items":_vm.finalpoDetailsProducts,"hide-default-footer":"","mobile-breakpoint":"1023","items-per-page":500},scopedSlots:_vm._u([{key:"body",fn:function(ref){
var items = ref.items;
return [_c('tbody',_vm._l((items),function(item,i){return _c('tr',{key:i},[_c('td',{staticClass:"table_headers1",staticStyle:{"height":"100px"}},_vm._l((item.duplicate),function(ite,index){return _c('div',{key:ite.id},[(index == 0)?_c('span',[_vm._v(" "+_vm._s(ite.product.category_sku !== null ? "#" + ite.product.category_sku : "--")+" ")]):_vm._e(),(index == 0)?_c('p',{staticClass:"mb-0",staticStyle:{"color":"#69758C"}},[_vm._v(" "+_vm._s(ite.product.name)+" ")]):_vm._e()])}),0),_c('td',{staticStyle:{"height":"100px"}},_vm._l((item.duplicate),function(ite){return _c('div',{key:ite.id,staticClass:"po-tb-set po-tb-tr-td-div-padding"},[_vm._v(" "+_vm._s(ite.ship_date)+" ")])}),0),_c('td',_vm._l((item.duplicate),function(ite,index){return _c('div',{key:ite.id + index,staticClass:"po-tb-set po-tb-tr-td-div-padding",class:_vm.checkFeildExists('quantity', ite.change_log)
									? 'change-log'
									: ''},[(
										ite.productionStatusItems &&
											ite.productionStatusItems.length > 0
									)?_c('div',{staticClass:"mb-0 text-right"},_vm._l((ite.productionStatusItems),function(value,i){return _c('p',{key:i},[_vm._v(" "+_vm._s(value.carton)+" ")])}),0):_vm._e(),(
										!_vm.checkFeildExists('quantity', ite.change_log) &&
											ite.productionStatusItems.length == 0
									)?_c('div',{staticClass:"mb-0 text-right"},[_vm._v(" "+_vm._s(ite.quantity)+" ")]):_vm._e(),_vm._l((ite.change_log),function(changedData,i){return _c('div',{key:i},[(changedData.field == 'quantity')?_c('div',{staticClass:"changed-value"},[_c('p',{staticClass:"mb-0 item-danger"},[_vm._v(" "+_vm._s(changedData.new_value)+" ")]),_c('p',{staticClass:"mb-0 item-cancel"},[_vm._v(" "+_vm._s(changedData.old_value)+" ")])]):_vm._e()])})],2)}),0),_c('td',_vm._l((item.duplicate),function(ite,index){return _c('div',{key:ite.id + index,staticClass:"po-tb-set po-tb-tr-td-div-padding",class:_vm.checkFeildExists('units_per_carton', ite.change_log)
									? 'change-log'
									: ''},[(
									ite.productionStatusItems &&
										ite.productionStatusItems.length > 0
								)?_c('div',{staticClass:"mb-0 text-right"},_vm._l((ite.productionStatusItems),function(value,i){return _c('p',{key:i},[_vm._v(" "+_vm._s(value.in_each)+" ")])}),0):_vm._e(),_c('div',[(
										!_vm.checkFeildExists('units_per_carton', ite.change_log) &&
											ite.productionStatusItems.length == 0
									)?_c('p',{staticClass:"mb-0 text-right"},[_c('span',{staticStyle:{"color":"#69758C"}},[_vm._v(_vm._s(ite.units_per_carton))])]):_vm._e(),_vm._l((ite.change_log),function(changedData,i){return _c('div',{key:i},[(changedData.field == 'units_per_carton')?_c('div',{staticClass:"changed-value"},[_c('p',{staticClass:"mb-0 item-danger"},[_c('span',{staticStyle:{"color":"#69758C"}},[_vm._v(_vm._s(changedData.new_value))])]),_c('p',{staticClass:"mb-0 item-cancel "},[_c('span',{staticStyle:{"color":"#69758C"}},[_vm._v(_vm._s(changedData.old_value))])])]):_vm._e()])})],2)])}),0),_c('td',_vm._l((item.duplicate),function(ite,index){return _c('div',{key:ite.id + index,staticClass:"po-tb-set po-tb-tr-td-div-padding",class:_vm.checkFeildExists('units', ite.change_log) ? 'change-log' : ''},[(
									ite.productionStatusItems &&
										ite.productionStatusItems.length > 0
								)?_c('div',{staticClass:"mb-0 text-right"},_vm._l((ite.productionStatusItems),function(value,i){return _c('p',{key:i},[_vm._v(" "+_vm._s(value.unit)+" ")])}),0):_vm._e(),_c('div',[(
										!_vm.checkFeildExists('units', ite.change_log) &&
											ite.productionStatusItems.length == 0
									)?_c('p',{staticClass:"mb-0 text-right"},[_vm._v(" "+_vm._s(ite.units)+" ")]):_vm._e()]),_vm._l((ite.change_log),function(changedData,i){return _c('div',{key:i},[(changedData.field == 'units')?_c('div',{staticClass:"changed-value"},[_c('p',{staticClass:"mb-0 item-danger"},[_vm._v(_vm._s(changedData.new_value))]),_c('p',{staticClass:"mb-0 item-cancel "},[_vm._v(_vm._s(changedData.old_value))])]):_vm._e()])})],2)}),0),(
								_vm.getPoDetail.state !== 'in_progress' &&
								_vm.getPoDetail.state !== 'approved')?_c('td',_vm._l((item.duplicate),function(ite,index){return _c('div',{key:ite.id + index,staticClass:"po-tb-set po-tb-tr-td-div-padding",class:_vm.checkFeildExists('volume', ite.change_log)
									? 'change-log' : ''},[(!_vm.checkFeildExists('volume', ite.change_log))?_c('div',[_c('p',{staticClass:"mb-0 text-right"},[_vm._v(" "+_vm._s(ite.volume !== null ? ite.volume : 0)+" CBM ")])]):_vm._e(),_vm._l((ite.change_log),function(changedData,i){return _c('div',{key:i},[(changedData.field == 'volume')?_c('div',{staticClass:"changed-value"},[_c('p',{staticClass:"mb-0 item-danger"},[_vm._v(" "+_vm._s(changedData.new_value)+" CBM ")]),_c('p',{staticClass:"mb-0 item-cancel"},[_vm._v(" "+_vm._s(changedData.old_value)+" CBM ")])]):_vm._e()])})],2)}),0):_vm._e(),(_vm.getPoDetail.state !== 'in_progress' &&
								_vm.getPoDetail.state !== 'approved')?_c('td',_vm._l((item.duplicate),function(ite,index){return _c('div',{key:ite.id + index,staticClass:"po-tb-set po-tb-tr-td-div-padding",class:_vm.checkFeildExists('weight', ite.change_log)
										? 'change-log'
										: ''},[(!_vm.checkFeildExists('weight', ite.change_log))?_c('div',[_c('p',{staticClass:"mb-0 text-right"},[_vm._v(" "+_vm._s(ite.weight !== null ? parseFloat(ite.weight).toFixed(2) : 0)+" KG ")])]):_vm._e(),_vm._l((ite.change_log),function(changedData,i){return _c('div',{key:i},[(changedData.field == 'weight')?_c('div',{staticClass:"changed-value"},[_c('p',{staticClass:"mb-0 item-danger"},[_vm._v(" "+_vm._s(parseFloat(changedData.new_value).toFixed(2))+" KG ")]),_c('p',{staticClass:"mb-0 item-cancel"},[_vm._v(" "+_vm._s(parseFloat(changedData.old_value).toFixed(2))+" KG ")])]):_vm._e()])})],2)}),0):_vm._e(),(
								_vm.getPoDetail.state == 'in_progress' ||
								_vm.getPoDetail.state == 'approved')?_c('td',_vm._l((item.duplicate),function(ite,index){return _c('div',{key:ite.id + index,staticClass:"po-tb-set po-tb-tr-td-div-padding"},[(ite.productionStatusItems.length == 0)?_c('div',[_c('p',{staticClass:"mb-0 text-right"},[_vm._v(" "+_vm._s(ite.volume !== null ? ite.volume : 0)+" CBM ")]),_c('p',{staticClass:"mb-0 text-right"},[_vm._v(" "+_vm._s(ite.weight !== null ? parseFloat(ite.weight).toFixed(2) : 0)+" KG ")])]):_vm._e(),(
									ite.productionStatusItems &&
										ite.productionStatusItems.length > 0
								)?_c('div',{staticClass:"mb-0 text-right"},_vm._l((ite.productionStatusItems),function(value,i){return _c('div',{key:i},[_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.nNumberDecimalFormat( value.unit * _vm.calculateVolume(ite), 5 ))+" CBM ")]),_c('p',{staticClass:"mb-1"},[_vm._v(" "+_vm._s(parseFloat(value.unit * _vm.calculateWeight(ite)).toFixed( 2 ))+" KG ")])])}),0):_vm._e()])}),0):_vm._e(),(
								_vm.getPoDetail.state == 'in_progress' ||
								_vm.getPoDetail.state == 'approved')?_c('td',_vm._l((item.duplicate),function(ite,index){return _c('div',{key:ite.id + index,staticClass:"po-tb-set po-tb-tr-td-div-padding"},[(ite.productionStatusItems.length == 0)?_c('div',[_c('p',{staticClass:"mb-0",staticStyle:{"color":"#BC4410"}},[_vm._v(" "+_vm._s(ite.productionStatusName ? ite.productionStatusName : "Pending")+" ")])]):_vm._e(),(
									ite.productionStatusItems &&
										ite.productionStatusItems.length > 0
								)?_c('div',{staticClass:"mb-0 text-start"},_vm._l((ite.productionStatusItems),function(value,i){return _c('p',{key:i,staticStyle:{"color":"#BC4410"}},[_vm._v(" "+_vm._s(value.production_status_name)+" ")])}),0):_vm._e()])}),0):_vm._e(),(
								_vm.getPoDetail.state == 'in_progress' ||
								_vm.getPoDetail.state == 'approved')?_c('td',_vm._l((item.duplicate),function(ite,index){return _c('div',{key:ite.id + index,staticClass:"po-tb-set po-tb-tr-td-div-padding"},[(ite.productionStatusItems.length == 0)?_c('div',[_c('p',{staticClass:"mb-0"},[_vm._v(" "+_vm._s(_vm.formatDate(ite.productionECRD))+" ")])]):_vm._e(),(
									ite.productionStatusItems &&
										ite.productionStatusItems.length > 0
								)?_c('div',{staticClass:"mb-0"},_vm._l((ite.productionStatusItems),function(value,i){return _c('p',{key:i},[_vm._v(" "+_vm._s(_vm.formatDate(value.expected_cargo_ready_date))+" ")])}),0):_vm._e()])}),0):_vm._e(),_c('td',_vm._l((item.duplicate),function(ite,index){return _c('div',{key:ite.id + index,staticClass:"po-tb-set po-tb-tr-td-div-padding",class:_vm.checkFeildExists('unit_price', ite.change_log)
									? 'change-log'
									: ''},[(!_vm.checkFeildExists('unit_price', ite.change_log))?_c('p',{staticClass:"mb-0 text-right"},[_vm._v(" $"+_vm._s(ite.unit_price)+" ")]):_vm._e(),_vm._l((ite.change_log),function(changedData,i){return _c('div',{key:i},[(changedData.field == 'unit_price')?_c('div',{staticClass:"changed-value"},[_c('p',{staticClass:"mb-0 item-danger"},[_vm._v("$"+_vm._s(changedData.new_value))]),_c('p',{staticClass:"mb-0 item-cancel "},[_vm._v(" $"+_vm._s(changedData.old_value)+" ")])]):_vm._e()])})],2)}),0),_c('td',_vm._l((item.duplicate),function(ite,index){return _c('div',{key:ite.id + index,staticClass:"po-tb-set po-tb-tr-td-div-padding",class:_vm.checkFeildExists('amount', ite.change_log)
										? 'change-log' : ''},[(!_vm.checkFeildExists('amount', ite.change_log))?_c('p',{staticClass:"mb-0 text-right"},[_vm._v(" $"+_vm._s(ite.amount !== null ? parseFloat(ite.amount).toFixed(2) : 0)+" ")]):_vm._e(),_vm._l((ite.change_log),function(changedData,i){return _c('div',{key:i},[(changedData.field == 'amount')?_c('div',{staticClass:"changed-value"},[_c('p',{staticClass:"mb-0 item-danger"},[_vm._v("$"+_vm._s(changedData.new_value))]),_c('p',{staticClass:"mb-0 item-cancel "},[_vm._v(" $"+_vm._s(changedData.old_value)+" ")])]):_vm._e()])})],2)}),0)])}),0)]}}],null,false,237132235)}):_vm._e(),(_vm.isMobile)?_c('div',{staticClass:"mobile-table-details-product-wrapper"},[_vm._m(0),_c('v-data-table',{staticClass:"mobile-podetails-product elevation-0",attrs:{"headers":_vm.headers,"items":_vm.poDetailsProducts,"hide-default-footer":"","mobile-breakpoint":"1023"},scopedSlots:_vm._u([{key:"item.sku",fn:function(ref){
										var item = ref.item;
return [_c('div',{staticClass:"mobile-description"},[_c('p',[_vm._v(" SKU "),_c('span',[_c('span',[_vm._v(_vm._s(item.product.category_sku !== null ? item.product.category_sku : "--"))])])]),_c('p',[_vm._v(" "+_vm._s(item.product.description !== "undefined" && item.product.description !== null ? item.product.description : "--")+" ")]),_c('p',[_vm._v(" "+_vm._s(item.units !== null ? item.units : "0")+" Units x $"+_vm._s(item.unit_price !== null ? parseFloat(item.unit_price).toFixed(2) : 0)+" ")])]),_c('div',[_vm._v(" $"+_vm._s(item.amount !== null ? parseFloat(item.amount).toFixed(2) : 0)+" ")])]}}],null,true)})],1):_vm._e(),_c('div',{staticClass:"poDetail-total"},[_c('div'),_c('div'),_c('div'),_c('div',{staticClass:"py-3 font-weight-bold"},[_vm._v("Subtotal")]),_c('div',{staticClass:"py-3 font-weight-bold",class:_vm.checkFeildExists('sub_total', _vm.getPoDetail.change_log)
						? 'change-log'
						: ''},[_vm._v(" $"+_vm._s(_vm.getPoDetail.sub_total !== null ? parseFloat(_vm.getPoDetail.sub_total).toFixed(2) : 0)+" ")])]),_c('div',{staticClass:"poDetail-total"},[_c('div'),_c('div'),_c('div'),_c('div',{staticClass:"py-3"},[_vm._v("Tax")]),_c('div',{staticClass:"py-3",class:_vm.checkFeildExists('tax', _vm.getPoDetail.change_log) ? 'change-log' : ''},[_vm._v(" $"+_vm._s(_vm.getPoDetail.tax !== null ? parseFloat(_vm.getPoDetail.tax).toFixed(2) : 0)+" ")])]),_c('div',{staticClass:"poDetail-total"},[_c('div'),_c('div'),_c('div'),_c('div',{staticClass:"py-3 font-weight-bold no-border"},[_vm._v("Total")]),_c('div',{staticClass:"py-3 font-weight-bold no-border",class:_vm.checkFeildExists('total', _vm.getPoDetail.change_log)
						? 'change-log'
						: ''},[_vm._v(" $"+_vm._s(_vm.getPoDetail.total !== null ? parseFloat(_vm.getPoDetail.total).toFixed(2) : 0)+" ")])]),_c('div',{staticClass:"po-notes-wrapper"},[_c('div',{staticClass:"po-notes"},[_c('h4',[_vm._v("Notes")]),_c('p',[_vm._v(" "+_vm._s(_vm.getPoDetail.notes !== null ? _vm.getPoDetail.notes : "--")+" ")])])])],1)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"mobile-products-header"},[_c('div',{staticClass:"header-title"},[_vm._v("DESCRIPTION")]),_c('div',{staticClass:"header-title"},[_vm._v("AMOUNT")])])}]

export { render, staticRenderFns }
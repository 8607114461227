<template>
    <v-dialog v-model="dialog" max-width="660" content-class="request-upload-documents" persistent v-resize="onResize" scrollable>
        <v-card class="request-document">
            <v-card-title>
                <span class="headline">Request Document</span>
                <button icon dark class="btn-close" @click="close">
                    <v-icon>mdi-close</v-icon>
                </button>
            </v-card-title>

            <v-card-text>
                <v-form ref="form" v-model="valid" action="#" @submit.prevent="">
                    <div class="request-checkboxes pb-4">
                        <p class="request-checkbox-label">Document Type</p>
                        <!-- <v-row v-if="!isMobile">
                            <v-col cols="12" sm="4">
                                <v-checkbox
                                    v-model="selected"
                                    label="Commercial Invoice"
                                    value="Commercial Invoice"
                                    hide-details="auto">
                                </v-checkbox>
                            </v-col>
                            <v-col cols="12" sm="4">
                                <v-checkbox
                                    v-model="selected"
                                    label="Packing List"
                                    value="Packing List"
                                    hide-details="auto">
                                </v-checkbox>
                            </v-col>
                            <v-col cols="12" sm="4">
                                <v-checkbox
                                    v-model="selected"
                                    label="Other Documents"
                                    value="Other Documents"
                                    hide-details="auto">
                                </v-checkbox>
                            </v-col>
                            <v-col cols="12" sm="5">
                                <v-checkbox
                                    v-model="selected"
                                    label="Commercial Invoice & Packing List"
                                    value="Commercial Invoice & Packing List"
                                    hide-details="auto">
                                </v-checkbox>
                            </v-col>
                        </v-row> -->

                        <div v-if="!isMobile" class="checkboxes-wrapper d-flex align-center">
                            <div class="pr-4" style="margin-left: -4px;">
                                <v-checkbox
                                    v-model="selected"
                                    label="Commercial Invoice"
                                    value="Commercial Invoice"
                                    hide-details="auto">
                                </v-checkbox>
                            </div>
                            <div class="pr-4">
                                <v-checkbox
                                    v-model="selected"
                                    label="Packing List"
                                    value="Packing List"
                                    hide-details="auto">
                                </v-checkbox>
                            </div>
                            <div class="pr-4">
                                <v-checkbox
                                    v-model="selected"
                                    label="Other Documents"
                                    value="Other Documents"
                                    hide-details="auto">
                                </v-checkbox>
                            </div>
                        </div>

                        <div class="row" v-if="isMobile">
                            <div class="col col-sm-6">
                                <v-checkbox
                                    v-model="selected"
                                    label="Commercial Invoice"
                                    value="Commercial Invoice"
                                    hide-details="auto">
                                    <!-- :disabled="isPackingAndComSelected" -->
                                </v-checkbox>
                            </div>
                            <div class="col col-sm-6">
                                <v-checkbox
                                    v-model="selected"
                                    label="Packing List"
                                    value="Packing List"
                                    hide-details="auto">
                                    <!-- :disabled="isPackingAndComSelected" -->
                                </v-checkbox>
                            </div>
                        </div>
                        
                        <div class="row" v-if="isMobile">
                            <!-- <div class="col col-sm-12 pt-1">
                                <v-checkbox
                                    v-model="selected"
                                    label="Commercial Invoice & Packing List"
                                    value="Commercial Invoice & Packing List"
                                    hide-details="auto">
                                </v-checkbox>
                            </div> -->
                            <div class="col col-sm-12 pt-1">
                                <v-checkbox
                                    v-model="selected"
                                    label="Other Documents"
                                    value="Other Documents"
                                    hide-details="auto">
                                </v-checkbox>
                            </div>
                        </div>

                        <!-- <v-row>
                            <v-col cols="12" sm="4" class="pt-1">
                                <v-checkbox
                                    v-model="selected"
                                    label="Other Documents"
                                    value="Other Documents"
                                    hide-details="auto">
                                </v-checkbox>
                            </v-col>
                        </v-row> -->
                    </div>

                    <div class="request-checkboxes pt-4">
                        <p class="request-checkbox-label">Request To</p>
                        <v-radio-group v-model="requestDocument.requestTo" mandatory hide-details="auto" class="pt-0 mt-0">
                            <v-radio label="Vendor" value="Vendor"></v-radio>
                            <!-- <v-radio label="Trucker" value="Trucker"></v-radio> -->
                            <v-radio label="Other Party" value="Other Party"></v-radio>
                        </v-radio-group>
                    </div>

                    <div class="request-checkboxes pt-4" v-if="requestDocument.requestTo === 'Vendor'">
                        <p class="request-checkbox-label">{{ requestDocument.requestTo }}</p>
                        <v-autocomplete
                            class="text-fields select-items"
                            v-model="requestDocument.vendor"
                            :items="shipmentVendorLists"
                            item-text="company_name"
                            item-value="id"
                            outlined
                            hide-details="auto"
                            :placeholder="`Select ${requestDocument.requestTo}`"
                            clearable
                            multiple
                            return-object
                            :disabled="shipmentVendorLists.length === 1"
                            :menu-props="{ bottom: true, offsetY: true, contentClass: 'request-lists-items' }"
                            @click:clear="clearVendor"
                            :rules="[v => !!(v && v.length) || 'Item is required']">

                            <template v-slot:selection="{ item }">
                                <v-chip class="vendor-selection font-medium" text-color="#253041" close @click:close="removeVendor(item)" v-if="shipmentVendorLists.length > 1">
                                    {{ item.company_name }}
                                </v-chip>

                                <span v-else style="color: #253041;">
                                    {{ item.company_name }}
                                </span>
                            </template>

                            <template v-slot:item="{ item, on, attrs }">
                                <v-list-item v-on="on" v-bind="attrs" #default="{ active }">
                                    <v-list-item-action>
                                        <v-checkbox :input-value="active"></v-checkbox>
                                    </v-list-item-action>

                                    <v-list-item-content>
                                        <v-list-item-title>           
                                            <p class="company-name mb-0 font-semi-bold">{{ item.company_name }}</p>
                                            <p class="company-address mb-0">
                                                {{ item.address !== null && item.address !== "" ? item.address : "N/A" }}
                                            </p>
                                        </v-list-item-title>
                                    </v-list-item-content>
                                </v-list-item>
                            </template>
                        </v-autocomplete>
                    </div>

                    <div class="request-checkboxes pt-4">
                        <p class="request-checkbox-label">
                            {{ requestDocument.requestTo !== 'Vendor' ? 'Recipient' : requestDocument.requestTo }}'s Emails
                        </p>
                        <div class="tags-input-wrapper">                            
                            <vue-tags-input
                                hide-details="auto"
                                :rules="arrayNotEmptyRules"
                                :tags="options"
                                :add-on-blur=true
                                :add-on-key="[13, ',']"
                                v-model="reportsEmailAddress"
                                :class="checkVendorsDisabled ? 'disable-input' : ''"
                                :placeholder="options.length > 0 ? '' : `Enter ${requestDocument.requestTo !== 'Vendor' ? 'Recipient' : requestDocument.requestTo}'s emails`"
                                :autocomplete-items="filteredItems"
                                :autocomplete-min-length="0"
                                @before-adding-tag="checkEmailBeforeAdding"
                                @tags-changed="newTags => {
                                    this.options = newTags
                                    this.tagsInput.touched = true
                                    this.tagsInput.hasError = (this.options.length > 0) ? false : true
                                    let el = this.documentProto.getElementsByClassName('ti-input')[0]
                                    if (typeof el!=='undefined') {
                                        if (this.tagsInput.hasError)
                                            el.classList.add('ti-new-tag-input-error')
                                        else
                                            el.classList.remove('ti-new-tag-input-error')
                                    }
                                }"
                            />
                            <!-- :validation="tagsValidation" -->

                            <!-- <v-tooltip bottom v-if="checkVendorsDisabled" content-class="tooltip-bottom">
                                <template v-slot:activator="{ on, attrs }">
                                    <span v-bind="attrs" v-on="on" class="hidden-wrapper-emails">
                                    </span>
                                </template>
                                <span>You can not add additional email for multiple vendors</span>
                            </v-tooltip> -->
                        </div>

                        <!-- <p class="my-0" style="color: #819FB2; font-size: 12px; margin-top: 5px;" v-if="requestDocument.vendor.length <= 1">
                            Separate multiple email addresses with comma
                        </p>

                        <p class="mb-2" style="color: #9CA5B4; font-size: 12px; margin-top: 5px; line-height: 18px;" v-else>
                            You cannot add vendor emails from here, due to multiple vendors selected. 
                            Please add emails to your vendor in the 
                            <a href="/contact" class="font-medium" target="_blank" style="color: #1A6D9E !important;">
                                vendor Module
                            </a>
                            to appear here
                        </p> -->

                        <div style="min-height: 12px;" class="mt-1 mb-1">
                            <div v-if="errorMessage === ''">
                                <p class="my-0" style="color: #819FB2; font-size: 12px;" v-if="requestDocument.vendor.length <= 1">
                                    Separate multiple email addresses with comma
                                </p>

                                <p class="mb-2" style="color: #819FB2; font-size: 12px; margin-top: 5px; line-height: 18px;" v-else>
                                    You cannot add vendor emails from here, due to multiple vendors selected. 
                                    Please add emails to your vendor in the 
                                    <a href="/contact" class="font-medium" target="_blank" style="color: #0171A1 !important;">
                                        vendor Module
                                    </a>
                                    to appear here
                                </p>
                            </div>

                            <div v-else class="v-text-field__details">
                                <span class="v-messages theme--light error--text">{{ errorMessage }}</span>
                            </div>
                        </div>
                    </div>

                    <div class="request-checkboxes pb-3 pt-1">
                        <p class="request-checkbox-label">Note</p>
                        <v-textarea
                            height="85"
                            class="text-fields address"
                            outlined
                            name="input-7-4"
                            placeholder="Add Note"
                            v-model="requestDocument.notes"
                            hide-details="auto"
                            autocomplete="off">
                        </v-textarea>
                    </div>
                </v-form>
            </v-card-text>

            <v-card-actions class="card-actions">
                <v-btn class="btn-blue" color="primary" @click="submitRequest" :disabled="isSubmitting">                    
                    {{ isSubmitting ? 'Submitting...' : 'Submit Request'}}
                </v-btn>

                <v-btn class="btn-white" @click="close" :disabled="isSubmitting">
                    Cancel
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import globalMethods from '../../../utils/globalMethods'
import jQuery from 'jquery'
import VueTagsInput from '@johmun/vue-tags-input'

const baseURL = process.env.VUE_APP_BASE_URL;
import axios from "axios";
import _ from "lodash";

export default {
    name: 'RequestDocumentsDialog',
    props: ['dialogData', 'getShipmentDetails', 'isFrom'],
    components: { VueTagsInput },
    data: () => ({
        isMobile: false,
        valid: true,
        selected: [],
        requestDocument: {
            document_types: [],
            vendor: [],
            vendor_emails: [],
            notes: "",
            requestTo: "Vendor",
            vendorCopy: []
        },
        options: [],
        tagsValidation: [
            {
                classes: 't-new-tag-input-text-error',
                rule: (/^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/),
                disableAdd: true
            }
        ],
        documentProto: document,
        tagsInput: {
            touched: false,
            hasError: false,
            errorMessage: 'Please confirm the entered email address by pressing the "Enter" or "," key in your keyboard.'
        },
        reportsEmailAddress: '',
        arrayNotEmptyRules: [
            (v) => !!v || "Email is required",
            () => this.optionsFiltered.length > 0 || "Make sure to supply at least 1 email." 
        ],
        isSubmitting: false,
        suggestedEmails: [],
        errorMessage: ""
    }),
    computed: {
        ...mapGetters({
            getUser: "getUser"
        }),
        dialog: {
            get () {
                return this.dialogData
            },
        },
        isPackingAndComSelected() {
            if (this.selected.length > 0) {                
                if (this.selected.includes("Commercial Invoice & Packing List")) {
                    return true
                } else {
                    return false
                }
            }
            return false
        },
        shipmentVendorLists() {
            let suppliers = this.getShipmentDetails.shippers.map(item=>{
                const {supplier_details:{address,display_name,emails,id},company_name } = item
                return {
                    address:address,
                    company_name:company_name,
                    display_name:display_name,
                    emails:emails,
                    id:id,
                }
            });

            return suppliers && suppliers.length > 0 ? suppliers : []
        },
        checkVendorsDisabled() {
            if (this.requestDocument.vendor.length > 1) {
                return true
            } else {
                return false
            }
        },
        parseUserDetails() {
            let customerDetails = null;

            if (typeof this.getUser !== "undefined" && this.getUser !== "") {
                customerDetails = typeof this.getUser === "string" ? JSON.parse(this.getUser) : this.getUser;
            }

            return customerDetails
        },
        filteredItems() {
            return this.suggestedEmails.filter(i => {
                return i.text.toLowerCase().indexOf(this.reportsEmailAddress.toLowerCase()) !== -1;
            });
        }
    },
    mounted() {
    },
    watch: {
        tagsInput(value) {
            if (value.hasError) {
                jQuery('.ti-input').addClass('ti-new-tag-input-error')
            } else {
                jQuery('.ti-input').removeClass('ti-new-tag-input-error')
            }
        },
        "requestDocument.vendor": {
            handler: function (value) {
                if (value.length > 0) {
                    let validEmailAddress = []

                    value.map(v => {
                        if (v.emails.length > 0) {
                            v.emails.map(c => {
                                if (c?.email) {
                                    validEmailAddress.push({
                                        supplier_id: v.id,
                                        text: c.email,
                                        tiClasses: ["ti-valid"],
                                        emails: [c.email],
                                        connected_customer: v.connected_customer
                                    })
                                } else if (c !== null) {
                                    validEmailAddress.push({ 
                                        supplier_id: v.id, 
                                        text: c, 
                                        tiClasses: ["ti-valid"],
                                        emails: [c],
                                        connected_customer: v.connected_customer
                                    })
                                }
                            })
                        }
                    })
                    this.options = validEmailAddress
                }

                if (value.length === 1) {
                    value.map(v => {
                        if (v.emails.length > 0) {
                            v.emails.map(c => {
                                if (c?.email) {
                                    this.suggestedEmails.push({
                                        supplier_id: v.id,
                                        text: c.email,
                                        tiClasses: ["ti-valid"],
                                        emails: [c.email],
                                        connected_customer: v.connected_customer
                                    })
                                } else if (c !== null) {
                                    this.suggestedEmails.push({ 
                                        supplier_id: v.id, 
                                        text: c, 
                                        tiClasses: ["ti-valid"],
                                        emails: [c],
                                        connected_customer: v.connected_customer
                                    })
                                }
                            })
                        }
                    })
                } else {
                    this.suggestedEmails = [];
                }
            },
            deep: true
        },
        dialog(val) {
            this.tagsInput.hasError = false
			this.tagsInput.touched = false

			jQuery(".ti-input").removeClass("ti-new-tag-input-error")
			if (typeof el !== "undefined") {
				let el = document.getElementsByClassName("ti-input")[0]
				el.classList.remove("ti-new-tag-input-error")
			}

            if (val) {
                // set default selected to Commercial Invoice and Packing List
                // this.selected.push('Commercial Invoice & Packing List');
                this.selected.push('Commercial Invoice');
                let shipmentSuppliers = this.getShipmentDetails.shipment_suppliers

                if (this.isFrom === "to-dos") {
                    this.shipmentVendorLists.map(v => {
                        if (shipmentSuppliers !== null && shipmentSuppliers.length > 0) {
                            let filterSuppliers = shipmentSuppliers.filter(v => (v.commercial_documents_filled == false))
                            let findSupplierNoCommercialDocs = filterSuppliers.find(e => ( e.supplier_id === v.id))

                            if (findSupplierNoCommercialDocs !== undefined) {
                                this.requestDocument.vendor.push(v)
                            }
                        }
                    })
                } else {
                    if (this.shipmentVendorLists.length === 1) {
                        this.requestDocument.vendor.push(this.shipmentVendorLists[0])
                    }
                }
            } else {
                this.selected = []
                this.requestDocument.vendor = []
            }
        },
        "requestDocument.requestTo": {
            handler: function (value) {
                if (value === "Other Party") {
                    this.requestDocument.vendorCopy = this.requestDocument.vendor
                    this.options = []
                    this.requestDocument.vendor = []

                } else { // if selected is vendor
                    let vendorCopy = this.requestDocument.vendorCopy
                    let validEmailAddress = []

                    if (typeof vendorCopy !== "undefined" && vendorCopy.length > 0) {
                        vendorCopy.map(v => {
                            if (v.emails.length > 0) {
                                v.emails.map(c => {
                                    if (c?.email) {
                                        validEmailAddress.push({
                                            supplier_id: v.id,
                                            text: c.email,
                                            tiClasses: ["ti-valid"],
                                            emails: [c.email],
                                            connected_customer: v.connected_customer
                                        })
                                    } else if (c !== null) {
                                        validEmailAddress.push({ 
                                            supplier_id: v.id, 
                                            text: c, 
                                            tiClasses: ["ti-valid"],
                                            emails: [c],
                                            connected_customer: v.connected_customer
                                        })
                                    }
                                })
                            }
                        })
                    
                        if (this.shipmentVendorLists.length === 1) {
                            this.requestDocument.vendor.push(this.shipmentVendorLists[0])
                        } else {
                            this.requestDocument.vendor = []
                            validEmailAddress = []
                        }
                    }
                    this.options = validEmailAddress
                }
            },
            deep: true
        },
        options(val) {
            if (val.length > 0) {
                jQuery('.ti-input').removeClass('ti-new-tag-input-error')
            }
        },
        reportsEmailAddress() {
            this.errorMessage = "";
            jQuery('.ti-input').removeClass('ti-new-tag-input-error');
            jQuery('.ti-new-tag-input').removeClass("t-new-tag-input-text-error ti-invalid").addClass('ti-valid');
        }
    },
    methods: {
        ...globalMethods,
        onResize() {
			if (window.innerWidth < 768) {
				this.isMobile = true;
			} else {
				this.isMobile = false;
			}
		},
        submitRequest() {
            if (!this.tagsInput.touched) this.tagsInput.touched = true

			this.$refs.form.validate()
			this.tagsInput.hasError = this.options.length > 0 ? false : true

			this.generateErrorMessage()

            setTimeout(async () => {
				if (this.$refs.form.validate()) {
					if (!this.tagsInput.hasError) {
						try {
							jQuery(".ti-new-tag-input").trigger(
								jQuery.Event("keyup", { keyCode: 13, which: 13 })
							)

                            if (this.errorMessage === "") {
                                let payload = {
                                    shipment_id: this.getShipmentDetails.shipment_id,
                                    notes: this.requestDocument.notes,
                                    type: this.requestDocument.requestTo,
                                    customer_admin_id: this.parseUserDetails.id
                                }

                                var newVendorEmails = [];
                                let forEmptyEmails = [];
                                
                                if (this.requestDocument.requestTo !== "Other Party") {
                                    for (var obj of this.options) {
                                        var empty = true;
                                        for (var newobjA of newVendorEmails) {
                                            if (obj.supplier_id == newobjA.supplier_id) { empty = false; }
                                        }
                                        if (empty) {
                                            if (obj.supplier_id !== undefined) {
                                                newVendorEmails.push({ 
                                                    supplier_id: obj.supplier_id,
                                                    suppliers_customer_id: obj.connected_customer,
                                                    emails: obj.emails
                                                });
                                            } else { // check if there is a new email added
                                                if (obj.text !== undefined) {
                                                    if (newVendorEmails[0] !== undefined) {
                                                        let findDuplicateEmail = _.find(newVendorEmails[0].emails, e => (e === obj.text));

                                                        if (findDuplicateEmail === undefined) {
                                                            newVendorEmails[0].emails.push(obj.text)
                                                        }
                                                    } else {
                                                        forEmptyEmails.push(obj.text)
                                                    }
                                                }                                            
                                            }
                                        } else {
                                            for (var newobjB of newVendorEmails) {
                                                if (newobjB.supplier_id == obj.supplier_id) {
                                                    for (var o of obj.emails) {
                                                        newobjB.emails.push(o);
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    payload.suppliers = newVendorEmails
                                    payload.requestTo = "Vendor"
                                } else {
                                    if (this.options.length > 0) {
                                        newVendorEmails = this.options.map(v => {
                                            return v.text
                                        })
                                    }

                                    payload.suppliers = [
                                        {
                                            emails: JSON.stringify(newVendorEmails)
                                        }
                                    ]
                                    payload.requestTo = "Other Party"
                                }

                                if (forEmptyEmails.length > 0) {
                                    if (payload.suppliers.length === 0) {
                                        payload.suppliers = [
                                            { 
                                                supplier_id: this.requestDocument.vendor[0].id,
                                                suppliers_customer_id: this.requestDocument.vendor[0].connected_customer,
                                                emails: forEmptyEmails 
                                            }
                                        ]
                                    } else if (payload.suppliers[0] !== undefined) {
                                        forEmptyEmails.map(v => {
                                            payload.suppliers[0].emails.push(v)
                                        })
                                    }
                                }
                                
                                if (this.selected.length > 0) {
                                    if (this.selected.includes("Commercial Invoice & Packing List")) {
                                        if (this.selected.includes("Commercial Invoice") && 
                                            this.selected.includes("Packing List")) {
                                            this.selected = this.selected.filter(v => {
                                                return v !== "Commercial Invoice" && v !== "Packing List"
                                            })
                                        }
                                    }
                                }

                                payload.document_types = JSON.stringify(this.selected);
                                this.requestDocumentAPI(payload);
                            }
						} catch (e) {
							this.notificationError(e)
							console.log(e)
						}
					}
				}
			}, 200)
        },
        async requestDocumentAPI(payload) {
            this.isSubmitting = true;
            if (payload !== null) {
                if (payload.requestTo === "Vendor") {
                    payload.suppliers.map(v => {
                        if (v.emails.length > 0) {
                            v.emails = JSON.stringify(v.emails)
                        }
                    })
                }

                await axios.post(`${baseURL}/request-documents`, payload)
                    .then(async (res) => {
                    if (res.status === 200) {
                        this.isSubmitting = false;
                        this.notificationMessage('Documents requested successfully!')
                        this.close()
                        this.$emit("fetchRequestDocuments", payload.shipment_id)
                    } 
                }).catch(e => {
                    this.notificationError(e.message)
                    this.isSubmitting = false;
                }) 
            }
        },
        close() {
            this.$emit('close');
            this.selected = [];
            this.requestDocument = {
                vendor: [],
                note: "",
                vendorCopy: []
            };
            this.tagsInput.hasError = false;
			this.tagsInput.touched = false;
            this.options = [];
            this.errorMessage = "";
            this.reportsEmailAddress = "";
            this.suggestedEmails = [];
            this.$refs.form.reset();
			this.$refs.form.resetValidation();
        },
        generateErrorMessage() {
            this.tagsInput.hasError = (this.options.length > 0) ? false : true
            if (this.tagsInput.hasError)
                jQuery('.ti-input').addClass('ti-new-tag-input-error')
            else
                jQuery('.ti-input').removeClass('ti-new-tag-input-error');

            if (this.errorMessage !== "" || this.options.length === 0) {
				jQuery('.ti-input').addClass('ti-new-tag-input-error');
                jQuery('.ti-new-tag-input').removeClass('ti-valid').addClass("t-new-tag-input-text-error ti-invalid");
				this.errorMessage = this.options.length === 0 && this.reportsEmailAddress === ""
					? "Please provide at least 1 valid email address" 
					: (this.reportsEmailAddress !== "" 
						? "Please make sure to enter a valid email address" 
						: this.errorMessage)
			} else {
				this.errorMessage = "";
				jQuery('.ti-input').removeClass('ti-new-tag-input-error');
                jQuery('.ti-new-tag-input').removeClass("t-new-tag-input-text-error ti-invalid").addClass('ti-valid');
			}
        },
        clearVendor() {
            this.requestDocument.vendor = []
            this.requestDocument.vendorCopy = []
            this.tagsInput.hasError = false;
			this.tagsInput.touched = false;
            this.options = [];
            // this.$refs.form.reset();
			this.$refs.form.resetValidation();
        },
        removeVendor(item) {
            if (this.requestDocument.vendor.length === 1) {
                this.options = []
                this.requestDocument.vendorCopy = []
            }
            const index = this.requestDocument.vendor.indexOf(item);
            if (index >= 0) this.requestDocument.vendor.splice(index, 1);
        },
        checkEmailBeforeAdding(item) {
            if ((/^([a-zA-Z0-9_.-])+@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,4})+$/).test(item.tag.text)) {
                item.addTag();
                this.errorMessage = "";
                jQuery('.ti-input').removeClass('ti-new-tag-input-error');
                jQuery('.ti-new-tag-input').removeClass("t-new-tag-input-text-error ti-invalid").addClass('ti-valid');
            } else {
                this.errorMessage = "Please make sure to enter a valid email address";
                jQuery('.ti-input').addClass('ti-new-tag-input-error');
                jQuery('.ti-new-tag-input').removeClass('ti-valid').addClass("t-new-tag-input-text-error ti-invalid");
            }
        }
    },
    updated() {}
}
</script>

<style lang="scss">
@import '@/assets/scss/pages_scss/dialog/globalDialog.scss';
@import '@/assets/scss/buttons.scss';
@import '@/assets/scss/pages_scss/shipment/requestDocumentDialog.scss';

</style>
<template>
    <div class="statement-content-wrapper">
        <div class="overlay" :class="nextPageACHLoading ? 'show' : ''">  
            <div class="preloader" v-if="(nextPageACHLoading)">
                <v-progress-circular
                    :size="30"
                    color="#1A6D9E"
                    indeterminate>
                </v-progress-circular>
            </div>       
        </div>

        <v-data-table
            :headers="headers"
            mobile-breakpoint="769"
            :items="statements"
            class="statements-table elevation-1"
            v-bind:class="{
                'no-data-table': (statements !== null && statements.length !== 'undefined' && statements.length === 0),
                'no-current-pagination' : (getTotalPage <= 1),
            }"
			:search="search"
			:page.sync="page"
            :items-per-page="itemsPerPage"
			@page-count="pageCount = $event"
            item-key="statement_no"
			hide-default-footer
            fixed-header
            show-expand
            :expanded.sync="expanded"
            :single-expand="true">

            <template v-slot:[`header.data-table-expand`]="{ header }">
                <div class="d-flex justify-start align-center">
                    <span class="mr-2">{{ header.text }}</span>
                    <v-tooltip top content-class="ach-month-tooltip-wrapper tooltip-top">  
                        <template v-slot:activator="{ on, attrs }">
                            <div class="d-flex justify-start align-center" v-bind="attrs" v-on="on">   
                                <img src="@/assets/icons/icon-filled-gray.svg" alt="" width="18px">
                            </div>
                        </template>
                        <span class="d-flex text-center" style="line-height: 20px; font-size: 13px;">
                            Month When Entries <br/> Were Processed
                        </span>
                    </v-tooltip>
                </div>
            </template>

            <template v-slot:[`header.pms_day`]="{ header }">
                <div class="d-flex justify-start align-center">
                    <span class="mr-2">{{ header.text }}</span>
                    <v-tooltip top content-class="ach-month-tooltip-wrapper tooltip-top pms">  
                        <template v-slot:activator="{ on, attrs }">
                            <div class="d-flex justify-start align-center" v-bind="attrs" v-on="on">   
                                <img src="@/assets/icons/icon-filled-gray.svg" alt="" width="18px">
                            </div>
                        </template>
                        <span class="d-flex text-center" style="line-height: 20px; font-size: 13px;">
                            <!-- Month When Entries  Were Processed -->
                            Month in which the Periodic Monthly Statement (PMS) <br/> is issued and money is withdrawn.
                        </span>
                    </v-tooltip>
                </div>
            </template>

            <template v-slot:top>
                <v-toolbar flat>
                    <v-toolbar-title>ACH Statements</v-toolbar-title>					
                    <v-spacer></v-spacer>
                    <v-btn color="primary" class="btn-white mr-2" @click="showReportSchedule" v-if="!getAchStatementsLoading"
                        :disabled="statements.length === 0">
                        <img src="../../../assets/icons/settings.svg" class="mr-1" alt=""/>
                        Report Schedule
                    </v-btn>

                    <v-btn color="primary" class="btn-white" @click="showCBPDialog"
                        v-if="statements !== null && statements.length !== 'undefined' && statements.length !== 0">
                        <img src="../../../assets/icons/shipment-view-icons/calendar-view-blue.svg" class="mr-1" alt="" height="16px" width="16px" />
                        CBP Calendar
                    </v-btn>

					<!-- <Search 
						placeholder="Search Statement"
						className="search custom-search"
						:inputData.sync="search" 
                        v-if="statements !== null && statements.length !== 'undefined' && statements.length !== 0"/> -->
                </v-toolbar>
            </template>
        
            <template v-slot:item="{ item, expand, isExpanded }">
                <tr>
                    <!-- For PMS Month column -->
                    <td class="text-start">
                        <button @click.stop="expand(!isExpanded)" flat class="btn-dropdown-month">
                            <v-icon v-if="isExpanded" color="#1A6D9E">mdi-chevron-up</v-icon>
                            <v-icon v-if="!isExpanded" color="#1A6D9E">mdi-chevron-down</v-icon>
                        </button> {{ item.pms_day || '--' }}
                    </td>

                    <!-- For Entry Month column -->
                    <td class="text-start">{{ item.month_year | current }}</td>

                    <!-- Statement Number column -->
                    <td class="text-start">
                        <span v-if="item.statement_no"> {{ item.statement_no }} </span>

                        <!-- no statement number -->
                        <span v-else>
                            <!-- If PMS month is current month, show orange -->
                            <span v-if="item.pms_day === getCurrentMonthYear" style="color: #DD530E;">
                                Issue Date {{ formatAllDate(item.expected_date) }}
                            </span>
                            <span v-else>
                                <!-- If Entry month is current month and PMS will be processed next month, show gray -->
                                <span v-if="item.month_year === getCurrentMonthYear" style="color: #69758C;">
                                    Issue Date {{ formatDate(item, "issue") }}
                                </span>
                            </span>
                        </span>
                    </td>

                    <!-- Periodic Statement Date column -->
                    <td class="text-start">
                        <span v-if="item.statement_no">
                            <!-- for those dates that are passed the current month -->
                            <span v-if="item.pms_day !== getCurrentMonthYear">
                                {{ formatDate(item, "processed") }}
                            </span>

                            <span v-else>
                                <span v-if="checkDateIfPassedTodaysDate(item.process_date)">
                                    {{ formatAllDate(item.process_date) }}
                                </span>
                                <span v-else style="color: #DD530E;">
                                    Final on {{ formatAllDate(item.process_date) }}
                                </span>
                            </span>
                        </span>

                        <span v-else>
                            <!-- If PMS month is current month, show orange -->
                            <span v-if="item.pms_day === getCurrentMonthYear" style="color: #DD530E;">
                                Final on {{ formatDate(item, "final") }}
                            </span>
                            <span v-else>
                                <!-- If Entry month is current month and PMS will be processed next month, show gray -->
                                <span v-if="item.month_year === getCurrentMonthYear" style="color: #69758C;">
                                    Final on {{ formatDate(item, "final") }}
                                </span>
                            </span>
                        </span>
                    </td>

                    <td class="text-end">
                        <span class="font-medium">{{ item.amount !== null ? `$${item.amount}` : "--" }}</span>

                        <!-- show next withdrawal date in particular condition -->
                        <p v-if="item.pms_day === getCurrentMonthYear && !checkDateIfPassedTodaysDate(item.process_date)"
                            style="color: #DD530E;" class="mb-0 mt-1">
                            Next withdrawal on 
                            <span v-if="item.statement_no" style="color: #DD530E;">{{ formatAllDate(item.process_date) }}</span>
                            <span v-else style="color: #DD530E;">{{ formatDate(item, "final") }}</span>
                        </p>
                    </td>

                    <td class="text-start">
                        <div class="actions" v-if="item.statement_no !== null">
                            <div class="preview-statement-wrapper mr-2">
                                <v-menu offset-y bottom left min-width="120px" max-height="90px" content-class="ach-menu-dropdown monthly">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn class="btn-white" color="primary" dark v-bind="attrs" v-on="on">
                                            <span class="font-medium">Preview</span>
                                            <v-icon color="#1A6D9E" size="20px">mdi-chevron-down</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-item @click.stop="previewStatement(item, 'pdf', 'monthly')">
                                            <v-list-item-title>View PDF</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item @click.stop="previewStatement(item, 'xlsx', 'monthly')">
                                            <v-list-item-title>View xlsx</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item @click.stop="previewSummaryReport(item)">
                                            <v-list-item-title>Summary Report</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </div>

                            <div class="download-statement-wrapper">
                                <v-menu offset-y bottom left min-width="120px" max-height="90px" content-class="ach-menu-dropdown monthly">
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-btn class="btn-white" color="primary" dark v-bind="attrs" v-on="on">
                                            <span class="font-medium">Download</span>
                                            <v-icon color="#1A6D9E" size="20px">mdi-chevron-down</v-icon>
                                        </v-btn>
                                    </template>
                                    <v-list>
                                        <v-list-item @click.stop="downloadStatement(item, 'pdf', 'monthly')" :disabled="isDownloading">
                                            <v-list-item-title>Download PDF</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item @click.stop="downloadStatement(item, 'xlsx', 'monthly')" :disabled="isDownloading">
                                            <v-list-item-title>Download xlsx</v-list-item-title>
                                        </v-list-item>
                                        <v-list-item @click.stop="exportAsExcel(item)" :disabled="isDownloading">
                                            <v-list-item-title>Summary Report</v-list-item-title>
                                        </v-list-item>
                                    </v-list>
                                </v-menu>
                            </div>
                        </div>
                    </td>
                </tr>
            </template>

            <!-- daily expanded data -->
            <template v-slot:expanded-item="{ headers, item }">
                <td colspan="1" style="background-color: #F9FAFB;"></td>
                <td :colspan="headers.length-1">
                    <div class="pa-2" style="background-color: #F9FAFB;">
                        <v-data-table
                            :headers="dailyStatementHeaders"
                            mobile-breakpoint="769"
                            :items="item.daily_statements"
                            class="statements-table expanded-item-statements elevation-1"
                            hide-default-footer
                            v-bind:class="{
                                'no-data-table child-table': (item.daily_statements !== null && item.daily_statements.length !== 'undefined' && item.daily_statements.length === 0)
                            }"
                            :items-per-page="300"
                            fixed-header
                            :expanded.sync="expandedChild"
                            :single-expand="true"
                            item-key="statement_no"
                            v-if="item.statement_no">

                            <template v-slot:item="{ item, expand, isExpanded }">
                                <tr :class="isExpanded ? 'expanded-daily-statement' : ''">
                                    <td class="text-start"> {{ item.statement_no }} </td>
                                    
                                    <td class="text-start">
                                        <button @click.stop="expand(!isExpanded)" flat class="btn-dropdown-month">
                                            <v-icon v-if="isExpanded" color="#1A6D9E">mdi-chevron-up</v-icon>
                                            <v-icon v-if="!isExpanded" color="#1A6D9E">mdi-chevron-down</v-icon>
                                        </button> {{ item.statement_date | format_date }}
                                    </td>

                                    <td class="text-end"> {{ item.amount }} </td>

                                    <td class="text-end">
                                        <div class="actions">
                                            <!-- <button @click.stop="downloadStatement(item, 'daily')" :disabled="isDownloading">                    
                                                <img src="@/assets/icons/download-po-blue.svg" class="mr-1" width="16px" height="16px">
                                            </button> -->

                                            <div class="preview-statement-wrapper mr-2">
                                                <v-menu offset-y bottom left min-width="120px" max-height="90px" content-class="ach-menu-dropdown">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn class="btn-white" color="primary" dark v-bind="attrs" v-on="on">
                                                            <span class="font-medium">Preview</span>
                                                            <v-icon color="#1A6D9E" size="20px">mdi-chevron-down</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <v-list>
                                                        <v-list-item @click.stop="previewStatement(item, 'pdf', 'daily')">
                                                            <v-list-item-title>View PDF</v-list-item-title>
                                                        </v-list-item>
                                                        <v-list-item @click.stop="previewStatement(item, 'xlsx', 'daily')">
                                                            <v-list-item-title>View xlsx</v-list-item-title>
                                                        </v-list-item>
                                                    </v-list>
                                                </v-menu>
                                            </div>

                                            <div class="download-statement-wrapper">
                                                <v-menu offset-y bottom left min-width="120px" max-height="90px" content-class="ach-menu-dropdown">
                                                    <template v-slot:activator="{ on, attrs }">
                                                        <v-btn class="btn-white" color="primary" dark v-bind="attrs" v-on="on">
                                                            <span class="font-medium">Download</span>
                                                            <v-icon color="#1A6D9E" size="20px">mdi-chevron-down</v-icon>
                                                        </v-btn>
                                                    </template>
                                                    <v-list>
                                                        <v-list-item @click.stop="downloadStatement(item, 'pdf', 'daily')" :disabled="isDownloading">
                                                            <v-list-item-title>Download PDF</v-list-item-title>
                                                        </v-list-item>
                                                        <v-list-item @click.stop="downloadStatement(item, 'xlsx', 'daily')" :disabled="isDownloading">
                                                            <v-list-item-title>Download xlsx</v-list-item-title>
                                                        </v-list-item>
                                                    </v-list>
                                                </v-menu>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </template>

                            <!-- inner expanded data - with Container No -->
                            <template v-slot:expanded-item="{ headers, item }">
                                <td style="background-color: #E1F4FF;"></td>
                                <td :colspan="headers.length-1" style="background-color: #E1F4FF;">
                                    <div class="py-2" style="width: calc(100% - 90px);">
                                        <v-data-table 
                                            :headers="dailyDetailsHeaders1"
                                            mobile-breakpoint="769"
                                            :items="item.details"
                                            class="statements-table-inner-content elevation-1"
                                            :class="item.details !== null && item.details.length !== 'undefined' && item.details.length !== 0 ? '' : 'no-data-table'"
                                            hide-default-footer
                                            fixed-header>

                                            <template v-slot:[`item.reference_no`]="{ item }">
                                                <a :href="`/shipment/${item.shipment_id}`" target="_blank"
                                                    style="color: #0171A1; text-decoration: none;">
                                                    {{ item.reference_no }}
                                                </a>
                                            </template>

                                            <template v-slot:[`item.container_list`]="{ item }">
                                                <div v-if="item.container_list.length > 0">
                                                    <div v-if="(item.container_list.length <= 2)">
                                                        {{ getJoinData(item.container_list) }}
                                                    </div>
                                                    
                                                    <div v-else v-for="(cont, i) in item.container_list" :key="i">
                                                        <p v-if="(i <= 1)" class="mb-0">
                                                            {{ cont }}<br v-if="i === 0">

                                                            <v-menu
                                                                :close-on-content-click="false"
                                                                :nudge-width="113"
                                                                offset-y 
                                                                right bottom
                                                                v-if="i === 1"
                                                                content-class="ach-menu-content-inner">

                                                                <template v-slot:activator="{ on, attrs }">
                                                                    <v-icon v-bind="attrs" v-on="on" size="22" class="ml-1 down-icon">
                                                                        mdi-chevron-down
                                                                    </v-icon>
                                                                </template>
                                                                
                                                                <div class="ach-containers-popover">
                                                                    <p class="mb-0 ach-popover-title">Containers</p>
                                                                    <div class="ach-popover-body">
                                                                        <p v-for="(contInner, i) in item.container_list" 
                                                                            :key="i" 
                                                                            class="mb-0">
                                                                            {{ contInner }}
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </v-menu>
                                                        </p>
                                                    </div>
                                                </div>
                                                <div v-else>--</div>
                                            </template>

                                            <template v-slot:[`item.pos_numbers`]="{ item }">
                                                <div class="ach-menu-content-inner-po" @mouseenter="detectLineClamp(item, item.entry_no, 'pos')">
                                                    <CalendarTooltip :data="item" isFor="event-menu-content-inner ach" :isShowTooltip.sync="isTruncatedText" :nudge="false">
                                                        <template slot="tooltip-main-data" slot-scope="{ on, attrs }">
                                                            <div v-on="on" v-bind="attrs" :id="`td-ach-pocontent-${item.entry_no}`" class="ach-pos-field">
                                                                {{ getJoinData(item.pos_numbers) }}
                                                            </div>
                                                        </template>

                                                        <template slot="tooltip-data">
                                                            <div class="ach-containers-popover">
                                                                <p class="font-semi-bold ach-popover-title mb-0">POs:</p>
                                                                <div class="ach-popover-body">
                                                                    <p v-for="(poInner, i) in item.pos_numbers" 
                                                                        :key="i" 
                                                                        class="mb-0"> {{ poInner }}
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </template>
                                                    </CalendarTooltip>
                                                </div>
                                            </template>
                                        </v-data-table>
                                    </div>
                                </td>
                            </template> 
                        </v-data-table>

                        <v-data-table 
                            :headers="dailyDetailsHeaders2"
                            mobile-breakpoint="769"
                            :items="item.daily_statements"
                            class="statements-table expanded-item-statements elevation-1"
                            :class="item.daily_statements !== null && item.daily_statements.length !== 'undefined' && item.daily_statements.length !== 0 ? '' : 'no-data-table child-table'"
                            hide-default-footer
                            :items-per-page="300"
                            fixed-header
                            v-else>

                            <template v-slot:[`item.reference_no`]="{ item }">
                                <!-- <span style="color: #253041;">{{ item.details[index].reference_no }}</span> -->
                                <a :href="`/shipment/${findReferenceNo(item, 'id')}`" target="_blank"
                                    style="color: #0171A1; text-decoration: none;" v-if="findReferenceNo(item, 'id') !== '--'">
                                    {{ findReferenceNo(item, 'ref') }}
                                </a>
                                <span v-else>--</span>
                            </template> 

                            <template v-slot:[`item.container_list`]="{ item }">
                                <div v-if="(findContainersInDetails(item).length <= 2)">
                                    {{ getJoinData(findContainersInDetails(item)) }}
                                </div>
                                
                                <div v-else v-for="(cont, i) in findContainersInDetails(item)" :key="i">
                                    <p v-if="(i <= 1)" class="mb-0">
                                        {{ cont }}<br v-if="i === 0">

                                        <v-menu
                                            :close-on-content-click="false"
                                            :nudge-width="113"
                                            offset-y 
                                            right bottom
                                            v-if="i === 1"
                                            content-class="ach-menu-content-inner">

                                            <template v-slot:activator="{ on, attrs }">
                                                <v-icon v-bind="attrs" v-on="on" size="22" class="ml-1 down-icon">
                                                    mdi-chevron-down
                                                </v-icon>
                                            </template>
                                            
                                            <div class="ach-containers-popover">
                                                <p class="mb-0 ach-popover-title">Containers</p>
                                                <div class="ach-popover-body">
                                                    <p v-for="(contInner, i) in findContainersInDetails(item)" 
                                                        :key="i" 
                                                        class="mb-0"> {{ contInner }}
                                                    </p>
                                                </div>
                                            </div>
                                        </v-menu>
                                    </p>
                                </div>
                            </template>

                            <template v-slot:[`item.pos_numbers`]="{ item }">
                                <div v-if="findPOSInDetails(item) !== ''">
                                    <div class="ach-menu-content-inner-po" @mouseenter="detectLineClamp(findPOSInDetails(item), findPOSInDetails(item).entry_no, 'pos')">
                                        <CalendarTooltip :data="item" isFor="event-menu-content-inner ach" :isShowTooltip.sync="isTruncatedText" :nudge="false">
                                            <template slot="tooltip-main-data" slot-scope="{ on, attrs }">
                                                <div v-on="on" v-bind="attrs" :id="`td-ach-pocontent-${findPOSInDetails(item).entry_no}`" class="ach-pos-field">
                                                    {{ getJoinData(findPOSInDetails(item).pos_numbers) }}
                                                </div>
                                            </template>

                                            <template slot="tooltip-data">
                                                <div class="ach-containers-popover">
                                                    <p class="font-semi-bold ach-popover-title mb-0">POs:</p>
                                                    <div class="ach-popover-body">
                                                        <p v-for="(poInner, i) in findPOSInDetails(item).pos_numbers" 
                                                            :key="i" 
                                                            class="mb-0"> {{ poInner }}
                                                        </p>
                                                    </div>
                                                </div>
                                            </template>
                                        </CalendarTooltip>
                                    </div>
                                </div>
                                <div v-else>--</div>
                            </template>

                            <template v-slot:[`item.actions`]="{ item }">
                                <div class="actions">
                                    <div class="preview-statement-wrapper mr-2">
                                        <v-menu offset-y bottom left min-width="120px" max-height="90px" content-class="ach-menu-dropdown">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn class="btn-white" color="primary" dark v-bind="attrs" v-on="on">
                                                    <span class="font-medium">Preview</span>
                                                    <v-icon color="#1A6D9E" size="20px">mdi-chevron-down</v-icon>
                                                </v-btn>
                                            </template>
                                            <v-list>
                                                <v-list-item @click.stop="previewStatement(item, 'pdf', 'daily')">
                                                    <v-list-item-title>View PDF</v-list-item-title>
                                                </v-list-item>
                                                <v-list-item @click.stop="previewStatement(item, 'xlsx', 'daily')">
                                                    <v-list-item-title>View xlsx</v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                    </div>

                                    <div class="download-statement-wrapper">
                                        <v-menu offset-y bottom left min-width="120px" max-height="90px" content-class="ach-menu-dropdown">
                                            <template v-slot:activator="{ on, attrs }">
                                                <v-btn class="btn-white" color="primary" dark v-bind="attrs" v-on="on">
                                                    <span class="font-medium">Download</span>
                                                    <v-icon color="#1A6D9E" size="20px">mdi-chevron-down</v-icon>
                                                </v-btn>
                                            </template>
                                            <v-list>
                                                <v-list-item @click.stop="downloadStatement(item, 'pdf', 'daily')" :disabled="isDownloading">
                                                    <v-list-item-title>Download PDF</v-list-item-title>
                                                </v-list-item>
                                                <v-list-item @click.stop="downloadStatement(item, 'xlsx', 'daily')" :disabled="isDownloading">
                                                    <v-list-item-title>Download xlsx</v-list-item-title>
                                                </v-list-item>
                                            </v-list>
                                        </v-menu>
                                    </div>
                                </div>
                            </template>
                        </v-data-table>
                    </div>
                </td>
            </template>

            <template v-slot:no-data>
                <div class="no-data-preloader mt-5" v-if="getAchStatementsLoading">
                    <v-progress-circular
                        :size="30"
                        color="#1A6D9E"
                        indeterminate
                        v-if="getAchStatementsLoading">
                    </v-progress-circular>
                </div>

                <div class="no-data-wrapper" 
                    v-if="!getAchStatementsLoading && statements !== null && statements.length !== 'undefined' && statements.length == 0">
                    <div class="no-data-heading">
                        <div class="shifl-custom-logo">
                            <img src="../../../assets/icons/shifl-custom-blue.svg" width="125px" height="48px" alt="">
                        </div>

                        <div class="no-data-content">
                            <h3> Sign up for ACH </h3>
                            <p>
                                For effortless payment of custom duties and fees, 
                                Please fill out the CBP ACH form below and share with Shifl Customs or your account rep.
                            </p> 

                            <div class="no-data-buttons">
                                <button class="btn-blue mr-2" @click="downloadACHForm" :disabled="isDownloading">
                                    <img src="../../../assets/icons/download-white.svg" 
                                        width="16px" height="16px" alt="download" class="mr-1">                                    
                                    {{ isDownloading ? 'Downloading...' : 'ACH Form' }}
                                </button>

                                <button class="btn-white" style="font-family: 'Inter-Medium', sans-serif !important;"
                                    @click="showLearnMore">
                                    Learn More
                                    <img src="../../../assets/icons/play-video-blue.svg" 
                                        width="18px" height="18px" alt="video icon" class="ml-2">
                                </button>
                            </div>
                        </div>                     
                    </div>

                    <div class="ach-setup-details">
                        <div class="d-flex align-center">
                            Already set up with CBP? Please share your PUN 
                            <div class="d-flex align-center ml-1">
                                <v-tooltip bottom nudge-right="100" content-class="tooltip-no-data-wrapper tooltip-bottom">
                                    <template v-slot:activator="{ on }">
                                        <span v-on="on" class="d-flex align-center ml-1">                                            
                                            <img src="../../../assets/icons/icon-filled-gray.svg" alt="info" width="20px" height="20px">
                                        </span>
                                    </template>
                                    <div style="padding: 8px 0; line-height: 16px;">
                                        <p class="mb-2">PUN</p>
                                        <p class="mb-0 text-center" style="line-height: 18px; font-size: 12px;">
                                            Payer Unit Number (PUN) is a unique number assigned by CBP to the filer as a security measure. 
                                        </p>
                                    </div>
                                </v-tooltip>                                
                            </div>
                        </div>
                        with <span class="email-customs">customs@shifl.com</span>
                    </div> 
                </div>
            </template>
        </v-data-table>

        <PMSDatesDialog 
            :dialogData.sync="isShowStatementDialog" />

        <PaginationComponent 
            :totalPage.sync="getTotalPage"
            :currentPage.sync="getCurrentPage"
            @handlePageChange="handlePageChange"
            :isMobile="false" />

        <ViewStatementsDialog
            :dialogData.sync="previewDataDialog"
            :item="previewData"
            @close="closePreview('previewDataDialog')"
            @downloadStatement="downloadStatement"
            :isMobile="false"
            @exportAsExcel="exportAsExcel" />

        <ConfirmDialog :dialogData.sync="isShowLearnMoreDialog" customWidth="1100px" className="learn-more-dialog">
            <template v-slot:dialog_icon>
                <div class="header-wrapper-close">
                    <h2> Learn More on ACH </h2>

                    <v-icon @click="closeLearnMore">
                        mdi-close
                    </v-icon>
                </div>
            </template>

            <template v-slot:dialog_content>
                <div style="
                    position: relative; width: 100%; height: 0; padding-top: 56.2500%;
                    padding-bottom: 0; box-shadow: 0 2px 8px 0 rgba(63,69,81,0.16); 
                    margin-top: 0; margin-bottom: 24px; overflow: hidden;
                    border-radius: 8px; will-change: transform;">
                    <iframe 
                        style="position: absolute; width: 100%; height: 100%; top: 0; left: 0; border: none; padding: 0;margin: 0;"                        
                        src="https://www.canva.com/design/DAFvuRsLm5M/view?embed"
                        allowfullscreen="allowfullscreen" 
                        allow="fullscreen">
                    </iframe>
                </div>

                <a style="color: #1A6D9E;" class="font-regular"
                    href="https://www.canva.com/design/DAFvuRsLm5M/view?utm_content=DAFvuRsLm5M&utm_campaign=designshare&utm_medium=embeds&utm_source=link" 
                    target="_blank" 
                    rel="noopener">
                    ACH Statement Presentation</a> <span style="color: #253041;" class="font-regular">by Shifl</span>
            </template>
        </ConfirmDialog> 
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
// import Search from '../../../components/Search.vue';
import PaginationComponent from '../../../components/PaginationComponent/PaginationComponent.vue';
import moment from 'moment';
import PMSDatesDialog from '../../AchStatementComponents/PMSDatesDialog.vue';
import globalMethods from '../../../utils/globalMethods';
const baseURL = process.env.VUE_APP_BASE_URL;
import axios from "axios";
import _ from "lodash";
import ConfirmDialog from '../../Dialog/GlobalDialog/ConfirmDialog.vue';
import ViewStatementsDialog from "../../AchStatementComponents/ViewStatementsDialog.vue"
import CalendarTooltip from '../Shipments/Tabs/Views/components/CalendarTooltip.vue';

import { utils, writeFileXLSX } from 'xlsx';

export default {
    name: "StatementDesktopTable",
    props: ['items', 'isMobile'],
	components: {
        PMSDatesDialog,
		// Search,
		PaginationComponent,
        ConfirmDialog,
        ViewStatementsDialog,
        CalendarTooltip,
	},
    data: () => ({
		page: 1,
        pageCount: 0,
        itemsPerPage: 20,
        headers: [
            {
                text: "PMS Month",
				align: "start",
                value: "pms_day",
                sortable: false,
                width: "12%", 
                fixed: false
            },
            { text: 'Entry Month', value: 'data-table-expand', width: "12%", },            
            {
                text: "Statement Number",
				align: "start",
                value: "statement_no",
                sortable: false,
                width: "16%", 
                fixed: false
            },
            {
                text: "Periodic Statement Date",
				align: "start",
                value: "process_date",
                sortable: false,
                width: "16%", 
                fixed: true
            },
            // {
            //     text: "",
            //     align: "start",
            //     value: "final_statement_date",
            //     sortable: false,
            //     width: "19%", 
            //     fixed: true
            // },
			{
                text: "Amount",
				align: "end",
                value: "amount",
                sortable: false,
                width: "18%", 
                fixed: true,
                class: "amount-header"
            },
			{
                text: "",
				align: "center",
                value: "actions",
                sortable: false,
                width: "18%", 
                fixed: true
            },
        ],
		search: '',
		expanded: [],
        expandedChild: [],
        dailyStatementHeaders: [
            {
                text: "Daily Statement",
                align: "start",
                sortable: false,
                value: "statement_no",
                width: "14%", 
                fixed: false
            },
            {
                text: "Daily Statement Date",
                align: "start",
                value: "statement_date",
                sortable: false,
                width: "28%", 
                fixed: true
            },
            {
                text: "Amount",
				align: "end",
                value: "amount",
                sortable: false,
                width: "20%", 
                fixed: true
            },
            {
                text: "",
				align: "start pl-0",
                value: "actions",
                sortable: false,
                width: "20%", 
                fixed: true
            },
        ],
        dailyDetailsHeaders1: [
            {
                text: "Entry No",
                align: "start",
                sortable: false,
                value: "entry_no",
                width: "13%", 
                fixed: true
            },
            {
                text: "Reference",
                align: "start",
                sortable: false,
                value: "reference_no",
                width: "9%", 
                fixed: true
            },
            {
                text: "POs",
                align: "start",
                sortable: false,
                value: "pos_numbers",
                width: "15%", 
                fixed: true,
            },
            {
                text: "Container No",
                align: "start",
                sortable: false,
                value: "container_list",
                width: "14%", 
                fixed: true,
                class: ""
            },
            {
                text: "Amount",
				align: "end",
                value: "amount",
                sortable: false,
                width: "10%", 
                fixed: true
            },
        ],
        dailyDetailsHeaders2: [
            {
                text: "Entry No",
                align: "start",
                sortable: false,
                value: "entry_no",
                width: "12%", 
                fixed: true
            },
            {
                text: "Reference",
                align: "start",
                sortable: false,
                value: "reference_no",
                width: "8%", 
                fixed: true
            },
            {
                text: "POs",
                align: "start",
                sortable: false,
                value: "pos_numbers",
                width: "14%", 
                fixed: true,
            },
            {
                text: "Container No",
                align: "start",
                sortable: false,
                value: "container_list",
                width: "14%", 
                fixed: true,
                class: ""
            },
            {
                text: "Amount",
				align: "end",
                value: "amount",
                sortable: false,
                width: "10%", 
                fixed: true
            },
            {
                text: "",
				align: "start pl-0",
                value: "actions",
                sortable: false,
                width: "19%", 
                fixed: true,
            },
        ],
        isShowStatementDialog: false,
        nextPageACHLoading: false,
        request: null,
        isDownloading: false,
        isShowLearnMoreDialog: false,
        previewData: {
            item: null,
            type: null,
            isFor: null
        },
        previewDataDialog: false,
        menu: false,
        isTruncatedText: false,
        fileList: [],
        previewDataDialogSummary: false,
    }),
    computed: {
        ...mapGetters({
            getAchStatements: 'statements/getAchStatements',
            getUser: 'getUser',
            getAchStatementsLoading: 'statements/getAchStatementsLoading',
            getPeriodicStatements: "statements/getPeriodicStatements",
        }),
        formTitle() {
            return this.editedIndex === -1 ? "Add Statement" : "Edit Statement";
        },
        statements: {
            get() {
                return this.items;
            }, 
            set(value) {
                this.$emit('items', value);
            }
        },
        getTotalPage() {
            let pages = 1;
            if (typeof this.getAchStatements !== 'undefined' && this.getAchStatements !== null) {
                if (typeof this.getAchStatements.last_page !== 'undefined') {
                    pages = this.getAchStatements.last_page;
                }
            }
            return pages;
        },
        getCurrentPage: {
            get() {
                let currentPage = 1;
                if (typeof this.getAchStatements !== 'undefined' && this.getAchStatements !== null) {
                    if (typeof this.getAchStatements.current_page !== 'undefined') {
                        currentPage = this.getAchStatements.current_page;
                    }
                }
				return currentPage;
            },
            set() {
                return {}
            }
        }, 
        getCurrentMonthYear() {
            return moment().format("MMM YYYY");
        }
    },
    watch: {},
    created() {},
    methods: {
        ...mapActions({
            fetchAchStatements: 'statements/fetchAchStatements',            
            fetchPeriodicStatements: 'statements/fetchPeriodicStatements'
        }),
        ...globalMethods,
        showReportSchedule() {
            this.$parent.showReportSchedule();
        },
        showCBPDialog() {
            this.isShowStatementDialog = true;
            if (this.getPeriodicStatements.length === 0) {
                this.fetchPeriodicStatements();
            }
        },
        async handlePageChange(page) {
			if (page !== null) {
				let storePagination = this.$store.state.statements.statements;
				try {
					if (storePagination.old_page !== page) {
						this.nextPageACHLoading = true;
						await this.fetchAchStatements(page);
						storePagination.old_page = page;
						this.nextPageACHLoading = false;
					}
				} catch(e) {
					this.notificationError(e);
					console.log(e);
				}
			}
        },
        getJoinData(data) {
            if (typeof data !== "undefined" && data.length > 0) {
                return data.join(", ");
            } else return "--"
        },
        findPOSInDetails(item) {
            if (item.details !== null && item.details.length > 0) {
                let entry = item.entry_no;

                let findEntryPos = _.find(item.details, e => (e.entry_no === entry));
                if (findEntryPos !== undefined) {
                    return findEntryPos;
                } else {
                    return "";
                }
            } else return ""
        },
        findContainersInDetails(item) {
            if (item.details !== null && item.details.length > 0) {
                let entry = item.entry_no;

                let findEntryPos = _.find(item.details, e => (e.entry_no === entry));
                if (findEntryPos !== undefined) {
                    return findEntryPos.container_list;
                } else {
                    return [];
                }
            }
        },
        goToDetailsPage() { },
        async downloadStatement(item, docType, type) {
            if (item.statement_no !== null) {
                this.isDownloading = true;
                // cancel
                this.cancel();
                let axiosSource = axios.CancelToken.source();
                this.request = { cancel: axiosSource.cancel };
                let url = "";

                if (docType === "pdf") {
                    url = type === "monthly" 
                        // ? `${baseURL}/ach-monthly-downlod-pdf/${item.statement_no}?file=final`
                        ? `${baseURL}/ach-monthly-downlod-pdf/${item.statement_no}`
                        : `${baseURL}/ach-daily-downlod-pdf/${item.statement_no}`
                } else {
                    url = type === "monthly" 
                        ? `${baseURL}/ach-download-final/${item.statement_no}` 
                        : `${baseURL}/ach-download-daily/${item.statement_no}` 
                }

                let passedData = {
                    method: "get",
                    url,
                    responseType: 'blob',
                    cancelToken: axiosSource.token,
                }

                axios(passedData)
                .then((res) => {
                    var url;
                    if (docType === "pdf") {
                        url = window.URL.createObjectURL(
                            new Blob([res.data], { type: "application/pdf", })
                        );
                    } else {
                        url = window.URL.createObjectURL(
                            new Blob([res.data], { type: "application/vnd.ms-excel", })
                        );
                    }
                    let name = `CBP_${item.statement_no}.${docType !== "pdf" ? 'xls' : 'pdf'}`
                    var a = document.createElement("a");
                    a.href = url;
                    a.setAttribute("download", name);
                    document.body.appendChild(a);
                    a.click();
                    a.remove();
                    this.notificationError("Statement has been downloaded.");
                    this.isDownloading = false;
                })
                .catch((e) => {
                    this.isDownloading = false;
                    if (e.message !== undefined && e.message === "cancel_previous_request") return;
                    else this.notificationError("Something went wrong, the file might not exist. Please try again later.");
                })
            } else {
                this.notificationError("Statement number is null.");
                this.isDownloading = false;
            }
        },
        cancel() {
			if (this.request) this.request.cancel("cancel_previous_request");
		},
        async downloadACHForm() {
            this.isDownloading = true;
            let passedData = {
                method: "get",
                url: `${baseURL}/ach-form-download`,
                responseType: 'blob',
            }

            await axios(passedData)
            .then((res) => {
                var url = window.URL.createObjectURL(
                    new Blob([res.data], {
                        type: "application/pdf",
                    })
                );
                let name = `CBP_ACH_Form.pdf`
                var a = document.createElement("a");
                a.href = url;
                a.setAttribute("download", name);
                document.body.appendChild(a);
                a.click();
                a.remove();
                URL.revokeObjectURL(url);
                this.notificationError("ACH Form has been downloaded.");
                this.isDownloading = false;
            })
            .catch(() => {
                this.notificationError("Something went wrong, please try again later.");
                this.isDownloading = false;
            });
        },
        showLearnMore() {
            this.isShowLearnMoreDialog = true;
        },
        closeLearnMore() {
            this.isShowLearnMoreDialog = false;
        },
        formatDate(item, isFor) {
            // if (item !== null) {
            //     if (item.process_date !== null) {
            //         return moment(item.process_date).format("MM/DD/YYYY");
            //     } else if (typeof item.expected_date !== "undefined") {
            //         if (item.expected_date !== null) {
            //             let date = null;
            //             date = moment(item.expected_date).format("MM/DD/YYYY");
            //             // return `Expected on ${date}`
            //             return `Issue Date ${date}`
            //         } else {
            //             return '--'
            //         }
            //     }
            // }

            if (item.statement_no === null) {
                if (isFor === "issue") {
                    if (typeof item.expected_date !== "undefined" && item.expected_date !== null) {
                        return moment(item.expected_date).format("MM/DD/YYYY");
                    }
                } else if (isFor === "final") {
                    if (typeof item.final_statement !== "undefined" && item.final_statement !== null) {
                        return moment(item.final_statement).format("MM/DD/YYYY");
                    }
                }
            } else {
                if (item.process_date !== null) {
                    return moment(item.process_date).format("MM/DD/YYYY");
                }
            }

            return "--";
        },
        checkDateIfPassedTodaysDate(date) {
            let currentDate = moment().format("MM/DD/YYYY");
            let processDate = moment(date).format("MM/DD/YYYY");            
            if (moment(currentDate).isAfter(processDate)) {
                return true;
            } else return false;
        },
        formatAllDate(date) {
            return date !== null ? moment(date).format("MM/DD/YYYY") : "--"
        },
        previewStatement(item, type, isFor) {
            this.previewData = {
                item, type, isFor
            }
            this.previewDataDialog = true;
        },
        closePreview(field) {
            this.previewData = {
                item: null, type: null, isFor: null
            }
            this[field] = false;
        },
        detectLineClamp(item, id) {
            let element = null;
            element = document.querySelector(`#td-ach-pocontent-${id}`);
            if (item !== null && element !== null) {
                let elementValues = this.isEllipsisActive(element, "ellipsis");
                this.isTruncatedText = elementValues;
            }
        },
        isEllipsisActive(element, type) {
            if (type === "line") {
                if (element.clientHeight < element.scrollHeight) return true;                
            } else {
                if (element.clientWidth < element.scrollWidth) {
                    var style = element.currentStyle || window.getComputedStyle(element);
                    return style.textOverflow === 'ellipsis'
                }
            }
            return false;
        },
        findReferenceNo(item, isFor) {
            if (item.details !== null && item.details.length > 0) {
                let entry = item.entry_no;

                let findEntry = _.find(item.details, e => (e.entry_no === entry));
                if (findEntry !== undefined) {
                    return isFor === 'ref' ? findEntry.reference_no : findEntry.shipment_id;
                } else {
                    return "--";
                }
            }
        },
        exportAsExcel(item, from) {
            let processedData = from !== 'view' ? this.processDataToJson(item) : item.processedData;
            let month = from !== 'view' ? moment(item.month_year) : moment(item.month);
            let currentMonth = moment(month).format("MMMM");

            if (from === 'view' && processedData.length > 0) {
                processedData.shift();
            }

            let name = `${currentMonth} Monthly Statement.xlsx`;            
            const ws = utils.json_to_sheet(processedData);
            ws['!cols'] = this.fitToColumn(processedData);
            const wb = utils.book_new();
            utils.book_append_sheet(wb, ws, "Data");
            writeFileXLSX(wb, name);
        },
        previewSummaryReport(item) {
            let processedData = this.processDataToJson(item);
            let month = moment(item.month_year);
            let currentMonth = moment(month).format("MMMM");

            let isFor = "monthly";
            let type = "summary"
            this.previewData = {
                processedData, type, isFor, title: currentMonth, month: moment(month).format("MMMM YYYY")
            }
            // this.previewDataDialogSummary = true;
            this.previewDataDialog = true;
        },
        processDataToJson(data) {
            if (data !== null) {
                let jsonArray = [];
                data.daily_statements.map(v => {
                    if (v.details.length > 0) {
                        v.details.map((cd) => {
                            let data = {
                                // "Daily Statement": i === 0 ? v.statement_no : "",
                                "Daily Statement": v.statement_no,
                                "Entry No": cd.entry_no,
                                "Reference": cd.reference_no,
                                "POs": cd.pos_numbers.length > 0 ? cd.pos_numbers.join(", ") : "",
                                "Container No": cd.container_list.length > 0 ? cd.container_list.join(", ") : "",
                                "Amount": cd.amount
                            }
                            jsonArray.push(data)
                        })
                    }
                });            
                return jsonArray;
            }
        },
        fitToColumn(data) {
            const columnWidths = [];    
            for (const property in data[0]) {
                columnWidths.push({        
                    wch: Math.max(        
                        property ? property.toString().length : 0,         
                        ...data.map(obj =>           
                            obj[property] ? obj[property].toString().length : 0         
                    ))      
                });   
            }   
            return columnWidths; 
        }
    },
    filters: {
        format_date(value) {
            if(!value) {
                return '--';
            }
            return moment(value).format("MM/DD/YYYY");
            // return moment(value).format('ll');
        },
        current(value) {
            if(!value) {
                return moment().format('MMM')+' '+moment().get('year');
            }
            return value;
        }
    },
    async mounted() {
        //set current page
        this.$store.dispatch("page/setPage", "statements");
    },
    updated() {}
};
</script>

<style lang="scss">
.ach-pos-field {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}
.ach-menu-content-inner {
    max-width: 200px !important;
    min-width: 100px !important;
    .ach-containers-popover {
        min-height: 50px;
        background-color: #fff;

        .ach-popover-title {
            color: $shifl-neutral-n90;
            font-size: 12px;
            font-family: "Inter-SemiBold", sans-serif !important;
            padding: 6px 12px;
            background-color: $shifl-neutral-n10;
        }

        .ach-popover-body {
            p {
                padding: 4px 12px;
                border-bottom: 1px solid #EBF2F5;
                font-size: 12px;
                font-family: "Inter-Medium", sans-serif !important;
                word-wrap: break-word;
            }
        }
    }
}

.v-tooltip__content {
    &.calendar-cards-tooltip.event-menu-content-inner.ach {
        padding: 0 !important;
        border-radius: 4px;
        max-width: 200px !important;
        min-width: 100px !important;
        width: unset !important;

        .ach-containers-popover {
            min-height: 50px;
            background-color: #fff;
            border-radius: 4px;

            .ach-popover-title {
                color: $shifl-neutral-n90;
                font-size: 12px;
                font-family: "Inter-SemiBold", sans-serif !important;
                padding: 6px 12px;
                background-color: $shifl-neutral-n10;
                border-top-left-radius: 4px;
                border-top-right-radius: 4px;
            }

            .ach-popover-body {
                p {
                    padding: 4px 12px;
                    border-bottom: 1px solid #EBF2F5;
                    font-size: 12px;
                    font-family: "Inter-Medium", sans-serif !important;
                    word-wrap: break-word;
                }
            }
        }
    }
}
</style>
<!-- @format -->

<template>
	<div class="milestone-shipments-wrapper-v2">
		<div class="milestone-ordering-details">
			<div class="milestone-title-status">
				<h2 class="milestone-name">
					Milestones
					<span class="shipment-status" v-if="this.getDetails.tracking_status" >{{
						this.getDetails.tracking_status
					}}</span>
				</h2>

				<!-- <img
					src="../../../assets/icons/sort-descending.svg"
					class="order-icon"
					@click="ascending = false"
					v-if="ascending"
				/>
				<img
					src="../../../assets/icons/sort-ascending.svg"
					class="order-icon"
					@click="ascending = true"
					v-else
				/> -->
			</div>
			<p class="mb-0 milestone-note">
				The displayed timings are adjusted to users local time zone
			</p>
		</div>
		<v-stepper v-model="e13" vertical>
			<div class="preloader" v-if="loading">
				<v-progress-circular :size="40" color="#1A6D9E" indeterminate>
				</v-progress-circular>
			</div>

			<div
				v-if="!loading && milestonesData.length > 0"
				class="milestone-content"
			>
				<div
					v-for="(item, index) in milestonesData"
					:key="index"
					class="milestone-items"
				>
					<v-stepper-step
						class="custom-vue-stepper-v2"
						:class="`d-flex ${item.event} ${getTerminalHoldsClass(item)}`"
						:step="index + 1"
						color="#1A6D9E"
						:complete="item.highlighted"
						v-if="item.display"
					>
						<span
							v-if="
								item.event !== 'released_at_terminal' &&
									item.event !== 'eta_updates'
							"
						>
							{{ item.title }}
						</span>

						<span
							v-if="item.event == 'eta_updates'"
							style="color: #EB9B26 !important;"
						>
							{{ item.title }}
						</span>

						<div
							:class="getStatus(item)"
							class="released-event"
							v-if="item.event == 'released_at_terminal'"
						>
							<span>{{ item.title }}</span>

							<span
								class="status no"
								v-if="!item.isReleased && item.isReleased !== undefined"
							>
								<img
									src="../../../assets/icons/released-no.svg"
									width="10px"
									height="10px"
									alt=""
									class="mr-1"
								/>
								No
							</span>

							<span
								class="status yes"
								v-if="item.isReleased && item.isReleased !== undefined"
							>
								<img
									src="../../../assets/icons/checkMark.png"
									alt=""
									width="12px"
									height="12px"
									class="mr-1"
								/>
								Yes
							</span>
						</div>
					</v-stepper-step>

					<v-stepper-content
						:step="index + 1"
						v-if="
							item.event === 'booking_created' ||
								(item.event === 'booking_confirmed' && item.date !== null) ||
								item.event === 'departed'
						"
					>
						<small>{{ convertDate(item.title, item.created_at) }} </small>
					</v-stepper-content>

					<v-stepper-content
						:step="index + 1"
						v-if="
							typeof item.data !== 'undefined' &&
								item.event !== 'released_at_terminal' &&
								item.event !== 'eta_updates'
						"
					>
						<div v-for="(data, index) in item.data" :key="index">
							<div class="milestone-container-wrapper">
								<div class="milestone-container-num">
									<p>{{ data.title }}</p>
								</div>

								<div class="milestone-container-dates">
									<span>
										<small v-if="data.updated_at">{{
											convertDate(item.title, data.updated_at)
										}}</small>
										<small
											v-if="
												item.event == 'last_free_day' &&
													(data.updated_at == null || data.updated_at == '')
											"
											class="pr-1"
											>N/A</small
										>
									</span>
									<small
										class="ml-1"
										v-if="isContainerDateComplete(data.updated_at)"
									>
										<img
											class="completed-icon"
											src="../../../assets/icons/checkMark.png"
											width="10px"
											height="10px"
											v-if="item.event !== 'last_free_day'"
										/>
									</small>
								</div>
							</div>
						</div>
					</v-stepper-content>

					<v-stepper-content
						v-if="item.event === 'released_at_terminal'"
						:step="index + 1"
					>
						<div
							v-for="(newItem, index) in item.data"
							:key="index"
							class="milestone-container-wrapper released"
						>
							<div class="milestone-container-num">
								<p>{{ Object.keys(newItem)[0] }}</p>
							</div>

							<div
								class="milestone-container-status"
								v-if="newItem && Object.values(newItem)[0].length > 0"
							>
								<div
									class="status"
									v-for="(status, secondIndex) in Object.values(newItem)[0]"
									:key="secondIndex"
								>
									<span>{{ status.title }}</span>
								</div>
							</div>

							<!-- IF THERE ARE DEMURRAGE FEES -->
							<!-- <div
								class="milestone-container-status"
								v-if="
									typeof newItem.fees !== 'undefined' &&
										newItem.fees !== null &&
										newItem.fees.length !== 'undefined' &&
										newItem.fees.length > 0
								"
							>
								<div
									class="status"
									v-for="(fee, index) in newItem.fees"
									:key="index"
								>
									<span>{{ `${fee.type} Hold` }}</span>
								</div>
							</div> -->

							<!-- If no holds -->

							<!-- <div
								class="milestone-container-status"
								v-if="
									typeof newItem.data !== 'undefined' &&
										newItem.data !== null &&
										newItem.data.length !== 'undefined' &&
										newItem.data.length == 0
								"
							>
								<span class="status yes" v-if="newItem.releasedEvent">
									<img
										src="../../../assets/icons/checkMark.png"
										alt=""
										width="12px"
										height="12px"
										class="mr-1"
									/>
									Yes
								</span>

								<span class="status no" v-if="!newItem.releasedEvent">
									<img
										src="../../../assets/icons/released-no.svg"
										alt=""
										width="12px"
										height="12px"
										class="mr-1"
									/>
									No
								</span>
							</div> -->
						</div>
					</v-stepper-content>

					<v-stepper-content
						v-if="item.event === 'eta_updates'"
						:step="index + 1"
					>
						<div
							class="milestone-container-wrapper released"
							v-if="item.data && item.data.length > 0"
						>
							<div class="milestone-container-num">
								<p>{{ item.data[0].title }}</p>
							</div>

							<div>
								<small>{{
									convertDate(item.data[0].title, item.data[0].updated_at)
								}}</small>
							</div>
							<div class="eta-expansion-content" v-if="item.data.length > 1">
								<v-expansion-panels accordion v-model="panel" flat>
									<v-expansion-panel>
										<v-expansion-panel-header>
											<template v-slot:default="{ open }">
												<span class="font-medium">
													({{ item.data.length - 1 }})
													{{ open ? "Hide ETA" : "View More ETA" }}
												</span>
											</template>
										</v-expansion-panel-header>

										<v-expansion-panel-content>
											<div
												class="other-eta-items"
												v-for="(newItem, i) in item.data.slice(1)"
												:key="i"
											>
												<div class="milestone-container-num">
													<p>
														{{ newItem.title }}
													</p>
												</div>
												<div class="pt-1">
													<small>{{
														convertDate(null, newItem.updated_at)
													}}</small>
												</div>
											</div>
										</v-expansion-panel-content>
									</v-expansion-panel>
								</v-expansion-panels>
							</div>
						</div>
					</v-stepper-content>

					<v-stepper-content v-if="item.data == null" :step="index + 1">
						<span> </span>
					</v-stepper-content>
				</div>
			</div>
		</v-stepper>
	</div>
</template>

<script>
import moment from "moment";
// import _ from "lodash";

export default {
	name: "MilestonesV2",
	props: ["getDetails", "status", "loading"],
	components: {},
	data: () => ({
		e13: 0,
		containersLists: [],
		counter: 0,
		panel: null,
		ascending: false,
	}),
	methods: {
		convertDate(name, date) {
			let dateVal = null;

			if (name !== "Last Free Day") {
				if (date) {
					dateVal = moment.utc(date).format("hh:mmA, MMM DD, YYYY");
				}
			} else {
				if (date) {
					dateVal = moment.utc(date).format("MMM DD, YYYY");
				}
			}

			return dateVal;
		},
		isContainerDateComplete(date) {
			if (date !== null) {
				if (moment(date).format("x") < moment().format("x")) {
					return true;
				} else {
					return false;
				}
			} else {
				return false;
			}
		},
		getStatus(item) {
			if (item !== "undefined") {
				if (item.event == "released_at_terminal") {
					if (typeof item.isReleased !== "undefined") {
						if (item.isReleased == false) {
							return "both-f no";
						}

						if (item.isReleased) {
							return "yes";
						}
					}
				}
			}
		},
		getTerminalHoldsClass(item) {
			if (item !== null && item.event === "released_at_terminal") {
				if (typeof item.isReleased !== "undefined") {
					if (item.isReleased) {
						return "no-holds";
					} else {
						return "has-data";
					}
				} else {
					return "no-holds";
				}
			}
		},
	},
	computed: {
		milestonesData() {
			let staticEvents = [
				"so_issued",
				"isf_submitted",
				"ams_filed",
				"per_diem",
			];
			let filteredEvents = this.getDetails?.milestones.filter((item) => {
				return (
					(staticEvents.includes(item.event) && item.highlighted) ||
					!staticEvents.includes(item.event)
				);
			});
			filteredEvents.map(eventData =>{
				if(eventData.event == 'eta_updates'){
					eventData.data.sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
				}
			})
			return filteredEvents.slice().sort((a, b) => {
				if (!this.ascending) {
					return a.created_at - b.created_at;
				} else {
					return b.created_at - a.created_at;
				}
			});
		},
	},
};
</script>

<style>
@import "../../../assets/css/shipments_styles/milestonesV2.css";
</style>

<style lang="scss">
.milestone-ordering-details {
	.milestone-title-status {
		display: flex;
		justify-content: space-between;
		.milestone-name {
			display: flex;
			align-items: center;
			gap: 8px;
			.shipment-status {
				// color: $shifl-success-g80;
				// background-color: $shifl-success-g20;
				// padding: 4px 8px;
				// font-size: 12px;
				// font-weight: 500;
				// border-radius: 99px;
				height: 26px !important;
				padding: 3px 8px !important;
				font-size: 12px !important;
				border-radius: 99px !important;
				background-color: $shifl-success-g10 !important;
				border: 1px solid $shifl-success-g20 !important;
				color: $shifl-success-g80 !important;
			}
		}
		.order-icon {
			cursor: pointer;
		}
	}
	.milestone-note {
		font-size: 12px;
		line-height: 20px;
		color: #819fb2;
	}
}
.eta-expansion-content {
	.v-expansion-panels {
		.v-expansion-panel-header {
			padding: 0 !important;
			min-height: 35px;
			width: auto;

			span {
				color: $shifl-primary-b90;
				font-size: 14px;
			}

			.v-expansion-panel-header__icon {
				i {
					color: $shifl-primary-b90 !important;
				}
			}
		}

		.v-expansion-panel-content {
			.v-expansion-panel-content__wrap {
				padding: 0;

				.other-eta-items {
					padding: 12px 0;
					border-bottom: 1px solid $shifl-neutral-n20;
				}
			}
		}
	}
}

@media screen and (max-width: 767px) {
	.milestone-ordering-details {
		padding: 16px 16px 0;
	}
}
</style>

<!-- @format -->

<template>
	<div>
		<v-data-table :headers="getGroupWithUsersAndPermissionsData.length > 0 ? _header : []"
			:items="getGroupWithUsersAndPermissionsData" class="methods-table elevation-1"
			:class="items !== 'undefined' && items.length > 0 ? '' : 'no-data-table'" hide-default-footer :page.sync="page"
			:items-per-page="itemsPerPage" :search="search" @page-count="pageCount = $event" mobile-breakpoint="769"
			fixed-header :item-class="getItemClass">

			<template v-slot:top>
				<div class="tab-name">
					<div class="tab-method" v-if="activeTab == 0" style="padding-bottom: 0;">
						<v-tabs class="company-profile-user-group-tab" style=""  v-model="activeUserGroupTab" background-color="">
							<v-tab class="text-capitalize" :class="index == 3 ? 'company-profile-tab-last' : ''"
								v-for="(tab, index) in UserGroupTab" :key="index">
								{{ tab }}
							</v-tab>
						</v-tabs>
					</div>

					<div class="d-flex align-center justify-space-between pa-2 mobile-search-section-company">
						<div class="search-component">
							<Search :placeholder="getPlaceholderSearch" className="search custom-search"
								:inputData.sync="search" />
						</div>

						<v-btn v-if="activeUserGroupTab == 0" color="primary" dark class="ml-2 btn-blue" @click="addUser"
							style="min-width: 45px;">
							<v-icon style="font-size: 16px">mdi-plus</v-icon>
						</v-btn>
						<v-btn v-if="activeUserGroupTab == 1" color="primary" dark class="ml-2 btn-blue"
							@click="addUserGroup" style="min-width: 45px;">
							<v-icon style="font-size: 16px">mdi-plus</v-icon>
						</v-btn>
						<v-btn v-if="activeUserGroupTab == 2" color="primary" dark class="ml-2 btn-blue"
							@click="addNewImportName(null, 'Add')" style="min-width: 45px;">
							<v-icon style="font-size: 16px">mdi-plus</v-icon>
						</v-btn>
						<v-btn v-if="activeUserGroupTab == 3" color="primary" dark class="ml-2 btn-blue"
							@click="addDeliveryLocation(null, 'Add')" style="min-width: 45px;">
							<v-icon style="font-size: 16px">mdi-plus</v-icon>
						</v-btn>
					</div>
				</div>
			</template>

			<template v-slot:[`item.name`]="{ item }">
				<div :class="{ 'deactivate-border-row': item.deactivated == 1 }">
					<p :class="item.deactivated == 1 ? 'mb-0 ml-2' : 'mb-0 '">
						<span class="font-medium mr-1" style="color: #253041;">{{ item.name }}</span> 
						<span v-if="item.isCurrent" :class="item.deactivated == 1 ? 'ml-2' : ''"
						style="color: #1A6D9E !important;">(Current User)</span>
						
					</p>
					<span v-if="item.deactivated == 1" :class="item.deactivated == 1 ? 'ml-2' : ''"
						style="color: #69758C !important;">Deactivated<br></span> 
					<span  :class="item.deactivated == 1 ? 'mb-0 ml-2' : 'mb-0 '">
						{{ item.email_address }}
					</span>
				</div>

				<div class="user-action-buttons mr-2">
					<div class="three-dots-wrapper" v-if="isCustomerAdmin">
						<v-menu offset-y bottom left content-class="pending-dropdown-container">
							<template v-slot:activator="{ on, attrs }">
								<button v-bind="attrs" v-on="on" class="delivery-location-three-dots-container">
									<custom-icon iconName="three-dots" color="#1A6D9E" width="11" height="3" />
								</button>
							</template>
							<v-list>
								<v-list-item v-if="item.deactivated == 0">
									<v-list-item-title class="k-fw-sm k-font-inter-regular k-red"
										@click="showDeActivateUserAccountDialog(item, 'deactivate')">
										Deactivate Account
									</v-list-item-title>
								</v-list-item>
								<v-list-item v-if="item.deactivated == 0">
									<v-list-item-title class="k-fw-sm k-font-inter-regular k-red"
										@click="showDeActivateUserAccountDialog(item, 'delete')">
										Delete Account
									</v-list-item-title>
								</v-list-item>
								<v-list-item v-else>
									<v-list-item-title class="k-fw-sm k-font-inter-regular k-dark-blue"
										@click="showReActivateUserAccountDialog(item)">
										Reactivate User
									</v-list-item-title>
								</v-list-item>
							</v-list>
						</v-menu>
					</div>
				</div>
			</template>

			<template v-slot:[`item.email_address`]="{ item }">
				<div class="ml-2 email-wrapper-mobile">
					<div class="content-wrapper">
						<span>Date Added</span>
						<p>{{ item.date_added }}</p>
					</div>

					<div class="content-wrapper">
						<span>Last Activity At</span>
						<p class="mb-0" v-if="item.is_confirm">
							{{ item.activity_at }}
						</p>
						
						<p class="mb-0" v-else>
							<span style="color:#BC4410;padding-right: 5px;">Invitation Pending</span>
							<v-btn icon @click="resedInvitation(item)">
								<img src="../../../../assets/icons/email-blue.svg" alt="Resend Invitation" title="Resend Invitation" />
							</v-btn>
						</p>
					</div>
				</div>
			</template>

			<!-- start user groups mobile -->
			<template v-slot:[`item.group_name`]="{ item }">
				<div class="user-name d-flex justify-space-between align-center" style="width: 100%;">
					<p class="mb-0">{{ item.group_name !== null ? item.group_name : '--' }}</p>

					<div v-if="activeUserGroupTab == 1" class="user-action-buttons mr-2">
						<v-btn v-if="showButtonBasedOnPermissions(item, 'view')" class="btn-white icons mr-2"
							@click="viewUserGroup(item)">
							<img src="../../../../assets/icons/view-blue.svg" alt="" />
						</v-btn>
						<v-btn v-if="showButtonBasedOnPermissions(item, 'update')" class="btn-white icons mr-2"
							@click="editUserGroup(item)">
							<img src="../../../../assets/icons/edit-blue.svg" alt="" />
						</v-btn>

						<v-btn v-if="showButtonBasedOnPermissions(item, 'delete')" class="btn-white icons"
							@click="deleteUserGroup(item)">
							<img src="../../../../assets/icons/delete-blue.svg" alt="" />
						</v-btn>
					</div>
				</div>
			</template>

			<template v-slot:[`item.users_length`]="{ item }">
				<div class="users-and-permissions px-2" style="width: 100%;">
					<p class="mb-2">
						<span class="font-medium mr-3" style="color: #69758C;">Users</span> 
						{{ item.users.length }}
					</p>

					<div class="d-flex justify-start align-center">
						<span class="font-medium" style="color: #69758C;">Permissions </span>
						<p class="mb-0 ml-3" v-if="item.permissions && item.permissions.length">
							<span class="py-1 px-2 mx-1 ml-0 text-capitalize"
								style="background: #F9FAFB;border: 1px solid #F3F4F7;border-radius: 99px; color:#69758C; font-size: 12px;">
								{{ GetPermissionTotal(item.permissions) }} permissions
							</span>
						</p>
						<p class="mb-0 d-flex align-center mr-2" v-else>
							--
						</p>
					</div>
				</div>
			</template>
			<!-- end user groups mobile -->

			<!-- start import names mobile -->
			<template v-slot:[`item.image`]="{ item }">
				<div class="user-name d-flex justify-space-between align-center" style="width: 100%;">
					<div class="d-flex justify-start align-center">
						<img width="30px" height="30px" :src="getImgUrl(item.image)" alt="no" style="border-radius: 4px;">
						<div class="user-name ml-3">
							<p class="mb-0" v-if="item.isCurrent" style="font-size: 14px;">
								{{ item.import_name }}
								<span class=" ml-2 pt-1"><img src="../../../../assets/icons/CustomerCurrent.svg" alt=""></span>
							</p>
							<p class="mb-0" v-else>{{ item.import_name }}</p>
						</div>
					</div>
					<div class="user-action-buttons mr-2">
						<v-btn @click="addNewImportName(item, 'Edit')" class="btn-white icons mr-2">
							<img src="../../../../assets/icons/edit-blue.svg" alt="" />
						</v-btn>

						<v-btn @click="deleteNewImport(item)" class="btn-white icons">
							<img src="../../../../assets/icons/delete-blue.svg" alt="" />
						</v-btn>
					</div>
				</div>
			</template>

			<template v-slot:[`item.import_name`]="{ item }">
				<div class="px-2">					
					<div class="import-name-mobile-phone d-flex justify-start align-center mb-1">
						<p class="mb-0 font-medium" style="width: 30%; color: #69758C;">Phone Number</p>
						<p class="mb-0" style="width: 70%;">{{ item.phone_number !== null ? item.phone_number : '--' }}</p>
					</div>

					<div class="import-name-mobile-email d-flex justify-start align-center mb-1">
						<p class="mb-0 font-medium" style="width: 30%; color: #69758C;">Email</p>
						<p class="mb-0" style="width: 70%;">{{ item.email !== null ? item.email : '--' }}</p>
					</div>
					
					<div class="import-name-mobile-address d-flex justify-start align-center mb-1">
						<p class="mb-0 font-medium" style="width: 30%; color: #69758C;">Address</p>
						<p class="mb-0" style="width: 70%;">{{ item.address !== null ? item.address : '--' }}</p>
					</div>
				</div>
			</template>
			<!-- end import names mobile -->

			<template v-slot:no-data>
				<div class="loading-wrapper pt-4"
					v-if="(activeUserGroupTab == 0 && getCustomerAdminsLoading) || (activeUserGroupTab == 1 && getGroupWithUsersAndPermissionsLoading)">
					<v-progress-circular :size="40" color="#1A6D9E" indeterminate>
					</v-progress-circular>
				</div>
				<div style="background-color: transparent;" class="no-data-wrapper" v-if="activeUserGroupTab == 0 && !getCustomerAdminsLoading">
					No Users
				</div>
				<div style="background-color: transparent;" class="no-data-wrapper" v-if="activeUserGroupTab == 1 && !getGroupWithUsersAndPermissionsLoading">
					No User Groups
				</div>
				<div class="no-data-wrapper" v-if="activeUserGroupTab == 2">
					No Import Names
				</div>
				<div class="no-data-wrapper " v-if="activeUserGroupTab == 3 && !getDeliveryLocationLoading">
					<div class="no-location-container">
						<img src="../../../../assets/icons/no-delivery-location.svg" alt="" />
						<span class="no-location-lb">No Delivery Location</span>
						<span class="no-location-lb-sm">No delivery location added yet. Let’s add new delivery location.
						</span>
						<v-btn color="primary" outlined class="mt-4 mb-5 btn-white"
							@click="addDeliveryLocation(null, 'Add')" style="min-width: 95px;">
							Add Delivery Location
						</v-btn>
					</div>
				</div>
				<div class="loading-wrapper pt-4"
				v-if="activeUserGroupTab == 3 && getDeliveryLocationLoading">
					<v-progress-circular :size="40" color="#1A6D9E" indeterminate>
					</v-progress-circular>
				</div>
			</template>

			<!-- Delivery Location mobile UI -->
			<template v-slot:item="{ item }" v-if="this.activeUserGroupTab == 3">
				<tr style="vertical-align: top !important;">
					<td style="padding: 0 !important; background-color: #F9FAFB;">
						<div class="delivery-loc-mobile-div ma-2 mb-1">
							<div class="delivery-loc-mobile-div-header py-2 px-4">
								<span style="font-size: 14px; color:#253041;" class="font-semi-bold">
									{{ item.location_name }}
								</span>

								<div class="user-action-buttons">
									<v-btn @click="viewDeliveryLocation(item)" class="btn-white icons">
										<img src="../../../../assets/icons/view-blue.svg" alt="" />
									</v-btn>

									<div class="three-dots-wrapper ">
										<v-menu offset-y bottom left content-class="pending-dropdown-container">
											<template v-slot:activator="{ on, attrs }">
												<button v-bind="attrs" v-on="on"
													class="delivery-location-three-dots-container delivery-custom-menu-button">
													<custom-icon iconName="three-dots" color="#1A6D9E" width="11" height="3" />
												</button>
											</template>
											<v-list>
												<v-list-item>
													<v-list-item-title @click="editDeliveryLocation(item)"
														class="k-fw-sm k-font-inter-regular k-dark-black">
														Edit
													</v-list-item-title>
												</v-list-item>

												<v-list-item>
													<v-list-item-title class="k-fw-sm k-font-inter-regular k-red"
														@click="deleteNewLocation(item)">
														Delete
													</v-list-item-title>
												</v-list-item>
											</v-list>
										</v-menu>
									</div>
								</div>
							</div>

							<div class="delivery-loc-mobile-div-body py-2 px-4">
								<div class="delivery-loc-mobile-other-details">
									<p class="other-details-title">Type</p>
									<p class="other-details-data">{{ item.type }}</p>
								</div>

								<div class="delivery-loc-mobile-other-details">
									<p class="other-details-title">Address</p>
									<p class="other-details-data">{{ item.address }}</p>
								</div>

								<div class="delivery-loc-mobile-other-details">
									<p class="other-details-title">Contact</p>
									<p class="other-details-data">
										<span v-for="(contact, index) in item.contacts" :key="index">
											<span v-if="contact.primary == 1" class="td-name"> {{ contact.contact_name }} 
												<div v-if="contact.primary == 1 && contact.on_site == 1" class="onsite-chip">
													Onsite
												</div>
											</span>
											<br>
											<span v-if="contact.primary == 1" class="td-role">{{ contact.phone_number }} </span>
										</span>
									</p>
								</div>

								<div class="delivery-loc-mobile-other-details">
									<p class="other-details-title">Special Instruction</p>
									<p class="other-details-data">{{ item.special_delivery_instruction }}</p>
								</div>
							</div>
						</div>
					</td>
				</tr>
			</template>
		</v-data-table>

		<Pagination
			v-if="typeof items !== 'undefined' && items.length > 35"
			:pageData.sync="page"
			:lengthData.sync="pageCount"
			:isMobile="isMobile" />

		<EditUserGroupDialog 
			:dialogEdit.sync="editUserGroupdialogValue" 
			@close="closeEditUserGroupDialog"
			:userGroupData="ViewUserGroupData" 
			:companyId="companyId" />

		<AddNewImportDialog 
			:dialogImportName.sync="addNewImportDialog" 
			@closeImport="closeAddNewImportDialog"
			:editImportIndex="editImportIndex" 
			:defaultItems="defaultItems" 
			:defaultCustomer="defaultCustomer" />

		<AddNewDeliveryLocationDialog 
			:dialogImportName.sync="addNewDeliveryLocationDialog"
			@closelocation="closeLocationDialog" 
			@getDeliveryLocations="GetDeliveryLocations"
			:editImportIndex="editDeliveryLocationIndex" 
			:defaultLocationItems="defaultLocationItems"
			:defaultCustomer="defaultCustomer"
			:isMobile="isMobile" />

		<EditDeliveryLocationDialog 
			:dialogEditDeliveryLocation.sync="editDeliveryLocationDialog"
			@closelocation="closeLocationDialog" 
			@getDeliveryLocations="GetDeliveryLocations"
			:editDeliveryLocationIndex="editDeliveryLocationIndex" 
			:EditLocationItem="defaultLocationItems"
			:defaultCustomer="defaultCustomer" 
			:comingFrom="this.screenName"
			:isMobile="isMobile" />
		
		<ConfirmDialog :dialogData.sync="deactivateUserAccountConfirmDialog">
			<template v-slot:dialog_icon>
				<div class="header-wrapper-close">
					<img src="@/assets/icons/alert.svg" alt="alert">
				</div>
			</template>

			<template v-slot:dialog_title>
				<h2>{{ !isForDeleteAccount ? "Deactivate Your Account" : "Delete User Account" }}</h2>
			</template>

			<template v-slot:dialog_content>
				<div>
					<p>
						{{ !isForDeleteAccount ?
							"Are you sure you want to deactivate your user account? This action will temporarily limit your access to the platform."
							:
							"Are you sure you want to delete your user account? This action will remove the account and all its data from the platform."
						}}
					</p>
					<br>
					<div class="deUser-alert-container" v-if="!isForDeleteAccount">
						<div class="div-deUser-alert-icon">
							<img src="@/assets/icons/alert-info-gray.svg" alt="alert">
						</div>
						<p class="deUser-alert-text">
							Your information will be retained, and you can reactivate your account at any time by reaching
							out to us at <span style="color:#1A6D9E"><a href="mailto:hello@shifl.com"
									class="custom-email-link">hello@shifl.com</a></span>
						</p>
					</div>
					<div class="for-delete-checkbox-wrapper" v-else>
						<v-checkbox
							class="my-0 text-center associated-checkbox"
							hide-details
							v-model="deleteAcceptCheckbox">
						</v-checkbox>
						<span>
							I understand this action will delete all my account data permanently and it can't be retrieved later.
						</span>
					</div>
				</div>
			</template>

			<template v-slot:dialog_actions>
				<v-btn @click="deactivateUserAccountFunc" class="btn-red" text
					:disabled="loadingDeActivateUserAccount || (isForDeleteAccount && !deleteAcceptCheckbox)">
					<span v-if="!isForDeleteAccount">
						{{ loadingDeActivateUserAccount ? 'Deactivating..' : 'Deactivate' }}
					</span>
					<span v-if="isForDeleteAccount">
						{{ loadingDeActivateUserAccount ? 'Deleting..' : 'Delete' }}
					</span>
				</v-btn>
				<v-btn class="btn-white" text @click="closeDeActivateUserAccountDialog"
					:disabled="loadingDeActivateUserAccount">
					Cancel
				</v-btn>
			</template>
		</ConfirmDialog>

		<ConfirmDialog :dialogData.sync="reactivateUserAccountConfirmDialog">
			<template v-slot:dialog_icon>
				<div class="header-wrapper-close">
					<img src="@/assets/icons/icon-delete-blue.svg" alt="alert">
				</div>
			</template>

			<template v-slot:dialog_title>
				<h2>Reactivate User Account</h2>
			</template>

			<template v-slot:dialog_content>
				<div>
					<p>
						Are you sure you want to reactivate <span class="font-semi-bold">{{ reActivateUserAccount !== null ?
							reActivateUserAccount.name : 'N/A' }}’s</span> account? This action will allow them to access
						Shifl platform again.
					</p>

				</div>
			</template>

			<template v-slot:dialog_actions>
				<v-btn @click="reactivateUserAccountFunc" :disabled="loadingReActivateUserAccount" class="btn-blue" text>
					{{ loadingReActivateUserAccount ? 'Reactivating..' : 'Reactiave' }}
				</v-btn>
				<v-btn class="btn-white" text @click="closeReActivateUserAccountDialog"
					:disabled="loadingReActivateUserAccount">
					Cancel
				</v-btn>
			</template>
		</ConfirmDialog>

		<ConfirmDialog :dialogData.sync="getReActivateAccount">
			<template v-slot:dialog_icon>
				<div class="header-wrapper-close">
					<img src="@/assets/icons/success-green-tick.svg" alt="alert">
				</div>
			</template>

			<template v-slot:dialog_title>
				<h2>User Account Reactivated</h2>
			</template>

			<template v-slot:dialog_content>
				<div>

					<p>
						<span class="font-semi-bold">{{ reActivateUserAccount !== null ?
							reActivateUserAccount.name : 'N/A' }}’s</span> account has been reactivated. We will send them
						an email to let them know that they can re-access their account to Shifl platform again.
					</p>
				</div>
			</template>

			<template v-slot:dialog_actions>
				<v-btn class="btn-blue" text @click="closeSuccessReActivateUserAccountDialog">
					Okay
				</v-btn>
			</template>
		</ConfirmDialog>
	</div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from "vuex";
import Search from "../../../Search.vue";
import Pagination from "../../../Pagination.vue";
import globalMethods from "../../../../utils/globalMethods";
import EditUserGroupDialog from "../../../SettingsComponents/Dialog/EditUserGroupDialog.vue";
import ConfirmDialog from "../../../Dialog/GlobalDialog/ConfirmDialog.vue";
import AddNewImportDialog from "../../../SettingsComponents/Dialog/AddNewImportDialog.vue";
import AddNewDeliveryLocationDialog from "../../../SettingsComponents/Dialog/AddDeliveryLocationDialog.vue";
import EditDeliveryLocationDialog from "../../../SettingsComponents/Dialog/EditDeliveryLocationDialog.vue";
import CustomIcon from "@/components/Icons/CustomIcon";

export default {
	name: "UsersDesktopTable",
	props: ["items", "isMobile", "companyId"],
	components: {
		CustomIcon,
		Search,
		Pagination,
		EditUserGroupDialog,
		ConfirmDialog,
		AddNewImportDialog,
		AddNewDeliveryLocationDialog,
		EditDeliveryLocationDialog
	},
	data: () => ({
		deleteAcceptCheckbox: false,
		hideTableHeahder: false,
		screenName: 'dlTable',
		deleteNewLocationData: null,
		deActivateUserAccount: null,
		reActivateUserAccount: null,
		page: 1,
		pageCount: 0,
		itemsPerPage: 35,
		search: "",
		headers: [
			{
				text: "User",
				align: "start",
				sortable: false,
				value: "name",
				width: "22%",
				fixed: true,
			},
			{
				text: "Email Address",
				align: "start",
				sortable: false,
				value: "email_address",
				width: "20%",
				fixed: true,
			},
			{
				text: "User Group",
				align: "start",
				sortable: false,
				value: "group_name",
				width: "14%",
				fixed: true,
			},
			{
				text: "Date Added",
				align: "start",
				sortable: false,
				value: "date_added",
				width: "12%",
				fixed: true,
			},
			{
				text: "Last Activity At",
				align: "start",
				sortable: false,
				value: "activity_at",
				width: "17%",
				fixed: true,
			},
			{
				text: "",
				align: "start",
				sortable: false,
				value: "actions",
				width: "10%",
				fixed: true,
			},
		],
		activeTab: 0,
		// user Group
		activeUserGroupTab: 0,
		UserGroupTab: ['User', 'User Groups', 'Import Name', 'Delivery Locations'],
		headersUserGroup: [
			{
				text: "User Group",
				align: "start",
				sortable: false,
				value: "group_name",
				width: "20%",
				fixed: true,
				class: 'user-group-header-class-for-text'
			},
			{
				text: "No. of Users",
				align: "start",
				sortable: false,
				value: "users_length",
				width: "13%",
				fixed: true,
				class: 'user-group-header-class-for-text'
			},
			{
				text: "Users",
				align: "start",
				sortable: false,
				value: "users",
				width: "32%",
				fixed: true,
				class: 'user-group-header-class-for-text'
			},
			{
				text: "PERMISSIONS",
				align: "start",
				sortable: false,
				value: "permissions",
				width: "18%",
				fixed: true,
				class: 'user-group-header-class-for-permissions'
			},
			{
				text: "",
				align: "start",
				sortable: false,
				value: "actions",
				width: "18%",
				fixed: true,
			},
		],
		editUserGroupdialogValue: false,
		deleteUserGroupConfirmDialog: false,
		ViewUserGroupData: [],
		deleteUserGroupData: null,
		target_group_id: null,
		// import Name Code
		addNewImportDialog: false,
		editImportIndex: false,
		headersImportName: [
			{
				text: "",
				align: "start",
				sortable: false,
				value: "image",
				width: "5%",
				fixed: true,
				class: 'user-group-header-class-for-text'
			},
			{
				text: "Name",
				align: "start",
				sortable: false,
				value: "import_name",
				width: "18%",
				fixed: true,
				class: 'user-group-header-class-for-text'
			},
			{
				text: "Email Address",
				align: "start",
				sortable: false,
				value: "email",
				width: "16%",
				fixed: true,
				class: 'user-group-header-class-for-text'
			},
			{
				text: "Phone Number",
				align: "start",
				sortable: false,
				value: "phone_number",
				width: "18%",
				fixed: true,
				class: 'user-group-header-class-for-text'
			},
			{
				text: "Address",
				align: "start",
				sortable: false,
				value: "address",
				width: "31%",
				fixed: true,
				class: 'user-group-header-class-for-text'
			},
			{
				text: "",
				align: "start",
				sortable: false,
				value: "actions",
				width: "13%",
				fixed: true,
			},
		],
		defaultItems: {
			import_name: '',
			email: '',
			phone_number: '',
			address: '',
			country: '',
			state: '',
			city: '',
			zip: '',
			customer_id: null,
			id: null,
			ein: null,
			url: null
		},
		deleteImportNameConfirmDialog: false,
		deleteNewImportData: null,
		allImportNameData: [],
		loadingDeleteImportName: false,
		editDeliveryLocationIndex: false,
		loadingDeleteLocationName: false,
		addNewDeliveryLocationDialog: false,
		editDeliveryLocationDialog: false,
		deleteLocationConfirmDialog: false,
		deactivateUserAccountConfirmDialog: false,
		loadingDeActivateUserAccount: false,
		reactivateUserAccountConfirmDialog: false,
		loadingReActivateUserAccount: false,
		headersDeliveryName: [
			{
				text: "Location Name & Type",
				align: "start",
				sortable: false,
				width: "18%",
				fixed: true,
				value: "location_name",
				class: 'delivery-location-table-header-class'

			},
			{
				text: "Address",
				align: "start",
				sortable: false,
				width: "18%",
				fixed: true,
				value:"address",
				class: 'delivery-location-table-header-class'
			}, {
				text: "Primary Contact",
				align: "start",
				sortable: false,
				width: "18%",
				fixed: true,
				value:"contacts",
				class: 'delivery-location-table-header-class'
			},
			{
				text: "Special Delivery Instruction",
				align: "start",
				sortable: false,
				width: "18%",
				value: "special_delivery_instruction",
				fixed: true,
				class: 'delivery-location-table-header-class'
			},
			{
				text: "",
				align: "start",
				sortable: false,
				value: "actions",
				width: "13%",
				fixed: true,
			},
		],
		defaultLocationItems: {
			location_name: "",
			type: "",
			country: "",
			state: "",
			city: "",
			address: "",
			zip_code: "",
			facility_operation_schedule: 0,
			special_delivery_instruction: "",
			contacts: [
				{
					contact_name: "",
					phone_number: "",
					email: "",
					role: "",
					on_site: 0,
					primary: ''
				}
			],
			facility_operation_hour: {
				type: "weekly",
				days: [],
				mon_open: "",
				mon_close: "",
				tue_open: "",
				tue_close: "",
				wed_open: "",
				wed_close: "",
				thu_open: "",
				thu_close: "",
				fri_open: "",
				fri_close: "",
				sat_open: "",
				sat_close: "",
				sun_open: "",
				sun_close: ""
			}
		},
	}),
	computed: {
		...mapGetters({
			getCustomerAdminsLoading: "getCustomerAdminsLoading",
			getGroupWithUsersAndPermissions: 'settings/getGroupWithUsersAndPermissions',
			getDeliveryLocations: 'settings/getDeliveryLocations',
			getDeliveryLocationLoading: 'settings/getDeliveryLocationLoading',
			getGroupWithUsersAndPermissionsLoading: 'settings/getGroupWithUsersAndPermissionsLoading',
			getGroupDeleteWithoutMoveUserToOtherGroupLoading: 'settings/getGroupDeleteWithoutMoveUserToOtherGroupLoading',
			getGroupDeleteAndMoveUserToOtherGroupLoading: 'settings/getGroupDeleteAndMoveUserToOtherGroupLoading',
			getUserAndUserGroupActveTab: 'settings/getUserAndUserGroupActveTab',
			getUser: 'getUser',
			getAllImportName: 'settings/getAllImportName',
			getDeleteImportNameLoading: 'settings/getDeleteImportNameLoading',
			getDeActivateAccountLoading: 'settings/getDeActivateAccountLoading',
			getReActivateAccount: 'settings/getReActivateAccount'
		}),
		defaultCustomer() {
			let getUser = this.$store.getters.getUser;
			getUser = typeof getUser === "string" && JSON.parse(getUser);
			return getUser.default_customer_id;
		},
		_header() {
			let final_header = [];
			if (this.activeUserGroupTab == 0) {
				final_header = this.headers;
			} else if (this.activeUserGroupTab == 1) {
				final_header = this.headersDeliveryName;
				final_header = this.headersUserGroup;
			} else if (this.activeUserGroupTab == 2) {
				final_header = this.headersImportName;
			} else if (this.activeUserGroupTab == 3) {
				final_header = this.headersDeliveryName;
			} else {
				final_header = this.headersDeliveryName;
			}
			return final_header;
		},
		getGroupWithUsersAndPermissionsData() {
			let data = [];
			if (this.activeUserGroupTab == 0) {
				data = this.items;
			} else if (this.activeUserGroupTab == 1) {
				if (typeof this.getGroupWithUsersAndPermissions !== 'undefined' &&
					this.getGroupWithUsersAndPermissions !== null) {
					data = this.getGroupWithUsersAndPermissions;
				} else {
					data = [];
				}
			} else if (this.activeUserGroupTab == 3) {
				if (typeof this.getDeliveryLocations !== 'undefined' &&
					this.getDeliveryLocations !== null) {
					data = this.getDeliveryLocations;
				} else {
					data = [];
				}
			} else {
				data = this.getAllImportNameData;
			}
			return data;
		},
		MoveUserToAnotherGroup() {
			let data = []
			if (this.deleteUserGroupData !== null) {
				data = this.getGroupWithUsersAndPermissions.filter(val => val.id !== this.deleteUserGroupData.id)
			} else {
				data = []
			}
			return data
		},
		deleteGroupLoading() {
			let loading = false
			if (this.target_group_id !== null) {
				loading = this.getGroupDeleteAndMoveUserToOtherGroupLoading
			} else {
				loading = this.getGroupDeleteWithoutMoveUserToOtherGroupLoading
			}
			return loading
		},
		getAllImportNameData() {
			let data = [];

			if (typeof this.getAllImportName !== "undefined" && this.getAllImportName !== null) {
				if (typeof this.getAllImportName.data !== "undefined" && this.getAllImportName.data !== null &&
					this.getAllImportName.data.length > 0) {
					let importNames = this.getAllImportName.data
					let userId;
					if (typeof this.getUser === "string") {
						userId = JSON.parse(this.getUser).id;
					} else {
						userId = this.getUser.id;
					}

					data = importNames.map(val => {
						return {
							import_name: val.import_name,
							id: val.id,
							address: val.address,
							ein: val.ein,
							phone_number: val.phone_number,
							customer_id: val.customer_id,
							updated_at: val.updated_at,
							created_at: val.created_at,
							isCurrent: val.customer_id === userId,
							userId: userId,
							city: val.city,
							country: val.country,
							zip: val.zipcode,
							state: val.state,
							image: val.image,
							email: val.email
						}
					})
				}
			}
			// if(typeof this.getAllImportName !== 'undefined' && this.getAllImportName !== null &&
			// 	typeof this.this.getAllImportName.data !== "undefined" && this.getAllImportName.data.length > 0){
			// 		let userId;
			// 		if (typeof this.getUser === "string") {
			// 			userId = JSON.parse(this.getUser).id;
			// 		} else {
			// 			userId = this.getUser.id;
			// 		}
			// 		data = this.getAllImportName.data.map(val => {
			// 			return {
			// 				import_name:val.import_name,
			// 				id:val.id,
			// 				address:val.address,
			// 				ein:val.ein,
			// 				phone_number:val.phone_number,
			// 				customer_id:val.customer_id,
			// 				updated_at:val.updated_at,
			// 				created_at:val.created_at,
			// 				isCurrent: val.customer_id === userId,
			// 				userId:userId,
			// 				city:val.city,
			// 				country:val.country,
			// 				zip:val.zipcode,
			// 				state:val.state,
			// 				image:val.image,
			// 				email:val.email
			// 			}
			// 		})
			// }else{
			// 	data = []
			// }

			return data;
		},
		getPlaceholderSearch() {
			let text = ''

			if (this.activeUserGroupTab === 0) {
				text = 'Search User'
			} else if (this.activeUserGroupTab === 1) {
				text = 'Search Group User'
			} else if (this.activeUserGroupTab === 2) {
				text = 'Search Import Name'
			} else if (this.activeUserGroupTab === 3) {
				text = 'Search Delivery Location'
			}

			return text
		},
		isForDeleteAccount() {
			let data = false;
			if (this.deActivateUserAccount !== null) {
				data = this.deActivateUserAccount.isFor === "delete" ? true : false;
			}
			return data;
		},
		isCustomerAdmin() {
			let getUser = this.$store.getters.getUser;
			getUser = typeof getUser === "string" && JSON.parse(getUser);
			let role = getUser.roles;
			let allowed = false;
			if (role.length > 0 && role[0].name === "Customer Admin") {
				allowed = true; 
			}
			return allowed;
		}
	},
	watch: {},
	methods: {
		getItemClass(item) {
			return item.deactivated == 1 ? "deactivate-border-row" : ""
		},
		userLogout() {
			this.$store.dispatch("page/setTab", 1);
			this.logout();
		},
		showUserDetails(item) {
			console.log("showUserDetails: ", item)
		},
		...mapActions({
			fetchGroupWithUsersAndPermissions: 'settings/fetchGroupWithUsersAndPermissions',
			fetchDeliveryLocationsApi: 'settings/fetchDeliveryLocationsApi',
			deleteLocationApi: 'settings/deleteLocationApi',
			deleteGrouAndMoveUserToOtherGroupApi: 'settings/deleteGrouAndMoveUserToOtherGroupApi',
			deleteGrouWithoutMoveUserToOtherGroupApi: 'settings/deleteGrouWithoutMoveUserToOtherGroupApi',
			deactivateAccountApi: 'settings/deactivateAccountApi',
			reactivateAccountApi: 'settings/reactivateAccountApi',
			// import Names
			fetchAllImportNames: 'settings/fetchAllImportNames',
			deleteImportNameApi: 'settings/deleteImportNameApi',
			logout: 'logout'
		}),
		...mapMutations({
			setUserGroupAsASelectiveTab: 'settings/setUserGroupAsASelectiveTab'
		}),
		...globalMethods,
		getCurrentTab(id) {
			console.log(id);
		},
		onTabChange() {
			this.page = 1;
			if (this.activeTab == 0) {
				this.$router.push(`notifications`);
			}
			if (this.activeTab == 1) {
				this.$router.push(`manage-payment-methods`);
			}
		},
		addUser() {
			this.$emit("addUser");
		},
		addUserGroup() {
			this.$emit("addUserGroup");
		},
		editUser(user) {
			this.editedIndex = this.items.indexOf(user);
			this.editedItem = Object.assign({}, user);
			this.dialog = true;
		},
		close() {
			this.$emit("close");
		},
		deleteUser(item) {
			this.$emit("deleteUser", item);
		},
		closeDelete() {
			this.$emit("closeDelete");
		},
		resedInvitation(item) {
			this.$emit("resendInvitation", item);
		},
		save() {
			if (this.editedIndex > -1) {
				Object.assign(this.desserts[this.editedIndex], this.editedItem);
			} else {
				this.desserts.push(this.editedItem);
			}
			this.close();
		},
		// User Group Code Start
		deleteUserGroup(item) {
			this.deleteUserGroupData = item
			this.deleteUserGroupConfirmDialog = true
		},
		async deleteGroupAndMoveUserToOtherGroup() {
			let payload = {
				target_group_id: this.target_group_id,
				company_id: this.deleteUserGroupData.company_id,
				group_id: this.deleteUserGroupData.id
			}
			try {
				if (this.target_group_id !== null) {
					await this.deleteGrouAndMoveUserToOtherGroupApi(payload)
				} else {
					let senData = {
						group_id: this.deleteUserGroupData.id,
						company_id: this.deleteUserGroupData.company_id,
					}
					await this.deleteGrouWithoutMoveUserToOtherGroupApi(senData)
				}
				await this.fetchGroupWithUsersAndPermissions(this.companyId)

				if (this.target_group_id !== null) {
					this.notificationMessage("Group has been moved successfully")
				} else {
					this.notificationMessage("Group with permissions has been deleted successfully")
				}
				this.closeDeleteUserGroup()

			} catch (e) {
				this.notificationError(e);
			}
		},
		closeDeleteUserGroup() {
			this.deleteUserGroupConfirmDialog = false
			this.target_group_id = null
			this.deleteUserGroupData = null
		},
		viewUserGroup(item) {
			this.ViewUserGroupData = item
			this.editUserGroupdialogValue = true
		},
		closeEditUserGroupDialog() {
			this.editUserGroupdialogValue = false
		},
		editUserGroup(item) {
			let role = 1
			if (item.group_name == 'Admin') {
				role = 1
			} else if (item.group_name == 'Manager') {
				role = 2
			} else if (item.group_name == 'Operator') {
				role = 3
			}
			else {
				role = 4
			}
			if (this.$route.name === 'Settings') {
				this.$router.push(`/settings/newusergroup?group_id=${item.id}&add_edit=edit&role=${role}`)
			}
		},
		GetGroupWithUserAndPermissions() {
			if (this.companyId !== undefined && this.companyId !== 'undefined') {
				this.fetchGroupWithUsersAndPermissions(this.companyId)
			}
		},
		GetDeliveryLocations() {
			this.fetchDeliveryLocationsApi()
		},
		getName(name) {
			if (name !== null && typeof name !== undefined && name !== 'undefined') {
				const first = name.split(' ').at(0);
				return first
			} else {
				return name
			}

		},
		GetPermissionTotal(item) {
			let total = 0;
			if (item !== undefined && item !== null) {
				item.map(val => {
					if (val.is_view == 1 || val.is_add == 1 || val.is_update == 1 || val.is_delete == 1) {
						total = total + 1;
					}
				})
			}
			return total == 110 ? 'All' : total;
		},
		showButtonBasedOnPermissions(item, from) {
			if (item.permissions.length > 0) {
				let result = item.permissions.filter(val => val.const_name == 'SETTINGS_COMPANY_PROFILE_USER_GROUPS')
				if (result.length == 0) return false
				if (from == 'view') return result[0].is_view == 1 ? true : false
				else if (from == 'update') return result[0].is_update == 1 ? true : false
				else {
					return result[0].is_delete == 1 ? true : false
				}
			} else {
				return false
			}
		},
		checkLoggedInUserData(activityDate) {
			let user = this.getUser;
			if (typeof user !== "object") {
				user = JSON.parse(this.getUser);
			}

			let roles = [];
			if (user.roles && user.roles.length > 0) {
				roles = user.roles.map((role) => role.name);
			}
			if (roles[0] === 'Customer Admin' && user.for_testing === 1) {
				return activityDate;
			} else if (roles[0] === 'Account Manager') {
				return activityDate;
			} else {
				return 'N/A';
			}
		},
		getEmailAddress(email) {
			let data = ''
			if (email !== null && email !== undefined) {
				let company_email = '@';
				let shifl_account = '@shifl'
				let emailfind = email.indexOf(company_email)
				let shifl_is = email.indexOf(shifl_account)
				if (shifl_is === -1) {
					let before_AtTheRate = email.substr(0, emailfind)
					let AfterAtTheRate = email.slice(emailfind).replace(/./g, '*');
					data = before_AtTheRate + AfterAtTheRate
				} else {
					data = email
				}
			}
			return data
		},
		// import Code
		addNewImportName(item, from) {
			if (from == 'Add') {
				this.editImportIndex = false
			} else {
				this.editImportIndex = true
				let itemCopy = { ...item }
				itemCopy.phone_number = itemCopy.phone_number !== null ? itemCopy.phone_number : ''
				itemCopy.url = itemCopy.image !== null && itemCopy.image !== undefined ? itemCopy.image : null
				this.defaultItems = itemCopy
			}
			this.addNewImportDialog = true
		},
		viewDeliveryLocation(item) {
			this.$router.push(`/settings/delivery-location-view?location_id=${item.id}`);
		},
		addDeliveryLocation(item, from) {
			if (from == 'Add') {
				this.editDeliveryLocationIndex = false
			} else {
				item, from
				this.editDeliveryLocationIndex = true
				let itemCopy = { ...item }
				this.defaultLocationItems = itemCopy
			}
			this.addNewDeliveryLocationDialog = true
		},
		editDeliveryLocation(item) {
			this.editDeliveryLocationIndex = true
			let itemCopy = { ...item }
			this.defaultLocationItems = itemCopy
			this.editDeliveryLocationIndex = true
			this.editDeliveryLocationDialog = true
		},
		closeLocationDialog() {
			this.addNewDeliveryLocationDialog = false
			this.editDeliveryLocationDialog = false
			this.editDeliveryLocationIndex = false
			this.defaultLocationItems = {
				location_name: '',
				address: '',
				country: '',
				city: '',
				state: '',
				location_contacts: []
			}
		},
		closeAddNewImportDialog() {
			this.addNewImportDialog = false
			this.editImportIndex = false
			this.defaultItems = {
				import_name: '',
				email: '',
				phone_number: '',
				address: '',
				country: '',
				state: '',
				city: '',
				zip: '',
				customer_id: null,
				id: null,
				ein: null,
				url: null
			}
		},
		closeDeleteImportName() {
			this.deleteImportNameConfirmDialog = false
			this.deleteNewImportData = null
		},
		closeDeleteLocationName() {
			this.deleteLocationConfirmDialog = false
			this.deleteNewLocationData = null
		},
		deleteNewImport(item) {
			this.deleteNewImportData = item
			this.deleteImportNameConfirmDialog = true
		},
		deleteNewLocation(item) {
			this.deleteNewLocationData = item
			this.deleteLocationConfirmDialog = true
		},
		showDeActivateUserAccountDialog(item, isFor) {
			this.deActivateUserAccount = item;
			this.deActivateUserAccount.isFor = isFor;
			this.deactivateUserAccountConfirmDialog = true;
		},
		closeDeActivateUserAccountDialog() {
			this.deactivateUserAccountConfirmDialog = false;
			setTimeout(() => {
				this.deActivateUserAccount = null;
			}, 150);
		},
		showReActivateUserAccountDialog(item) {
			this.reActivateUserAccount = item
			this.reactivateUserAccountConfirmDialog = true
		},
		closeReActivateUserAccountDialog() {
			this.reactivateUserAccountConfirmDialog = false
		},
		closeSuccessReActivateUserAccountDialog() {
			this.$store.commit('settings/SET_REACTIVATE_ACCOUNT', false)
			window.location.reload();
		},
		async deactivateUserAccountFunc() {
			try {
				this.loadingDeActivateUserAccount = true;
				let payload = {
					id: this.deActivateUserAccount.customer_admin_id,
					type: !this.isForDeleteAccount ? 'deactivate' : 'delete'
				}
				await this.deactivateAccountApi(payload);
				if (!this.isForDeleteAccount) {
					let message = `${this.deActivateUserAccount.isCurrent ? 'Your' : 'An'} account has been deactivated!`;
					this.notificationInfoDeactivate(message);
				} else {
					let message = `${this.deActivateUserAccount.isCurrent ? 'Your' : 'An'} account has been deleted!`;
					this.notificationWarning(message, 'c-warning');
				}

				this.loadingDeActivateUserAccount = false;
				if (this.deActivateUserAccount.isCurrent) {
					this.userLogout();
				} else {
					window.location.reload();
				}
			} catch (e) {
				this.loadingDeActivateUserAccount = false
				this.notificationError(e)
			}
		},
		async reactivateUserAccountFunc() {
			try {
				this.loadingReActivateUserAccount = true
				await this.reactivateAccountApi(this.reActivateUserAccount.customer_admin_id)
				this.closeReActivateUserAccountDialog()
				this.notificationMessage('Account has been reactivated!')
				this.loadingReActivateUserAccount = false

			} catch (e) {
				this.loadingDeActivateUserAccount = false
				this.notificationError(e)
			}
		},
		async deletelocationFunc() {
			try {
				console.log('to be deleted' + this.deleteNewLocationData.id)
				this.loadingDeleteLocationName = true
				await this.deleteLocationApi(this.deleteNewLocationData.id)
				this.GetDeliveryLocations()
				this.notificationMessage('Delivery location deleted successfully')
				this.loadingDeleteLocationName = false
				this.closeDeleteLocationName()
			} catch (e) {
				this.loadingDeleteLocationName = false
				this.notificationError(e)
			}
		},
		async deleteImportNameFunc() {
			try {
				this.loadingDeleteImportName = true
				await this.deleteImportNameApi(this.deleteNewImportData.id)
				await this.fetchAllImportNames(this.companyId)
				this.notificationMessage('Import name deleted successfully')
				this.loadingDeleteImportName = false
				this.closeDeleteImportName()
			} catch (e) {
				this.loadingDeleteImportName = false
				this.notificationError(e)
			}
		},
		getImgUrl(pic) {
			let imageUrl = 'https://staging.shifl.com/storage/'

			if (pic !== undefined && pic !== 'undefined' && pic !== null) {
				if (pic.includes(imageUrl) !== 'undefined' && !pic.includes(imageUrl)) {
					let newImage = imageUrl + pic
					return newImage
				} else {
					return pic
				}
			} else {
				return require('../../../../assets/icons/import-name-logo.svg')
			}
		},
	},
	mounted() {
		//set current page
		this.$store.dispatch("page/setPage", "settings/users");
		this.GetGroupWithUserAndPermissions()
		this.GetDeliveryLocations()
		this.fetchAllImportNames(this.companyId)
		if (this.getUserAndUserGroupActveTab !== null && this.getUserAndUserGroupActveTab !== undefined &&
			this.getUserAndUserGroupActveTab !== 'undefined') {
			if (this.getUserAndUserGroupActveTab == 1) {
				this.activeUserGroupTab = this.getUserAndUserGroupActveTab
			}
		}
	},
	beforeDestroy() {
		this.setUserGroupAsASelectiveTab(0)
	},
};
</script>

<style lang="scss">
// @import "../../../../assets/scss/pages_scss/settings/users.scss";
// @import "../../../../assets/scss/pages_scss/dialog/globalDialog.scss";
// @import "../../../../assets/scss/buttons.scss";
@import '../../../../assets/scss/utilities.scss';
@import '../../../../assets/scss/pages_scss/settings/deliveryLocations.scss';
</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"generic-table-wrapper"},[_c('v-data-table',{staticStyle:{"box-shadow":"none !important"},attrs:{"headers":_vm.headers,"items":_vm.documentFiles,"mobile-breakpoint":"769","page":1,"items-per-page":100,"hide-default-footer":"","fixed-header":"","hide-default-header":""},scopedSlots:_vm._u([{key:"header",fn:function(ref){
var headers = ref.props.headers;
return [_c('thead',[_c('tr',_vm._l((headers),function(item,index){return _c('th',{key:index,style:(("text-transform: uppercase !important;text-align: " + (item.textAlign) + "; color: #69758C;width: " + (item.width) + "; padding-left: " + (index == 0 ? '0px' : '12px') + " !important; padding-right: " + (index == 0 ? '0px' : '12px') + " !important; height: 36px !important; background-color: " + _vm.headerBackground + "; box-shadow: none !important;")),attrs:{"role":"column-header","aria-label":item.text,"scope":"col"}},[_vm._v(" "+_vm._s(item.text)+" ")])}),0)])]}},{key:"item.name",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('span',{style:(("color: " + _vm.textColor + ";"))},[_vm._v(_vm._s(item.name))])])]}},{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"d-flex"},[_c('input-select',{attrs:{"field":item.type,"id":"autocomplete-wrapper","placeholder":"Select Document Type","noBorder":true,"typeOptions":_vm.documentTypes,"selectedValue":item.type},on:{"update:field":function($event){return _vm.$set(item, "type", $event)}}})],1)]}},{key:"item.document_beginning",fn:function(){return [_c('generic-icon',{attrs:{"iconName":"document"}})]},proxy:true},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('a',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){$event.stopPropagation();return _vm.removeFile(item)}}},[_c('generic-icon',{attrs:{"iconName":"close"}})],1)]}},{key:"no-data",fn:function(){return [_c('div',{staticClass:"d-flex flex-center justify-center py-1"},[_c('a',{staticClass:"d-flex pom-upload-document",staticStyle:{"cursor":"pointer"},on:{"click":function($event){$event.stopPropagation();return _vm.addDocuments($event)}}},[_c('generic-icon',{attrs:{"iconName":"upload"}}),_c('span',{staticStyle:{"color":"#1A6D9E","padding-left":"6px"}},[_vm._v(_vm._s("Upload Document"))])],1),_c('input',{ref:"upload_documents_reference",staticStyle:{"display":"none"},attrs:{"type":"file","id":"upload_documents","name":"upload_po_documents","accept":"","multiple":""},on:{"change":function (e) { return _vm.inputChanged(e); }}})])]},proxy:true}],null,true)}),(_vm.documentFiles.length > 0)?_c('div',{staticClass:"d-flex upload-document-bottom-wrapper"},[_c('a',{staticClass:"d-flex",staticStyle:{"cursor":"pointer"},on:{"click":function($event){$event.stopPropagation();return _vm.addDocuments($event)}}},[_c('generic-icon',{attrs:{"iconName":"upload"}}),_c('span',{staticStyle:{"color":"#1A6D9E","padding-left":"6px"}},[_vm._v(_vm._s("Upload Document"))])],1),_c('input',{ref:"upload_documents_reference",staticStyle:{"display":"none"},attrs:{"type":"file","id":"upload_documents","name":"upload_po_documents","accept":"","multiple":""},on:{"change":function (e) { return _vm.inputChanged(e); }}})]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }
var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"max-width":"1040px","content-class":"add-inventory-dialog","retain-focus":false},on:{"click:outside":_vm.close},model:{value:(_vm.inventoryDialog),callback:function ($$v) {_vm.inventoryDialog=$$v},expression:"inventoryDialog"}},[_c('v-card',[_c('v-form',{ref:"form",attrs:{"action":"#"},on:{"submit":function($event){$event.preventDefault();}},model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle)+" "),_c('span',{staticClass:"warehouse-name"},[_vm._v(" "+_vm._s(("[Warehouse: " + (_vm.currentWarehouseSelected !== null ? _vm.currentWarehouseSelected.name : '') + "]"))+" ")])]),_c('v-spacer'),_c('v-btn',{staticClass:"btn-close",attrs:{"icon":"","dark":""},on:{"click":_vm.close}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',{staticClass:"add-inventory-info"},[_c('v-data-table',{staticClass:"elevation-1 add-table",class:(_vm.editedInventoryIndex === -1 && typeof _vm.items!=='undefined' && _vm.items.length > 1) ? '' : 'updating-stock',attrs:{"headers":_vm.headers,"items":_vm.items,"mobile-breakpoint":"769","hide-default-footer":""},scopedSlots:_vm._u([{key:"item.product",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(!_vm.isMobile)?_c('div',{staticClass:"product-selection"},[_c('v-select',{staticClass:"select-product",attrs:{"items":_vm.productListsDropdown,"item-text":"name","item-value":"id","return-object":"","placeholder":"Select Product","outlined":"","rules":_vm.rules,"hide-details":"auto"},on:{"change":function($event){return _vm.updateProductInEach(index)}},model:{value:(_vm.items[index].product.id),callback:function ($$v) {_vm.$set(_vm.items[index].product, "id", $$v)},expression:"items[index].product.id"}})],1):_vm._e(),(_vm.isMobile)?_c('div',{staticClass:"product-mobile-wrapper"},[_c('div',{staticClass:"product-mobile-header"},[_c('p',[_vm._v("Product")]),_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.items.length > 1),expression:"items.length > 1"}],staticClass:"btn remove-btn",attrs:{"icon":""},on:{"click":function($event){return _vm.removeRow(item)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-select',{staticClass:"select-product",attrs:{"items":_vm.productListsDropdown,"item-text":"name","item-value":"id","return-object":"","placeholder":"Select Product","outlined":"","rules":_vm.rules,"hide-details":"auto"},on:{"change":function($event){return _vm.updateProductInEach(index)}},model:{value:(_vm.items[index].product.id),callback:function ($$v) {_vm.$set(_vm.items[index].product, "id", $$v)},expression:"items[index].product.id"}})],1):_vm._e()]}},{key:"item.carton_count",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(!_vm.isMobile)?_c('div',[_c('v-text-field',{staticClass:"table-text-fields icc-carton-count",attrs:{"placeholder":"0","type":"number","outlined":"","rules":_vm.cartonCountRules,"hide-details":"auto"},on:{"keyup":function($event){return _vm.updateTotal(item, index)}},model:{value:(item.carton_count),callback:function ($$v) {_vm.$set(item, "carton_count", $$v)},expression:"item.carton_count"}})],1):_vm._e(),(_vm.isMobile)?_c('div',{staticClass:"product-mobile-wrapper in-line"},[_c('p',[_vm._v(" Carton Count ")]),_c('v-text-field',{staticClass:"table-text-fields shrink icc-carton-count",attrs:{"placeholder":"0","type":"number","outlined":"","rules":_vm.cartonCountRules,"hide-details":"auto"},on:{"keyup":function($event){return _vm.updateTotal(item, index)}},model:{value:(item.carton_count),callback:function ($$v) {_vm.$set(item, "carton_count", $$v)},expression:"item.carton_count"}})],1):_vm._e()]}},{key:"item.in_each_carton",fn:function(ref){
var item = ref.item;
return [(!_vm.isMobile)?_c('div',[_c('v-text-field',{staticClass:"table-text-fields amount",attrs:{"color":"#253041","outlined":"","readonly":"","placeholder":"N/A","hide-details":"auto"},model:{value:(item.in_each_carton),callback:function ($$v) {_vm.$set(item, "in_each_carton", $$v)},expression:"item.in_each_carton"}})],1):_vm._e(),(_vm.isMobile)?_c('div',{staticClass:"product-mobile-wrapper in-line"},[_c('p',[_vm._v(" In Each Carton ")]),_c('v-text-field',{staticClass:"table-text-fields amount",attrs:{"color":"#253041","outlined":"","readonly":"","placeholder":"N/A","hide-details":"auto"},model:{value:(item.in_each_carton),callback:function ($$v) {_vm.$set(item, "in_each_carton", $$v)},expression:"item.in_each_carton"}})],1):_vm._e()]}},{key:"item.total_unit",fn:function(ref){
var item = ref.item;
var index = ref.index;
return [(!_vm.isMobile)?_c('div',[_c('v-text-field',{staticClass:"table-text-fields itu-total-unit",attrs:{"placeholder":"0","type":"number","outlined":"","rules":_vm.totalUnitRules,"hide-details":"auto"},on:{"change":function (item) { return _vm.updateItem(index, item); }},model:{value:(item.total_unit),callback:function ($$v) {_vm.$set(item, "total_unit", $$v)},expression:"item.total_unit"}})],1):_vm._e(),(_vm.isMobile)?_c('div',{staticClass:"product-mobile-wrapper in-line"},[_c('p',[_vm._v(" Total Unit ")]),_c('v-text-field',{staticClass:"table-text-fields shrink  itu-total-unit",attrs:{"placeholder":"0","type":"number","outlined":"","rules":_vm.totalUnitRules,"hide-details":"auto"},on:{"change":function (item) { return _vm.updateItem(index, item); }},model:{value:(item.total_unit),callback:function ($$v) {_vm.$set(item, "total_unit", $$v)},expression:"item.total_unit"}})],1):_vm._e()]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{directives:[{name:"show",rawName:"v-show",value:(_vm.items.length > 1),expression:"items.length > 1"}],staticClass:"btn remove-btn",attrs:{"icon":""},on:{"click":function($event){return _vm.removeRow(item)}}},[_c('v-icon',[_vm._v("mdi-close")])],1)]}}],null,true)}),(!_vm.isEditing)?_c('div',{staticClass:"add-row-wrapper"},[_c('button',{staticClass:"btn-white add-btn",on:{"click":_vm.addRow}},[_vm._v("+ Add Product")])]):_vm._e()],1),_c('v-card-actions',[_c('button',{staticClass:"btn-blue",attrs:{"text":""},on:{"click":_vm.saveInventory}},[(!_vm.isEditing)?_c('span',[(!_vm.loading)?_c('span',[_vm._v("Add Inventory")]):_vm._e(),(_vm.loading)?_c('span',[_vm._v("Adding Inventory...")]):_vm._e()]):_vm._e(),(_vm.isEditing)?_c('span',[(!_vm.loading)?_c('span',[_vm._v("Update Stock")]):_vm._e(),(_vm.loading)?_c('span',[_vm._v("Updating Stock...")]):_vm._e()]):_vm._e()]),_c('button',{staticClass:"btn-white",attrs:{"text":""},on:{"click":_vm.close}},[_vm._v(" Cancel ")])])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
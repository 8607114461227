<template>
    <!-- condition to hide pagination if it has 1 total page only - v-if="getTotalPage > 1" -->
    <div class="pagination-wrapper custom-pagination-component">
        <div class="pagination-overlay" v-if="isLoading"></div>
        <div class="pagination-showing">
            Showing <span class="font-semi-bold">{{ from }}-{{ to }}</span> of {{ totalItems }}
        </div>

        <div class="rows-and-pagination">
            <div class="mr-5 d-flex align-center">
                Row per page
                <v-select
                    class="rows-per-page-select mt-0 pt-0 ml-2"
                    :items="perPageItems"
                    :menu-props="{ contentClass: 'items-rows-per-page-list', top: true, offsetY: true, left: true }"
                    v-model="rowsPerPage"
                    append-icon="mdi-chevron-down"
                    hide-details="auto"
                    outlined
                    dense
                    attach>

                    <template v-slot:item="{ item, on, attrs }">
                        <v-list-item
                            class="d-flex justify-start"
                            style="border: none;"
                            v-on="on"
                            v-bind="attrs">
                            <p class="name d-flex justify-space-between align-center font-regular mb-0">
                                {{ item }}
                                <img class="py-0" 
                                    width="13"
                                    src="@/assets/icons/checkMark-green.svg" 
                                    v-if="item === rowsPerPage"/>
                            </p>
                        </v-list-item>
                    </template>
                </v-select>
            </div>

            <v-pagination
                v-model="getCurrentPage"
                :length="getTotalPage"
                prev-icon="mdi-chevron-left"
                next-icon="mdi-chevron-right"
                :total-visible="!isMobile ? '11' : '5' "
                @input="handlePageChange">
            </v-pagination>
        </div>
    </div>
</template>

<script>
export default {
    props: ['totalPage', 'currentPage', 'isMobile', 'from', 'to', 'totalItems', 'perPage', 'isLoading'],
	name: 'PaginationComponent',
	components: { },
	data: () => ({ 
        perPageItems: [20, 30, 50, 75, 100],
    }),
	computed: {
        getTotalPage: {
            get() {
                return this.totalPage
            },
            set (value) {
                this.$emit('update:totalPage', value)
            }
        },
        getCurrentPage: {
            get() {
                return this.currentPage
            },
            set (value) {
                this.$emit('update:currentPage', value)
            }
        },
        rowsPerPage: {
            get() {
                return this.perPage
            },
            set (value) {
                this.$emit('update:perPage', value)
            }
        }
    },
	methods: {
        async handlePageChange(page) {
            this.$emit('handlePageChange', page)
        },
	},
	mounted() { },
	updated() { }
}
</script>

<style lang="scss">
.custom-pagination-component.pagination-wrapper {
    border-color: #F3F4F7 !important;
    padding: 3px 24px !important;
    border-right: none !important;
    border-left: none !important;
    border-top-width: 1px !important;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 58px;
    color: $shifl-neutral-n90 !important;

    .pagination-overlay {
        position: absolute;
        width: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        background-color: #ffffffa8;
        z-index: 1;
    }

    .pagination-showing {
        font-size: 14px !important;
        color: $shifl-neutral-n90 !important;
    }

    .rows-and-pagination {
        display: flex;
        align-items: center;
        font-size: 14px !important;
        color: $shifl-neutral-n90 !important;

        .rows-per-page-select {
            // width: 60px;
            // border: 1px solid $shifl-primary-b90 !important;
            font-size: 14px;
            padding-top: 0 !important;
            height: 32px;
            border-radius: 4px;
            margin: 0 6px;

            .v-input__control,
            .v-input__slot {
                height: 32px;
                min-height: 32px;
                background-color: #fff !important;
                .v-select__selection.v-select__selection--comma {
                    margin: 0 !important;
                }
                input {
                    padding: 0 !important;
                    width: 4px;
                }
                .v-icon {
                    font-size: 18px !important;
                }
                fieldset {
                    border: none !important;
                }
            }

            .v-input__slot {							
                border: 1px solid $shifl-neutral-n40 !important;
                padding: 0 4px 0 6px;
            }
        }
    }

    .v-pagination {
        button {
            height: 32px !important;
            width: 32px !important;
            min-width: 32px !important;
            letter-spacing: 0;
            text-transform: capitalize;
            color: #253041 !important;
            border: none;

            i {
                font-size: 20px !important;
                margin-top: 4px;
            }

            &.v-pagination__item--active {
                border: 1px solid $shifl-primary-b30 !important;
                background-color: $shifl-primary-b20 !important;
                color: $shifl-primary-b90 !important;
            }
        }
    }
}

.v-menu__content {
    &.items-rows-per-page-list {
        min-width: 96px !important;
        .v-list {
            padding: 8px 0;
            .v-list-item {
                min-height: 36px !important;
                .name {
                    width: 100%;
                }
                &:hover {
                    background-color: $shifl-primary-b10 !important;
                }
                &::before {
                    display: none;
                }
                &.v-list-item--active {
                    background-color: #fff;
                    color: $shifl-neutral-n90 !important;
                }
            }
        }
    }
}
</style>
